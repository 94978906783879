import axios from "../utils/axios";

const KYCService = {};

KYCService.getAllKyc = function (id, user_id, skip, limit) {
  return axios({
    url: `/kyc/${id}?user_id=${user_id}&skip=${skip}&limit=${limit}`,
    method: "GET",
  });
};

export default KYCService;
