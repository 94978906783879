import React from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const Dialer = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  touched,
  name,
}) => {
  // Function to handle increment and decrement
  const handleIncrement = () => {
    setFieldValue(name, (parseInt(values) || 0) + 1);
  };

  const handleDecrement = () => {
    setFieldValue(name, Math.max(0, (parseInt(values) || 0) - 1));
  };

  return (
    <TextField
      fullWidth
      size="small"
      name={name}
      type="number" // set type as number for better UX
      placeholder={name}
      onChange={handleChange}
      value={values}
      helperText={touched[name] && errors[name]}
      error={Boolean(errors[name] && touched[name])}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleDecrement} aria-label="decrement">
              <Remove />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleIncrement} aria-label="increment">
              <Add />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Dialer;
