import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  styled,
} from "@mui/material";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import Scrollbar from "components/ScrollBar";
import { H2, H6 } from "components/Typography";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axios";
import * as Yup from "yup"; // component props interface
import UserService from "services/UserService";
import { LoadingButton } from "@mui/lab";
import VirtualAccountService from "services/VirtualAccountService";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 450,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

const ChangePassword = ({ open, onClose, edit, data, Password }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const initialValues = {
    password: "",
    confirmpassword: "",
  };
  const onhandleClose = () => {
    resetForm();
    onClose();
  };
  const fieldValidationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Enter atleast 6 chracters")
      .required("Password is Required!"),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords mismatch")
      .min(6, "Enter atleast 6 chracters")
      .required("Confirm your Password!"),
  });

  const { values, errors, handleChange, handleSubmit, touched, resetForm } =
    useFormik({
      initialValues,

      validationSchema: fieldValidationSchema,
      onSubmit: async ({ password }) => {
        setIsLoading(true);
        const resp = await VirtualAccountService.updatePassword(data._id, {
          password,
        });
        if (resp && resp.status === true) {
          resetForm();
          onClose();
        }
        setIsLoading(false);
      },
    });
  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <H2 mb={2}>{Password ? "password" : "Change Password"}</H2>

      <form onSubmit={handleSubmit}>
        <Scrollbar
          style={{
            maxHeight: 400,
          }}
        >
          <FlexBox justifyContent="flex-end" gap={2} marginTop={1}>
            <Grid item xs={6}>
              <H6 mb={1}>Password</H6>
              <AppTextField
                fullWidth
                size="small"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                onChange={handleChange}
                value={values.password}
                helperText={touched.password && errors.password}
                error={Boolean(errors.password && touched.password)}
                InputProps={{
                  endAdornment: values.password && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <H6 mb={1}>Confirm Password</H6>
              <AppTextField
                fullWidth
                size="small"
                name="confirmpassword"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm password"
                onChange={handleChange}
                value={values.confirmpassword}
                helperText={touched.confirmpassword && errors.confirmpassword}
                error={Boolean(
                  errors.confirmpassword && touched.confirmpassword
                )}
                InputProps={{
                  endAdornment: values.confirmpassword && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </FlexBox>
        </Scrollbar>

        <FlexBox justifyContent="flex-end" gap={2} marginTop={4}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={onhandleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="small"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </FlexBox>
      </form>
    </StyledAppModal>
  );
};

export default ChangePassword;
