import { ArrowOutward, Edit } from "@mui/icons-material";
import {
  IconButton,
  styled,
  Button,
  Box,
  Switch,
  FormControlLabel,
} from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span, Tiny } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import FlexBox from "components/flexbox/FlexBox";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils/currencies";

import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag from "components/shared/CountryFlag";
import { minWidth } from "@mui/system";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import CryptoImg from "components/shared/CryptoImg";
import Platform from "components/shared/Platform";
import PaxfulAccount from "components/shared/PaxfulAccount";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import PaxfullAccount from "../overview/PaxfullAccount";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));

const IOSSwitch = styled((SwitchProps) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...SwitchProps}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "##143b76",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#143b76",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "##143b76",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

const columnShape = (onClick) => [
  {
    Header: " CRYPTO",
    accessor: "offer",
    Cell: ({ row }) => {
      const { is_active } = row.original;
      return (
        <Switch
          sx={{
            // opacity: "0.4",
            cursor: "default",
          }}
          disabled
          checked={is_active}
          // onChange={() => handleSwitchChange(_id, !is_active)}
          inputProps={{ "aria-label": "controlled" }}
        />
      );
    },
  },
  {
    Header: " CRYPTO",
    accessor: "offer.crypto_currency_code",
    Cell: ({ value }) => (
      // <Paragraph fontSize={13} color="text.secondary">
      //   {value}
      // </Paragraph>
      <CryptoImg currency={value} size={25} />
    ),
  },
  {
    Header: "PAYMENT METHOD",
    accessor: "offer.payment_method_name",
    minWidth: 200,
    Cell: ({ row, value }) => {
      const { offer, offer_type, paxful_offer_hash, account } = row.original;
      return (
        <>
          {
            <Box>
              <FlexBox justifyContent="start" alignItems="center">
                <Paragraph fontWeight={700} mr={0.5}>
                  {offer?.payment_method_name}
                </Paragraph>
                <Platform platform={account.platform} size={20} />
              </FlexBox>
              <Box>
                <Span fontSize={12}>
                  {offer?.payment_method_label?.toUpperCase()}
                </Span>
              </Box>
              <FlexBox>
                <Small
                  sx={{
                    width: 50,
                    fontSize: 10,
                    color: "#fff",
                    borderRadius: "4px",
                    textAlign: "center",
                    padding: ".1rem 1rem",
                    backgroundColor:
                      offer_type == "buy" ? "success.main" : "error.main",
                  }}
                >
                  {offer_type}
                </Small>
                <Tiny fontSize={12} fontWeight={500}>
                  <a
                    style={{
                      marginLeft: "3px",
                    }}
                    href={
                      `https://${account.platform}.com/trade/` +
                      paxful_offer_hash
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {paxful_offer_hash}
                    <ArrowOutward
                      sx={{
                        fontSize: 14,
                        marginLeft: "1px",
                      }}
                    />
                  </a>
                </Tiny>
              </FlexBox>
              <FlexBox mt={0.5}>
                <FlexBox>
                  {offer?.tags.map((v, i) => {
                    return (
                      <>
                        <Small
                          sx={{
                            fontSize: 10,
                            color: "grey.500",
                            borderRadius: "4px",
                            textAlign: "center",
                            padding: "2px 4px",
                            margin: "0px 2px 2px  0px",
                            backgroundColor: "grey.200",
                          }}
                        >
                          {v.name}
                        </Small>
                      </>
                    );
                  })}
                </FlexBox>
              </FlexBox>
            </Box>
          }
        </>
      );
    },
  },
  {
    Header: "ACCOUNT",
    accessor: "account.paxful_username",
    Cell: ({ row }) => {
      const { account } = row.original;
      return (
        <FlexBox>
          {account && <PaxfulAccount item={account} size={25} />}
        </FlexBox>
      );
    },
  },
  {
    Header: "RANGE",
    accessor: "is_active",
    Cell: ({ row }) => {
      const { offer } = row.original;
      return (
        <FlexBox>
          <Paragraph mr={0.5}>
            {formatCurrency(
              offer.fiat_amount_range_min,
              offer.fiat_currency_code
            )}
          </Paragraph>{" "}
          -
          <Paragraph ml={0.5}>
            {formatCurrency(
              offer.fiat_amount_range_max,
              offer.fiat_currency_code
            )}
          </Paragraph>
          {/* <Paragraph fontWeight={500}>{offer.fiat_amount_range_min}</Paragraph>
          {"-"}
          <Paragraph fontWeight={500}>{offer.fiat_amount_range_max}</Paragraph> */}
        </FlexBox>
      );
    },
  },
  {
    Header: "MARGIN",
    accessor: "offer.dd",
    minWidth: 200,
    Cell: ({ row }) => {
      const { offer } = row.original;
      return (
        <Paragraph fontSize={13} color="text.secondary">
          {offer.margin}%
        </Paragraph>
      );
    },
  },
  // {
  //   Header: "Offer Hash",
  //   accessor: "paxful_offer_hash",
  //   minWidth: 200,
  //   Cell: ({ value }) => (
  //     <Tiny fontSize={12} fontWeight={500}>
  //       <a
  //         style={{
  //           marginLeft: "3px",
  //         }}
  //         href={"https://paxful.com/trade/" + value}
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         {value}
  //         <ArrowOutward
  //           sx={{
  //             fontSize: 14,
  //             marginLeft: "1px",
  //           }}
  //         />
  //       </a>
  //     </Tiny>
  //   ),
  // },
  // {
  //   Header: "PLATFORM",
  //   accessor: "account.platform",
  //   Cell: ({ value }) => (
  //     <>
  //       <Platform size={30} platform={value} />
  //     </>
  //   ),
  // },

  // {
  //   Header: "Range",
  //   accessor: "id",
  //   minWidth: 200,
  //   Cell: ({ row }) => {
  //     const { fiat_amount_range_max, fiat_amount_range_min, fiat_currency_code } =
  //       row.original.offer;
  //     return (
  //       <>
  //         <Paragraph fontSize={13} color="text.secondary">
  //           {formatCurrency(fiat_amount_range_max, fiat_currency_code)} - {formatCurrency(fiat_amount_range_min,fiat_currency_code)}
  //         </Paragraph>
  //       </>
  //     );
  //   },
  // },
  // {
  //   Header: "Margin",
  //   accessor: "offer.margin",
  //   minWidth: 200,
  //   Cell: ({ value }) => (
  //     <Paragraph fontSize={13} color="text.secondary">
  //       {value}%
  //     </Paragraph>
  //   ),
  // },

  // {
  //   Header: "Price Per Crypto",
  //   accessor: "offer.fiat_price_per_crypto",
  //   minWidth: 200,
  //   Cell: ({ row }) => {
  //     const { fiat_currency_code, fiat_price_per_crypto } =
  //       row.original.offer;
  //     return <Paragraph fontSize={13} color="text.secondary">
  //       {formatCurrency(fiat_price_per_crypto, fiat_currency_code)}
  //     </Paragraph>
  //   },
  // },
  {
    Header: "ACTIONS",
    accessor: "offer_type",
    minWidth: 200,
    Cell: ({ row }) => {
      const { _id, is_active, account } = row.original;
      return (
        <>
          <StyledButton
            onClick={() => onClick(row.original)}
            variant="outlined"
            color="primary"
            size="medium"
            disabled={account.is_deleted}
          >
            Edit Offer
          </StyledButton>
          {/* <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                disabled
                checked={is_active}
                // onChange={() => handleSwitchChange(_id, !is_active)}
                inputProps={{ "aria-label": "controlled" }}
              />
            } */}
        </>
      );
    },
  },
];
export default columnShape;
