import { forwardRef } from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    flexGrow: 1,
    // backgroundColor: "#fff",
  },
}));

const PhoneInput = (props, ref) => {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      InputProps={{
        className: classes.input,
      }}
      inputRef={ref}
      size="small"
      // label="Phone Number"
      variant="outlined"
      name="phone_number"
    />
  );
};

export default forwardRef(PhoneInput);
