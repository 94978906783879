import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import { H5, Paragraph, Small, Tiny } from "components/Typography";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import AppModal from "components/AppModal";
import AppTextField from "components/input-fields/AppTextField";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import Scrollbar from "components/ScrollBar";
import { H3, H6, H4 } from "components/Typography";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axios";
import * as Yup from "yup"; // component props interface

import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";

import { useTranslation } from "react-i18next";
import JSONPretty from "react-json-pretty";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward, Close } from "@mui/icons-material";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import CryptoImg from "components/shared/CryptoImg";
import { formatCurrency } from "utils/currencies";
import Platform from "components/shared/Platform";

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 650,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

function ViewOfferModal({ data, open, onClose }) {
  const { t } = useTranslation();
  console.log("offer data", data);
  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <Box pb={4}>
        <FlexBetween>
          <H3 mb={3}>{t("Offer")}</H3>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </FlexBetween>
        <Grid container spacing={0} mt={0}>
          {/* <Grid item md={4} xs={6}>
            <H6 color="text.secondary">{t("Account")}</H6>
            <FlexBox justifyContent="start" alignItems="center">
              <PaxfulProfilePic size={35} account={data.account} />
              {data.account.paxful_username && (
                <Tiny fontSize={12} fontWeight={500}>
                  <a
                    style={{
                      marginLeft: "3px",
                    }}
                    href={
                      "https://paxful.com/user/" + data.account.paxful_username
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.account.paxful_username}
                    <ArrowOutward
                      sx={{
                        fontSize: 14,
                        marginLeft: "1px",
                      }}
                    />
                  </a>
                </Tiny>
              )}
            </FlexBox>
          </Grid> */}
          {/* <Grid item md={4} xs={6}>
            <H6 color="text.secondary">{t("Status")}</H6>
            <Small
              sx={{
                width: 100,
                fontSize: 10,
                color: "#fff",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".2rem 1rem",
                backgroundColor:
                  data.is_active == true ? "success.main" : "error.main",
              }}
            >
              {data.is_active == true ? "active" : " not active"}
            </Small>
          </Grid> */}
          <Grid item md={4} xs={6}>
            <H6 color="text.secondary">{t("Payment Method")}</H6>
            <FlexBox alignItem="center">
              <Small mr={0.2}>{data.offer.payment_method_name}</Small>
              <Platform size={20} platform={data.account.platform} />
            </FlexBox>
            {/* <H4 fontWeight={500}>{data.offer.payment_method_name}</H4>
            <Platform size={20} platform={data.account.platform}/> */}
          </Grid>
          <Grid item md={4} xs={6}>
            <H6 color="text.secondary">{t("Offer Type")}</H6>
            <Small
              sx={{
                width: 100,
                fontSize: 10,
                color: "#fff",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".2rem 1rem",
                backgroundColor:
                  data.offer_type == "buy" ? "success.main" : "error.main",
              }}
            >
              {data.offer_type}
            </Small>
          </Grid>
          <Grid item md={4} xs={6}>
            <H6 color="text.secondary">{t("Crypto")}</H6>
            {/* <PaxfulAccountPreview account_id={data.id} /> */}
            {/* <H4 fontWeight={500}>{data.offer.crypto_currency_code}</H4> */}
            <FlexBox alignItem="center">
              <CryptoImg currency={data.offer.crypto_currency_code} size={20} />
              <Small ml={1}>{data.offer.crypto_currency_code}</Small>
            </FlexBox>
          </Grid>
        </Grid>

        <Divider
          sx={{
            my: 3,
          }}
        />

        <Grid container spacing={0} mt={0}>
          <Grid item md={4} xs={6}>
            <H6 color="text.secondary">{t("Status")}</H6>
            <Small
              sx={{
                width: 100,
                fontSize: 10,
                color: "#fff",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".2rem 1rem",
                backgroundColor:
                  data.is_active == true ? "success.main" : "error.main",
              }}
            >
              {data.is_active == true ? "active" : " not active"}
            </Small>
          </Grid>
          <Grid item md={4} xs={6}>
            <FlexBox>
              <div>
                <H6 color="text.secondary">{t("Offer Hash")}</H6>
                {/* {data.is_delivered == true ? "Success" :  "Fail"} */}
                <Tiny fontSize={13} fontWeight={500}>
                  <a
                    style={{
                      marginLeft: "3px",
                    }}
                    href={"https://paxful.com/trade/" + data.paxful_offer_hash}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.paxful_offer_hash}
                    <ArrowOutward
                      sx={{
                        fontSize: 13,
                        marginLeft: "1px",
                      }}
                    />
                  </a>
                </Tiny>
              </div>
            </FlexBox>
          </Grid>
          <Grid item md={4} xs={6}>
            <H6 color="text.secondary">{t("Range")}</H6>
            <FlexBox>
              <H5 fontWeight={500}>
                {formatCurrency(data.offer.fiat_amount_range_min)}
                {/* {data.offer.fiat_amount_range_min} */}
              </H5>
              {"-"}
              <H5 fontWeight={500}>
                {formatCurrency(data.offer.fiat_amount_range_max)}
              </H5>
            </FlexBox>
          </Grid>
        </Grid>

        <Divider
          sx={{
            my: 3,
          }}
        />

        <Grid container spacing={0} mt={0}>
          <Grid item md={4} xs={6}>
            <H6 color="text.secondary">{t("Price Per Crypto")}</H6>
            <H5 fontWeight={500}>
              {formatCurrency(data.offer.fiat_price_per_crypto)}
            </H5>
          </Grid>
          <Grid item md={4} xs={6}>
            <H6 color="text.secondary">{t("Payment Window")}</H6>
            <H5 fontWeight={500}>
              {formatCurrency(data.offer.payment_window)}
              {""} min
            </H5>
          </Grid>
        </Grid>

        <Divider
          sx={{
            my: 3,
          }}
        />
        <Grid container spacing={0} mt={0}>
          <Grid>
            <H6 color="text.secondary">{t("Tags")}</H6>
            <FlexBox>
              {data.offer.tags.map((v, i) => {
                console.log("AdminOffer", data.offer);
                return (
                  <>
                    <Small
                      sx={{
                        fontSize: 10,
                        color: "grey.500",
                        borderRadius: "4px",
                        textAlign: "center",
                        padding: ".2rem 1rem",
                        margin: "0px 2px",
                        backgroundColor: "grey.200",
                      }}
                    >
                      {v.name}
                    </Small>
                  </>
                );
              })}
            </FlexBox>
          </Grid>
        </Grid>
      </Box>
    </StyledAppModal>
  );
}

export default ViewOfferModal;
