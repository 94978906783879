import { SERVER_BASE_URL } from "config";
import React from "react";

function CryptoImg({ currency, size, className }) {
  return (
    <>
      {currency && (
        <img
          style={{
            height: size,
            width: size,
          }}
          src={`/media/svg/coins/${currency.toLowerCase()}.svg`}
          alt=""
          className={className}
        />
      )}
    </>
  );
}

CryptoImg.defaultProps = {
  size: "30px",
};

export default CryptoImg;
