import React from "react";
import FlexBox from "./flexbox/FlexBox";

function ExternalLink({ link, className, label }) {
  return (
    <>
      <a
        style={{
          display: "flex",
          alignItems: "center",
        }}
        href={link}
        target="_blank"
        rel={"noreferrer"}
        className={`external-link text-primary ${className ? className : " "}`}
      >
        <span className="external-link-label">{label}</span>
        <span class="external-link-icon svg-icon svg-icon-primary svg-icon-3">
          <svg
            width="22"
            height="24"
            viewBox="0 0 24 24"
            fill="#2499EF"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              opacity="0.5"
              x="16.9497"
              y="8.46448"
              width="13"
              height="2"
              rx="1"
              transform="rotate(135 16.9497 8.46448)"
              fill="#2499EF"
            ></rect>
            <path
              d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
              fill="#2499EF"
            ></path>
          </svg>
        </span>
      </a>
    </>
  );
}

export default ExternalLink;
