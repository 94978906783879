import React, { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useEffect } from "react";
import { useState } from "react";
import OtpInput from "react-otp-input";

function OTPInput({ onComplete, length, inputClass, showPasteButton }, ref) {
  const [value, setValue] = useState("");

  const handleInputChange = (otp) => {
    setValue(otp);
  };

  const pasteFromClipboard = async () => {
    const numbersOnly = /^-?\d+(\.\d*)?$/;
    let text = await navigator.clipboard.readText();
    if (numbersOnly.test(text) && text.length == length) {
      setValue(text);
      onComplete(text);
    }
  };

  useEffect(() => {
    console.log("complete");
    if (value.length == length) {
      console.log("complete 22");

      onComplete(value);
    }
  }, [value]);

  return (
    <>
      <OtpInput
        value={value}
        onChange={handleInputChange}
        numInputs={length}
        inputType={"number"}
        shouldAutoFocus={true}
        inputStyle={"form-control-solid form-control"}
        renderInput={(props) => <input {...props} />}
      />
      {showPasteButton && (
        <div className="text-center mt-3">
          <a
            className="text-primary fw-bold cursor-pointer"
            onClick={pasteFromClipboard}
          >
            Paste
          </a>
        </div>
      )}
    </>
  );
}

OTPInput.defaultProps = {
  length: 4,
  showPasteButton: false,
};

export default forwardRef(OTPInput);
