import axios from "../utils/axios";

const PaxfultransactionService = {};

PaxfultransactionService.getPaxfulTransactions = function (
  id,
  page,
  limit,
  account_id,
  type
) {
  return axios({
    url: `/paxful-transactions/${id}?page=${page}&limit=${limit}&account_id=${account_id}&type=${type}`,
    method: "GET",
  });
};

export default PaxfultransactionService;
