import { useEffect, useState } from "react";
import { Button, Grid, styled, Select, InputLabel, Box } from "@mui/material";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import Scrollbar from "components/ScrollBar";
import { H2, H6, Paragraph } from "components/Typography";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axios";
import * as Yup from "yup"; // component props interface
import FormControl from "@mui/material/FormControl";
import countries from "../../../data/country";
import Zones from "../../../data/timezones";
import MenuItem from "@mui/material/MenuItem";
import { openStdin } from "process";
import { saveUsers } from "../../../redux/actions/user";
import { connect } from "react-redux";
import UserService from "services/UserService";
import { LoadingButton } from "@mui/lab";
import VAService from "services/virtualAssistantService";
import CustomPhoneNumber from "./PhoneNumber";
import PhoneInput from "react-phone-number-input";

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 650,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

const AddEmployeeModal = (
  {
    open,
    onClose,
    edit,
    data,
    onSuccess,
    subuser,
    password,
    discord,
    getUserTeamMember,
  },
  props
) => {
  const [discordUser, setDiscordUser] = useState(null);
  const getDiscordUser = async () => {
    let resp = await VAService.getDiscordUser();
    setDiscordUser(resp);
  };

  useEffect(() => {
    getDiscordUser();
  }, []);
  const timeZoneOptions = Object.keys(Zones.zones).map((zone) => zone);
  const [isLoading, setIsLoading] = useState(false);
  // const [Role, setRole] = useState(null);

  const initialValues = {
    firstname: data && data.first_name ? data.first_name : "",
    lastname: data && data.last_name ? data.last_name : "",
    username: data && data.username ? data.username : "",
    email: data && data.email ? data.email : null,
    phone_number: data && data.mobile_number ? data.mobile_number : "",
    password: "",
    confirmpassword: "",
    country: data && data.country ? data.country : "US",
    timezone: data && data.timezone ? data.timezone : "America/New_York",
    discord: data && data.discord_username ? data.discord_username : "",
  };

  if (edit && subuser) {
    initialValues["role"] = data.role;
  }

  const validations = {
    // firstname: Yup.string()
    //   .min(1, "Too Short")
    //   .required("FirstName is Required!"),
    // lastname: Yup.string()
    //   .min(1, "Too Short")
    //   .required("Lastname is Required!"),
    username: Yup.string()
      .min(1, "Too Short")
      .required("Username is Required!"),
    email: data.email && Yup.string().required("Email is Required!"),
    phone_number:
      data.mobile_number && Yup.string().required("Mobile Number is Required!"),
  };

  if (!edit) {
    validations["password"] = Yup.string()
      .min(6, "Enter atleast 6 chracters.")
      .required("Password is Required!");
    validations["confirmpassword"] = Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords mismatch")
      .min(6, "Enter atleast 6 chracters.")
      .required("Confirm your Password!");
  }
  if (subuser) {
    validations["role"] = Yup.string().required("Role is Required!");
  }

  const fieldValidationSchema = Yup.object().shape(validations);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: fieldValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log("values", values);
      setIsLoading(true);

      let resp;
      if (edit) {
        console.log("values", values);
        resp = await UserService.updateUser(data._id, values);
      } else {
        resp = await UserService.createUser(values);
      }

      if (resp && resp.status === true) {
        resetForm();
        onClose();
        onSuccess();
        if (edit) {
          setIsLoading(false);
          getUserTeamMember();
        }
      }
      console.log("isLoad2");
      setIsLoading(false);
    },
  });

  const onhandleClose = () => {
    resetForm();
    onClose();
  };

  const rolesOptions = [
    { name: "Manager", value: "manager" },
    { name: "Employee", value: "employee" },
  ];

  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <H2 mb={2}>{edit ? "Edit User" : "Invite User"}</H2>

      <form onSubmit={handleSubmit}>
        {/* <Scrollbar
          style={{
            maxHeight: 500,
          }}
        > */}
        <Box
          sx={{
            minHeight: "260px",
          }}
        >
          {/* <Grid item xs={6}>
            {subuser && data.role !== "admin" && (
              <FormControl sx={{ width: 290, padding: 0, mb: 1 }}>
                <H6 mb={1}>Role</H6>

                <Select
                  sx={{ height: 39 }}
                  onChange={handleChange}
                  name="role"
                  value={values.role}
                >
                  {rolesOptions.map(({ name, value }) => (
                    <MenuItem key={name} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid> */}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <H6 mb={1}>UserName</H6>
              <AppTextField
                fullWidth
                size="small"
                name="username"
                placeholder="Username"
                value={values.username}
                onChange={handleChange}
                helperText={touched.username && errors.username}
                error={Boolean(errors.username && touched.username)}
              />
            </Grid>

            {/* <Grid item xs={6}>
              <H6 mb={1}>FirstName</H6>
              <AppTextField
                fullWidth
                size="small"
                name="firstname"
                placeholder="FirstName"
                value={values.firstname}
                onChange={handleChange}
                helperText={touched.firstname && errors.firstname}
                error={Boolean(errors.firstname && touched.firstname)}
              />
            </Grid>

            <Grid item xs={6}>
              <H6 mb={1}>LastName</H6>
              <AppTextField
                fullWidth
                size="small"
                name="lastname"
                placeholder="LastName"
                onChange={handleChange}
                value={values.lastname}
                helperText={touched.lastname && errors.lastname}
                error={Boolean(errors.lastname && touched.lastname)}
              />
            </Grid> */}

            <Grid item xs={6}>
              <H6 mb={1}>Email </H6>
              <AppTextField
                fullWidth
                size="small"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                value={values.email}
                helperText={touched.email && errors.email}
                error={Boolean(errors.email && touched.email)}
              />
            </Grid>
            <Grid item xs={6}>
              <H6 mb={1}>Phone Number</H6>
              {/* <AppTextField
                fullWidth
                size="small"
                name="phone_number"
                placeholder="Phone"
                onChange={handleChange}
                value={values.phone_number}
                helperText={touched.phone_number && errors.phone_number}
                error={Boolean(errors.phone_number && touched.phone_number)}
              /> */}
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="US"
                labels={"us"}
                onChange={(phoneNumber) =>
                  handleChange({
                    target: { name: "phone_number", value: phoneNumber },
                  })
                }
                value={values.phone_number}
                inputComponent={CustomPhoneNumber}
              />
              {errors.phone_number && (
                <Paragraph
                  mt={1}
                  sx={{
                    color: "red",
                    fontSize: "10px",
                  }}
                >
                  Enter a valid Phone Number{" "}
                </Paragraph>
              )}
            </Grid>

            {!edit && (
              <>
                <Grid item xs={6}>
                  <H6 mb={1}>Password</H6>
                  <AppTextField
                    fullWidth
                    size="small"
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(errors.password && touched.password)}
                  />
                </Grid>

                <Grid item xs={6}>
                  <H6 mb={1}>Confirm Password</H6>
                  <AppTextField
                    fullWidth
                    size="small"
                    name="confirmpassword"
                    type="Password"
                    placeholder="Confirmpassword"
                    onChange={handleChange}
                    value={values.confirmpassword}
                    helperText={
                      touched.confirmpassword && errors.confirmpassword
                    }
                    error={Boolean(
                      errors.confirmpassword && touched.confirmpassword
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <H6 mb={1}>Country</H6>
              {/* <AppTextField
                fullWidth
                size="small"
                name="country"
                placeholder="Country"
                onChange={(e)=>{
                  console.log(e)
                }}
                value={values.country}
                helperText={touched.country && errors.country}
                error={Boolean(errors.country && touched.country)}
              /> */}
              <FormControl sx={{ width: 290, padding: 0 }}>
                <Select
                  sx={{ height: 39 }}
                  onChange={handleChange}
                  name="country"
                  value={values.country}
                >
                  {countries.map(({ name, code }) => (
                    <MenuItem key={name} value={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <H6 mb={1}>TimeZone</H6>
              <FormControl sx={{ width: 290, padding: 0 }}>
                <Select
                  sx={{ height: 39 }}
                  onChange={handleChange}
                  value={values.timezone}
                  name="timezone"
                >
                  {timeZoneOptions.map((zone) => (
                    <MenuItem key={zone} value={zone}>
                      {zone}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* {discord && (
              <>
                <Grid item xs={6}>
                  <FormControl sx={{ width: 290, padding: 0 }}>
                    <H6 mb={1}>Discord</H6>
                    <Select
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{ height: 39 }}
                      onChange={handleChange}
                      name="discord"
                      value={values.discord.username}
                      defaultValue={""}
                      displayEmpty
                      renderValue={(selected) => {
                        console.log("selected", selected);

                        if (!selected) {
                          return (
                            <>
                              {data.discord_username
                                ? data.discord_username
                                : "Select a discord Members"}
                            </>
                          );
                        }

                        return selected.username;
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select a discord Members
                      </MenuItem>
                      {discordUser &&
                        discordUser.map((item) => (
                          <MenuItem key={item.username} value={item}>
                            {`${item.username} #${item.discriminator}`}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )} */}
          </Grid>
        </Box>

        {/* </Scrollbar> */}

        <FlexBox justifyContent="flex-end" gap={2} marginTop={4}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={onhandleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="small"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </FlexBox>
      </form>
    </StyledAppModal>
  );
};

export default AddEmployeeModal;
