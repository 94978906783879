import PersonOffIcon from "@mui/icons-material/PersonOff";
import { IconButton } from "@mui/material";

const PersonOffButton = (props) => {
  return (
    <IconButton {...props}>
      <PersonOffIcon
        sx={{
          fontSize: 17,
        }}
      />
    </IconButton>
  );
};

export default PersonOffButton;
