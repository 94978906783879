import axios from "../utils/axios";

const CronJobService = {};

CronJobService.getCronJob = function () {
  return axios({
    url: `/cron-job`,
    method: "GET",
  });
};

export default CronJobService;
