import React from "react";
import { FromNow } from "components/shared/FormattedDate";
import { H5, H6, Small, Tiny } from "components/Typography";
import moment from "moment-timezone";

function OnlineStatus({ member, is_live, team, color, className }) {
  let last_active = member.last_active;

  if (!is_live) {
    const _member = team?.find((user) => user._id === member._id);
    if (_member) {
      last_active = _member.last_active;
    }
  }

  const last_seen = moment(last_active);
  //const five_min_ago = moment().subtract(5, "minutes");
  const one_min_ago = moment().subtract(30, "seconds"); //to fix in a minute bug

  return (
    <>
      {last_active ? (
        last_seen.isAfter(one_min_ago) ? (
          <Small
            sx={{
              width: 100,
              fontSize: 10,
              color: "#fff",
              borderRadius: "4px",
              textAlign: "center",
              padding: ".2rem 1rem",
              backgroundColor: "grey.500",
            }}
          >
            a few seconds ago
          </Small>
        ) : (
          <Small
            sx={{
              width: 100,
              fontSize: 10,
              color: "#fff",
              borderRadius: "4px",
              textAlign: "center",
              padding: ".2rem 1rem",
              backgroundColor: "grey.500",
            }}
          >
            <FromNow date={last_active} />
          </Small>
        )
      ) : (
        <Small
          sx={{
            width: 100,
            fontSize: 10,
            color: "#fff",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor: "grey.500",
          }}
        >
          Never
        </Small>
      )}
    </>
  );
}

export default OnlineStatus;
