import React from "react";
import TableCell from "@mui/material/TableCell";
import { alpha, Box } from "@mui/system";
import { H5, H6, Small, Tiny } from "components/Typography";
import { styled } from "@mui/material";

function NoResultFound() {
  return (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Box>
        <Tiny fontSize={12} fontWeight={500}>
          No results found.
        </Tiny>
      </Box>
    </div>
  );
}

export default NoResultFound;
