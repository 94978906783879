import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { Fragment, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router";
import DashboardHeader from "../layout-parts/DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import HiringRequestService from "services/HireRequestService";
import { UserContextData } from "App";
import ProfileService from "services/ProfileService";
import { useLocation } from "react-router-dom";

const DashboardLayout = ({ children }) => {
  const location = useLocation();

  const { setHireRequestCount, setUserData } = useContext(UserContextData);

  const [sidebarCompact, setSidebarCompact] = useState(true);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  const handleCompactToggle = () => setSidebarCompact(!sidebarCompact);

  const handleMobileDrawerToggle = () =>
    setShowMobileSideBar((state) => !state); // dashboard body wrapper custom style

  const customStyle = {
    width: `calc(100% - ${sidebarCompact ? "86px" : "280px"})`,
    marginLeft: sidebarCompact ? "86px" : "280px",
  };
  const getUnassignedHireRequest = async () => {
    const resp = await HiringRequestService.unassignedHireRequest();
    console.log("resp", resp);
    if (resp?.status) {
      setHireRequestCount(resp.data);
    }
  };

  const getProfile = async () => {
    const response = await ProfileService.getProfile();
    setUserData(response);
  };

  useEffect(() => {
    getUnassignedHireRequest();
    getProfile();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Fragment>
      <DashboardSidebar
        sidebarCompact={sidebarCompact}
        showMobileSideBar={showMobileSideBar}
        setSidebarCompact={handleCompactToggle}
        setShowMobileSideBar={handleMobileDrawerToggle}
      />

      <LayoutBodyWrapper sx={customStyle}>
        <DashboardHeader
          sidebarCompact={sidebarCompact}
          setSidebarCompact={handleCompactToggle}
          setShowSideBar={handleCompactToggle}
          setShowMobileSideBar={handleMobileDrawerToggle}
        />

        {children || <Outlet />}
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default DashboardLayout;
