import axios from "../utils/axios";

const RVAService = {};

RVAService.getRVA = function (page, search) {
  return axios({
    url: `/atlantis-va?page=${page}&search=${search}` ,
    method: `GET`,
  })
}

RVAService.createRVA = function (data) {
    return axios({
        url: `/atlantis-va`,
        method: `POST`,
        data,
    })
}

RVAService.updateRVA = function (id, data) {
    return axios({
        url: `/atlantis-va/${id}`,
        method: `PUT`,
        data,
    })
}

RVAService.deleteRVA = function (id, type) {
    return axios({
        url: `/atlantis-va/${id}?type=${type}`,
        method: `DELETE`,
    })
}

export default RVAService;