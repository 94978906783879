import { useEffect, useState } from "react";
import { TabContext, TabList } from "@mui/lab";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  styled,
  Tab,
} from "@mui/material";
import { Box } from "@mui/system";
import FlexBox from "components/flexbox/FlexBox";
import { H5, Paragraph } from "components/Typography";
import IconWrapper from "components/IconWrapper";
import Add from "icons/Add";
import GroupSenior from "icons/GroupSenior";
import { useTranslation } from "react-i18next"; // styled components
import AddEmployeeModal from "page-sections/data-table/dataTableV2/AddEmployeeModal";
import InviteUserModal from "page-sections/data-table/dataTableV2/InviteUserModal";
import FlexBetween from "components/flexbox/FlexBetween";
import { useNavigate } from "react-router-dom";

const Wrapper = styled(Box)(() => ({
  // display: "flex",
  // flexWrap: "wrap",
  // alignItems: "center",
  // justifyContent: "space-between",
}));
const TabListWrapper = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(727)]: {
    order: 3,
  },
})); // --------------------------------------------------------------------

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));
// --------------------------------------------------------------------
const HeadingArea = ({
  value,
  changeTab,
  getUsers,
  users,
  vendor_count,
  option,
  handleTypeChange,
}) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation();
  return (
    <Wrapper gap={1}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <FlexBox alignItems="center">
          <IconWrapper>
            <GroupSenior
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t(`Vendors ( ${vendor_count ? vendor_count : 0} )`)}</H5>
        </FlexBox>

        {/* <TabContext value={value}>
        <TabListWrapper variant="scrollable" onChange={changeTab}>
          <Tab disableRipple label={t("All Users")} value="" />
          <Tab disableRipple label={t("Editor")} value="editor" />
          <Tab disableRipple label={t("Contributor")} value="contributor" />
          <Tab disableRipple label={t("Administrator")} value="administrator" />
          <Tab disableRipple label={t("Subscriber")} value="subscriber" />
        </TabListWrapper>
      </TabContext> */}

        <StyledStack alignItems="center" direction="row" gap={2}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              sx={{
                "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "10px !important", // Remove padding from the Select component
                  },
              }}
              value={option}
              onChange={handleTypeChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <Paragraph>All</Paragraph>
              </MenuItem>
              <MenuItem value={"active"}>
                {" "}
                <Paragraph>Active</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"delete"}>
                {" "}
                <Paragraph>Deleted</Paragraph>{" "}
              </MenuItem>
            </Select>
          </FormControl>

          {/* <Button
            variant="contained"
            size="small"
            endIcon={<Add />}
            onClick={() => setOpenModal(true)}
          >
            {t("Add User")}
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => navigate("/dashboard/pending-invitations")}
          >
            {t("Pending Invite")}
          </Button> */}
        </StyledStack>
        <InviteUserModal
          onSuccess={getUsers}
          open={openModal}
          invite={true}
          onClose={() => setOpenModal(false)}
        />
        {/* <AddEmployeeModal
        onSuccess={onSuccess}
        open={openModal}
        invite={true}
        onClose={() => setOpenModal(false)}
      /> */}
      </FlexBetween>
    </Wrapper>
  );
};

export default HeadingArea;
