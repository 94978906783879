import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  styled,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import DateRange from "icons/DateRange";
import FlexBox from "components/flexbox/FlexBox";
import Bratislava from "icons/Bratislava";
import MapMarkerIcon from "icons/MapMarkerIcon";
import FlexBetween from "components/flexbox/FlexBetween";
import IconButton from "@mui/material/IconButton";
import AvatarBadge from "components/avatars/AvatarBadge";
import { H4, H5, Tiny } from "components/Typography";
import AppTextField from "components/input-fields/AppTextField";
import LinearProgress from "@mui/material/LinearProgress";
import { CameraAlt, KeyboardArrowDown } from "@mui/icons-material";
import * as Yup from "yup"; // styled components
import Database from "services/Database";
import { useState, useEffect } from "react";

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  position: "relative",
  padding: "0.6rem 1.5rem",
  justifyContent: "flex-start",
  fontWeight: 500,
  color: theme.palette.text.primary,
}));

const SyncDatabase = () => {
  const syncCollection = async (collectionName) => {
    await Database.getCollection(collectionName, {});
  };

  return (
    <>
      <Card
        sx={{
          minHeight: "550px",
        }}
      >
        <H5 padding={3}>Sync Database</H5>
        <Divider />

        <FlexBox p={2}>
          <Button
            variant="contained"
            sx={{ marginRight: "10px" }}
            onClick={() => syncCollection("trades")}
          >
            Sync Trades
          </Button>
          <Button
            variant="contained"
            sx={{ marginRight: "10px" }}
            onClick={() => syncCollection("offers")}
          >
            Sync Offers
          </Button>
        </FlexBox>
      </Card>
    </>
  );
};

export default SyncDatabase;
