import axios from "../utils/axios";

const buyerService = {};

buyerService.getBuyers = function (page, limit, search) {
  return axios({
    url: `buyer?page=${page}&limit=${limit}&search=${search}`,
    method: "GET",
  });
};

buyerService.getBuyer = function (id) {
  return axios({
    url: `buyer/${id}`,
    method: "GET",
  });
};

buyerService.getBuyerTrade = function (id, page, limit, status, search) {
  return axios({
    url: `buyer/trade/${id}?page=${page}&limit=${limit}&status=${status}&search=${search}`,
    method: "GET",
  });
};

// ?page=${page}&limit=${limit}&status=${status}&search=${search}

export default buyerService;
