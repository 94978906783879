import {
  Card,
  Stack,
  styled,
  Table,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { alpha, Box } from "@mui/system";
import AppAvatar from "components/avatars/AppAvatar";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import Scrollbar from "components/ScrollBar";
import { H5, H6, Small, Tiny } from "components/Typography";
import InvertColors from "icons/InvertColors";
import KeyframeBezierIn from "icons/KeyframeBezierIn";
import PaletteOutlined from "icons/PaletteOutlined";
import { FormattedDate } from "components/shared/FormattedDate";
import { FromNow } from "components/shared/FormattedDate";
import EditButton from "../EditButton";
import DeleteButton from "../DeleteButton";

import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import Badge, { getRoleColor } from "utils/Badge";
import moment from "moment-timezone";
import Delete from "icons/Delete";
import { Link } from "react-router-dom";
import { ArrowOutward, ArrowRight } from "@mui/icons-material";
import ArrowOutwardButton from "./ArrowOutward";

// styled components
const StyledAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
  justifyContent: "flex-end",
  "& .MuiAvatarGroup-avatar": {
    width: 25,
    height: 25,
    fontSize: 12,
  },
}));
const IconWrapper = styled(FlexRowAlign)(({ color }) => ({
  width: 35,
  height: 30,
  borderRadius: "4px",
  backgroundColor: alpha(color, 0.2),
}));
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const Trades = () => {
  // Get data from usercontext api
  const { userdata } = useContext(UserContext);
  console.log(userdata?.trade);
  const theme = useTheme();

  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <H5 mb={1}>Trades</H5>

      <Scrollbar>
        <Table>
          <TableHead>
            <StyledTableCell>
              <Tiny fontSize={12} fontWeight={600}>
                DATE
              </Tiny>
            </StyledTableCell>
            <StyledTableCell>
              <Tiny fontSize={12} fontWeight={600}>
                TRADE HASH
              </Tiny>
            </StyledTableCell>
            <StyledTableCell>
              <Tiny fontSize={12} fontWeight={600}>
                ACCOUNT
              </Tiny>
            </StyledTableCell>
            <StyledTableCell>
              <Tiny fontSize={12} fontWeight={600}>
                PAYMENT METHOD
              </Tiny>
            </StyledTableCell>
            <StyledTableCell>
              <Tiny fontSize={12} fontWeight={600}>
                BUYER
              </Tiny>
            </StyledTableCell>
            <StyledTableCell>
              <Tiny fontSize={12} fontWeight={600}>
                STATUS
              </Tiny>
            </StyledTableCell>
          </TableHead>
          <TableBody>
            {userdata?.trade &&
              userdata?.trade.map((items) => (
                <TableRow>
                  <StyledTableCell>
                    <Box>
                      <Tiny>
                        {<FormattedDate date={items.account.createdAt} />}
                      </Tiny>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box>{/* <Tiny>{items.trade.trade_hash}</Tiny> */}</Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box>
                      <Tiny>{"abdshabd"}</Tiny>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box>
                      <Tiny>{"asdsa"}</Tiny>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box>
                      <Tiny>{"asdsa"}</Tiny>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box>
                      <Tiny>{"asdsa"}</Tiny>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};

export default Trades;
