import { UserContext } from "page-sections/user-list/ViewUser";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AutomationLayout from "./AutomationLayout";
import UserWebhook from "../userWebhook/UserWebhook";
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import Greetings from "./Greetings";
import { H3, Paragraph } from "components/Typography";
import { AccountSelect } from "../offer/Offers";
import PaxfulAccountsService from "services/PaxfulAccountsService";
import FlexBox from "components/flexbox/FlexBox";
import Platform from "components/shared/Platform";
import SettingService from "services/SettingService";
import Messaging from "./Messaging";
import PinSecurity from "./PinSecurity";
import KYC from "./KYC";
import Feedback from "./Feedback";
import ClosingMessage from "./ClosingMessage";

function Setting() {
  const {
    team,
    userdata,
    offers,
    activeAccount,
    setActiveAccount,
    activeAccountID,
    setActiveAccountID,
    userAccounts,
    setUserAccounts,
  } = useContext(UserContext);
  // const [userAccounts, setUserAccounts] = useState([]);

  const [tabValue, setTabValue] = useState("greeting");

  // Get User's accounts

  // useEffect(() => {
  //   getUserPaxfulAccounts(userdata._id);
  // }, []);

  const selectHandleChange = (event) => {
    setActiveAccountID(event.target.value);
    setActiveAccount(
      userAccounts.find((account) => {
        return account._id == event.target.value;
      })
    );
  };

  const handleTabChange = (_, value) => {
    setTabValue(value);
  };

  const [settings, setSettings] = useState({
    automated_feedback: [],
    automated_message: [],
    automated_margins: [],
    featured_messages: [],
    closing_messages: [],
    automated_kyc: [],
  });

  //Api Calling for save Setting
  const saveSettings = async (category, settings) => {
    await SettingService.saveSettings(
      category,
      settings,
      activeAccount._id,
      userdata._id
    );
  };

  const handleValueChange = (field, value) => {
    const _settings = { ...settings };
    _settings[field] = JSON.parse(JSON.stringify(value));

    setSettings(_settings);
    saveSettings("trades", _settings);
  };

  //   Api Call for get User Setting
  useEffect(async () => {
    if (activeAccount?._id) {
      const settings = await SettingService.getSettings(
        activeAccount?._id,
        userdata._id
      );
      if (settings) {
        const _settings = { ...settings };

        if (settings.trades) {
          settings.trades.forEach(({ key, value }) => {
            _settings[key] = value;
          });
        }
        setSettings(_settings);
      }
    }
  }, [activeAccount]);

  return (
    <Card
      sx={{
        marginTop: "24px",
        position: "relative",
      }}
    >
      <Box pt={2} pb={4}>
        <HeadingArea
          activeAccountID={activeAccountID}
          selectHandleChange={selectHandleChange}
          userAccounts={userAccounts}
        />
        <TabContext value={tabValue}>
          <AutomationLayout handleTabList={handleTabChange} tabValue={tabValue}>
            <TabPanel value="greeting">
              <>
                {activeAccount && (
                  <Greetings
                    messages={settings.automated_message}
                    onChange={(value) => {
                      handleValueChange("automated_message", value);
                    }}
                    activeAccount={activeAccount}
                  />
                )}
              </>
            </TabPanel>
            <TabPanel value="messaging">
              <>
                {activeAccount && (
                  <Messaging
                    messages={settings.featured_messages}
                    onChange={(value) => {
                      handleValueChange("featured_messages", value);
                    }}
                    activeAccount={activeAccount}
                  />
                )}
              </>
            </TabPanel>
            <TabPanel value="security">
              <>
                {activeAccount && (
                  <PinSecurity
                    teamMembers={team}
                    activeAccount={activeAccount}
                    profile={userdata}
                  />
                )}
              </>
            </TabPanel>
            <TabPanel value="kyc">
              <>
                {activeAccount && (
                  <KYC
                    settings={settings.automated_kyc}
                    onChange={(value) => {
                      handleValueChange("automated_kyc", value);
                    }}
                    activeAccount={activeAccount}
                  />
                )}
              </>
            </TabPanel>
            <TabPanel value="feedback">
              <>
                {activeAccount && (
                  <Feedback
                    feedbacks={settings.automated_feedback}
                    onChange={(value) => {
                      handleValueChange("automated_feedback", value);
                    }}
                    activeAccount={activeAccount}
                  />
                )}
              </>
            </TabPanel>
            <TabPanel value="closing-messages">
              <>
                {activeAccount && (
                  <ClosingMessage
                    messages={settings.closing_messages}
                    onChange={(value) => {
                      handleValueChange("closing_messages", value);
                    }}
                    activeAccount={activeAccount}
                  />
                )}
              </>
            </TabPanel>
          </AutomationLayout>
        </TabContext>
      </Box>
    </Card>
  );
}

export default Setting;
// { replace: true }

function HeadingArea({ activeAccountID, selectHandleChange, userAccounts }) {
  // console.log("activeAccount", activeAccount);
  return (
    <FlexBox px={3} sx={{ justifyContent: "space-between" }}>
      <H3>Automations</H3>

      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          sx={{
            "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
              {
                padding: "10px !important", // Remove padding from the Select component
              },
          }}
          value={activeAccountID}
          onChange={selectHandleChange}
          // defaultValue={activeAccountID}
          // inputProps={{ "aria-label": "Without label" }}
        >
          {/* <MenuItem value={"all"}>
            <Paragraph>All</Paragraph>
          </MenuItem> */}
          {userAccounts &&
            userAccounts.map((account, key) => {
              // console.log("select account", account);

              return (
                <MenuItem value={account._id}>
                  {" "}
                  <FlexBox
                    sx={{
                      opacity: account.is_deleted ? "0.5" : "",
                    }}
                    key={key}
                  >
                    <Paragraph>{account.paxful_username}</Paragraph>{" "}
                    <Box ml={2}>
                      <Platform size={20} platform={account.platform} />
                    </Box>
                  </FlexBox>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </FlexBox>
  );
}
