import { Edit } from "@mui/icons-material";
import { IconButton, styled, Button } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import FlexBox from "components/flexbox/FlexBox";
import { Link } from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag from "components/shared/CountryFlag";
import { minWidth } from "@mui/system";
import { FormattedDateTime } from "components/shared/FormattedDate";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));

const ColumnShapeAgency = [
  {
    Header: "DATE",
    accessor: "createdAt",
    Cell: ({ value }) => <FormattedDateTime date={value} />,
  },
  {
    Header: "AGENCY NAME",
    accessor: "agencyName",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "OWNER NAME",
    accessor: "ownerName",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "EMAIL",
    accessor: "email",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "COUNTRY",
    accessor: "country",
    Cell: ({ value }) => (
      <FlexBox>
        <Span fontSize={13} color="text.secondary">
          {value}
        </Span>

        {/* <Span>
          <CountryFlag code={value} width={"20px"} />
        </Span> */}
      </FlexBox>
    ),
  },
  {
    Header: "TIMEZONE",
    accessor: "timezone",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
];
export default ColumnShapeAgency;
