import React from "react";

function BuyerProfilePic({ buyer, size }) {
  return (
    <div>
      {
        <img
          style={{
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: "50%",
          }}
          src={
            buyer?.profile?.avatar_url
              ? buyer?.profile?.avatar_url
              : "/static/avatar/blank.png"
          }
          alt=""
        />
      }
    </div>
  );
}

export default BuyerProfilePic;
