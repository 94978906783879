import SecurityIcon from "@mui/icons-material/Security";
import { IconButton } from "@mui/material";

const LockButton = (props) => {
  return (
    <IconButton {...props}>
      <SecurityIcon
        sx={{
          fontSize: 15,
        }}
      />
    </IconButton>
  );
};

export default LockButton;
