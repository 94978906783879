import axios from "../utils/axios";

const PaxfulAccountsService = {};

PaxfulAccountsService.getPaxfulAccounts = function () {
  return axios({
    url: `/paxful-accounts`,
    method: "GET",
  });
};
PaxfulAccountsService.getUserPaxfulAccounts = function (id, active = 0) {
  return axios({
    url: `/paxful-accounts/${id}?active=${active}`,
    method: "GET",
  });
};

PaxfulAccountsService.getUserAccountAddress = function (id, account_id, type) {
  return axios({
    url: `/paxful-accounts/address/${id}?account_id=${account_id}&type=${type}`,
    method: "GET",
  });
};

PaxfulAccountsService.deleteAccount = function (id) {
  return axios({
    url: `/paxful-accounts/${id}`,
    method: "DELETE",
  });
};
PaxfulAccountsService.relinkAccount = function (userId, vendorId) {
  return axios({
    url: `/paxful-accounts/re-link/${userId}`,
    method: "POST",
    data: vendorId,
  });
};

PaxfulAccountsService.accountMasterList = function () {
  return axios({
    url: `/paxful-accounts/master-list`,
    method: "GET",
  });
};

PaxfulAccountsService.getAllAccount = function (
  page,
  limit,
  search,
  type,
  platform
) {
  return axios({
    url: `/paxful-accounts/all-account?page=${page}&limit=${limit}&search=${search}&type=${type}&platform=${platform}`,
    method: "GET",
  });
};

PaxfulAccountsService.deleteWebhook = function (id) {
  return axios({
    url: `/paxful-accounts/${id}/delete/webhook`,
    method: "PUT",
  });
};

PaxfulAccountsService.resetWebook = function (id) {
  return axios({
    url: `/paxful-accounts/${id}/reset/webhook`,
    method: "PUT",
  });
};

export default PaxfulAccountsService;
