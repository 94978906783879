import { useEffect, useState } from "react";
import { Button, Grid, styled, Select, MenuItem } from "@mui/material";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import { H3, H6, Paragraph } from "components/Typography";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import HireVirtualAssistantService from "services/HireVirtualAssistantService";
import UserSymbol from "components/shared/UserSymbol";
import HiringRequestService from "services/HireRequestService";
import VirtualAccountService from "services/VirtualAccountService";

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 650,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

const AssignedUser = ({ open, onClose, onSuccess, hireRequest }) => {
  const [hireVA, setHireVA] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getHireVAs = async (page = 1, limit = 100, search = "") => {
      try {
        const resp = await VirtualAccountService.getVirtualAccounts(
          page,
          limit,
          search
        );
        setHireVA(resp.data);
      } catch (error) {
        console.error("Error fetching virtual assistants:", error);
      }
    };
    getHireVAs();
  }, []);

  const validationSchema = Yup.object().shape({
    assignedUser: Yup.string().required("Please Select a Virtual Assistant"),
  });
  console.log("hireRequest._id", hireRequest?._id);
  const formik = useFormik({
    initialValues: {
      assignedUser: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      setIsLoading(true);
      try {
        // Call updated hire request API
        console.log("values", values);

        const resp = await HiringRequestService.AssignedHireRequest(
          hireRequest._id,
          { assignedUser: values.assignedUser }
        );

        if (resp && resp.status === true) {
          onSuccess();
          close();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      setIsLoading(false);
    },
  });

  const close = () => {
    formik.setFieldValue({ assignedUser: "" });
    formik.resetForm();
    onClose();
  };

  return (
    <StyledAppModal open={open} handleClose={close}>
      <H3 mb={2}>Assign VA to Hire Request</H3>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl sx={{ width: 290, padding: 0 }}>
              <H6 mb={1}>Assigned User</H6>
              <Select
                displayEmpty
                inputProps={{ "aria-label": "Select an option" }}
                sx={{ height: 50 }}
                onChange={formik.handleChange}
                name="assignedUser"
                value={formik.values.assignedUser}
                error={
                  formik.touched.assignedUser &&
                  Boolean(formik.errors.assignedUser)
                }
              >
                <MenuItem value="" disabled>
                  Select a Virtual Assistant
                </MenuItem>
                {hireVA.map((user) => (
                  <MenuItem key={user._id} value={user._id}>
                    <UserProfileForSelect user={user} />
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.assignedUser && formik.errors.assignedUser && (
                <Paragraph color="error">
                  {formik.errors.assignedUser}
                </Paragraph>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <FlexBox justifyContent="flex-end" gap={2} marginTop={4}>
          <Button fullWidth size="small" variant="outlined" onClick={close}>
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="small"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </FlexBox>
      </form>
    </StyledAppModal>
  );
};

export default AssignedUser;

const UserProfileForSelect = ({ user }) => {
  return (
    <FlexBox mb={0.7} sx={{ alignItems: "center" }}>
      <Box mt={1}>
        <UserSymbol user={user} size={30} isVa={true} />
      </Box>
      <Box ml={1}>
        <FlexBox sx={{ alignItems: "center" }}>
          <Paragraph fontSize={13} color="text.secondary" fontWeight={"bold"}>
            {user.first_name + " " + user.last_name}
          </Paragraph>
        </FlexBox>
      </Box>
    </FlexBox>
  );
};
