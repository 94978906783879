import React from "react";
import parsePhoneNumber from "libphonenumber-js";

import CountryFlag from "./CountryFlag";
import { getCountryNameByCode } from "utils/currencies";

function PhoneNumberFormatter({ number, flag }) {
  const phoneNumber = parsePhoneNumber(`${number}`);
  return (
    <span className="">
      <span className="me-2">{phoneNumber?.formatNational()}</span>
      {flag && (
        <CountryFlag
          code={phoneNumber?.country}
          width={"18px"}
          content={getCountryNameByCode(phoneNumber?.country)}
          showToolTip
        />
      )}
    </span>
  );
}

PhoneNumberFormatter.defaultProps = {
  flag: true,
};

export default PhoneNumberFormatter;
