import React, { useContext, useState } from "react";
import Dropzone from "react-dropzone";
import { useCallback } from "react";
import FsLightbox from "fslightbox-react";
import notification from "helpers/notifications";
import FilesServices from "services/FileServices";
import { Box, Input, TextField } from "@mui/material";
import { COMMON_API_BASE_URL } from "config";
import FlexBox from "components/flexbox/FlexBox";
import { Small } from "components/Typography";
import { UserContext } from "page-sections/user-list/ViewUser";
import CancelIcon from "@mui/icons-material/Cancel";

const styles = (theme) => ({
  textField: {
    padding: 0,
  },
});

function UploadImage({
  titleValue,
  onUpload,
  file,
  onRemove,
  onTitleChange,
  isPDF = false,
}) {
  let classes = styles();
  const { userdata, allOffers } = useContext(UserContext);

  const [isUploading, setIsUploading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [extension, setExtension] = useState(null);

  const PhotoItem = ({ image, thumb, onClick }) => (
    <a onClick={onClick}>
      <span className="symbol symbol-30px cursor-pointer">
        {extension == "PDF" ? (
          <img src="/media/svg/files/pdf.svg" />
        ) : (
          <img width={"50px"} src={image} />
        )}
      </span>
    </a>
  );

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      const resp = await FilesServices.uploadFile(formData, userdata._id);

      if (resp.status == true) {
        onUpload(resp.file.file_path);

        if (resp.file.mime_type == "application/pdf") {
          setExtension("PDF");
        } else {
          setExtension(null);
        }

        // const _value = JSON.parse(JSON.stringify(value));
        // _value.message[index].file = resp.file.file_path;
        // setValue(_value);
        // onChange(_value);
      }
    }
    if (rejectedFiles.length > 0) {
      notification.error(
        "Invalid File",
        "Only JPG, PNG, JPEG and PDF are supported."
      );
    }
    setIsUploading(false); //uploading completed
    setDialogOpen(false);
  });

  return (
    <>
      <Dropzone
        accept={{
          "image/png": [".png", ".jpg", ".jpeg"],
          "application/pdf": [".pdf"],
        }}
        noClick={true}
        onDrop={(acceptedFiles, rejectedFiles) =>
          onDrop(acceptedFiles, rejectedFiles)
        }
      >
        {({ getRootProps, getInputProps, open, isDragActive }) => (
          <Box>
            <Box
              mt={1}
              sx={{
                backgroundColor: "#f5f8fa",
                borderRadius: "10px",
                padding: "13px 25px 23px 25px",
              }}
              className="messages-input-box"
              {...getRootProps()}
            >
              <TextField
                className={classes.textField}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: "gray",
                    fontSize: "14px",
                    fontWeight: "bold",
                  },
                }}
                id="standard-basic"
                variant="standard"
                type="text"
                value={titleValue}
                placeholder="Title"
                // className="fw-bold input-title"
                onChange={(e) => onTitleChange(e.target.value)}
              ></TextField>

              <div className="">
                <div className="dropzone-root">
                  <input {...getInputProps()} />
                </div>

                {!file && (
                  <Box
                    mt={1}
                    sx={{
                      border: "3px dashed lightgray",
                      backgroundColor: "#f5f8fa !important",
                    }}
                    className="position-relative file-dropzone dropzone-inline"
                    onClick={() => open()}
                  >
                    <div style={{ width: "100%", padding: "12px" }}>
                      {isUploading ? (
                        <div>
                          <span className="indicator-progress d-block">
                            <span className="spinner-border spinner-border-sm align-middle ms-2 w-40px h-40px text-primary"></span>
                          </span>
                        </div>
                      ) : (
                        <Box>
                          <FlexBox
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="fw-bold text-muted fs-4 text-center"
                          >
                            <Small
                              fontWeight={"bold"}
                              color="text.secondary"
                              fontSize={14}
                            >
                              Drop files here or click to upload.
                            </Small>
                          </FlexBox>
                          <FlexBox
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="text-muted text-center"
                          >
                            <Small color="text.secondary" fontSize={12}>
                              (Only JPG, PNG, JPEG and PDF are supported.)
                            </Small>
                          </FlexBox>
                        </Box>
                      )}
                    </div>
                  </Box>
                )}

                {!isPDF && file && (
                  <>
                    <div
                      style={{ pointerEvents: "auto" }}
                      className="d-flex text-center position-relative added-image"
                    >
                      <a
                        className="symbol symbol-30px cursor-pointer"
                        onClick={() => {
                          setToggler(!toggler);
                        }}
                      >
                        <PhotoItem
                          image={`${COMMON_API_BASE_URL}/${file}`}
                          thumb={`${COMMON_API_BASE_URL}/${file}`}
                          onClick={() => {
                            setToggler(!toggler);
                          }}
                        />
                      </a>
                      {/* <a
                        // onClick={handleRemoveImage}
                        className="remove-image position-absolute bg-danger cursor-pointer"
                      >
                        <i className="fa-solid fa-xmark text-light"></i>
                      </a> */}

                      <FsLightbox
                        toggler={toggler}
                        sources={[`${COMMON_API_BASE_URL}/${file}`]}
                        thumbs={[`${COMMON_API_BASE_URL}/${file}`]}
                        thumbsIcons={[
                          <svg
                            key="0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="28px"
                            height="28px"
                            viewBox="00 430.118 430.118"
                          >
                            <path d="M367.243,28.754c-59.795-1.951-100.259,31.591-121.447,100.664c10.912-4.494,21.516-6.762,31.858-6.762c21.804,0,31.455,12.237,28.879,36.776c-1.278,14.86-10.911,36.482-28.879,64.858c-18.039,28.423-31.513,42.61-40.464,42.61c-11.621,0-22.199-21.958-31.857-65.82c-3.239-12.918-9.031-45.812-17.324-98.765c-7.775-49.046-28.32-71.962-61.727-68.741C112.15,34.873,90.98,47.815,62.726,72.308C42.113,91.032,21.228,109.761,0,128.471l20.225,26.112c19.303-13.562,30.595-20.311,33.731-20.311c14.802,0,28.625,23.219,41.488,69.651c11.53,42.644,23.158,85.23,34.744,127.812c17.256,46.466,38.529,69.708,63.552,69.708c40.473,0,90.028-38.065,148.469-114.223c56.537-72.909,85.725-130.352,87.694-172.341C432.498,58.764,411.613,30.028,367.243,28.754z" />
                          </svg>,
                        ]}
                        showThumbsOnMount={true}
                        svg={{
                          toolbarButtons: {
                            thumbs: {
                              viewBox: "0 0 278 278",
                              width: "17px",
                              height: "17px",
                              d: "M0 0 H119.054 V119.054 H0 V0 z M158.946 0 H278 V119.054 H158.946 V0 z M158.946 158.946 H278 V278 H158.946 V158.946 z M0 158.946 H119.054 V278 H0 V158.946 z",
                              title: "Preview",
                            },
                            zoomIn: {
                              width: "20px",
                            },
                            zoomOut: {
                              height: "20px",
                            },
                            slideshow: {
                              start: {
                                width: "20px",
                              },
                              pause: {
                                viewBox: "0 0 31 31",
                              },
                            },
                            fullscreen: {
                              enter: {
                                title: "Fullscreen — Show",
                              },
                              exit: {
                                title: "Fullscreen — Exit",
                              },
                            },
                            close: {
                              height: "32px",
                            },
                          },
                          slideButtons: {
                            previous: {
                              width: "40px",
                            },
                            next: {
                              title: "Next",
                            },
                          },
                        }}
                      />
                    </div>
                  </>
                )}
                {isPDF && file && (
                  <div
                    style={{
                      pointerEvents: "all",
                    }}
                    className="d-inline-block position-relative"
                  >
                    <a
                      onClick={() => {}}
                      href={`${COMMON_API_BASE_URL}/${file}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img width={"30px"} src="/media/svg/files/pdf.svg" />
                    </a>
                  </div>
                )}
              </div>
            </Box>
          </Box>
        )}
      </Dropzone>
    </>
  );
}

export default UploadImage;
