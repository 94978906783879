import React from "react";
// import FsLightbox from "fslightbox-react";
import { Button } from "@mui/material";
import FsLightbox from "fslightbox-react";

export const FslightGallery = ({ lightboxController, images }) => {
  return (
    <>
      <FsLightbox
        toggler={lightboxController.toggler}
        sourceIndex={lightboxController.sourceIndex}
        sources={images}
      />
    </>
  );
};

export const FslightGalleryForSingleImage = ({ index, image, toggler }) => {
  return (
    <>
      <FsLightbox key={`image-${index}`} toggler={toggler} sources={[image]} />
    </>
  );
};
