import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

const CustomPopover = ({
  target,
  children,
  fixed,
  marginX,
  marginY,
  placement,
  className,
}) => {
  const parent = useRef();
  const [position, setPosition] = useState({});

  const setBounds = () => {
    const bounds = parent.current?.getBoundingClientRect();
    if (bounds) {
      // console.log(bounds);
      let position = {
        position: "fixed",
        top: bounds.y + bounds.height + marginY,
        left: bounds.x + marginX,
        width: "fit-content",
      };
      setPosition(position);
    }
  };

  useEffect(() => {
    //let tooltip = document.querySelector(".agl-tooltip");
    if (fixed) {
      setBounds();
      document.addEventListener("scroll", (event) => {
        // console.log(event);
        setBounds();
      });
    }
  }, []);

  return (
    <>
      <div
        ref={parent}
        className={`agl-tooltip agl-tooltip-${placement}${
          className ? " " + className : ""
        }`}
      >
        {target}
        <div className="team-wrapper">
          <div
            style={position}
            className="menu menu-sub menu-sub-dropdown menu-column"
          >
            <div className="tooltip-content ">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

CustomPopover.defaultProps = {
  fixed: false,
  marginX: 0,
  marginY: 0,
  placement: "left",
};

export default CustomPopover;
