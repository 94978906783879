import React, { useContext, useEffect, useRef, useState } from "react";
import Select, { StylesConfig } from "react-select";
// import { Badge, Button, Popover, PopoverBody } from "reactstrap";
// import emitter from "../../../../helpers/emitter";
import emitter from "helpers/socketEmmiter";
// import { saveOffers } from "../../redux/actions/offers";
// import OfferServices from "../../services/OffersService";
import { getTitleFromSlug } from "utils/utils";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import { Box, Button, Popover } from "@mui/material";
import { UserContext } from "page-sections/user-list/ViewUser";
import FlexBox from "components/flexbox/FlexBox";
import { Small } from "components/Typography";

const Dropdown = ({ children, isOpen, target }) => (
  <Box
    sx={{
      position: "relative",
      height: "40px",
    }}
    className="position-relative"
  >
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
  </Box>
);

const Menu = (props) => {
  return (
    <div
      className="select-menu mt-1 search-input-hidden position-absolute w-100"
      {...props}
    />
  );
};

const DropdownIndicator = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z"
      fill="currentColor"
    />
  </svg>
);

function SelectOffers(props) {
  const { allOffers } = useContext(UserContext);
  const selectRef = useRef();

  const selectToogleRef = useRef(null);

  //   const router = useRouter();

  const [selectedValues, setSelectedValues] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  //   const getOffers = async () => {
  //     const offers = await OfferServices.getOffers();
  //     props.saveOffers(offers);
  //   };

  const groupedOffers = [];
  const paymentMethodLabels = {};

  let filteredOffers = allOffers;

  if (props.activeAccountId && allOffers) {
    filteredOffers = allOffers.filter(
      (offer) => offer.account._id == props.activeAccountId
    );
  }

  //   if (router.query?.act) {
  //     filteredOffers = props.offers.filter(
  //       (offer) => offer.account._id == router.query.act
  //     );
  //   }

  filteredOffers?.forEach((offer) => {
    if (!groupedOffers[offer.offer.payment_method_group]) {
      groupedOffers[offer.offer.payment_method_group] = [];
    }
    groupedOffers[offer.offer.payment_method_group].push(offer);

    if (!paymentMethodLabels[offer.offer.payment_method_group])
      paymentMethodLabels[offer.offer.payment_method_group] = getTitleFromSlug(
        offer.offer.payment_method_group
      );
  });

  let groupedOptions = Object.keys(paymentMethodLabels).map((slug) => ({
    label: paymentMethodLabels[slug],
    options: groupedOffers[slug]
      .map((offer) => {
        return {
          value: offer.paxful_offer_hash,
          label: `${offer.offer.payment_method_name}`,
          icon: <PaxfulProfilePic account={offer.account} size={20} />,
          offer_hash: offer.paxful_offer_hash,
          offer_type: offer.offer_type,
          offer_margin: offer.offer.margin,
          platform: offer.account.platform,
          account_id: offer.account._id,
        };
      })
      .filter(({ value }) => props.selected?.indexOf(value) == -1),
  }));

  useEffect(() => {
    // getOffers();
    const listner = emitter.addListener("clear:select:offers", function () {
      selectRef.current?.clearValue();
      setSelectedValues([]);
    });
    const listner2 = emitter.addListener(
      "clicked:outside:reactSelectMenu",
      (_id) => {
        if (_id !== "automations-offers") setIsOpen(false);
      }
    );
    const listner3 = emitter.addListener("clicked:outside:popover", () => {
      setShowMore(false);
    });

    return () => {
      listner?.remove();
      listner2.remove();
      listner3.remove();
    };
  }, []);

  const handleSelectChange = (_options) => {
    const value = _options.find(function (option) {
      return option.value === "all";
    });

    if (value) {
      const _options = options.filter(function (options) {
        return options.value !== "all";
      });
      setSelectedValues(_options);

      props.onChange(
        _options.map(({ label, value, account_id }) => ({
          label,
          value,
          account_id,
        }))
      );
    } else {
      setSelectedValues(_options);
      props.onChange(
        _options.map(({ label, value, account_id }) => ({
          label,
          value,
          account_id,
        }))
      );
    }
  };

  let options = filteredOffers
    ?.map((offer) => {
      return {
        value: offer.paxful_offer_hash,
        label: `${offer.offer.payment_method_name}`,
        icon: <PaxfulProfilePic account={offer.account} size={20} />,
        offer_hash: offer.paxful_offer_hash,
        offer_type: offer.offer_type,
        offer_margin: offer.offer.margin,
        platform: offer.account.platform,
        account_id: offer.account._id,
      };
    })
    .filter(({ value }) => props.selected?.indexOf(value) == -1);

  if (options?.length > 1 && options?.length - selectedValues.length > 1) {
    groupedOptions = [
      {
        value: "all",
        label: "All Offers",
      },
    ].concat(groupedOptions);
  }

  let reversedArray = [...selectedValues].reverse();
  let initial_offers = reversedArray;
  let more_offers = [];
  if (selectedValues?.length > 3) {
    initial_offers = reversedArray.slice(0, 3);
    more_offers = reversedArray.slice(3);
  }

  const toggleshowMore = () => {
    setShowMore(!showMore);
  };

  const removeOffer = ({ offer_hash }) => {
    let _value = selectedValues.filter(function (offer) {
      return offer.offer_hash != offer_hash;
    });
    setSelectedValues(_value);
    props.onChange(
      _value.map(({ label, value, account_id }) => ({
        label,
        value,
        account_id,
      }))
    );
  };

  const getOptionLabel = (value) =>
    value.value == "all" ? (
      value.label
    ) : (
      <>
        <Box
          sx={{
            alignItems: "center",
            padding: "5px",
          }}
          // key={index}
          // className={`mb-${
          //   index < value.length - 1 ? "1" : "0"
          // } py-1 px-2 rounded border d-inline-block`}
        >
          <FlexBox
            sx={{
              backgroundColor: "#f5f8fa",
              borderRadius: "5px",
              padding: "5px",
              margin: "2px",
              alignItems: "start",
              justifyContent: "space-between",
            }}
            // key={index}
            // className={`mb-${
            //   index < value.length - 1 ? "1" : "0"
            // } py-1 px-2 rounded border`}
          >
            <FlexBox>
              <Box mt={0.5}>
                <Small>{value.icon}</Small>
              </Box>
              <Box ml={1}>
                <FlexBox
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                  className="d-inline-flex align-items-center fw-bold"
                >
                  <Small color="black">{value.label}</Small>
                  <Platform
                    size="20"
                    platform={value.platform}
                    className={"h-20px ms-2"}
                  />
                </FlexBox>
                <FlexBox sx={{ alignItems: "center" }} className="">
                  <Small mr={1} fontSize={10} color="text.secondary">
                    {value.offer_hash}
                  </Small>

                  <Small
                    mr={1}
                    fontSize={10}
                    color={
                      value.offer_type == "sell" ? "error.main" : "success.main"
                    }
                  >
                    {value.offer_type}
                  </Small>

                  <Small fontSize={10} color="text.secondary">
                    {value.offer_margin}%{" "}
                  </Small>
                </FlexBox>
              </Box>
            </FlexBox>

            {/* <Small sx={{ cursor: "pointer" }}>
              <a
                className="cursor-pointer remove-offer"
                onClick={() => {
                  removeOffer(index);
                }}
              >
                <span className="svg-icon svg-icon-4">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x="7.05025"
                      y="15.5356"
                      width="12"
                      height="2"
                      rx="1"
                      transform="rotate(-45 7.05025 15.5356)"
                      fill="currentColor"
                    />
                    <rect
                      x="8.46447"
                      y="7.05029"
                      width="12"
                      height="2"
                      rx="1"
                      transform="rotate(45 8.46447 7.05029)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </a>
            </Small> */}
          </FlexBox>
        </Box>
      </>
    );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // document.body.style.overflow = "hidden";
  };

  const handleClose = () => {
    setAnchorEl(null);
    // document.body.style.overflow = "visible";
  };

  const _open = Boolean(anchorEl);
  const _id = _open ? "simple-popover" : undefined;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectToogleRef.current &&
        !selectToogleRef.current.contains(event.target) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <div
        className={`form-control-solid limit-max-height ${
          isOpen ? "is-open" : "is-closed"
        }`}
        ref={selectToogleRef}
      >
        <Dropdown
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          target={
            <Button
              onClick={() => setIsOpen((prev) => !prev)}
              id="automations-offers"
              // color="light"
              className="menu-dropdown-button select-offers position-relative"
              sx={{
                height: "38px",
              }}
            >
              Select Offers
            </Button>
          }
        >
          <Select
            components={{ DropdownIndicator, IndicatorSeparator: null }}
            menuIsOpen
            isSearchable={false}
            classNamePrefix={"atlantis-select"}
            // ref={selectRef}
            defaultValue={[]}
            isMulti={true}
            options={groupedOptions}
            placeholder={"Select Offer"}
            onChange={handleSelectChange}
            value={selectedValues}
            getOptionLabel={getOptionLabel}
          />
        </Dropdown>
      </div>

      {props.hasOffers && (
        <>
          <div className="selected-offers d-flex flex-wrap justify-content-between mt-2">
            {initial_offers.map((val, index) => {
              return (
                <>
                  <Box
                    sx={{
                      alignItems: "center",
                      // padding: "5px",
                    }}
                    // key={index}
                    // className={`mb-${
                    //   index < value.length - 1 ? "1" : "0"
                    // } py-1 px-2 rounded border d-inline-block`}
                  >
                    <FlexBox
                      mt={0.5}
                      mb={0.5}
                      sx={{
                        backgroundColor: "#f5f8fa",
                        borderRadius: "5px",
                        padding: "5px",
                        // margin: "2px",
                        alignItems: "start",
                        justifyContent: "space-between",
                      }}
                      key={val.offer_hash}
                      // className={`mb-${
                      //   index < value.length - 1 ? "1" : "0"
                      // } py-1 px-2 rounded border`}
                    >
                      <FlexBox>
                        <Box mt={0.5}>
                          <Small>{val.icon}</Small>
                        </Box>
                        <Box ml={1}>
                          <FlexBox
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                            className="d-inline-flex align-items-center fw-bold"
                          >
                            <Small color="black">{val.label}</Small>
                            <Platform
                              size="20"
                              platform={val.platform}
                              className={"h-20px ms-2"}
                            />
                          </FlexBox>
                          <FlexBox sx={{ alignItems: "center" }} className="">
                            <Small mr={1} fontSize={10} color="text.secondary">
                              {val.offer_hash}
                            </Small>

                            <Small
                              mr={1}
                              fontSize={10}
                              color={
                                val.offer_type == "sell"
                                  ? "error.main"
                                  : "success.main"
                              }
                            >
                              {val.offer_type}
                            </Small>

                            <Small fontSize={10} color="text.secondary">
                              {val.offer_margin}%{" "}
                            </Small>
                          </FlexBox>
                        </Box>
                      </FlexBox>

                      <Small sx={{ cursor: "pointer" }}>
                        <a
                          className="cursor-pointer remove-offer"
                          onClick={() => {
                            removeOffer(val);
                          }}
                        >
                          <span className="svg-icon svg-icon-4">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x="7.05025"
                                y="15.5356"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(-45 7.05025 15.5356)"
                                fill="currentColor"
                              />
                              <rect
                                x="8.46447"
                                y="7.05029"
                                width="12"
                                height="2"
                                rx="1"
                                transform="rotate(45 8.46447 7.05029)"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </a>
                      </Small>
                    </FlexBox>
                  </Box>
                  {/*  */}
                </>
              );
            })}
          </div>
          {more_offers && (
            <div className="mb-3 mt-1">
              {more_offers.length > 0 && (
                <>
                  <div className="text-end">
                    <a
                      id={"more-offers"}
                      onClick={handleClick}
                      className="open-popover cursor-pointer fw-bolder more-items-link fs-7"
                    >
                      <Small>+{more_offers.length} more</Small>
                    </a>
                  </div>

                  {_open && (
                    <Popover
                      open={_open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      placement="top-start"
                      disablePortal={false}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      modifiers={{
                        flip: {
                          enabled: false,
                        },
                        preventOverflow: {
                          enabled: true,
                          boundariesElement: "viewport",
                        },
                      }}
                    >
                      <Box className="p-2">
                        {more_offers.length > 0 && (
                          <div className="widget-scroll-y ">
                            {more_offers.map((val, index) => {
                              return (
                                <Box
                                  sx={{
                                    alignItems: "center",
                                    padding: "5px",
                                  }}
                                  key={index}
                                  // className={`mb-${
                                  //   index < value.length - 1 ? "1" : "0"
                                  // } py-1 px-2 rounded border d-inline-block`}
                                >
                                  <FlexBox
                                    sx={{
                                      backgroundColor: "#f5f8fa",
                                      borderRadius: "5px",
                                      padding: "5px",
                                      margin: "2px",
                                      alignItems: "start",
                                      justifyContent: "space-between",
                                    }}
                                    key={val.offer_hash}
                                    // className={`mb-${
                                    //   index < value.length - 1 ? "1" : "0"
                                    // } py-1 px-2 rounded border`}
                                  >
                                    <FlexBox>
                                      <Box mt={0.5}>
                                        <Small>{val.icon}</Small>
                                      </Box>
                                      <Box ml={1}>
                                        <FlexBox
                                          sx={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                          }}
                                          className="d-inline-flex align-items-center fw-bold"
                                        >
                                          <Small color="black">
                                            {val.label}
                                          </Small>
                                          <Platform
                                            size="20"
                                            platform={val.platform}
                                            className={"h-20px ms-2"}
                                          />
                                        </FlexBox>
                                        <FlexBox
                                          sx={{ alignItems: "center" }}
                                          className=""
                                        >
                                          <Small
                                            mr={1}
                                            fontSize={10}
                                            color="text.secondary"
                                          >
                                            {val.offer_hash}
                                          </Small>

                                          <Small
                                            mr={1}
                                            fontSize={10}
                                            color={
                                              val.offer_type == "sell"
                                                ? "error.main"
                                                : "success.main"
                                            }
                                          >
                                            {val.offer_type}
                                          </Small>

                                          <Small
                                            fontSize={10}
                                            color="text.secondary"
                                          >
                                            {val.offer_margin}%{" "}
                                          </Small>
                                        </FlexBox>
                                      </Box>
                                    </FlexBox>

                                    <Small sx={{ cursor: "pointer" }}>
                                      <a
                                        className="cursor-pointer remove-offer"
                                        onClick={() => {
                                          removeOffer(val);
                                        }}
                                      >
                                        <span className="svg-icon svg-icon-4">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              opacity="0.5"
                                              x="7.05025"
                                              y="15.5356"
                                              width="12"
                                              height="2"
                                              rx="1"
                                              transform="rotate(-45 7.05025 15.5356)"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="8.46447"
                                              y="7.05029"
                                              width="12"
                                              height="2"
                                              rx="1"
                                              transform="rotate(45 8.46447 7.05029)"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                      </a>
                                    </Small>
                                  </FlexBox>
                                </Box>
                              );
                            })}
                          </div>
                        )}
                      </Box>
                    </Popover>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

// SelectOffers.defaultProps = {
//   isMulti: false,
//   showBlank: false,
//   blankLabel: "All",
//   placeholder: "Select Offers",
//   defaultValue: [],
// };

export default SelectOffers;
