import { Delete, NoAccounts } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";

const BanButton = (props) => {
  return (
    <IconButton {...props}>
      <BlockIcon
        sx={{
          fontSize: 17,
        }}
      />
    </IconButton>
  );
};

export default BanButton;

export const BanUser = (props) => {
  return (
    <IconButton {...props}>
      <NoAccounts
        sx={{
          fontSize: 17,
        }}
      />
    </IconButton>
  );
};
