import { Badge, Box } from "@mui/material";

export const ChatIcon = ({ index, active, blink, count, disabled }) => {
  return (
    <>
      <a>
        {count > 0 ? (
          <Badge badgeContent={count} color="primary">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z"
                fill="currentColor"
              />
              <rect
                x="6"
                y="12"
                width="7"
                height="2"
                rx="1"
                fill="currentColor"
              />
              <rect
                x="6"
                y="7"
                width="12"
                height="2"
                rx="1"
                fill="currentColor"
              />
            </svg>
          </Badge>
        ) : (
          <Badge badgeContent={"0"} color="primary">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z"
                fill="currentColor"
              />
              <rect
                x="6"
                y="12"
                width="7"
                height="2"
                rx="1"
                fill="currentColor"
              />
              <rect
                x="6"
                y="7"
                width="12"
                height="2"
                rx="1"
                fill="currentColor"
              />
            </svg>
          </Badge>
        )}
      </a>
      {/* <a
        id={`new-message-${index}`}
        className={`me-${count > 0 ? "3" : "2"} position-relative`}
      >
        <span
          className={`icon-chat-status svg-icon svg-icon-${
            active ? "primary" : "muted"
          } svg-icon-2 me-0${blink ? " highlight-danger" : ""}`}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z"
              fill="currentColor"
            />
            <rect
              x="6"
              y="12"
              width="7"
              height="2"
              rx="1"
              fill="currentColor"
            />
            <rect
              x="6"
              y="7"
              width="12"
              height="2"
              rx="1"
              fill="currentColor"
            />
          </svg>
        </span>
        {count > 0 && (
          <span
            className={`message-counter badge badge-circle ${
              disabled
                ? "badge-secondary text-white"
                : blink
                ? "badge-danger animation-blink"
                : "badge-primary"
            }`}
          >
            {count}
          </span>
        )}
      </a> */}
      {/* <UncontrolledTooltip placement="bottom" target={`new-message-${index}`}>
        New Message
      </UncontrolledTooltip>{" "} */}
    </>
  );
};
