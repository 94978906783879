import { Badge, Box } from "@mui/material";

export const AttachmentIcon = ({ index, active, count, blink, disabled }) => (
  <>
    <a>
      {count > 0 ? (
        <Badge badgeContent={count} color="primary">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
              fill="currentColor"
            />
            <path
              d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
              fill="currentColor"
            />
            <path
              opacity="0.3"
              d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
              fill="currentColor"
            />
          </svg>
        </Badge>
      ) : (
        <Badge badgeContent={"0"} color="primary">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
              fill="currentColor"
            />
            <path
              d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
              fill="currentColor"
            />
            <path
              opacity="0.3"
              d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
              fill="currentColor"
            />
          </svg>
        </Badge>
      )}
    </a>
    {/* <a
      id={`new-attachment-${index}`}
      className={`me-${count > 0 ? "3" : "2"} position-relative`}
    >
      <span
        className={`icon-chat-status svg-icon svg-icon-${
          active ? "primary" : "muted"
        } svg-icon-2 me-0${blink ? " highlight-danger" : ""}`}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
            fill="currentColor"
          />
          <path
            d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
            fill="currentColor"
          />
          <path
            opacity="0.3"
            d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
            fill="currentColor"
          />
        </svg>
      </span>
      {count > 0 && (
        <span
          className={`message-counter badge badge-circle ${
            disabled
              ? "badge-secondary text-white"
              : blink
              ? "badge-danger animation-blink"
              : "badge-primary"
          }`}
        >
          {count}
        </span>
      )}
    </a> */}
    {/* <UncontrolledTooltip placement="bottom" target={`new-attachment-${index}`}>
        New Attachment
      </UncontrolledTooltip>{" "} */}
  </>
);
