import { ArrowOutward, CameraAlt } from "@mui/icons-material";
import { TabList } from "@mui/lab";
import { Card, IconButton, Stack, styled, Tab } from "@mui/material";
import { Box } from "@mui/system";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import { H4, H5, Small, Tiny } from "components/Typography";
import AvatarBadge from "components/avatars/AvatarBadge";
import Bratislava from "icons/Bratislava";
import DateRange from "icons/DateRange";
import MapMarkerIcon from "icons/MapMarkerIcon";
import numeral from "numeral";
import { Fragment } from "react";
import { Outlet } from "react-router-dom"; // styled components

import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";

import { FormattedDate } from "components/shared/FormattedDate";
import TableCell from "@mui/material/TableCell";

const StyledTabList = styled(TabList)(({ theme }) => ({
  paddingLeft: 16,
  paddingRight: 16,
  [theme.breakpoints.up("sm")]: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
})); // -------------------------------------------------

// -------------------------------------------------
const TabManagement = ({ children, handleTabList }) => {
  // get data from usercontext api
  const { userdata } = useContext(UserContext);
  return (
    <Fragment>
      <Card
        sx={{
          position: "relative",
        }}
      >
        <StyledTabList variant="scrollable" onChange={handleTabList}>
          <Tab disableRipple label="All" value="1" />
          <Tab disableRipple label="Users" value="2" />
          <Tab disableRipple label="System" value="3" />
        </StyledTabList>
      </Card>

      {children || <Outlet />}
    </Fragment>
  );
};

export default TabManagement;
