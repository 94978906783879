import { Box, FormControl, MenuItem, Select } from "@mui/material";
import AppPagination from "components/AppPagination";
import React from "react";

const PaginanationAndLimit = ({
  tableData,
  handleChange,
  count,
  currentPage,
  handleLimitChange,
  limit,
}) => {
  return (
    <div>
      {tableData.length > 0 && (
        <Box
          sx={{
            position: "relative",
          }}
          marginY={0}
        >
          <Box
            component="span"
            sx={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={limit}
                onChange={handleLimitChange}
                label="Age"
              >
                {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={75}>75</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              right: 0,
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              transform: "translateY(-50%)",
            }}
          >
            <AppPagination
              shape="rounded"
              onChange={handleChange}
              count={count}
              page={currentPage} // Control the selected page
              
            />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default PaginanationAndLimit;
