import { Paragraph, Small } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { FormattedDateTime } from "components/shared/FormattedDate";
import { formatCryptoDecimal, formatCurrency } from "utils/currencies";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyTextToClipboard } from "utils/utils";
import CryptoImg from "components/shared/CryptoImg";
import { Box, Button, ButtonBase } from "@mui/material";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import CryptoSymbol from "components/shared/CryptoSymbol";
import { ro } from "date-fns/locale";
import { getFormatedFiatAmount } from "utils/crypto";

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  padding: "10px",
  borderRadius: "10px",
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.secondary[200]
      : theme.palette.divider,
}));

function NetworkTag({ network }) {
  const networkTagColor = {
    "ERC-20": "primary.main",
    "TRC-20": "error.main",
    BSC: "warning.main",
    "BEP-20": "warning.main",
    SOL: "info.main",
  };
  return (
    <>
      <Small
        sx={{
          backgroundColor: networkTagColor[network],
          padding: "4px 6px",
          borderRadius: "5px",
          textAlign: "center",
          fontSize: "12px",
          fontWeight: "bold",
          color: "white",
        }}
      >
        {network}
      </Small>
    </>
  );
}

const columnShape = (syncWallet) => {
  return [
    {
      Header: "CRYPTO",
      accessor: "name",
      Cell: ({ row }) => {
        const { name, symbol, network_name, assetID } = row.original;
        // console.log("__ROW__", row);
        return (
          <>
            <FlexBox sx={{ alignItems: "center" }}>
              {/* <CryptoImg currency={symbol} size={25} /> */}
              <CryptoSymbol id={assetID} size={25} />
              <Paragraph ml={2} fontSize={13} color="text.secondary">
                {name}
              </Paragraph>
              <Box ml={1}>
                <NetworkTag network={network_name} />
              </Box>
            </FlexBox>
          </>
        );
      },
    },
    {
      Header: "ADDRESS",
      accessor: "asset_info.address",
      Cell: ({ value }) => (
        <>
          <FlexBox sx={{ alignItems: "center" }}>
            <Paragraph
              mr={1}
              fontSize={13}
              color="text.secondary"
              sx={{ maxWidth: "450px", overflowWrap: "break-word" }}
            >
              {value}
            </Paragraph>
            {value && (
              <ContentCopyIcon
                color={"primary"}
                onClick={() => copyTextToClipboard(value)}
              />
            )}
          </FlexBox>
        </>
      ),
    },
    {
      Header: "TAG/MEMO/COMMENT",
      accessor: "asset_info.tag",
      Cell: ({ value }) => {
        return (
          <>
            <FlexBox sx={{ alignItems: "center" }}>
              <Paragraph
                mr={1}
                fontSize={13}
                color="text.secondary"
                sx={{ maxWidth: "550px", overflowWrap: "break-word" }}
              >
                {value}
              </Paragraph>
              {value && (
                <ContentCopyIcon
                  color={"primary"}
                  onClick={() => copyTextToClipboard(value)}
                />
              )}
            </FlexBox>
          </>
        );
      },
    },
    // {
    //   Header: "BLOCKCHAIN",
    //   accessor: "blockchain",
    //   Cell: ({ value }) => (
    //     <Paragraph fontSize={13} color="text.secondary">
    //       {value}
    //     </Paragraph>
    //   ),
    // },
    {
      Header: "",
      accessor: "sdsds",
      Cell: ({ row }) => {
        return (
          <FlexBox sx={{ justifyContent: "end" }}>
            <Button
              size="small"
              color="primary"
              onClick={() => syncWallet(row.original.id)}
              variant="outlined"
            >
              <span> Sync Balance</span>
            </Button>
          </FlexBox>
        );
      },
    },
    {
      Header: "BALANCE",
      accessor: "asset_balance.",
      Cell: ({ row }) => {
        const { asset_balance, symbol, usd_balance, assetID } = row.original;
        return (
          <Box sx={{ textAlign: "end", maxWidth: "250px" }}>
            <Paragraph fontSize={13} color="text.secondary">
              {formatCryptoDecimal(asset_balance?.crypto_balance, 6)} {symbol}
            </Paragraph>
            <Paragraph fontSize={13} color="text.secondary" fontWeight={600}>
              {/* {formatCurrency(usd_balance)}USD */}
              {getFormatedFiatAmount(asset_balance?.crypto_balance, assetID)}USD
            </Paragraph>
          </Box>
        );
      },
    },
  ];
};

// sx={{
//   textAlign: "end !important",
//   paddingRight: "20px !important",
// }}
export default columnShape;
