const suggestions = {
  messages: [
    {
      title: "Welcome",
      text: "Hello, welcome! Please follow trade instructions closely, and I'll release your crypto as soon as I receive payment.",
    },
    {
      title: "Continue",
      text: "Welcome to my trade! Please let me know you're here so we can continue with the trade.",
    },
    {
      title: "Cancel",
      text: "Sorry, please cancel the trade. This gift card has already been redeemed.",
    },
    {
      title: "Discontinue",
      text: "Due to our trading policies, I have decided not to continue with this trade. Please cancel.",
    },
    {
      title: "Thanks",
      text: "Thanks for the amazing trade, and I'll hope to see you again next time. Have a great rest of your day!",
    },
  ],
  greetings: [
    "Welcome to my trade! Please let me know you're here so we can continue with the trade.",
    "Sorry, please cancel the trade. This gift card has already been redeemed.",
    "Due to our trading policies, I have decided not to continue with this trade. Please cancel.",
    "Thanks for the amazing trade, and I'll hope to see you again next time. Have a great rest of your day!",
  ],
  feedback: [
    "Sorry, please cancel the trade. This gift card has already been redeemed.",
    "Due to our trading policies, I have decided not to continue with this trade. Please cancel.",
    "Thanks for the amazing trade, and I'll hope to see you again next time. Have a great rest of your day!",
  ],
  kyc: [
    "{OTP} is your verification code.",
    "Verification code: {OTP}",
    "Your [Company Name] Verification code is: {OTP}",
  ],
};
export default suggestions;
