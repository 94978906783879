import { ArrowRightAlt, ConstructionOutlined } from "@mui/icons-material";
import { Box, ButtonBase, Stack, styled, Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AppPagination from "components/AppPagination";
import FlexBetween from "components/flexbox/FlexBetween";
import Scrollbar from "components/ScrollBar";
import NoResultFound from "components/shared/NoResultFound";
import { Paragraph } from "components/Typography";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { arrayIsEmpty } from "utils/utils";
import VaColumnShape from "./VaColumnShape";
import AddVaModal from "./AddVaModal";
// import ViewActivityModal from "./view";

// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 10px",
  color: theme.palette.text.primary,
}));

const VaTable = ({ data, num_pages, limit, onPageChange, getVA }) => {
  const [openModal, setOpenModal] = useState(false);

  const [vaData, SetVaData] = useState(false);

  const onCellClick = (value) => {
    // console.log(`Cell clicked: ${value}`);
    SetVaData(value);
    setOpenModal(true);
  };
  const tableData = useMemo(() => data, [data]);
  const columns = useMemo(() => VaColumnShape(onCellClick), []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: tableData, //UserRequestData
      pageCount: num_pages,
      manualPagination: true,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  ); // handle pagination

  // console.log(pageSize);
  // const handleChange = () => gotoPage(invoiceList.length);
  const handleChange = (_, currentPageNo) => onPageChange(currentPageNo);

  // Save the state for open and close the model
  const [selectedRecord, setSelectedRecord] = useState(null);

  // console.log(tableData);
  const navigate = useNavigate();
  return (
    <Box>
      <Scrollbar autoHide={false}>
        <Table
          {...getTableProps()}
          sx={{
            borderSpacing: "0 1rem",
            borderCollapse: "separate",
            minWidth: 900,
          }}
        >
          <TableHead>
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <HeadTableCell
                    key={index}
                    // {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </HeadTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {arrayIsEmpty(tableData) ? (
              <StyledTableCell colSpan={7} align="center">
                <NoResultFound />
              </StyledTableCell>
            ) : (
              page.map((row, index) => {
                prepareRow(row);

                return (
                  <StyledTableRow
                    key={index}
                    {...row.getRowProps()}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {row.cells.map((cell, index) => (
                      <BodyTableCell key={index} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </BodyTableCell>
                    ))}
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
          {/* <AppPagination
            shape="rounded"
            onChange={handleChange}
            count={num_pages}
            page={num_pages} // Control the selected page
          /> */}
        </Table>
        <AddVaModal
          onSuccess={() => getVA(1, "")}
          data={vaData}
          open={openModal}
          edit={true}
          onClose={() => setOpenModal(false)}
        />
      </Scrollbar>
    </Box>
  );
};

export default VaTable;
