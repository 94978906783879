import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import { H4, H6, Small } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import BackButton from "page-sections/profiles/profile/Trades/BackButton";
import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { FormattedDate } from "components/shared/FormattedDate";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function KycBasicInfo({ data, status }) {
  const navigate = useNavigate();
  return (
    <div>
      <Card
        sx={{
          minHeight: 700,
          height: "100%",
          pb: 1,
        }}
      >
        <Box>
          <FlexBox p={3} sx={{ justifyContent: "space-between" }}>
            <FlexBox sx={{ alignItems: "center" }}>
              <BackButton onClick={() => navigate("/dashboard/prepaid2Coin")} />
              {/* <ArrowBack /> */}
              <H4>{"Basic Details"}</H4>
            </FlexBox>

            <Small
              sx={{
                fontSize: 10,
                color: "white",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".5rem .5rem",
                backgroundColor: status ? "success.main" : "error.main",
              }}
            >
              {status ? "Approved" : "Pending"}
            </Small>
          </FlexBox>
        </Box>
        <Divider />
        {data && (
          <Box pt={3} pl={4}>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>First Name : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {data.first_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>Last Name : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {data.last_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>Gender : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {data.gender}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>Date Of Birth : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {FormattedDate(data.date_of_birth)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>Discord : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {data.discord}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>Mobile Number : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {data.mobile_number}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>State : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {data.state}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>Timezone : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {data.timezone}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>Zip Code : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {data.zip_code}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>Address Line 1 : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {data.address_line_1}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>Business Name : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {data.business_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>Business Type : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {data.business_type.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={4}>
                <Typography fontSize={14}>Country : </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  color={"text.secondary"}
                  fontSize={14}
                  fontWeight={500}
                >
                  {data.country}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {/* <Box pt={3} pl={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              //   justifyContent: "space-between",
            }}
          >
            <Typography fontSize={14}>First Name : </Typography>
            <Typography ml={4} fontSize={14} fontWeight={500}>
              {data.first_name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              //   justifyContent: "space-between",
            }}
          >
            <Typography fontSize={14}>Last Name : </Typography>
            <Typography ml={4} fontSize={14} fontWeight={500}>
              {data.last_name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              //   justifyContent: "space-between",
            }}
          >
            <Typography fontSize={14}>gender : </Typography>
            <Typography ml={4} fontSize={14} fontWeight={500}>
              {data.gender}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              //   justifyContent: "space-between",
            }}
          >
            <Typography fontSize={14}>Last Name : </Typography>
            <Typography ml={4} fontSize={14} fontWeight={500}>
              {data.last_name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              //   justifyContent: "space-between",
            }}
          >
            <Typography fontSize={14}>Last Name : </Typography>
            <Typography ml={4} fontSize={14} fontWeight={500}>
              {data.last_name}
            </Typography>
          </Box>
        </Box> */}
      </Card>
    </div>
  );
}

export default KycBasicInfo;
