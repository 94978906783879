import React from "react";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import countries from "../data/country";
import CountryFlag from "./shared/CountryFlag";

const CountryFlagByPhone = ({ phoneNumber }) => {
  const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);

  // Extract the country code
  const countryCode = phoneNumberObj.country;
  console.log("phoneNumberObj", phoneNumberObj);
  const country = countries.find((country) => country.code === countryCode);
  const countryName = country ? country.name : countryCode;

  if (!countryCode) return null;

  return (
    <CountryFlag
      code={countryCode}
      width="16px"
      content={countryName}
      showToolTip={true}
    />
  );
};

export default CountryFlagByPhone;
