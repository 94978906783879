import React, { useContext, useState } from "react";
// import { Button, Card, CardBody } from "reactstrap";
// import emitter from "../../helpers/emitter";
// import CraftNotice from "../widgets/Notice";
// import Textarea from "./Textarea";
import auto_suggestions from "../../../../data/auto_suggestions";
import Lottie from "lottie-react-web";
import kycAnimationLight from "../../../../assets/animations/kyc_animation_light.json";
import kycAnimationDark from "../../../../assets/animations/kyc_animation_dark.json";
// import ImageGallery, { ImageGalleryContext } from "../widgets/ImageGallery";
import { useRef } from "react";
// import PaxfulProfilePic from "../widgets/PaxfulProfilePic";
import platforms from "../../../../data/platforms";
// import PlatformLogo from "../widgets/PlatformLogo";
// import { TextareaMessagesSkeleton } from "../skeleton/AutomationsSkeleton";
import Platform from "components/shared/Platform";
import CraftNotice from "components/CraftNotice";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { Box, Button, Card, Grid } from "@mui/material";
import ATCraftNotice from "components/ATCraftNotice";
import FlexBox from "components/flexbox/FlexBox";
import { Paragraph } from "components/Typography";
import { UserContext } from "page-sections/user-list/ViewUser";
import Textarea from "./Textarea";
import PlatformLogo from "components/shared/PlatformLogo";
// import Textarea from "./Textarea";

function KYC({ settings, onChange, activeAccount }) {
  // const { settings, saveSettings } = useContext(SettingsContext);

  const { userdata, offers } = useContext(UserContext);
  const _setting = {
    enabled: true,
    message: "",
    otp_length: "",
    offers: [],
  };

  const [setting, setSetting] = useState(_setting);

  const addItem = () => {
    let _settings = [...settings];
    _settings.push(setting);

    onChange(_settings);

    //clear inputs
    // emitter.emit("clear:select:offers");
    setSetting(_setting);
  };

  const removeItem = (index) => {
    let _settings = [...settings];
    _settings.splice(index, 1);

    onChange(_settings);
  };

  const handleTextareaChange = (index, value) => {
    let _settings = [...settings];
    _settings[index] = {
      enabled: value.enabled,
      message: value.message,
      offers: value.offers,
    };

    onChange(_settings);
  };

  //already selected offers
  const selectedOffers = settings?.flatMap((message) => {
    return message.offers.map((offer) => {
      return offer.value;
    });
  });

  console.log("selectedOffers", selectedOffers);

  // console.log(selectedoffers)

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  return (
    <>
      <Box py={2} px={4}>
        <ATCraftNotice
          icon={
            <svg
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M3 6C2.4 6 2 5.6 2 5V3C2 2.4 2.4 2 3 2H5C5.6 2 6 2.4 6 3C6 3.6 5.6 4 5 4H4V5C4 5.6 3.6 6 3 6ZM22 5V3C22 2.4 21.6 2 21 2H19C18.4 2 18 2.4 18 3C18 3.6 18.4 4 19 4H20V5C20 5.6 20.4 6 21 6C21.6 6 22 5.6 22 5ZM6 21C6 20.4 5.6 20 5 20H4V19C4 18.4 3.6 18 3 18C2.4 18 2 18.4 2 19V21C2 21.6 2.4 22 3 22H5C5.6 22 6 21.6 6 21ZM22 21V19C22 18.4 21.6 18 21 18C20.4 18 20 18.4 20 19V20H19C18.4 20 18 20.4 18 21C18 21.6 18.4 22 19 22H21C21.6 22 22 21.6 22 21ZM16 11V9C16 6.8 14.2 5 12 5C9.8 5 8 6.8 8 9V11C7.2 11 6.5 11.7 6.5 12.5C6.5 13.3 7.2 14 8 14V15C8 17.2 9.8 19 12 19C14.2 19 16 17.2 16 15V14C16.8 14 17.5 13.3 17.5 12.5C17.5 11.7 16.8 11 16 11ZM13.4 15C13.7 15 14 15.3 13.9 15.6C13.6 16.4 12.9 17 12 17C11.1 17 10.4 16.5 10.1 15.7C10 15.4 10.2 15 10.6 15H13.4Z"
                fill="currentColor"
              />
              <path
                d="M9.2 12.9C9.1 12.8 9.10001 12.7 9.10001 12.6C9.00001 12.2 9.3 11.7 9.7 11.6C10.1 11.5 10.6 11.8 10.7 12.2C10.7 12.3 10.7 12.4 10.7 12.5L9.2 12.9ZM14.8 12.9C14.9 12.8 14.9 12.7 14.9 12.6C15 12.2 14.7 11.7 14.3 11.6C13.9 11.5 13.4 11.8 13.3 12.2C13.3 12.3 13.3 12.4 13.3 12.5L14.8 12.9ZM16 7.29998C16.3 6.99998 16.5 6.69998 16.7 6.29998C16.3 6.29998 15.8 6.30001 15.4 6.20001C15 6.10001 14.7 5.90001 14.4 5.70001C13.8 5.20001 13 5.00002 12.2 4.90002C9.9 4.80002 8.10001 6.79997 8.10001 9.09997V11.4C8.90001 10.7 9.40001 9.8 9.60001 9C11 9.1 13.4 8.69998 14.5 8.29998C14.7 9.39998 15.3 10.5 16.1 11.4V9C16.1 8.5 16 8 15.8 7.5C15.8 7.5 15.9 7.39998 16 7.29998Z"
                fill="currentColor"
              />
            </svg>
          }
          type={"primary"}
          title={
            <FlexBox
              color="black"
              sx={{ alignItem: "center", flex: "wrap" }}
              className="d-flex align-items-center flex-wrap"
            >
              <FlexBox
                sx={{ alignItem: "center" }}
                className="d-flex align-items-baseline"
              >
                {"Set Automatic KYC for"}
              </FlexBox>

              <FlexBox
                sx={{ alignItem: "center", position: "relative" }}
                className="user-inner-wrap mt-1 mb-1"
              >
                <Box
                  mx={0.5}
                  sx={{ alignItem: "center", position: "relative" }}
                >
                  <PaxfulProfilePic
                    account={activeAccount}
                    size={20}
                    className={"ms-2"}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: -4,
                      left: 10,
                    }}
                  >
                    <Platform
                      platform={activeAccount?.platform}
                      size={15}
                      className={"w-20px me-1"}
                    />
                  </Box>
                </Box>

                <span className="ms-2">{activeAccount?.paxful_username}</span>
              </FlexBox>
            </FlexBox>
          }
          content={
            activeAccount && (
              <Paragraph color="black" fontSize={12}>
                Auto KYC gives the vendor the ability to setup SMS-based KYC
                (Know Your Customer) verification directly in {""}
                <Box
                  mx={0.3}
                  sx={{
                    alignItem: "center",
                    position: "relative",
                    display: "inline",
                  }}
                >
                  <PaxfulProfilePic
                    account={activeAccount}
                    size={20}
                    className={"ms-2"}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: -10,
                      left: 10,
                    }}
                  >
                    <Platform
                      platform={activeAccount?.platform}
                      size={15}
                      className={"w-20px me-1"}
                    />
                  </Box>
                </Box>
                <span className="ms-2">{activeAccount?.paxful_username}</span>{" "}
                {platforms[activeAccount?.platform].title} trades on the Venva
                trading tool, now allowing the vendor & team members to perform
                KYC verification on trades. Simply select your offers and choose
                one of the predetermined SMS verification text message formats
                you want your trade partners to receive. Please note that KYC is
                an optional feature, meaning that trades with KYC enabled can
                still be released without KYC being completed in the trade.
              </Paragraph>
            )
          }
        />
        <Box
          py={2}
          sx={{ borderBottom: "1px dashed lightgrey" }}
          className="separator separator-dashed my-5 my-xl-10"
        ></Box>
        <Grid container spacing={3} className="mb-1">
          <Grid item lg={2} xl={2} className="d-none d-xl-block left">
            {/* Your Lottie animation code */}
            <Box sx={{ transform: " translate3d(0px, 62px, 0px)" }}>
              {/* {settings && settings.theme == "light" && (
                <Lottie
                  options={{
                    animationData: kycAnimationLight,
                  }}
                  // width="100%"
                />
              )}
              {settings && settings.theme == "dark" && (
                <Lottie
                  options={{
                    animationData: kycAnimationDark,
                  }}
                  // width="100%"
                />
              )} */}
              <Lottie
                options={{
                  animationData: kycAnimationLight,
                }}
                // width="100%"
              />
            </Box>
          </Grid>
          <Grid item xs={5} xl={5} mt={3} className="mid">
            {/* <Grid item md={9} mt={3}> */}
            <Box
              p={2}
              sx={{
                // border: "1px solid lightgrey",
                boxShadow: "0px 8px 24px 0px rgba(140, 149, 159, 0.2)",
              }}
              borderRadius={"5px"}
              className="card-shadow overflow--hidden"
            >
              <Box style={{ pointerEvents: "none" }}>
                {activeAccount && (
                  <Textarea
                    messageSuggestions={auto_suggestions.kyc}
                    selectedOffers={selectedOffers}
                    {...setting}
                    onChange={(value) => {
                      setSetting(value);
                      console.log(setting.message.includes("{OTP}"));
                    }}
                    uploadImage={false}
                    id="new_kyc"
                    buttonLabel={false}
                    emoji={false}
                    kycMessage={true}
                    valid={
                      setting.message == "" || setting.message.includes("{OTP}")
                    }
                  />
                )}
                <FlexBox mt={3} className="d-flex justify-content-start mt-3">
                  <div className="col-md-9 d-flex justify-content-start mt-3">
                    <Button
                      variant="contained"
                      color="success"
                      type="button"
                      disabled={
                        setting.offers.length == 0 ||
                        setting.message == "" ||
                        !setting.message.includes("{OTP}")
                      }
                      className="cursor-pointer btn btn-success btn-sm fs-6 fw-bolder"
                      onClick={() => addItem()}
                    >
                      {" "}
                      Save
                    </Button>
                  </div>
                </FlexBox>
              </Box>
            </Box>
            {/* </Grid> */}
          </Grid>
          <Grid item xs={5} xl={5} className="right">
            <Grid container direction="column" mt-lg={0}>
              {settings &&
                settings.map((setting, index) => (
                  <Box
                    style={{ pointerEvents: "none" }}
                    mt={3}
                    p={2}
                    sx={{
                      // border: "1px solid lightgrey",
                      boxShadow: "0px 8px 24px 0px rgba(140, 149, 159, 0.2)",
                    }}
                    borderRadius={"5px"}
                    className="card-shadow overflow--hidden"
                  >
                    <Box>
                      <Textarea
                        messageSuggestions={auto_suggestions.kyc}
                        isEditing={true}
                        label={"KYC"}
                        onChange={(newValue, messageChanged) => {
                          let doUpdate = true;
                          if (
                            messageChanged &&
                            newValue.message == setting.message
                          ) {
                            doUpdate = false;
                          }
                          if (doUpdate) handleTextareaChange(index, newValue);
                        }}
                        {...setting}
                        onRemove={() => removeItem(index)}
                        uploadImage={false}
                        id={`edit_kyc_${index}`}
                        emoji={false}
                        valid={setting.message?.includes("{OTP}")}
                        kycMessage={true}
                        platform={activeAccount?.platform}
                      />
                    </Box>
                  </Box>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default KYC;
