import React from "react";
import { H5, H6, Small, Tiny } from "components/Typography";
import { getRoleColor } from "utils/utils";
import { Box } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import { greyLight } from "theme/colors";
import AppAvatar from "components/avatars/AppAvatar";
import { COMMON_API_BASE_URL } from "config";

function VASymbol({ user, size }) {
  return (
    <>
      {user && user?.profile_pic?.file_path ? (
        <AppAvatar
          alt="Team Member"
          src={`${
            user.profile_pic
              ? `${COMMON_API_BASE_URL}/${user.profile_pic.file_path}`
              : `${user.social_profile_pic}`
            // "/static/avatar/001-man.svg"
          }`}
          sx={{
            width: size ? size : 35,
            height: size ? size : 35,
          }}
        />
      ) : (
        <FlexBox
          sx={{
            width: size ? size : 35,
            height: size ? size : 35,
            borderRadius: "50%",
            fontSize: size ? 10 : 16,
            color: "#fff",
            textAlign: "center",
            padding: "5px 0",
            backgroundColor: user?.role
              ? getRoleColor(user?.role)
              : "lightgrey",
            alignItems: "center",
            justifyContent: "center",
            color: user?.role ? "white" : "black",
          }}
        >
          {user?.first_name
            ? user?.first_name?.substr(0, 1).toUpperCase()
            : user?.email?.substr(0, 1).toUpperCase()}
        </FlexBox>
      )}
    </>
  );
}

export default VASymbol;
