import { Small } from "components/Typography";
import React from "react";
import { getCurrencyNameByCode } from "utils/currencies";
import CryptoSymbol from "./CryptoSymbol";

function Balance({ crypto, fiatBalance, assetBalance, assetID }) {
  console.log(
    "crypto, fiatBalance, assetBalance, assetID",
    crypto,
    fiatBalance,
    assetBalance,
    assetID
  );
  return (
    <>
      {/* <span className="symbol symbol-35px bg-light inner_wrapper">
        <img
          src={`/media/svg/coins/${crypto.toLowerCase()}.svg`}
          alt={crypto}
          width={"20px"}
          className="me-2"
          style={{ marginRight: "10px" }}
        />     
      </span> */}
      <Small mr={1}>
        <CryptoSymbol id={assetID} />
      </Small>

      <div className="current-balance">
        <Small fontSize={14} className="text-gray-800 fw-semibold fs-6">
          Current {getCurrencyNameByCode(crypto)} Balance
        </Small>
        <div>
          <Small mr={1} fontSize={16} className="text-gray-800 fs-3 fw-bolder">
            {fiatBalance}
          </Small>
          <Small
            fontSize={16}
            color={"text.secondary"}
            className="ms-3 subtext-color fs-4 fw-semibold"
          >
            {assetBalance} {crypto}
          </Small>
        </div>
      </div>
    </>
  );
}

export default Balance;
