import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { H5, Paragraph } from "components/Typography";
import Invoice from "icons/sidebar/Invoice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { LoadingButton } from "@mui/lab";
import TradeHistoryList from "page-sections/user-list/TradeHistoryList";
import TradeServices from "services/TradeService";
import HireVirtualAssistantList from "page-sections/hire-virtual-assistant/HireVirtualAssistantList";
import HireVirtualAssistantService from "services/HireVirtualAssistantService";
import AddHireVirtualAssistant from "page-sections/hire-virtual-assistant/AddHireVirtualAssistant";
import { Add } from "@mui/icons-material";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const HireVirtualAssistant = (props) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);

  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItem, setFilteredItem] = useState();
  const [option, setOption] = React.useState("");
  const [openModal, setOpenModal] = useState(false);

  const [data, setData] = useState(null);

  const getAllHireVA = async (page = 1, limit = 50, search = "") => {
    setisLoading(true);
    const resp = await HireVirtualAssistantService.getHireVirtualAssistant(
      page,
      limit,
      search
    );
    setData(resp);
    setPage(page);
    setisLoading(false);
  };

  const reload = () => {
    // setPage(1);
    setLimit(50);
    setSearchValue("");
    getAllHireVA();
  };

  const handlePageChange = (page) => {
    // setPage(page);
    getAllHireVA(page, limit, searchValue);
  };

  const handleLimitChange = (event) => {
    // setPage(1);
    setLimit(event.target.value);
    getAllHireVA(1, event.target.value, searchValue);
  };

  useEffect(() => {
    getAllHireVA(1, limit, searchValue);
  }, []);
  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
    getAllHireVA(1, limit, searchValue);
  };

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("Virtual Assistant")}</H5>
        </Stack>

        <StyledStack alignItems="center" direction="row" gap={2}>
          <SearchInput
            disable_border="true"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
          <Button
            onClick={() => setOpenModal(true)}
            variant="contained"
            startIcon={<Add />}
            sx={{
              flexShrink: 0,
            }}
          >
            Add New
          </Button>
        </StyledStack>
      </FlexBetween>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data && (
          <HireVirtualAssistantList
            {...data}
            onPageChange={handlePageChange}
            currentPage={page}
            handleLimitChange={handleLimitChange}
            limit={limit}
            getAllHireVA={getAllHireVA}
          />
        )
      )}

      <AddHireVirtualAssistant
        onSuccess={() => getAllHireVA()}
        open={openModal}
        onClose={() => setOpenModal(false)}
        // callBack={() => getVA(1, "")}
      />
    </Box>
  );
};

export default HireVirtualAssistant;
