import axios from "../utils/axios";

const Prepaid2CoinService = {};

Prepaid2CoinService.getP2P = function (page, limit) {
  return axios({
    url: `/prepaid/?page=${page}&limit=${limit}`,
    method: "GET",
  });
};

Prepaid2CoinService.getSingleP2P = function (id) {
  return axios({
    url: `/prepaid/${id}`,
    method: "GET",
  });
};

export default Prepaid2CoinService;
