import React, { useContext } from "react";
import WebhookService from "services/WebhookService";
import { useEffect, useState } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import { Box, CircularProgress, Stack } from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import Invoice from "icons/duotone/Invoice";
import { H5 } from "components/Typography";
import InvoiceListTable from "page-sections/invoice/list";
import { t } from "i18next";
import { LoadingButton } from "@mui/lab";

function UserWebhook() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  // get data from usercontext api
  const { userdata } = useContext(UserContext);
  // console.log(userdata.paxfullAccounts[0]._id);
  const account_id = userdata.paxfullAccounts[0]?._id;
  const [isLoading, setisLoading] = useState(false);

  /**
   * =====================
   * get user's webhook
   * =====================
   */
  const [webhook, setWebhook] = useState(null);
  const userWebhook = async (account_id, page = "1", limit = "50") => {
    setisLoading(true);
    const resp = await WebhookService.getWebhook(account_id, page, limit);
    setWebhook(resp);
    setisLoading(false);
  };
  useEffect(() => {
    if (account_id) userWebhook(account_id);
  }, []);
  // ================================

  const reload = () => {
    if (account_id) userWebhook(account_id);
  };

  const handlePageChange = (page) => {
    setPage(page);
    userWebhook(account_id, page, limit);
  };

  const handleLimitChange = (event) => {
    setPage(1);
    setLimit(event.target.value);
    userWebhook(account_id, page, event.target.value);
  };

  return (
    <>
      <Box pt={2} pb={4}>
        <FlexBetween flexWrap="wrap" gap={1} mb={2}>
          <Stack direction="row" alignItems="center">
            <IconWrapper>
              <Invoice
                sx={{
                  color: "primary.main",
                }}
              />
            </IconWrapper>
            <H5>{t("WebHooks")}</H5>
          </Stack>
          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </FlexBetween>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            {" "}
            <CircularProgress />
          </Box>
        ) : (
          webhook && (
            <InvoiceListTable
              {...webhook}
              onPageChange={handlePageChange}
              currentPage={page}
              handleLimitChange={handleLimitChange}
              limit={limit}
              loading={isLoading}
            />
          )
        )}
      </Box>
    </>
  );
}

export default UserWebhook;
