import React from "react";
import Timezone from "./shared/Timezone";
import { API_BASE_URL } from "config";
import { Small } from "./Typography";
import CountryFlag from "./shared/CountryFlag";

function ReleasedByVA({ trade }) {
  return (
    <>
      <div className="d-flex align-items-start text-start mt-2">
        <span className="symbol symbol-30px symbol-circle">
          <img
            src={`${API_BASE_URL}/${trade.releasedBy.profile_pic.file_path.md}`}
          />
        </span>
        <div className="ms-2">
          <span className="fs-6 text-dark ms-2">
            {trade.releasedBy.first_name}
          </span>
          <span className="ms-2">
            <CountryFlag code={trade.releasedBy.country} />
          </span>
          <div className="d-flex mt-1 mb-1">
            <Small
              sx={{
                width: 100,
                fontSize: 10,
                color: "primary.main",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".2rem 1rem",
                backgroundColor: "lightgrey",
                color: "white",
              }}
            >
              ASSISTANT
            </Small>

            <Timezone
              className={"timezone-tooltip-badge"}
              timezone={trade.releasedBy.timezone}
              showTooltip={false}
              id={`member-${trade.releasedBy._id}`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ReleasedByVA;
