import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import FlexBox from "components/flexbox/FlexBox";
import { Typography } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "white",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  minWidth: "480px",
  // padding: 40,
};
function ConfirmBox({
  title,
  content,
  confirmButtonLabel,
  cancelButtonLabel,
  confirmButtonColor,
  showConfirm,
  onCancel,
  onConfirm,
}) {
  return (
    <Modal
      open={showConfirm}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style}>
        <Box p={3}>
          <FlexBox sx={{ alignItem: "center" }}>{title}</FlexBox>
        </Box>
        <Box sx={{ borderBottom: "1px solid lightgray" }}></Box>
        <Box px={2} py={3}>
          {content}
        </Box>
        <Box sx={{ borderBottom: "1px solid lightgray" }}></Box>

        <Box>
          <FlexBox py={3} sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              type="button"
              color="success"
              // color={confirmButtonColor}
              onClick={onConfirm}
            >
              {/* {confirmButtonLabel} */}
              Remove
            </Button>

            <Button
              sx={{
                marginLeft: "10px",
              }}
              variant="contained"
              type="button"
              color="error"
              onClick={onCancel}
            >
              {/* {cancelButtonLabel} */}
              Cancel
            </Button>
          </FlexBox>
        </Box>
      </Box>
    </Modal>
  );
}

ConfirmBox.defaultProps = {
  confirmButtonLabel: "Confirm",
  cancelButtonLabel: "Cancel",
  confirmButtonColor: "danger",
};

export default ConfirmBox;
