import React, { useContext, useState } from "react";
// import { Button, Card, CardBody } from "reactstrap";
// import emitter from "../../helpers/emitter";
// import CraftNotice from "../widgets/Notice";
// import Textarea from "./Textarea";
import auto_suggestions from "../../../../data/auto_suggestions";
import Lottie from "lottie-react-web";
import feedbackAnimationLight from "../../../../assets/animations/Feedback-01-Light.json";
import feedbackAnimationDark from "../../../../assets/animations/Feedback-01-Dark.json";
// import ImageGallery, { ImageGalleryContext } from "../widgets/ImageGallery";
import { useRef } from "react";
// import PaxfulProfilePic from "../widgets/PaxfulProfilePic";
import platforms from "../../../../data/platforms";
// import PlatformLogo from "../widgets/PlatformLogo";
// import { TextareaMessagesSkeleton } from "../skeleton/AutomationsSkeleton";
import Platform from "components/shared/Platform";
import CraftNotice from "components/CraftNotice";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { Box, Button, Card, Grid } from "@mui/material";
import ATCraftNotice from "components/ATCraftNotice";
import FlexBox from "components/flexbox/FlexBox";
import { Paragraph } from "components/Typography";
import { UserContext } from "page-sections/user-list/ViewUser";
import Textarea from "./Textarea";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SettingsContext } from "contexts/settingsContext";
import PlatformLogo from "components/shared/PlatformLogo";

// import Textarea from "./Textarea";

function Feedback({ feedbacks, onChange, activeAccount }) {
  const { settings, saveSettings } = useContext(SettingsContext);

  const { userdata, offers } = useContext(UserContext);

  const _message = {
    enabled: true,
    message: "",
    offers: [],
  };

  const [message, setMessage] = useState(_message);

  const addItem = () => {
    let _feedbacks = [...feedbacks];
    _feedbacks.push(message);

    onChange(_feedbacks);

    //clear inputs
    // emitter.emit("clear:select:offers");
    setMessage(_message);
  };

  const removeItem = (index) => {
    // let _feedbacks = [...feedbacks];
    // _feedbacks.splice(index, 1);

    let _feedbacks = [...feedbacks].filter((value, _index) => {
      return index != _index;
    });

    onChange(_feedbacks);
  };

  const handleTextareaChange = (index, value) => {
    let _feedbacks = [...feedbacks];
    _feedbacks[index] = { ...value };

    onChange(_feedbacks);
  };

  // console.log(feedbacks);

  //already selected offers
  const selectedOffers = feedbacks?.flatMap((feedback) => {
    return feedback.offers.map((offer) => {
      return offer.value;
    });
  });

  console.log("selectedOffers", selectedOffers);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const ordered = reorder(
      feedbacks,
      result.source.index,
      result.destination.index
    );

    onChange([...ordered]);
  };

  const hasSpaceOnly = /^\s*$/;
  const noMessage = hasSpaceOnly.test(message.message);
  return (
    <>
      <Box py={2} px={4}>
        <ATCraftNotice
          icon={
            <svg
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                fill="currentColor"
              />
              <path
                d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                fill="currentColor"
              />
              <path
                d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                fill="currentColor"
              />
            </svg>
          }
          type={"primary"}
          title={
            <FlexBox
              color="black"
              sx={{ alignItems: "center" }}
              className="d-flex align-items-center"
            >
              <div className="d-flex align-items-baseline">
                {"Set Automatic Feedback for"}
              </div>

              <FlexBox
                sx={{ alignItem: "center", position: "relative" }}
                className="user-inner-wrap mt-1 mb-1"
              >
                <Box
                  mx={0.5}
                  sx={{ alignItem: "center", position: "relative" }}
                >
                  <PaxfulProfilePic
                    account={activeAccount}
                    size={20}
                    className={"ms-2"}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: -4,
                      left: 10,
                    }}
                  >
                    <Platform
                      platform={activeAccount?.platform}
                      size={15}
                      className={"w-20px me-1"}
                    />
                  </Box>
                </Box>

                <span className="ms-2">{activeAccount?.paxful_username}</span>
              </FlexBox>
            </FlexBox>
          }
          content={
            activeAccount && (
              <Paragraph color="black" fontSize={13}>
                Auto Feedback automatically leaves positive feedback after all
                completed{" "}
                <Box
                  mx={0.3}
                  sx={{
                    alignItem: "center",
                    position: "relative",
                    display: "inline",
                  }}
                >
                  <PaxfulProfilePic
                    account={activeAccount}
                    size={20}
                    className={"ms-2"}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: -10,
                      left: 10,
                    }}
                  >
                    <Platform
                      platform={activeAccount?.platform}
                      size={15}
                      className={"w-20px me-1"}
                    />
                  </Box>
                </Box>
                {platforms[activeAccount?.platform].title} trades on the Venva
                platform, increasing your P2P accounts reputation statistics.
                Simply type your feedback message and select which offers it
                should apply to.
              </Paragraph>
            )
          }
        />
        <Box
          py={2}
          sx={{ borderBottom: "1px dashed lightgrey" }}
          className="separator separator-dashed my-5 my-xl-10"
        ></Box>
        <Grid container spacing={3} className="mb-1">
          <Grid item lg={2} xl={2} className="d-none d-xl-block left">
            {/* Your Lottie animation code */}
            <Box
              style={{ pointerEvents: "none" }}
              sx={{ transform: " translate3d(0px, 62px, 0px)" }}
            >
              {settings && settings.theme == "light" && (
                <Lottie
                  options={{
                    animationData: feedbackAnimationLight,
                  }}
                  // width="100%"
                />
              )}
              {settings && settings.theme == "dark" && (
                <Lottie
                  options={{
                    animationData: feedbackAnimationDark,
                  }}
                  // width="100%"
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={5} xl={5} className="mid" mt={3}>
            {/* <Grid item md={9} mt={3}> */}
            <Box
              p={2}
              sx={{
                // border: "1px solid lightgrey",
                boxShadow: "0px 8px 24px 0px rgba(140, 149, 159, 0.2)",
              }}
              borderRadius={"5px"}
              className="card-shadow overflow--hidden"
            >
              <Box style={{ pointerEvents: "none" }}>
                {activeAccount && (
                  <Textarea
                    messageSuggestions={auto_suggestions.feedback}
                    selectedOffers={selectedOffers}
                    {...message}
                    onChange={(value) => {
                      setMessage(value);
                    }}
                    valid={true}
                  />
                )}
                <FlexBox mt={3} className="d-flex justify-content-start mt-3">
                  <div className="col-md-9 d-flex justify-content-start mt-3">
                    <Button
                      variant="contained"
                      color="success"
                      type="button"
                      disabled={
                        noMessage ||
                        message.message == "" ||
                        message.offers.length === 0
                      }
                      className="cursor-pointer btn btn-success btn-sm fs-6 fw-bolder"
                      onClick={() => addItem("field")}
                    >
                      {" "}
                      Save
                    </Button>
                  </div>
                </FlexBox>
              </Box>
            </Box>
            {/* </Grid> */}
          </Grid>
          <Grid item xs={5} xl={5} className="right">
            <Grid container direction="column" mt-lg={0}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="droppable-feedback"
                  direction="vertical"
                >
                  {(provided, snapshot) => (
                    <div className="d-flex flex-column" ref={provided.innerRef}>
                      {feedbacks &&
                        feedbacks.map((message, index) => (
                          <Draggable
                            key={index}
                            draggableId={`feedback-card-${index}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Box
                                style={{ pointerEvents: "none" }}
                                mt={3}
                                p={2}
                                sx={{
                                  // border: "1px solid lightgrey",
                                  boxShadow:
                                    "0px 8px 24px 0px rgba(140, 149, 159, 0.2)",
                                }}
                                borderRadius={"5px"}
                                className="card-shadow overflow--hidden"
                              >
                                <Box>
                                  {/* <span className="grip-icon">
                                  <i className="bi bi-grip-vertical"></i>
                                </span> */}
                                  <Textarea
                                    messageSuggestions={
                                      auto_suggestions.feedback
                                    }
                                    isEditing={true}
                                    label={"Feedback"}
                                    id={index}
                                    onChange={(newValue, messageChanged) => {
                                      let doUpdate = true;
                                      if (
                                        messageChanged &&
                                        newValue.message == message.message
                                      ) {
                                        doUpdate = false;
                                      }
                                      if (doUpdate)
                                        handleTextareaChange(index, newValue);
                                    }}
                                    {...message}
                                    onRemove={() => removeItem(index)}
                                    valid={true}
                                    platform={activeAccount?.platform}
                                  />
                                </Box>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Feedback;
