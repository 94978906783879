import axios from "../utils/axios";

const HiringRequestService = {};

HiringRequestService.getHireRequest = function (page, limit, status, search) {
  return axios({
    url: `/hiring-request?page=${page}&limit=${limit}&status=${status}&search=${search}`,
    method: "GET",
  });
};

HiringRequestService.singleHireRequest = function (id, page, limit) {
  return axios({
    url: `/hiring-request/view/${id}?page=${page}&limit=${limit}`,
    method: "GET",
  });
};
HiringRequestService.UserHireRequest = function (id, page, limit) {
  return axios({
    url: `/hiring-request/${id}?page=${page}&limit=${limit}`,
    method: "GET",
  });
};

HiringRequestService.AssignedHireRequest = function (id, data) {
  return axios({
    url: `/hiring-request/assigned/${id}`,
    method: "PUT",
    data,
  });
};
HiringRequestService.unassignedHireRequest = function () {
  return axios({
    url: `/hiring-request/unassigned-request`,
    method: "GET",
  });
};

export default HiringRequestService;
