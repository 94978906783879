import axios from "../utils/axios";

const SettingService = {};

SettingService.saveSettings = function (
  category,
  settings,
  account = null,
  id,
  message = true
) {
  return axios({
    url: `/settings/${category}/${id}?message=${message ? "1" : "0"}`,
    method: "POST",
    data: {
      settings,
      account,
    },
  });
};

SettingService.getSettings = function (account, id) {
  return axios({
    url: `/settings/${id}?account=${account}`,
    method: "GET",
  });
};

export default SettingService;
