import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  styled,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import AppPagination from "components/AppPagination";
import Scrollbar from "components/ScrollBar";
import BlankCheckBoxIcon from "icons/BlankCheckBoxIcon";
import CheckBoxIcon from "icons/CheckBoxIcon";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import columnShape from "page-sections/user-list/columnShape";
import HeadingArea from "page-sections/user-list/heading-area";
import UserService from "../services/UserService";
import { connect } from "react-redux";
import DeleteDialog from "components/shared/DeleteDialog";
import CircularProgress from "@mui/material/CircularProgress";
import {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { getUsers } from "redux/actions/user";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { arrayIsEmpty, copyTextToClipboard } from "utils/utils";
import NoResultFound from "components/shared/NoResultFound";
import { H5, H6, Paragraph, Small, Span } from "components/Typography";
import { LocalFireDepartment } from "@mui/icons-material";
import SearchInput from "components/input-fields/SearchInput";
import AddEmployeeModal from "page-sections/data-table/dataTableV2/AddEmployeeModal";
import { UserContextData } from "App";
import PaginanationAndLimit from "components/shared/PaginanationAndLimit";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import IconWrapper from "components/IconWrapper";
import GroupSenior from "icons/GroupSenior";
import Log from "icons/duotone/Log";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import JSONPretty from "react-json-pretty";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Webhooklog from "services/Webhooklog";
import { FormattedDateTime, FromNow } from "components/shared/FormattedDate";

const Wrapper = styled(Box)(() => ({
  // display: "flex",
  // flexWrap: "wrap",
  // alignItems: "center",
  // justifyContent: "space-between",
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 13,
  // fontWeight: 500,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const SelectCheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  useEffect(() => {
    if (resolvedRef) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);
  return (
    <Checkbox
      {...rest}
      disableRipple
      ref={resolvedRef}
      icon={<BlankCheckBoxIcon fontSize="small" color="disabled" />}
      checkedIcon={<CheckBoxIcon fontSize="small" color="primary" />}
    />
  );
});

const WebhookLog = () => {
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 50,
    tabValue: "",
  });
  const [option, setOption] = useState("active");
  const [tabValue, setTabValue] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  const handleTabChange = (event, newValue) => {
    const filters = { ...filter };
    filters.limit = 50;
    filters.page = 1;
    filters.tabValue = newValue;
    setSelectedRow(null);
    setFilter(filters);
  };
  const handleChangePage = (event, newPage) => {
    const filters = { ...filter };
    filters.page = newPage + 1; // Adjust for 1-based index
    setFilter(filters);
  };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const filters = { ...filter };
    filters.limit = newLimit;
    filters.page = 1;
    setFilter(filters);
  };

  const webhookLog = async (filter) => {
    const resp = await Webhooklog.getWebhooklog(filter);
    setData(resp);
  };

  useEffect(() => {
    webhookLog(filter);
  }, [filter]);

  console.log("data", data);

  return (
    <Box pt={2} pb={4}>
      <Card
        sx={{
          py: 2,
          height: "85vh",
        }}
      >
        <Box px={3}>
          <Wrapper gap={1}>
            <FlexBetween flexWrap="wrap" gap={1} mb={2}>
              <FlexBox alignItems="center">
                <IconWrapper>
                  <Log
                    sx={{
                      color: "primary.main",
                    }}
                  />
                </IconWrapper>
                <H5>{`Webhook Log`}</H5>
              </FlexBox>
            </FlexBetween>
          </Wrapper>
        </Box>

        <Box py={4}>
          <Grid container>
            <Grid
              xl={4}
              md={4}
              xs={12}
              sx={{
                // borderLeft: 1,
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              <Box my={2.5} sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={filter.tabValue}>
                  <TabList
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="All" value="" />
                    <Tab label="Stripe" value="stripe" />
                    <Tab label="Fireblock" value="fireblock" />
                    <Tab label="Open Node" value="opennode" />
                  </TabList>
                </TabContext>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
              </Box>
              <TableContainer px={5} sx={{ height: "55vh" }}>
                <Box px={1}>
                  <Table px={5} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>TYPE</TableCell>
                        <TableCell align="left">DATE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data && data.data && arrayIsEmpty(data.data) ? (
                        <TableCell colSpan={2} align="center">
                          <NoResultFound />
                        </TableCell>
                      ) : (
                        data &&
                        data.data &&
                        data.data.map((row) => (
                          <TableRow
                            key={row._id}
                            onClick={() => handleRowClick(row)}
                            sx={{
                              cursor: "pointer",
                              backgroundColor:
                                selectedRow && selectedRow._id === row._id
                                  ? "primary.300"
                                  : "inherit",
                              borderRadius: "25px !important",
                            }}
                          >
                            <TableCell
                              align="left"
                              sx={{ paddingLeft: "5px !important" }}
                            >
                              <Paragraph fontSize={13} color="text.secondary">
                                {row.type}
                              </Paragraph>
                            </TableCell>
                            <TableCell align="left">
                              {
                                <>
                                  <Paragraph
                                    fontSize={13}
                                    color="text.secondary"
                                  >
                                    {<FormattedDateTime date={row.createdAt} />}
                                  </Paragraph>
                                  <Small
                                    sx={{
                                      width: 100,
                                      fontSize: 10,
                                      color: "#fff",
                                      borderRadius: "4px",
                                      textAlign: "center",
                                      padding: ".2rem 1rem",
                                      backgroundColor: "lightgrey",
                                      color: "black",
                                    }}
                                  >
                                    <FromNow date={row.createdAt} />
                                  </Small>
                                </>
                              }
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </TableContainer>

              {data && (
                <Box mt={1}>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={data.num_pages * filter.limit}
                    rowsPerPage={filter.limit}
                    page={filter.page ? filter.page - 1 : 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleLimitChange}
                  />
                </Box>
              )}
            </Grid>

            <Grid
              xl={8}
              md={8}
              xs={12}
              sx={{
                borderLeft: 1,
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              <Box sx={{ width: "100%", typography: "body1", height: "100vh" }}>
                <Box my={2.9} sx={{ alignItems: "center" }}>
                  <Typography
                    ml={1}
                    fontSize={15}
                    fontWeight={500}
                    color="text.secondary"
                  >
                    Response
                  </Typography>
                </Box>
                <Box
                  // mt={6.4}
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                ></Box>
                {selectedRow && (
                  <Box p={2} sx={{ maxHeight: "500px", overflow: "auto" }}>
                    <FlexBetween sx={{ textAlign: " center" }}>
                      <Paragraph fontSize={13}>{"	Payload"}</Paragraph>
                      <ContentCopyIcon
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          copyTextToClipboard(
                            JSON.stringify(selectedRow.response)
                          )
                        }
                      />
                    </FlexBetween>
                    <JSONPretty
                      id="json-pretty"
                      data={selectedRow.response}
                      style={{ color: "grey" }} // Apply grey color to JSON text
                    ></JSONPretty>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default WebhookLog;
