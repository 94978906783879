// import { EventEmitter } from "fbemitter";
import EventEmitter from "events";

var emitter = new EventEmitter();

export const emit = (name, data) => {
  emitter.emit(name, data);
};

export default emitter;
