import React from "react";
import { FromNow } from "components/shared/FormattedDate";
import { H5, H6, Small, Tiny } from "components/Typography";
import moment from "moment-timezone";

function LastActive({ date }) {
  const fiveMinutesAgo = moment().subtract(5, "minutes");
  const is_online = moment(date).isAfter(fiveMinutesAgo);

  return (
    <>
      {date == undefined ? (
        <Small
          sx={{
            width: 100,
            fontSize: 10,
            color: "#fff",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor: "grey.500",
          }}
        >
          Never
        </Small>
      ) : (
        <Small
          sx={{
            width: 100,
            fontSize: 10,
            color: "#fff",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor: is_online ? "success.main" : "grey.500",
          }}
        >
          {is_online ? "Online" : <FromNow date={date} />}
        </Small>
      )}
    </>

    // <Small
    //   sx={{
    //     width: 100,
    //     fontSize: 10,
    //     color: "#fff",
    //     borderRadius: "4px",
    //     textAlign: "center",
    //     padding: ".2rem 1rem",
    //     backgroundColor: is_online ? "success.main" : "grey.500",
    //   }}
    // >
    //   {is_online ? "Online" : <FromNow date={date} />}
    // </Small>
  );
}

export default LastActive;
