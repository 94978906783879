import { SvgIcon } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const Accounts = (props) => {
  return (
    <>
      <SvgIcon viewBox="0 0 15 15" {...props}>
        <AccountBalanceIcon />
      </SvgIcon>
    </>
  );
};

export default Accounts;
