import { List } from "@mui/material";
import axios from "../utils/axios";

const VaService = {};

VaService.getList = function (skip, limit) {
  return axios({
    url: `/va-agency/?skip=${skip}&limit=${limit}`,
    method: "GET",
  });
};

VaService.getSingleVa = function (id) {
  return axios({
    url: `/va-agency/${id}`,
    method: "GET",
  });
};

export default VaService;
