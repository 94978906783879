import { SvgIcon } from "@mui/material";

const ChartBar4 = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M8,13v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1H7A1,1,0,0,1,8,13Zm5-9H11a1,1,0,0,0-1,1V19a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V5A1,1,0,0,0,13,4Zm6,4H17a1,1,0,0,0-1,1V19a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V9A1,1,0,0,0,19,8Z" />
    </SvgIcon>
  );
};

export default ChartBar4;
