import { useState } from "react";
import Calendar from "./Calender";
import Details from "./Details";

function ScheduleViewer({ date, schedule, timezone }) {
  const [data, setData] = useState(new Date());

  const showDetailsHandle = (day) => {
    setData(day);
  };

  return (
    <div className="App">
      <Calendar
        schedule={schedule}
        startFrom={date}
        showDetailsHandle={showDetailsHandle}
      />
      <Details schedule={schedule} startFrom={date} timezone={timezone}  />
    </div>
  );
}
ScheduleViewer.defaultProps = {
  date: new Date(),
};

export default ScheduleViewer;
