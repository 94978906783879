// import { Player } from "@lottiefiles/react-lottie-player";
import Lottie from "react-lottie-player";

import React from "react";

function AnimationPlayer({ light, dark, autoplay, loop, width }) {
  console.log("animation", light, dark, autoplay, loop, width);
  return (
    <div className="has-animations">
      {light && (
        <div className="animation-light">
          <Lottie
            loop
            animationData={light}
            play
            style={{ width: width, height: width }}
          />
        </div>
      )}
      {dark && (
        <div className="animation-dark">
          <Lottie
            loop
            animationData={dark}
            play
            style={{ width: width, height: width }}
          />
        </div>
      )}
      {/* <Spinner color='primary' className='init-spinner' type='border' /> */}
    </div>
  );
}

AnimationPlayer.defaultProps = {
  autoplay: true,
  loop: true,
  width: "100%",
};

export default AnimationPlayer;
