import { ArrowOutward, CameraAlt } from "@mui/icons-material";
import { TabList } from "@mui/lab";
import { Card, IconButton, Stack, styled, Tab } from "@mui/material";
import { Box } from "@mui/system";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import { H4, H5, Small, Span, Tiny } from "components/Typography";
import AvatarBadge from "components/avatars/AvatarBadge";
import Bratislava from "icons/Bratislava";
import DateRange, { clock, person } from "icons/DateRange";
import MapMarkerIcon from "icons/MapMarkerIcon";
import numeral from "numeral";
import { Fragment } from "react";
import { Outlet } from "react-router-dom"; // styled components

import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";

import { FormattedDate } from "components/shared/FormattedDate";
import TableCell from "@mui/material/TableCell";
import CountryFlag from "components/shared/CountryFlag";
import LastActive from "components/shared/LastActive";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import OnlineStatus from "components/shared/OnlineStatus";
import Platform from "components/shared/Platform";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";

const ContentWrapper = styled(Box)(({ theme }) => ({
  zIndex: 1,
  padding: 24,
  marginTop: 55,
  position: "relative",
}));
const CoverPicWrapper = styled(Box)(() => ({
  top: 0,
  left: 0,
  height: 125,
  width: "100%",
  overflow: "hidden",
  position: "absolute",
  backgroundColor: "#C6D3ED",
}));
// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));
const StyledFlexBetween = styled(FlexBetween)(() => ({
  margin: "auto",
  flexWrap: "wrap",
}));
const StyledTabList = styled(TabList)(({ theme }) => ({
  paddingLeft: 16,
  paddingRight: 16,
  [theme.breakpoints.up("sm")]: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
}));

// -------------------------------------------------

// -------------------------------------------------
const Layout = ({ buyer }) => {
  console.log("buyer?.profile?.avatar_url", buyer?.profile?.avatar_url);
  // get data from usercontext api
  const { userdata } = useContext(UserContext);
  return (
    <>
      <Card
        sx={{
          position: "relative",
        }}
      >
        <CoverPicWrapper>
          <img
            width="100%"
            height="100%"
            alt="Team Member"
            src="/static/background/user-cover-pic.png"
            style={{
              objectFit: "cover",
            }}
          />
        </CoverPicWrapper>

        <ContentWrapper>
          <FlexBox justifyContent="center">
            <PaxfulProfilePic size={80} account={buyer?.profile} buyer />
          </FlexBox>

          <Box mt={2}>
            <div
              fontWeight={600}
              textAlign="center"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h4>{buyer?.profile.username}</h4>
              <div
                style={{
                  marginLeft: "5px",
                }}
              >
                {/* {userdata?.country && (
                  <CountryFlag code={userdata?.country} width={"20px"} />
                )} */}
              </div>
            </div>

            <StyledFlexBetween
              paddingTop={1}
              maxWidth={440}
              alignItems={"center"}
            >
              <ListItem
                title={
                  <>
                    {buyer?.profile.localized_country_name
                      ? buyer?.profile.localized_country_name
                      : "country"}
                  </>
                }
                // `${buyer?.profile.localized_country_name}`}
                Icon={MapMarkerIcon}
              />
              <ListItem
                title={<FormattedDate date={buyer?.createdAt} />}
                Icon={DateRange}
              />
              <ListItem
                title={
                  buyer && <Platform size={20} platform={buyer.platform} />
                }
                Icon={clock}
              />
            </StyledFlexBetween>
          </Box>
        </ContentWrapper>
      </Card>
    </>
  );
};

export default Layout; // ----------------------------------------------------------------

// ----------------------------------------------------------------
function ListItem({ title, Icon }) {
  return (
    <FlexBox alignItems="center">
      <Icon
        sx={{
          fontSize: 14,
          color: "text.secondary",
          mr: 1,
        }}
      />
      <Tiny fontWeight={500} color="text.secondary">
        {title}
      </Tiny>
    </FlexBox>
  );
}
