import React from "react";
import { Small } from "components/Typography";
import { getRoleColor, getRoleName } from "./utils";

function RoleBadge({ role }) {
  return (
    <div>
      <Small
        sx={{
          width: 100,
          fontSize: 10,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: ".2rem 1rem",
          backgroundColor: getRoleColor(role),
        }}
      >
        {getRoleName(role)}
      </Small>
    </div>
  );
}

export default RoleBadge;
