// configuration
import axios from "../utils/axios";

const ConfigurationService = {};

ConfigurationService.savePrepaid2Coin = function (category, data) {
  return axios({
    url: `/configuration/${category}`,
    method: "POST",
    data,
  });
};
ConfigurationService.getPrepaid2Coin = function (category) {
  return axios({
    url: `/configuration/${category}`,
    method: "GET",
  });
};
ConfigurationService.saveAvtar = function (formData) {
  return axios({
    url: `/configuration/avatar`,
    method: "POST",
    data: formData,
  });
};

ConfigurationService.getAvtar = function () {
  return axios({
    url: `/configuration/avatar`,
    method: "GET",
  });
};

ConfigurationService.deleteAvtar = function (data) {
  return axios({
    url: `/configuration/avatar`,
    method: "DELETE",
    data,
  });
};

export default ConfigurationService;
