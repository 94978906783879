import { TabContext, TabPanel } from "@mui/lab";
import { Box } from "@mui/system";
import LayoutVa from "page-sections/profiles-va/LayoutVa";
import OverViewVa from "page-sections/profiles-va/overview";
import Activity from "page-sections/profiles/profile/activity";
import Campaigns from "page-sections/profiles/profile/campaigns";
import Connections from "page-sections/profiles/profile/connections";
import Documents from "page-sections/profiles/profile/documents";
import Layout from "page-sections/profiles/profile/Layout";
import Overview from "page-sections/profiles/profile/overview";
import Projects from "page-sections/profiles/profile/projects";
import { createContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const UserContext = createContext();

const ViewVa = () => {
  console.log("va agencie");
  const [tabValue, setTabValue] = useState("1");
  const location = useLocation();
  console.log("location.state", location.state);
  const { id } = useParams();
  console.log("id", id);

  const handleTabChange = (_, value) => setTabValue(value);

  return (
    <Box pt={2} pb={4}>
      <UserContext.Provider value={location.state}>
        <TabContext value={tabValue}>
          <LayoutVa handleTabList={handleTabChange}>
            <TabPanel value="1">{<OverViewVa />}</TabPanel>

            <TabPanel value="2">
              <Projects />
            </TabPanel>

            <TabPanel value="3">
              <Campaigns />
            </TabPanel>

            <TabPanel value="4">
              <Documents />
            </TabPanel>

            <TabPanel value="5">
              <Connections />
            </TabPanel>

            <TabPanel value="6">
              <Activity />
            </TabPanel>
          </LayoutVa>
        </TabContext>
      </UserContext.Provider>
    </Box>
  );
};

export default ViewVa;
