import React, { useContext, useEffect, useRef, useState } from "react";
import { useStore } from "react-redux";
import { API_BASE_URL, SERVER_BASE_URL } from "../../../../config";
// import AuthCheck from "../AuthCheck";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import SettingService from "services/SettingService";
import { Span } from "components/Typography";
import { SettingsContext } from "contexts/settingsContext";

const Message = ({ title, text, file, is_file, onSelect }) => {
  const { settings } = useContext(SettingsContext);

  return (
    <Box
      component="a"
      sx={{
        display: "block",
        cursor: "pointer",
        mb: 2,
      }}
      // onClick={() => onSelect({ title, text, file, is_file })}
    >
      <Box
        sx={{
          backgroundColor: "aliceblue",
          borderRadius: "5px",
          padding: "2px 5px",
        }}
      >
        {title && (
          <Span
            // variant="subtitle1"
            // component="strong"
            color={settings.theme === "light" ? "text.secondary" : "black"}
            sx={{ display: "block", fontWeight: "600", fontSize: "12px" }}
          >
            {title}
          </Span>
        )}
        {is_file ? (
          <Box sx={{ mt: 1 }}>
            <img
              src={`${SERVER_BASE_URL}/${file}`}
              alt=""
              // style={{ width: "100%" }}
            />
          </Box>
        ) : (
          <Span
            variant="body2"
            sx={{ mt: 1, display: "block", fontSize: "12px" }}
            color={settings.theme === "light" ? "text.secondary" : "black"}
          >
            {text && text.length > 40
              ? `${text.substring(0, 40).trimEnd()}...`
              : text}
          </Span>
        )}
      </Box>
    </Box>
  );
};

function FeaturedMessagesList({ trade, onSelect, profile, account, show }) {
  // const { settings } = store.getState();
  console.log("trade---", trade);

  const scrollElText = useRef();
  const scrollElFiles = useRef();

  const [settings, setSettings] = useState(null);
  const getSetting = async () => {
    const resp = await SettingService.getSettings(
      trade.account._id,
      trade.account.user
    );
    setSettings(resp);
  };
  useEffect(() => {
    getSetting();
  }, []);

  const { _id } = trade.account;
  console.log("settings", settings);

  let messages = [];

  if (trade && settings) {
    // const tradeSetting = settings.find((obj) => obj.key === "trade");

    const featured_messages = settings?.trades?.find(
      (obj) => obj.key === "featured_messages"
    );
    console.log("featured_messages", featured_messages);
    if (featured_messages) {
      featured_messages.value.forEach(({ enabled, message, offers }) => {
        if (
          enabled &&
          offers.find(({ value }) => value === trade.trade.offer_hash)
        ) {
          messages = message;
        }
      });
    }
  }

  const handleSelect = (msg) => {
    onSelect(msg);
  };

  // const handleWheelEvent = (event, el) => {
  //   if (event.deltaY !== 0) {
  //     event.preventDefault();
  //     let maxSpeed = 15;
  //     let scroll =
  //       event.deltaY > 0
  //         ? Math.min(event.deltaY, maxSpeed)
  //         : Math.max(event.deltaY, -maxSpeed);
  //     el.scrollLeft += scroll;
  //   }
  // };

  useEffect(() => {
    setTimeout(() => {
      const txtMsgEl = scrollElText.current;
      const fileMsgEl = scrollElFiles.current;

      const handleWheelEvent = (event, el) => {
        if (event.deltaY !== 0) {
          event.preventDefault();
          let maxSpeed = 15;
          let scroll =
            event.deltaY > 0
              ? Math.min(event.deltaY, maxSpeed)
              : Math.max(event.deltaY, -maxSpeed);
          el.scrollLeft += scroll;
        }
      };

      if (txtMsgEl && fileMsgEl) {
        txtMsgEl.addEventListener(
          "wheel",
          (e) => handleWheelEvent(e, txtMsgEl),
          {
            passive: false,
          }
        );
        fileMsgEl.addEventListener(
          "wheel",
          (e) => handleWheelEvent(e, fileMsgEl),
          {
            passive: false,
          }
        );

        const container = document.querySelector(".filtered-messages");

        // Adjust text message container width if file messages are present
        setTimeout(() => {
          if (container?.classList.contains("has-files")) {
            txtMsgEl.style.width = `calc(100% - ${
              fileMsgEl.offsetWidth + 5
            }px)`;
            fileMsgEl.classList.add("is-loaded");
          }
        }, 1000);
      }
    }, 1000);

    // return () => {
    //   txtMsgEl?.removeEventListener("wheel", handleWheelEvent);
    //   fileMsgEl?.removeEventListener("wheel", handleWheelEvent);
    // };
  }, [trade]);

  // useEffect(() => {
  //   const txtMsgEl = document.querySelector(".text-messages");
  //   const fileMsgEl = document.querySelector(".files-messages");
  //   console.log("txtMsgEl", txtMsgEl);

  //   console.log("USE EFFECT CALLED...", trade, txtMsgEl, fileMsgEl);

  //   //scrollElText.current
  //   //scrollElFiles.current

  //   if (trade && txtMsgEl && fileMsgEl) {
  //     txtMsgEl.addEventListener("wheel", (e) => handleWheelEvent(e, txtMsgEl), {
  //       passive: false,
  //     });
  //     fileMsgEl.addEventListener(
  //       "wheel",
  //       (e) => handleWheelEvent(e, fileMsgEl, 2),
  //       {
  //         passive: false,
  //       }
  //     );

  //     const container = document.querySelector(".filtered-messages");

  //     //let images load
  //     setTimeout(() => {
  //       if (container?.classList.contains("has-files")) {
  //         txtMsgEl.style.width = `calc(100% - ${fileMsgEl.offsetWidth + 5}px)`;

  //         fileMsgEl.classList.add("is-loaded");
  //       }
  //     }, 1000);
  //   }

  //   return () => {
  //     txtMsgEl?.removeEventListener("wheel", handleWheelEvent);
  //     txtMsgEl?.removeEventListener("wheel", handleWheelEvent);
  //   };
  // }, [trade]);

  const textMessages = [];
  const filesMessages = [];
  messages?.forEach((message) => {
    if (message.is_file) {
      filesMessages.push(message);
    } else {
      textMessages.push(message);
    }
  });
  return (
    <>
      {show && (
        <Box className="automated-messages-wrapper">
          <Box className="featured-messages-list">
            <Box
              className={`messages-content${
                messages.length === 0 ? " no-content" : ""
              }`}
            >
              {messages && messages.length > 0 ? (
                <Box
                  className={`filtered-messages ${
                    textMessages.length === 0 ? " no-text-message" : ""
                  }${
                    filesMessages.length === 0
                      ? " no-file-message"
                      : " has-files"
                  }`}
                >
                  <Box className="text-messages" ref={scrollElText}>
                    {textMessages.length > 0 && (
                      <>
                        {textMessages.map((message, index) => (
                          <Message
                            // onSelect={handleSelect}
                            key={`message_${index}`}
                            {...message}
                          />
                        ))}
                      </>
                    )}
                  </Box>
                  <Box
                    ref={scrollElFiles}
                    className={`files-messages ${
                      filesMessages.length > 2 ? "has-multiple-images" : ""
                    }`}
                  >
                    {filesMessages.length > 0 && (
                      <>
                        {filesMessages.map((message, index) => (
                          <Message
                            // onSelect={handleSelect}
                            key={`message_${index}`}
                            {...message}
                          />
                        ))}
                      </>
                    )}
                  </Box>
                </Box>
              ) : (
                <Typography variant="body2" className="no-automated-message">
                  Automated messages are not configured.{" "}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default FeaturedMessagesList;
