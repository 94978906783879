import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5, Paragraph } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import InvoiceListTable from "page-sections/activity-log/index";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components

import { WEBHOOK_API_URL } from "config";
import WebhookService from "services/WebhookService";
import { io } from "socket.io-client";
import ActivityLogServices from "services/ActivityLogService";
import { LoadingButton } from "@mui/lab";
import ActivityLogListTable from "page-sections/activity-log/index";

const socket = io(WEBHOOK_API_URL);

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const ActivityLog = (props) => {
  const [option, setOption] = useState("all");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItem, setFilteredItem] = useState(INVOICE_LIST);

  // useEffect(() => {
  //   const result = searchByName(INVOICE_LIST, searchValue);
  //   setFilteredItem(result);
  // }, [searchValue]);

  const [data, setData] = useState(null);

  // get Activity Log
  const getActivityLog = async (page = 1, limit = 50, search, type) => {
    setisLoading(true);
    const resp = await ActivityLogServices.getActivityLog(
      page,
      limit,
      search,
      type
    );
    setData(resp);
    setPage(page);
    setisLoading(false);
  };

  useEffect(() => {
    getActivityLog(page, limit, searchValue, option);
  }, []);

  const reload = () => {
    setSearchValue("");
    setOption("all");
    getActivityLog(1, limit, "", "all");
  };

  const handlePageChange = (page) => {
    setPage(page);
    getActivityLog(page, limit, searchValue, option);
  };

  const handleLimitChange = (event) => {
    // setPage(1);
    setLimit(event.target.value);
    getActivityLog(1, event.target.value, searchValue, option);
  };

  const handleTabChange = (event) => {
    console.log("type", event.target.value);
    setOption(event.target.value);
    // setPage(1);
    getActivityLog(1, limit, searchValue, event.target.value);

    // getPaxfulTransactions(userdata._id, pg, limit, option, event.target.value);
  };
  const handleSearch = (searchValue) => {
    setSearchValue(searchValue)
    getActivityLog(1, limit, searchValue, option);
  }
  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("Activity Log")}</H5>
        </Stack>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            sx={{
              "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                {
                  padding: "10px !important", // Remove padding from the Select component
                },
            }}
            onChange={(e) => handleTabChange(e)}
            value={option}
            // disabled={option != "all"}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="all">
              <Paragraph>All</Paragraph>
            </MenuItem>
            <MenuItem value={"HTTP"}>
              {" "}
              <Paragraph>HTTP</Paragraph>{" "}
            </MenuItem>
            <MenuItem value={"WS"}>
              {" "}
              <Paragraph>Web Socket </Paragraph>{" "}
            </MenuItem>
          </Select>
        </FormControl>

        <StyledStack alignItems="center" direction="row" gap={2}>
          <SearchInput
            disable_border="true"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data && (
          <ActivityLogListTable
            {...data}
            onPageChange={handlePageChange}
            currentPage={page}
            handleLimitChange={handleLimitChange}
            limit={limit}
          />
        )
      )}
    </Box>
  );
};

export default ActivityLog;
