import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { API_BASE_URL } from "../../config";
import VaService from "services/VaService";
import HiringRequestService from "services/HireRequestService";

function VAProfilePic({ requestID, defaultImg }) {
  const [va, setVa] = useState(null);

  const getVa = async () => {
    let resp = await HiringRequestService.getHireRequest(
      1,
      100,
      "succesfull",
      ""
    );
    console.log("HiringRequestService", resp);
    if (resp.status) {
      const va = resp.data.find(({ request_id }) => request_id == requestID);
      setVa(va);
    }
  };

  useEffect(() => {
    getVa();
  }, []);

  return (
    <div className="position-relative">
      <img
        className={`w-30px rounded-circle symbol`}
        src={`${API_BASE_URL}/${
          va?.va.profile_pic ? va?.va.profile_pic.file_path : defaultImg
        }`}
        alt=""
      />
      <span
        className={`online-status position-absolute bg-${
          va?.is_active ? "success" : "secondary"
        } rounded-circle border border-1 h-8px w-8px `}
      ></span>
    </div>
  );
}

export default VAProfilePic;
