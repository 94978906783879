import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import Invoice from "icons/duotone/Invoice";
import { H5, Paragraph } from "components/Typography";
import InvoiceListTable from "page-sections/activity-log/index";
import { t } from "i18next";
import { LoadingButton } from "@mui/lab";
import { FormattedDate } from "components/shared/FormattedDate";
import moment from "moment-timezone";
import plans from "data/plans";
import AddressList from "./AddressList";
import WhiteListdAddressService from "services/WhiteListdAddressService";
import FlexBox from "components/flexbox/FlexBox";

function WhiteListAddress() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [option, setOption] = useState("active");

  // get data from usercontext api
  const { userdata } = useContext(UserContext);
  // console.log(userdata.paxfullAccounts[0]._id);
  const [isLoading, setisLoading] = useState(false);

  const [data, setData] = useState(null);
  const getUserAddress = async (id, page, limit, type) => {
    setisLoading(true);
    const resp = await WhiteListdAddressService.getUserWhiteListdAddress(
      id,
      page,
      limit,
      type
    );
    setData(resp);
    setisLoading(false);
  };

  useEffect(() => {
    getUserAddress(userdata._id, page, limit, "active");
  }, []);
  // ================================
  console.log("data", data);

  const reload = () => {
    setOption("active");
    getUserAddress(userdata._id, page, limit, "active");
  };

  const handlePageChange = (page) => {
    setPage(page);
    getUserAddress(userdata._id, page, limit, option);
  };

  const handleLimitChange = (event) => {
    setPage(1);
    setLimit(event.target.value);
    getUserAddress(userdata._id, 1, event.target.value, option);
  };

  const handleChange = (event) => {
    // console.log("type", event.target.value);
    setPage(1);
    setLimit(limit);
    setOption(event.target.value);
    getUserAddress(userdata._id, 1, limit, event.target.value);
  };

  console.log("userdata", userdata);

  return (
    <>
      <Box pt={2} pb={4}>
        <FlexBetween flexWrap="wrap" gap={1}>
          <Stack direction="row" alignItems="center">
            <IconWrapper>
              <Invoice
                sx={{
                  color: "primary.main",
                }}
              />
            </IconWrapper>
            <H5>{t("Whitelisted Address")}</H5>
          </Stack>

          <FlexBox alignItems="center">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                sx={{
                  "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "10px !important", // Remove padding from the Select component
                    },
                }}
                value={option}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="all">
                  <Paragraph>All</Paragraph>
                </MenuItem>
                <MenuItem value={"active"}>
                  {" "}
                  <Paragraph>Active</Paragraph>{" "}
                </MenuItem>
                <MenuItem value={"delete"}>
                  {" "}
                  <Paragraph>Deleted</Paragraph>{" "}
                </MenuItem>
              </Select>
            </FormControl>
            <LoadingButton
              onClick={() => reload()}
              loading={isLoading}
              variant="contained"
              sx={{
                flexShrink: 0,
              }}
            >
              Reload
            </LoadingButton>
          </FlexBox>
        </FlexBetween>

        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
            }}
          >
            {" "}
            <CircularProgress />
          </Box>
        ) : (
          data && (
            <AddressList
              {...data}
              onPageChange={handlePageChange}
              currentPage={page}
              handleLimitChange={handleLimitChange}
              limit={limit}
              getUserAddress={getUserAddress}
              setOption={setOption}
            />
          )
        )}
      </Box>
    </>
  );
}

export default WhiteListAddress;
