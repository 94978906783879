import { GET_WEBHOOKS } from "../constants";

export default function reducer(
  state = {
    webhooks: null,
  },
  action
) {
  switch (action.type) {
    case GET_WEBHOOKS:
      return { ...state, webhooks: action.payload };
  }

  return state;
}
