import React from "react";

function CardBrandIcon({ brand, size }) {
  return (
    <img
      className={`w-${size}px`}
      style={{
        width: size,
      }}
      src={`/media/card-brands/brand-${brand}.png`}
      alt={brand}
    />
  );
}

CardBrandIcon.defaultProps = {
  size: 30,
};

export default CardBrandIcon;
