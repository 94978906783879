import React from "react";
import FsLightbox from "fslightbox-react";

export const FsligthGallery = ({ lightboxController, images }) => {
  return (
    <>
      <FsLightbox
        toggler={lightboxController.toggler}
        sourceIndex={lightboxController.sourceIndex}
        // sources={images}
        sources={images.map((src, index) => (
          <img src={src} alt="" key={index} />
        ))}
        key={images.length}
      />
    </>
  );
};

export const FsligthGalleryForSingleImage = ({ index, image, toggler }) => {
  return (
    <>
      <FsLightbox key={`image-${index}`} toggler={toggler} sources={[image]} />
    </>
  );
};
