import { DATE_TIME_FORMAT } from "config";
import React from "react";
import Moment from "react-moment";

function DateTime({ date, format }) {
  return (
    <>
      <Moment format={format}>{date}</Moment>
    </>
  );
}

DateTime.defaultProps = {
  format: DATE_TIME_FORMAT,
};

export default DateTime;
