import React, { useContext } from "react";
import BillingService from "services/BillingService";
import { useEffect, useState } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import Invoice from "icons/duotone/Invoice";
import { H5 } from "components/Typography";
import InvoiceListTable from "page-sections/activity-log/index";
import { t } from "i18next";
import { LoadingButton } from "@mui/lab";
import BillingList from "./BillingList";
import { FormattedDate } from "components/shared/FormattedDate";
import moment from "moment-timezone";
import plans from "data/plans";

function Billing() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  // get data from usercontext api
  const { userdata } = useContext(UserContext);
  // console.log(userdata.paxfullAccounts[0]._id);
  const [isLoading, setisLoading] = useState(false);

  const [data, setData] = useState(null);
  const getBilling = async (id, page, limit) => {
    setisLoading(true);
    const resp = await BillingService.getBilling(id, page, limit);
    setData(resp);
    setisLoading(false);
  };

  useEffect(() => {
    getBilling(userdata._id, page, limit);
  }, []);
  // ================================
  console.log("data", data);

  const reload = () => {
    getBilling(userdata._id, page, limit);
  };

  const handlePageChange = (page) => {
    setPage(page);
    getBilling(userdata._id, page, limit);
  };

  const handleLimitChange = (event) => {
    setPage(1);
    setLimit(event.target.value);
    getBilling(userdata._id, 1, event.target.value);
  };

  console.log("userdata?.subscriptionEndsAt", userdata?.subscriptionEndsAt);
  console.log("userdata?.timezone", userdata?.timezone);
  console.log("userdata?.plan", userdata?.plan);
  // console.log("userdata?.subscriptionEndsAt", userdata?.subscriptionEndsAt);
  // console.log("userdata?.subscriptionEndsAt", userdata?.subscriptionEndsAt);

  return (
    <>
      <Box pt={2} pb={4}>
        <FlexBetween flexWrap="wrap" gap={1}>
          <Stack direction="row" alignItems="center">
            <IconWrapper>
              <Invoice
                sx={{
                  color: "primary.main",
                }}
              />
            </IconWrapper>
            <H5>{t("Billing")}</H5>
          </Stack>
          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </FlexBetween>
        <Box py={2} pl={1}>
          {userdata?.timezone && (
            <Box>
              <Typography fontWeight={600} fontSize={15}>
                {userdata?.autoRenew && userdata?.autoRenew
                  ? "Renews on"
                  : "Active until"}{" "}
                {userdata?.subscriptionEndsAt &&
                  moment(userdata?.subscriptionEndsAt)
                    .tz(userdata?.timezone)
                    .format("ll")}
              </Typography>
              <Typography fontSize={13} color={"text.secondary"}>
                {userdata?.autoRenew && userdata?.autoRenew ? (
                  <>Your membership will auto renew</>
                ) : (
                  <>
                    We will send you a notification before subscription
                    expiration.
                  </>
                )}
              </Typography>
            </Box>
          )}

          <Box mt={2}>
            <Typography fontWeight={600} fontSize={13}>
              {userdata?.plan && plans[userdata?.plan].title}
            </Typography>

            <Typography fontSize={13} color={"text.secondary"}>
              {userdata?.plan && plans[userdata?.plan].price} Per Month
            </Typography>
          </Box>
        </Box>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
            }}
          >
            {" "}
            <CircularProgress />
          </Box>
        ) : (
          data && (
            <BillingList
              {...data}
              onPageChange={handlePageChange}
              currentPage={page}
              handleLimitChange={handleLimitChange}
              limit={limit}
            />
          )
        )}
      </Box>
    </>
  );
}

export default Billing;
