import { Box, Button, CircularProgress, Stack, Tab } from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5 } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components

import { io } from "socket.io-client";
import { LoadingButton, TabContext, TabList } from "@mui/lab";
import UserRequestTable from "page-sections/user-request";
import UserRequestApi from "services/userRequestService";
import BuyerTable from "page-sections/buyer";
import buyerService from "services/buyer";
import PendingList from "page-sections/profiles/pending-invite/PendingList";
import VendorInviteService from "services/VendorInviteService";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const TabListWrapper = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(727)]: {
    order: 3,
  },
}));

const PendingInvitations = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input
  const location = useLocation();
  const dataReceived = location.state;
  console.log("location", dataReceived);

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItem, setFilteredItem] = useState(INVOICE_LIST);
  const [page, setPage] = useState(1);
  //   const [tabValue, setTabValue] = useState("api");

  const [data, setData] = useState(null);

  // const getPendingInvite = async (page, search) => {
  //   console.log("api calling");
  //   setisLoading(true);
  //   const resp = await VendorInviteService.getPendingInvite(page, search);
  //   setData(resp);
  //   setisLoading(false);
  // };
  const getPendingInvite = async (page) => {
    console.log("api calling");
    setisLoading(true);
    const resp = await VendorInviteService.getInvitationLog(page);
    setData(resp);
    setisLoading(false);
  };
  console.log("pending invite", data);
  useEffect(() => {
    console.log("hello");
    getPendingInvite(1, searchValue);
  }, [searchValue, dataReceived?.call_again]);

  const reload = () => {
    getPendingInvite(1, searchValue);
  };

  const handlePageChange = (page) => {
    setPage(page);
    getPendingInvite(page, "");
  };

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("PENDING INVITATIONS")}</H5>
        </Stack>

        <StyledStack alignItems="center" direction="row" gap={2}>
          <SearchInput
            disable_border="true"
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data && (
          <PendingList
            {...data}
            onPageChange={handlePageChange}
            currentPage={page}
            getPendingInvite={getPendingInvite}
          />
        )
      )}
    </Box>

    // Commit
  );
};

export default PendingInvitations;
