import axios from "../utils/axios";

const WalletService = {};

WalletService.getWalletTransactions = function (user_id) {
  return axios({
    url: `/wallets?user_id=${user_id}`,
    method: "GET",
  });
};
WalletService.getTradingVolumeTrans = function (user_id, search, page, limit) {
  return axios({
    url: `wallets/transactions/${user_id}?search=${search}&page=${page}&limit=${limit}`,
    method: "GET",
  });
};
WalletService.syncWallet = function (id, assetId) {
  return axios({
    url: `wallets/sync/${id}/${assetId}`,
    method: "PUT",
  });
};

export default WalletService;
