import { Box, Button, CircularProgress, Stack, Tab } from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5 } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components

import { io } from "socket.io-client";
import { LoadingButton, TabContext, TabList } from "@mui/lab";
import UserRequestTable from "page-sections/user-request";
import UserRequestApi from "services/userRequestService";
import BuyerTable from "page-sections/buyer";
import buyerService from "services/buyer";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const TabListWrapper = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(727)]: {
    order: 3,
  },
}));

const Buyers = (props) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItem, setFilteredItem] = useState(INVOICE_LIST);
  //   const [tabValue, setTabValue] = useState("api");

  const [data, setData] = useState(null);

  const getBuyers = async (page, limit, search = "") => {
    setisLoading(true);
    const resp = await buyerService.getBuyers(page, limit, search);
    setData(resp);
    setPage(page);
    setisLoading(false);
    console.log("buyer", resp);
  };

  useEffect(() => {
    getBuyers(1, limit, searchValue);
  }, []);

  const reload = () => {
    setSearchValue("");
    getBuyers(page, limit);
  };

  const handlePageChange = (page) => {
    // setPage(page);
    getBuyers(page, limit, searchValue);
  };

  const handleLimitChange = (event) => {
    // setPage(1);
    setLimit(event.target.value);
    getBuyers(1, event.target.value, searchValue);
  };

  // Tab selection
  //   const handleTabChange = (_, value) => {
  //     setTabValue(value);
  //     getUserRequest(1, value, "");
  //   };
  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
    getBuyers(1, limit, searchValue);
  };

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("Trade Partners ")}</H5>
        </Stack>
        {/* tab management */}
        {/* <TabContext value={tabValue}>
          <TabListWrapper variant="scrollable" onChange={handleTabChange}>
            <Tab disableRipple label={t("Api")} value="api" />
            <Tab disableRipple label={t("Deletion")} value="delete_account" />
          </TabListWrapper>
        </TabContext> */}
        <StyledStack alignItems="center" direction="row" gap={2}>
          <SearchInput
            disable_border="true"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data && (
          <BuyerTable
            {...data}
            onPageChange={handlePageChange}
            currentPage={page}
            handleLimitChange={handleLimitChange}
            limit={limit}
          />
        )
      )}
    </Box>
  );
};

export default Buyers;
