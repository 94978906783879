import { ArrowOutward } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const ArrowOutwardButton = (props) => {
  return (
    <IconButton {...props}>
      <ArrowOutward
        sx={{
          fontSize: 17,
          color: "text.secondary",
        }}
      />
    </IconButton>
  );
};

export default ArrowOutwardButton;
