import { Badge, Box, Paper } from "@mui/material";
import { Paragraph, Small } from "components/Typography";
import BadgeMui from "components/shared/BadgeMui";
import moment from "moment-timezone";
// import { Badge } from "reactstrap";

const Details = ({ schedule, timezone, startFrom }) => {
  //const selectedSchedule = schedule[`d_${moment(selected).weekday()}`];

  const timeZoneAbbr =
    timezone && timezone ? moment().tz(timezone).zoneAbbr() : null;

  const allDates = {};
  for (let i = 0; i < 7; i++) {
    const now = moment(startFrom).tz(timezone).add(i, "days");
    allDates[`d_${now.weekday()}`] = now.format("ll");
  }

  const getFormatedSlot = (time) => {
    if (time % 1 == 0) {
      let t = time > 12 ? time - 12 : time;
      return (
        t.toString().padStart(2, "0") + ":00" + (time > 12 ? " PM" : " AM")
      );
    } else {
      let components = time.toString().split(".");
      let t = components[0] > 12 ? components[0] - 12 : components[0];
      return (
        t.toString().padStart(2, "0") +
        ":30" +
        (components[0] > 12 ? " PM" : " AM")
      );
    }
  };

  const scheduleDays = Object.keys(schedule);

  const getTotalHours = (schedule) => {
    let total_hours = 0;
    //console.log(schedule);
    if (schedule) {
      schedule.forEach(({ start, end }) => {
        if (start !== null && end !== null) {
          total_hours += end - start;
        }
      });
    }
    if (total_hours > 0) {
      return (
        <span style={{ margin: '8px' }}>
        <BadgeMui textColor={"black   "} color={"aliceblue"}>
          {total_hours} {total_hours > 1 ? "Hours" : "Hour"}
        </BadgeMui>
        </span>
      );
    }
    return null;
  };
 

  return scheduleDays.map((key) => {
    const selectedSchedule = schedule[key];
    return (
      <div key={key}>
        <Box mt={2}>
          {selectedSchedule && (
            <>
              <Box mb={1}>
                <Small fontWeight={"bold"}>{allDates[key]}</Small>
                {" ="}
                {getTotalHours(selectedSchedule)}
              </Box>
              {selectedSchedule.map((slot, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      color: "text.primary",
                      py: 1,
                      px: 2,
                      mb: 1,
                      border: "1px solid lightgrey",
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    component={Paper}
                  >
                    <div className="fw-semibold">
                      <Small fontWeight={"500"}>
                        {getFormatedSlot(slot.start)}
                      </Small>{" "}
                      -{" "}
                      <Small fontWeight={"500"}>
                        {getFormatedSlot(slot.end)}
                      </Small>
                    </div>
                    <BadgeMui textColor={"black"} color={"aliceblue"}>
                      {timeZoneAbbr}
                    </BadgeMui>
                  </Box>
                );
              })}
            </>
          )}
        </Box>
      </div>
    );
  });
};

export default Details;
