import React from "react";
import { Box, Tooltip } from "@mui/material";
import { makeStyles, styled, withStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useContext } from "react";
import { SettingsContext } from "contexts/settingsContext";

const useStyles = makeStyles({
  customDarkTooltip: {
    "& .MuiTooltip-tooltip": {
      backgroundColor: "black",
      color: "white",
      fontSize: "14px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
  },
  customLightTooltip: {
    "& .MuiTooltip-tooltip": {
      backgroundColor: "white",
      color: "black",
      fontSize: "14px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
  },
});

function ATooltip({ children, content, id, placement, className, addClass }) {
  const classes = useStyles();
  const { settings } = useContext(SettingsContext);

  return (
    <Tooltip
      title={content}
      PopperProps={{
        className:
          settings.theme === "light"
            ? classes.customLightTooltip
            : classes.customDarkTooltip,
      }}
      placement={placement}
      id={id}
    >
      <Box sx={{ display: "inline-block" }}>{children}</Box>
    </Tooltip>
  );
}

ATooltip.defaultProps = {
  placement: "bottom",
  className: "",
};

export default ATooltip;
