import { Box } from "@mui/system";
import FlexBox from "components/flexbox/FlexBox";
import { Paragraph, Small } from "components/Typography";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PaxfulProfilePic from "./PaxfulProfilePic";

function TradePreview({ accounts, account_id, trade_hash }) {
  // console.log(accounts)

  const account = accounts.find(({ _id }) => _id == account_id);

  return (
    <>
      {account ? (
        <Link to={`/dashboard/users/${account.user}?trade=${trade_hash}`}>
          {trade_hash}
        </Link>
      ) : (
        <span>{trade_hash}</span>
      )}
    </>
  );
}

const mapStateToProps = ({ paxfulAccount }) => ({
  accounts: paxfulAccount.paxfulAccounts,
});

export default connect(mapStateToProps, null)(TradePreview);
