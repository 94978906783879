import axios from "../utils/axios";

const ActivityLogServices = {};

ActivityLogServices.getActivityLog = function (page, limit, search, type) {
  return axios({
    url: `/activity-log?page=${page}&limit=${limit}&search=${search}&type=${type}`,
    method: "GET",
  });
};
ActivityLogServices.getUserActivityLog = function (id, page, limit) {
  return axios({
    url: `/activity-log/${id}?page=${page}&limit=${limit}`,
    method: "GET",
  });
};

export default ActivityLogServices;
