import { io } from "socket.io-client";
import { API_DOMAIN, SERVER_BASE_URL, AUTH_TOKEN } from "../config";

export default {
  connect(type) {
    return io(SERVER_BASE_URL, {
      reconnectionDelayMax: 10000,
      transports: ["websocket"],
      auth: {
        token: localStorage.getItem(AUTH_TOKEN),
      },
      query: {
        type: type,
      },
    });
  },
};
