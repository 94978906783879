import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { H3, H5, H6, Paragraph } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import React, { useContext, useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import * as Yup from "yup"; // component props interface
import { useFormik } from "formik";
import AppTextField from "components/input-fields/AppTextField";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import USAStates from "data/USAStates";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ConfigurationService from "services/ConfigurationService";
import { SettingsContext } from "contexts/settingsContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, city, theme) {
  return {
    fontWeight:
      city.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function Prepaid2CoinConfig() {
  const { settings } = useContext(SettingsContext);

  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  //   For save city
  const [accepted_states, setAccepted_states] = React.useState([]);
  const [data, setData] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAccepted_states(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  //   For BING
  const [inputValue, setInputValue] = useState("");
  const [accepted_bins, setAccepted_bins] = useState([]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      setAccepted_bins([...accepted_bins, inputValue.trim()]);
      setInputValue("");
    }
    if (event.key === "Backspace") {
      setAccepted_bins(accepted_bins.slice(0, -1));
      setInputValue("");
    }
  };

  const handleChipDelete = (chipToDelete) => () => {
    const newChips = accepted_bins.filter((chip) => chip !== chipToDelete);
    setAccepted_bins(newChips);
  };
  //   const validations = {
  //     city: Yup.string().required("City is Required!"),
  //     bng: Yup.string().required("BNG is Required!"),
  //   };

  //   const initialValues = {
  //     city: [],
  //     bng: [],
  //   };

  //   const fieldValidationSchema = Yup.object().shape(validations);
  //   const { values, errors, handleSubmit, resetForm } = useFormik({
  //     // initialValues,
  //     // validationSchema: fieldValidationSchema,
  //     enableReinitialize: true,
  //     onSubmit: async (values) => {
  //       console.log("hello");
  //       console.log("city", city);
  //       console.log("accepted_bins", accepted_bins);
  //     },
  //   });

  const handleSubmit = async () => {
    console.log("city", accepted_states);
    console.log("accepted_bins", accepted_bins);
    let data = {
      accepted_states: JSON.stringify(accepted_states),
      accepted_bins: JSON.stringify(accepted_bins),
    };
    // if (data) {
    //   // call update api
    // } else {
    //   // call data save api
    const resp = await ConfigurationService.savePrepaid2Coin("Prepaid2Coins", {
      data: data,
    });
    console.log("resp", resp);
    // }
  };

  const handleClick = () => {
    inputRef.current.focus();
  };

  const getConfiguration = async (category) => {
    const resp = await ConfigurationService.getPrepaid2Coin(category);
    console.log("resp", resp);
    setData(resp);
    if (resp.status) {
      resp.data &&
        resp.data.forEach((_data) => {
          if (_data.key == "accepted_states") {
            setAccepted_states(JSON.parse(_data.value));
          } else if (_data.key == "accepted_bins") {
            setAccepted_bins(JSON.parse(_data.value));
          }
        });
    }
  };
  useEffect(() => {
    getConfiguration("Prepaid2Coins");
  }, []);

  const inputRef = React.createRef();
  return (
    <div>
      <Card>
        <CardContent>
          <Box p={4}>
            <H3>Prepaid 2 Coins</H3>

            <form onSubmit={handleSubmit}>
              <Grid mt={2} container spacing={2}>
                <Grid item xs={3}>
                  <H6>City</H6>
                </Grid>
                <Grid item xs={9}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-chip-label">City</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={accepted_states}
                      onChange={handleChange}
                      input={
                        <OutlinedInput id="select-multiple-chip" label="City" />
                      }
                      renderValue={(selected) => {
                        return (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected &&
                              selected.map((value) => (
                                <Chip size="small" key={value} label={value} />
                              ))}
                          </Box>
                        );
                      }}
                      MenuProps={MenuProps}
                    >
                      {USAStates.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, accepted_states, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <H6>BINS</H6>
                </Grid>
                <Grid item xs={9}>
                  <Box
                    style={{
                      border: "1px solid lightgrey",
                      minHeight: "50px",
                    }}
                    className="clickable-div"
                    onClick={handleClick}
                  >
                    <FlexBox
                      sx={{
                        flexWrap: "wrap",
                      }}
                    >
                      {accepted_bins.map((chip, index) => (
                        <Chip
                          size="small"
                          key={index}
                          label={chip}
                          onDelete={handleChipDelete(chip)}
                          style={{ margin: "4px" }}
                        />
                      ))}
                      <FlexBox sx={{ alignItems: "center" }}>
                        <ArrowRightIcon
                          sx={{
                            fontSize: "30px",
                          }}
                        />
                        <input
                          //   label="Enter value and press Enter"
                          //   variant="outlined"

                          value={inputValue}
                          onChange={handleInputChange}
                          onKeyDown={handleInputKeyPress}
                          style={{
                            fontSize: "14px",
                            border: "0px ",
                            outline: "none",
                            color: "gray",
                            backgroundColor:
                              settings.theme === "light" ? "white" : "#222B36",
                          }}
                          ref={inputRef}
                        />
                      </FlexBox>
                    </FlexBox>
                  </Box>
                </Grid>{" "}
              </Grid>

              <FlexBox mt={2} sx={{ justifyContent: "end" }}>
                <LoadingButton
                  size="small"
                  onClick={handleSubmit}
                  variant="contained"
                  loading={isLoading}
                >
                  Save
                </LoadingButton>
              </FlexBox>
            </form>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default Prepaid2CoinConfig;
