import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Tab,
} from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5, Paragraph } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components
import { LoadingButton, TabContext, TabList } from "@mui/lab";
import PaxfulApiLogService from "services/PaxfulApiLogService";
import PaxfulApiLogListTable from "page-sections/paxful-api_log";
import TabManagement from "page-sections/paxful-api_log/TabManagement";
import FlexBox from "components/flexbox/FlexBox";
import PaxfulAccountsService from "services/PaxfulAccountsService";
import Platform from "components/shared/Platform";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const TabListWrapper = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(727)]: {
    order: 3,
  },
}));

const PaxfulApilog = (props, value, changeTab) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [optionAccount, setOptionAccount] = useState("all");
  const [option, setOption] = useState("user");

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItem, setFilteredItem] = useState(INVOICE_LIST);
  const [account, setAccount] = useState(null);
  console.log("account", account);

  // useEffect(() => {
  //   const result = searchByName(INVOICE_LIST, searchValue);
  //   setFilteredItem(result);
  // }, [searchValue]);

  const [data, setData] = useState(null);
  // const [tabValue, setTabValue] = useState("user");

  const getAccount = async () => {
    const resp = await PaxfulAccountsService.getPaxfulAccounts();
    setAccount(resp);
  };
  useEffect(() => {
    getAccount();
  }, []);

  // get Activity Log
  const getPaxfulApiLog = async (page, limit, type = "user", account) => {
    setisLoading(true);
    const resp = await PaxfulApiLogService.getPaxfulApiLog(
      page,
      limit,
      type,
      account
    );
    setData(resp);
    setisLoading(false);
  };

  useEffect(() => {
    getPaxfulApiLog(1, limit, option, optionAccount);
  }, [optionAccount, option]);

  const reload = () => {
    setOptionAccount("all");
    setOption("user");
    getPaxfulApiLog(1, limit, option, optionAccount);
  };

  // Tab selection
  // const handleTabChange = (_, value) => {
  //   setTabValue(value);
  //   getPaxfulApiLog(1, limit, value, "");
  // };
  const handlePageChange = (page) => {
    setPage(page);
    getPaxfulApiLog(page, limit, option, optionAccount);
  };

  const handleLimitChange = (event) => {
    setPage(1);
    setLimit(event.target.value);
    getPaxfulApiLog(1, event.target.value, option, optionAccount);
  };

  const selectHandleAccountChange = (event) => {
    setOptionAccount(event.target.value);
    setPage(1);
  };

  const selectHandleChange = (event) => {
    setOption(event.target.value);
    setPage(1);
  };
  console.log("option", option);

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("Api Log")}</H5>
        </Stack>{" "}
        {/* <TabManagement /> */}
        {/* <TabContext value={tabValue}>
          <TabListWrapper variant="scrollable" onChange={handleTabChange}>
            <Tab disableRipple label={t("Users")} value="user" />
            <Tab disableRipple label={t("System")} value="system" />
            <Tab disableRipple label={t("All")} value="" />
          </TabListWrapper>
        </TabContext> */}
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            sx={{
              "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                {
                  padding: "10px !important", // Remove padding from the Select component
                },
            }}
            value={option}
            onChange={selectHandleChange}
            // inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={"user"}>
              <Paragraph>User</Paragraph>
            </MenuItem>
            <MenuItem value={"system"}>
              <Paragraph>System</Paragraph>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            sx={{
              "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                {
                  padding: "10px !important", // Remove padding from the Select component
                },
            }}
            value={optionAccount}
            onChange={selectHandleAccountChange}
            // inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={"all"}>
              <Paragraph>All</Paragraph>
            </MenuItem>
            {account &&
              account.map((account, key) => {
                return (
                  <MenuItem value={account._id}>
                    {" "}
                    <FlexBox key={key}>
                      <Paragraph>{account.paxful_username}</Paragraph>{" "}
                      <Box ml={2}>
                        <Platform size={20} platform={account.platform} />
                      </Box>
                    </FlexBox>
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <StyledStack alignItems="center" direction="row" gap={2}>
          {/* <SearchInput
            disable_border="true"
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
          /> */}

          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data && (
          <PaxfulApiLogListTable
            {...data}
            onPageChange={handlePageChange}
            currentPage={page}
            handleLimitChange={handleLimitChange}
            limit={limit}
          />
        )
      )}
    </Box>
  );
};

export default PaxfulApilog;
