import React, { useContext } from "react";
import ActivityLogServices from "services/ActivityLogService";
import { useEffect, useState } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import { Box, CircularProgress, Stack } from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import Invoice from "icons/duotone/Invoice";
import { H5 } from "components/Typography";
import InvoiceListTable from "page-sections/activity-log/index";
import { t } from "i18next";
import { LoadingButton } from "@mui/lab";
import ActivityLogListTable from "page-sections/activity-log/index";

function UserActivityLog() {
  // get data from usercontext api
  const { userdata } = useContext(UserContext);
  // console.log(userdata.paxfullAccounts[0]._id);
  const [isLoading, setisLoading] = useState(false);

  const [data, setData] = useState(null);
  const getUserActivityLog = async (id, page = 1, limit = 100) => {
    setisLoading(true);
    const resp = await ActivityLogServices.getUserActivityLog(id, page, limit);
    setData(resp);
    setisLoading(false);
  };

  useEffect(() => {
    getUserActivityLog(userdata._id);
  }, []);
  // ================================

  const reload = () => {
    getUserActivityLog(userdata._id);
  };

  const handlePageChange = (page, limit) => {
    getUserActivityLog(userdata._id, page, limit);
  };

  return (
    <>
      <Box pt={2} pb={4}>
        <FlexBetween flexWrap="wrap" gap={1} mb={2}>
          <Stack direction="row" alignItems="center">
            <IconWrapper>
              <Invoice
                sx={{
                  color: "primary.main",
                }}
              />
            </IconWrapper>
            <H5>{t("Activity Log")}</H5>
          </Stack>
          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </FlexBetween>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
            }}
          >
            {" "}
            <CircularProgress />
          </Box>
        ) : (
          data && (
            <ActivityLogListTable {...data} onPageChange={handlePageChange} />
          )
        )}
      </Box>
    </>
  );
}

export default UserActivityLog;
