import React, { useContext, useEffect, useRef, useState } from "react";
import { Send } from "@mui/icons-material";
import {
  Box,
  Card,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputBase,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Button } from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import SearchInput from "components/input-fields/SearchInput";
import Scrollbar from "components/ScrollBar";
import { H4, H5, Paragraph, Small, Tiny, Span } from "components/Typography";
import AttachmentDiagonal from "icons/AttachmentDiagonal";
import Image from "icons/Image";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import InfoIcon from "@mui/icons-material/Info";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import OutgoingMsg from "page-sections/chats/chat-v1/OutgoingMsg";
import IncomingMsg from "page-sections/chats/chat-v1/IncomingMsg";
import AvatarBadge from "components/avatars/AvatarBadge";
import AppAvatar from "components/avatars/AppAvatar";
import { emitToSocket } from "helpers/socketEmmiter";
import io from "socket.io-client";
import { SERVER_BASE_URL } from "config";
import { UserContextData } from "App";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import UserSymbol from "components/shared/UserSymbol";

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
}));

function Chat({
  data,
  message,
  setMessage,
  sendMessage,
  selectedFile,
  setSelectedFile,
}) {
  const chatContainerRef = React.useRef();
  const scrollToBottom = () => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };

  // React.useEffect(() => {
  //   scrollToBottom();
  // }, [data.chat]);
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [data.chat]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setMessage(e.target.files[0].name);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents new line
      sendMessage();
    }
  };
  return (
    <div>
      <div>
        <Card
          sx={{
            maxHeight: 800,
          }}
        >
          <Box p={2}>
            <FlexBox sx={{ alignItems: "center" }} gap={1}>
              {/* <AvatarBadge variant="dot" width={7} height={7} color="success"> */}
              {data.user && (
                <UserSymbol
                  user={data.user}
                  size={25}
                  showOnline={true}
                  isBottomZero={0}
                />
              )}
              <Stack spacing={0.3}>
                <H5>
                  {data?.submittedBy?.first_name} {data?.submittedBy?.last_name}
                </H5>
                <Tiny fontWeight={500}>Online</Tiny>
              </Stack>
            </FlexBox>
          </Box>

          <Divider />

          <Box position="relative">
            <Scrollbar
              style={{
                maxHeight: 562,
              }}
            >
              <Stack
                className="chat-container"
                ref={chatContainerRef}
                sx={{ minHeight: "70vh" }}
                spacing={4}
                px={3}
                py={2}
              >
                {data &&
                  data.chat.map((item) => {
                    return (
                      <>
                        {item.from == "admin" ? (
                          <OutgoingMsg item={item} />
                        ) : (
                          <IncomingMsg item={item} />
                        )}
                      </>
                    );
                  })}
              </Stack>
            </Scrollbar>
          </Box>

          <Divider />

          <Box>
            <FlexBetween padding={2} gap={2}>
              <InputBase
                fullWidth
                multiline
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                value={message}
                placeholder="Write a message..."
                startAdornment={
                  <FlexBox mr={1.5} gap={1.5}>
                    <StyledIconButton>
                      <AttachmentDiagonal
                        sx={{
                          color: "text.disabled",
                        }}
                      />
                    </StyledIconButton>
                    {/* <StyledIconButton>
                      <Image />
                    </StyledIconButton> */}
                    <div>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="image-upload"
                        type="file"
                        onChange={handleFileChange}
                      />
                      <label htmlFor="image-upload">
                        <Box
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <div>
                            <Image />
                          </div>
                        </Box>
                      </label>
                    </div>
                  </FlexBox>
                }
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                }}
              />
              {data?.status == "closed" || data?.status == "completed" ? (
                <StyledIconButton>
                  <Send
                    sx={{
                      color: "primary.main",
                    }}
                  />
                </StyledIconButton>
              ) : (
                <StyledIconButton
                  onClick={() => sendMessage()}
                  disabled={!(message && message.trim())}
                >
                  <Send
                    sx={{
                      color: "primary.main",
                    }}
                  />
                </StyledIconButton>
              )}
            </FlexBetween>
          </Box>
        </Card>
      </div>
    </div>
  );
}

export default Chat;
