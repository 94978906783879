import axios from "../utils/axios";
const VirtualAccountService = {};

VirtualAccountService.getVirtualAccounts = function (page, limit, search) {
  return axios({
    url: `virtual-accounts?page=${page}&limit=${limit}&search=${search}`,
    method: "GET",
  });
};
VirtualAccountService.createVirtualAccount = function (data) {
  return axios({
    url: `virtual-accounts`,
    method: "POST",
    data,
  });
};
VirtualAccountService.updateVirtualAccount = function (id, data) {
  return axios({
    url: `virtual-accounts/${id}`,
    method: "PUT",
    data,
  });
};
VirtualAccountService.deleteVirtualAccount = function (id) {
  return axios({
    url: `virtual-accounts/${id}`,
    method: "DELETE",
  });
};
VirtualAccountService.unAssignedVAs = function () {
  return axios({
    url: `virtual-accounts/unassignedVAs`,
    method: "GET",
  });
};
VirtualAccountService.updatePassword = function (id, data) {
  return axios({
    url: `/virtual-accounts/${id}/password`,
    method: "PUT",
    data,
  });
};

export default VirtualAccountService;
