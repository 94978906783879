import { IconButton, Stack } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import { FormattedDateTime } from "components/shared/FormattedDate";
import { formatCurrency } from "utils/currencies";

const columnShape = [
  {
    Header: "DATE",
    accessor: "startedAt",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        <FormattedDateTime date={value} />
      </Paragraph>
    ),
  },
  {
    Header: "TRANSACTION NUMBER",
    accessor: "txn_id",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "TXN TYPE",
    accessor: "txn_type",
    Cell: ({ value }) => (
      <Small
        sx={{
          width: 100,
          fontSize: 20,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: "0 .5rem",
          backgroundColor: value == "debit" ? "error.main" : "success.main",
          fontWeight: 500,
        }}
      >
        {value == "debit" ? "-" : "+"}
      </Small>
    ),
  },
  {
    Header: "AMOUNT",
    accessor: "fiat_amount",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {formatCurrency(value)}
      </Paragraph>
    ),
  },
  {
    Header: "BALANCE",
    accessor: "fiat_balance",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {formatCurrency(value)}
      </Paragraph>
    ),
  },
];
export default columnShape;
