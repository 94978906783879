import { GET_USERS } from "../constants";

export default function reducer(
  state = {
    users: [],
  },
  action
) {
  switch (action.type) {
    case GET_USERS:
      return { ...state, users: action.payload };
  }
  return state;
}
