import axios from "../utils/axios";

const BlockChainTrasnService = {};

BlockChainTrasnService.getBcTranscation = function (
  user_id,
  page,
  limit,
  method,
  paid_for
) {
  return axios({
    url: `/blockchain-transactions/${user_id}?page=${page}&limit=${limit}&method=${method}&paid_for=${paid_for}`,
    method: "GET",
  });
};

BlockChainTrasnService.getSingleBcTranscation = function (id, user_id) {
  return axios({
    url: `/blockchain-transactions/transactions/${id}?user_id=${user_id}`,
    method: "GET",
  });
};

export default BlockChainTrasnService;
