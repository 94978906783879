import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import VAService from "services/virtualAssistantService";
import AppModal from "components/AppModal";
import { H2 } from "components/Typography";
import { Button, Grid, styled, Select, MenuItem } from "@mui/material";

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 650,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

function AddvaModal2({ open, onClose, onSuccess }, props) {
  const initialValues = {
    name: "",
    email: "",
    password: "",
    images: [],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // email: Yup.string().email("Invalid email").required("Email is required"),
    // password: Yup.string()
    //   .min(6, "Password must be at least 6 characters")
    //   .required("Password is required"),
    // images: Yup.array().min(1, "At least one image is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("country", values.country);
    formData.append("timezone", values.timezone);
    formData.append("experience", values.experience);
    values.images.forEach((image) => {
      formData.append("profile_pic", image);
    });
    let resp = await VAService.createVa(formData);
  };
  return (
    <>
      <StyledAppModal open={open} handleClose={onClose}>
        <H2 mb={2}>Add new Virtual Assistant</H2>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Field name="name" type="text" placeholder="Name" />
              <Field name="country" type="text" placeholder="country" />
              <Field name="timezone" type="text" placeholder="timezone" />
              <Field name="experience" type="text" placeholder="experience" />
              <input
                name="profile_pic"
                type="file"
                multiple
                onChange={(event) => {
                  setFieldValue("images", event.currentTarget.files);
                }}
              />
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </StyledAppModal>
    </>
  );
}

export default AddvaModal2;
