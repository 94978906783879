import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import { Paragraph, Small, Tiny } from "components/Typography";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import AppModal from "components/AppModal";
import AppTextField from "components/input-fields/AppTextField";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import Scrollbar from "components/ScrollBar";
import { H3, H6, H4 } from "components/Typography";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axios";
import * as Yup from "yup"; // component props interface

import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";

import { useTranslation } from "react-i18next";
import JSONPretty from "react-json-pretty";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward } from "@mui/icons-material";
import UserSymbol from "components/shared/UserSymbol";

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 750,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

const ViewActivityModal = ({ open, onClose, data }) => {
  console.log("data", data);
  const { t } = useTranslation();
  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <Box pb={4}>
        {/* <H3 mb={3}>{t("Activity Log Info")}</H3> */}
        <Grid item md={12} xs={12} m={1}>
          <H6 color="text.secondary">{t("ACTION")}</H6>
          <Paragraph fontSize={13} color="text.secondary">
            {data.path}
          </Paragraph>
        </Grid>
        <Grid container spacing={0} mt={0}>
          <Grid item md={4} xs={4}>
            <Grid container spacing={0} mt={0}>
              <Grid item md={12} xs={12} m={1}>
                <H6 color="text.secondary">{t("DATE")}</H6>
                <Paragraph fontSize={13} color="text.secondary">
                  <FormattedDateTime date={data.createdAt} />
                </Paragraph>
              </Grid>

              <Grid item md={12} xs={12} m={1}>
                <H6 color="text.secondary">{t("USER")}</H6>
                <H4 fontWeight={500}>
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    {data.user && (
                      <UserSymbol
                        user={data.user[0]}
                        size={25}
                        showOnline={true}
                        isBottomZero={0}
                      />
                    )}

                    <Box
                      sx={{
                        marginLeft: "10px !important",
                      }}
                    >
                      <Paragraph fontSize={13} color="text.secondary">
                        {data.user[0] == null ? "-" : data.user[0].username}
                      </Paragraph>
                    </Box>
                  </Stack>
                </H4>
              </Grid>
            </Grid>

            <Grid container spacing={0} mt={0}>
              <Grid item md={12} xs={12} m={1}>
                <H6 color="text.secondary">{t("MEHTOD")}</H6>
                <Paragraph fontSize={13} color="text.secondary">
                  {data.method}
                </Paragraph>
              </Grid>
            </Grid>

            <Grid container spacing={0} mt={0}>
              <Grid item md={12} xs={12} m={1}>
                <H6 color="text.secondary">{t("TYPE")}</H6>
                <Paragraph fontSize={13} color="text.secondary">
                  {data.type}
                </Paragraph>
              </Grid>
              <Grid item md={12} xs={12} m={1}>
                <H6 color="text.secondary">{t("IP")}</H6>
                <Paragraph fontSize={13} color="text.secondary">
                  {data.ip}
                </Paragraph>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={8} xs={8}>
            <Grid m={1}>
              <div
                style={{
                  maxHeight: "200px",
                  overflow: "auto",
                  fontSize: "10px",
                }}
              >
                <H6 color="text.secondary">{t("BODY")}</H6>
                <JSONPretty id="json-pretty" data={data.body}></JSONPretty>
              </div>
            </Grid>

            <Grid m={1}>
              <div
                style={{
                  maxHeight: "200px",
                  overflow: "auto",
                  fontSize: "10px",
                }}
              >
                <H6 color="text.secondary">{t("QUERY")}</H6>
                <JSONPretty id="json-pretty" data={data.query}></JSONPretty>
              </div>
            </Grid>
            <Grid m={1}>
              <div
                style={{
                  maxHeight: "200px",
                  overflow: "auto",
                  fontSize: "10px",
                }}
              >
                <H6 color="text.secondary">{t("HEADER")}</H6>
                <JSONPretty id="json-pretty" data={data.header}></JSONPretty>
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* <Divider
          sx={{
            my: 3,
          }}
        /> */}
        {/* <Grid container spacing={0} mt={0}></Grid> */}
        {/* 
        <Divider
          sx={{
            my: 3,
          }}
        /> */}
      </Box>
    </StyledAppModal>
  );
};

export default ViewActivityModal;
