import React from "react";
import ATooltip from "./ATooltip";
import UserSymbol from "./UserSymbol";
import UserInfo from "./UserInfo";
import UserSymbolAT from "./UserSymbolAT";
import FlexBox from "components/flexbox/FlexBox";
import { Box, Typography } from "@mui/material";
import { Paragraph } from "components/Typography";
// import UserSymbol from '../../widgets/UserSymbol';
// import ATooltip from '../bootstrap/ATooltip';
// import UserInfo from '../UserInfo';

function CreatedBy({ user, className, title, size }) {
  return (
    <>
      <ATooltip
        addClass
        content={
          <div className="text-start">
            <Paragraph color="text.secondary" className="text-gray-600 mb-2">
              {title ? title : "Initiated by:"}
            </Paragraph>
            <UserInfo user={user} showOnlineStatus={false} />
          </div>
        }
      >
        <FlexBox
          sx={{
            alignItems: "center",
          }}
          className={`d-flex align-items-center ${className}`}
        >
          {/* <UserSymbolAT
            user={user}
            size={20}
            fw={"semibold"}
            fs={8}
            showOnline={false}
          /> */}
          <UserSymbol
            size={size ? size : 25}
            user={user}
            showOnline={true}
            isBottomZero={0}
          />
          <Paragraph
            ml={1}
            fontSize={12}
            sx={{ color: "gray" }}
            className={`text-gray-600 cursor-default ms-1`}
          >
            <span style={{ cursor: "pointer" }}>{user.username}</span>
          </Paragraph>
        </FlexBox>
      </ATooltip>
    </>
  );
}

export default CreatedBy;
