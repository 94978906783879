import { ArrowOutward, CameraAlt } from "@mui/icons-material";
import { TabList } from "@mui/lab";
import { Card, IconButton, Stack, styled, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import { H3, H4, H5, Small, Span, Tiny } from "components/Typography";
import AvatarBadge from "components/avatars/AvatarBadge";
import Bratislava from "icons/Bratislava";
import DateRange, { clock, person } from "icons/DateRange";
import MapMarkerIcon from "icons/MapMarkerIcon";
import numeral from "numeral";
import { Fragment, useState } from "react";
import { Outlet } from "react-router-dom"; // styled components

import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";

import { FormattedDate } from "components/shared/FormattedDate";
import TableCell from "@mui/material/TableCell";
import CountryFlag from "components/shared/CountryFlag";
import LastActive from "components/shared/LastActive";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import OnlineStatus from "components/shared/OnlineStatus";
import { COMMON_API_BASE_URL } from "config";
import UserService from "services/UserService";

const AutomationLayout = ({ children, handleTabList, tabValue }) => {
  return (
    <Fragment>
      <Box px={2}>
        <Tabs
          value={tabValue}
          onChange={handleTabList}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            icon={
              <>
                <FlexBox sx={{ alignItems: "center" }}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                      fill="currentColor"
                    />
                    <path
                      d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                      fill="currentColor"
                    />
                  </svg>
                  <Small ml={1}>Greeting Messages</Small>
                </FlexBox>
              </>
            }
            disableRipple
            // label="Greeting Messages"
            value="greeting"
          />
          <Tab
            icon={
              <>
                <FlexBox sx={{ alignItems: "center" }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                      fill="currentColor"
                    />
                  </svg>

                  <Small ml={1}>Messaging</Small>
                </FlexBox>
              </>
            }
            disableRipple
            // label="Messaging"
            value="messaging"
          />
          <Tab
            icon={
              <>
                <FlexBox sx={{ alignItems: "center" }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.3"
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="10"
                      fill="currentColor"
                    />
                    <path
                      d="M15.8054 11.639C15.6757 11.5093 15.5184 11.4445 15.3331 11.4445H15.111V10.1111C15.111 9.25927 14.8055 8.52784 14.1944 7.91672C13.5833 7.30557 12.8519 7 12 7C11.148 7 10.4165 7.30557 9.80547 7.9167C9.19432 8.52784 8.88885 9.25924 8.88885 10.1111V11.4445H8.66665C8.48153 11.4445 8.32408 11.5093 8.19444 11.639C8.0648 11.7685 8 11.926 8 12.1112V16.1113C8 16.2964 8.06482 16.4539 8.19444 16.5835C8.32408 16.7131 8.48153 16.7779 8.66665 16.7779H15.3333C15.5185 16.7779 15.6759 16.7131 15.8056 16.5835C15.9351 16.4539 16 16.2964 16 16.1113V12.1112C16.0001 11.926 15.9351 11.7686 15.8054 11.639ZM13.7777 11.4445H10.2222V10.1111C10.2222 9.6204 10.3958 9.20138 10.7431 8.85421C11.0903 8.507 11.5093 8.33343 12 8.33343C12.4909 8.33343 12.9097 8.50697 13.257 8.85421C13.6041 9.20135 13.7777 9.6204 13.7777 10.1111V11.4445Z"
                      fill="currentColor"
                    />
                  </svg>

                  <Small ml={1}>Trade Security</Small>
                </FlexBox>
              </>
            }
            disableRipple
            // label="Trade Security"
            value="security"
          />
          <Tab
            icon={
              <>
                <FlexBox sx={{ alignItems: "center" }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M3 6C2.4 6 2 5.6 2 5V3C2 2.4 2.4 2 3 2H5C5.6 2 6 2.4 6 3C6 3.6 5.6 4 5 4H4V5C4 5.6 3.6 6 3 6ZM22 5V3C22 2.4 21.6 2 21 2H19C18.4 2 18 2.4 18 3C18 3.6 18.4 4 19 4H20V5C20 5.6 20.4 6 21 6C21.6 6 22 5.6 22 5ZM6 21C6 20.4 5.6 20 5 20H4V19C4 18.4 3.6 18 3 18C2.4 18 2 18.4 2 19V21C2 21.6 2.4 22 3 22H5C5.6 22 6 21.6 6 21ZM22 21V19C22 18.4 21.6 18 21 18C20.4 18 20 18.4 20 19V20H19C18.4 20 18 20.4 18 21C18 21.6 18.4 22 19 22H21C21.6 22 22 21.6 22 21ZM16 11V9C16 6.8 14.2 5 12 5C9.8 5 8 6.8 8 9V11C7.2 11 6.5 11.7 6.5 12.5C6.5 13.3 7.2 14 8 14V15C8 17.2 9.8 19 12 19C14.2 19 16 17.2 16 15V14C16.8 14 17.5 13.3 17.5 12.5C17.5 11.7 16.8 11 16 11ZM13.4 15C13.7 15 14 15.3 13.9 15.6C13.6 16.4 12.9 17 12 17C11.1 17 10.4 16.5 10.1 15.7C10 15.4 10.2 15 10.6 15H13.4Z"
                      fill="currentColor"
                    />
                    <path
                      d="M9.2 12.9C9.1 12.8 9.10001 12.7 9.10001 12.6C9.00001 12.2 9.3 11.7 9.7 11.6C10.1 11.5 10.6 11.8 10.7 12.2C10.7 12.3 10.7 12.4 10.7 12.5L9.2 12.9ZM14.8 12.9C14.9 12.8 14.9 12.7 14.9 12.6C15 12.2 14.7 11.7 14.3 11.6C13.9 11.5 13.4 11.8 13.3 12.2C13.3 12.3 13.3 12.4 13.3 12.5L14.8 12.9ZM16 7.29998C16.3 6.99998 16.5 6.69998 16.7 6.29998C16.3 6.29998 15.8 6.30001 15.4 6.20001C15 6.10001 14.7 5.90001 14.4 5.70001C13.8 5.20001 13 5.00002 12.2 4.90002C9.9 4.80002 8.10001 6.79997 8.10001 9.09997V11.4C8.90001 10.7 9.40001 9.8 9.60001 9C11 9.1 13.4 8.69998 14.5 8.29998C14.7 9.39998 15.3 10.5 16.1 11.4V9C16.1 8.5 16 8 15.8 7.5C15.8 7.5 15.9 7.39998 16 7.29998Z"
                      fill="currentColor"
                    />
                  </svg>

                  <Small ml={1}>KYC</Small>
                </FlexBox>
              </>
            }
            disableRipple
            // label="KYC"
            value="kyc"
          />
          <Tab
            icon={
              <>
                <FlexBox sx={{ alignItems: "center" }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                      fill="currentColor"
                    />
                    <path
                      d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                      fill="currentColor"
                    />
                    <path
                      d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                      fill="currentColor"
                    />
                  </svg>

                  <Small ml={1}>Feedback</Small>
                </FlexBox>
              </>
            }
            disableRipple
            // label="Feedback"
            value="feedback"
          />
          <Tab
            icon={
              <>
                <FlexBox sx={{ alignItems: "center" }}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                      fill="currentColor"
                    />
                    <path
                      d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                      fill="currentColor"
                    />
                  </svg>

                  <Small ml={1}>Closing Messages</Small>
                </FlexBox>
              </>
            }
            disableRipple
            // label="Closing Messages"
            value="closing-messages"
          />
        </Tabs>
      </Box>

      {/* </Card> */}

      {children || <Outlet />}
    </Fragment>
  );
};

export default AutomationLayout; // ----------------------------------------------------------------

// ----------------------------------------------------------------
function ListItem({ title, Icon }) {
  return (
    <FlexBox alignItems="center">
      <Icon
        sx={{
          fontSize: 14,
          color: "text.secondary",
          mr: 1,
        }}
      />
      <Tiny fontWeight={500} color="text.secondary">
        {title}
      </Tiny>
    </FlexBox>
  );
}

function BoxItem({ title, amount, color }) {
  return (
    <Stack
      spacing={0.5}
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "divider",
        width: {
          sm: 120,
          xs: "100%",
        },
        marginBottom: {
          sm: 0,
          xs: 1,
        },
      }}
    >
      <H5 color={color}>${numeral(amount).format("0,00")}</H5>
      <Tiny fontWeight={500}>{title}</Tiny>
    </Stack>
  );
}
