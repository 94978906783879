import axios from "../utils/axios";

const rateService = {};

rateService.getRate = function () {
  return axios({
    url: `/rate`,
    method: "GET",
  });
};

rateService.getFiatRate = function () {
  return axios({
    url: `/rate/fiat-rate`,
    method: "GET",
  });
};

export default rateService;
