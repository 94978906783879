import { Small } from "components/Typography";
import React from "react";

function MfaCheck({ value }) {
  return (
    <div>
      <Small
        sx={{
          width: 100,
          fontSize: 10,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: ".2rem 1rem",
          backgroundColor: value ? "success.main" : "error.main",
        }}
      >
        {value ? "Enabled" : "Disabled"}
      </Small>
    </div>
  );
}

export default MfaCheck;
