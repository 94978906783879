import { ArrowUpward, Wallet } from "@mui/icons-material";
import { alpha, Box, Button, Card, useTheme } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import { H1, H4, H6, Small } from "components/Typography";
import ShoppingBagIcon from "icons/ShoppingBagIcon";
import numeral from "numeral";
import React from "react";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";

export function formatCurrency(
  amount,
  currencyCode = "USD",
  currencyDisplay = "symbol"
) {
  const formatter = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: currencyDisplay,
  });
  return formatter.format(amount);
}

const Wallet2 = ({ tabchange }) => {
  // Get data from usercontext api
  const { userdata } = useContext(UserContext);
  const wallet = userdata.wallet[0];

  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        padding: 3,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box width="100%" textAlign="center">
        <H4 fontWeight={600} mb={3}>
          {t("Trade Volume")}
        </H4>
        <Box
          sx={{
            width: 30,
            height: 30,
            margin: "auto",
            borderRadius: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "primary.main",
          }}
        >
          <Wallet
            sx={{
              fontSize: 18,
              color: "common.white",
            }}
          />
        </Box>

        <H1 color="primary.main" my={1}>
          {wallet?.trading_limit
            ? formatCurrency(wallet?.trading_limit)
            : "$0 "}
        </H1>

        {/* <FlexBox alignItems="center" justifyContent="center">
          <Small color="grey.500" fontSize={10} fontWeight={600} ml={0.5}>
            {userdata?.wallet?.deposit_address}
          </Small>
        </FlexBox> */}

        <H6 color="text.disabled" mt={1} mb={3}>
          Topup Amount is{" "}
          {wallet?.balance ? formatCurrency(wallet.balance) : "$0"}
        </H6>

        <Button variant="contained" fullWidth onClick={() => tabchange("18")}>
          {t("View Transactions")}
        </Button>
      </Box>
    </Card>
  );
};

export default Wallet2;
