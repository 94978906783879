import { Box, Icon, Typography, styled } from "@mui/material";
import React from "react";
import FlexBox from "./flexbox/FlexBox";
import { Paragraph } from "./Typography";
// import { makeStyles } from "@material-ui/core/styles";
// import { Box, Typography } from "@material-ui/core";
// import { Icon } from "@material-ui/core";
// import { YourIconComponent } from "your-icon-package";

const CraftNotice = ({ icon, content }) => {
  return (
    <FlexBox
      sx={{
        backgroundColor: "aliceblue",
        padding: "20px 10px",
        borderRadius: "10px",
      }}
    >
      <Icon>{icon}</Icon>
      <Paragraph fontSize={13} ml={5}>
        {content}
      </Paragraph>
    </FlexBox>
  );
};

export default CraftNotice;
