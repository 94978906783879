const platforms = {
  noones: {
    title: "NoOnes",
    dev_portal_link: "https://dev.noones.com/dashboard/direct-access",
  },
  paxful: {
    title: "Paxful",
    dev_portal_link: "https://developers.paxful.com/dashboard/direct-access",
  },
};

export default platforms;
