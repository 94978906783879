import React from "react";

export const Image = ({ image, height, width, className, alt }) => {
  return (
    <>
      <img
        alt={alt}
        style={{
          height: `${height}px`,
          width: `${width}px`,
        }}
        src={image}
      />
    </>
  );
};
