import axios from "../utils/axios";

const ProfileService = {};

ProfileService.getProfile = function () {
  return axios({
    url: `/profile`,
    method: "GET",
  });
};

export default ProfileService;
