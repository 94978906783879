import axios from "../utils/axios";
const NotificationService = {};

NotificationService.getUsernotification = function (id, search, page, limit) {
  return axios({
    url: `/notification/${id}?search=${search}&page=${page}&limit=${limit}`,
    method: "GET",
  });
};
export default NotificationService;
