import axios from "axios";
import { WEBHOOK_API_URL } from "config";

const WebhookService = {};

WebhookService.getWebhooks = function (page, limit, search) {
  return axios
    .get(`${WEBHOOK_API_URL}/?page=${page}&limit=${limit}&search=${search}`)
    .then((res) => {
      return res.data;
    });
};

WebhookService.getWebhook = function (account_id, page, limit) {
  return axios
    .get(`${WEBHOOK_API_URL}/${account_id}/?page=${page}&limit=${limit}`)
    .then((res) => {
      return res.data;
    });
};

WebhookService.resendWebhook = async function (id) {
  return await axios.post(`${WEBHOOK_API_URL}/resend/${id}`).then((res) => {
    return res.data;
  });
};

export default WebhookService;
