import { Box, Card, CardContent, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import FlexBox from "components/flexbox/FlexBox";
import React, { useEffect, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import HiringRequestService from "services/HireRequestService";
import { FormattedDateTime } from "components/shared/FormattedDate";
import { Paragraph, Small } from "components/Typography";
import { formatCryptoDecimal, formatCurrency } from "utils/currencies";
import { FormatBold } from "@mui/icons-material";
import ScheduleViewer from "components/schedule-viewer/ScheduleViewer";
import CraftNotice from "components/CraftNotice";
import { styled } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CountryFlag from "components/shared/CountryFlag";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { arrayIsEmpty, copyTextToClipboard } from "utils/utils";
import CryptoImg from "components/shared/CryptoImg";
import crptoRateService from "services/crptoRateService";
import rateService from "services/rateService";
import { createConstructorTypeNode } from "typescript";
import PaxfulAccount from "components/shared/PaxfulAccount";
import NoResultFound from "components/shared/NoResultFound";

const useStyles = makeStyles({
  // table: {
  //   minWidth: 650,
  // },
  // tableCell: {
  //   borderBottom: "1px solid rgba(224, 224, 224, 1)", // Customize the border style here
  // },
});

const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 0",
  color: theme.palette.text.primary,
}));

const convertRate = (rate, item) => {
  let converRate;
  for (let code in rate) {
    if (code == item.crypto.toLowerCase()) {
      converRate = rate[code];
      return converRate;
    }
  }
};

function WalletTable({ data }) {
  const classes = useStyles();

  const [rates, setRates] = useState(null);
  useEffect(() => {
    const rate = rateService.getRate().then((rate) => {
      setRates(rate);
    });
  }, []);

  const networkTagColor = {
    "ERC-20": "primary.main",
    "TRC-20": "error.main",
    "BEP-20": "warning.main",
    BSC: "warning.main",
    TON: "primary.main",
    SOL: "info.main",
  };

  return (
    <div>
      <Grid xs={8} ml={1}>
        {/* <Card>
          <CardContent> */}
        <div className="mb-3"></div>
        <Box mt={1}>
          <TableContainer>
            <Table
              sx={{
                borderSpacing: "0 1rem",
                borderCollapse: "separate",
                minWidth: 900,
                backgroundColor: "transparent",
              }}
            >
              <TableHead>
                <TableRow>
                  <HeadTableCell>
                    <Paragraph
                      fontSize={12}
                      color="text.secondary"
                      fontWeight={600}
                    >
                      ACCOUNT
                    </Paragraph>
                  </HeadTableCell>
                  <HeadTableCell>
                    {" "}
                    <Paragraph
                      fontSize={12}
                      color="text.secondary"
                      fontWeight={600}
                      marginLeft={2}
                    >
                      CRYPTO
                    </Paragraph>
                  </HeadTableCell>
                  <HeadTableCell>
                    {" "}
                    <Paragraph
                      fontSize={12}
                      color="text.secondary"
                      fontWeight={600}
                      marginLeft={2}
                    >
                      ADDRESS
                    </Paragraph>
                  </HeadTableCell>
                  <HeadTableCell>
                    {" "}
                    <Paragraph
                      fontSize={12}
                      color="text.secondary"
                      fontWeight={600}
                      marginLeft={2}
                    >
                      TAG/MEMO/COMMENT
                    </Paragraph>
                  </HeadTableCell>

                  <HeadTableCell
                    sx={{
                      textAlign: "end !important",
                      paddingRight: "20px !important",
                    }}
                  >
                    <Paragraph
                      fontSize={12}
                      color="text.secondary"
                      fontWeight={600}
                    >
                      BALANCE
                    </Paragraph>
                  </HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && arrayIsEmpty(data) ? (
                  <BodyTableCell colSpan={4} align="center">
                    <NoResultFound />
                  </BodyTableCell>
                ) : (
                  data &&
                  data.map((item, index) => {
                    let rate = null;
                    if (rates) {
                      const currency_rate =
                        rates && rates?.find(({ code }) => code == "USD");
                      if (currency_rate) {
                        rate = currency_rate.rate;
                      }
                    }

                    const rateconverter = convertRate(rate, item);
                    // console.log("item.paxful_avatar", item.account_profile);
                    return (
                      <>
                        <StyledTableRow key={index}>
                          <BodyTableCell>
                            <PaxfulAccount item={item.account} size={25} />
                          </BodyTableCell>
                          <TableCell>
                            <FlexBox
                              sx={{
                                alignItems: "center",
                              }}
                            >
                              <CryptoImg currency={item.crypto} size={25} />
                              <Paragraph
                                ml={2}
                                fontSize={13}
                                color="text.secondary"
                              >
                                {item.crypto}
                              </Paragraph>
                              <Small
                                ml={2}
                                sx={{
                                  backgroundColor:
                                    networkTagColor[item.network],
                                  padding: "4px 6px",
                                  borderRadius: "5px",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  color: "white",
                                }}
                              >
                                {item.network}
                              </Small>
                            </FlexBox>
                          </TableCell>
                          <TableCell>
                            <FlexBox>
                              <Paragraph
                                mr={1}
                                fontSize={13}
                                color="text.secondary"
                              >
                                {item.crypto_address}
                              </Paragraph>
                              {item.crypto_address && (
                                <ContentCopyIcon
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                  color={"primary"}
                                  onClick={() =>
                                    copyTextToClipboard(item.crypto_address)
                                  }
                                />
                              )}
                            </FlexBox>
                          </TableCell>
                          <TableCell>
                            <FlexBox
                              sx={{
                                textAlign: "end",
                                paddingRight: "20px",
                              }}
                            >
                              {item?.address_memo}
                              {item?.address_memo && (
                                <ContentCopyIcon
                                  sx={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                  color={"primary"}
                                  onClick={() =>
                                    copyTextToClipboard(item?.address_memo)
                                  }
                                />
                              )}
                            </FlexBox>
                          </TableCell>

                          <TableCell>
                            <Box
                              sx={{
                                textAlign: "end",
                                paddingRight: "20px",
                              }}
                            >
                              <Paragraph fontSize={13} color="text.secondary">
                                {formatCurrency(
                                  item.crypto_balance * rateconverter,
                                  "USD"
                                )}
                              </Paragraph>
                              <Paragraph fontSize={13} color="text.secondary">
                                {item.crypto_balance} {item.crypto}
                              </Paragraph>
                            </Box>
                          </TableCell>
                        </StyledTableRow>
                      </>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* </CardContent>
        </Card> */}
      </Grid>
    </div>
  );
}

export default WalletTable;
