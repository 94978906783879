import React, {
  useRef,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useContext,
} from "react";

import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgRotate from "lightgallery/plugins/rotate";
import lgThumbnail from "lightgallery/plugins/thumbnail";

export const ImageGalleryContext = React.createContext({
  images: [],
  setImages: () => {},
});

function ImageGallery({ images }, ref) {
  const lightGallery = useRef(null);

  // const { images } = useContext(ImageGalleryContext);

  useImperativeHandle(ref, () => ({
    open(slide) {
      lightGallery.current.openGallery(slide);
    },
  }));

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  useEffect(() => {
    lightGallery.current.refresh();
  }, [images]);

  return (
    <LightGallery
      onInit={onInit}
      dynamic={true}
      dynamicEl={images.map((src) => ({ src, thumb: src }))}
      plugins={[lgThumbnail, lgZoom, lgRotate]}
    />
  );
}

export default forwardRef(ImageGallery);
