import React, { useEffect, useRef, useState } from "react";

function QRCode({ size, data, image, cornerSquareColor, imageSize, margin }) {
  const ref = useRef(null);
  let style = getComputedStyle(document.body);

  const [svg, setSvg] = useState(null);

  const options = {
    width: size,
    height: size,
    type: "svg",
    data: data,
    image: image,
    backgroundOptions: {
      //color: style.getPropertyValue("--kt-light"),
      color: "transparent",
    },
    dotsOptions: {
      type: "extra-rounded",
      color: style.getPropertyValue("--kt-text-gray-700"),
    },
    cornersSquareOptions: {
      color: cornerSquareColor
        ? cornerSquareColor
        : style.getPropertyValue("--kt-text-gray-700"),
    },
    imageOptions: {
      margin: 0,
      imageSize: imageSize ? imageSize : 0.4,
    },
  };

  const initQRCode = async () => {
    const QRCodeStyling = require("qr-code-styling");
    const qr = new QRCodeStyling(options);
    const blob = await qr.getRawData("svg");

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      setSvg(reader.result);
    };
  };

  useEffect(() => {
    // console.log('[ L O A D E D ]');
    // qr.getRawData("svg").then((svg) => {
    //   // setSvg(null);
    //   svg.text().then((result) => {
    //     setSvg(result);
    //   });
    // });
    initQRCode();
  }, [data, image]);

  return (
    //<div dangerouslySetInnerHTML={{ __html: svg }}></div>
    <>
      {svg ? (
        <img style={{ width: size, height: size }} src={svg} alt="" />
      ) : (
        <></>
      )}
    </>
  );
}

QRCode.defaultProps = {
  size: 300,
  data: null,
  image: null,
  cornerSquareColor: null,
  imageSize: 0.4,
};
export default QRCode;
