import { ArrowBack, ChevronRight, Rowing, Send } from "@mui/icons-material";
import {
  Box,
  Card,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputBase,
  Stack,
  styled,
  useMediaQuery,
} from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import SearchInput from "components/input-fields/SearchInput";
import Scrollbar from "components/ScrollBar";
import {
  H4,
  H5,
  H6,
  Paragraph,
  Small,
  Tiny,
  Span,
  H1,
} from "components/Typography";
import AttachmentDiagonal from "icons/AttachmentDiagonal";
import FileOutlined from "icons/FileOutlined";
import Image from "icons/Image";
import Link from "icons/Link";
import Common from "page-sections/chats/chat-v1/Common";
import FileType from "page-sections/chats/chat-v1/FileType";
import IncomingMsg from "page-sections/chats/chat-v1/IncomingMsg";
import OutgoingMsg from "page-sections/chats/chat-v1/OutgoingMsg";
import PersonInfo from "page-sections/chats/chat-v1/PersonInfo";
import RecentChatItem from "page-sections/chats/chat-v1/RecentChatItem";
import { useState, useEffect } from "react";
import RECENT_CHATS from "__fakeData__/recent_chats";
// import { lightTheme } from "../../constants"; // styled components
import { lightTheme } from "../../../../constants";
import { H3 } from "components/Typography";
import TradeStatus from "components/shared/TradeStatus";
import TradeServices from "services/TradeService";
import { useParams } from "react-router";
import UserService from "services/UserService";
import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import OfferType from "components/shared/OfferType";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import UserSymbol from "components/shared/UserSymbol";
import Badge from "utils/Badge";
import { formatCurrency, normalizeCrypto } from "utils/currencies";
import TradeDetail from "./TradeDetail";
import Chat from "./Chat";
import { ImageGalleryContext } from "components/ImageGalleryContext";
import { FsligthGallery } from "components/FsligthGallery";

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
}));
const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
  backgroundColor: lightTheme(theme)
    ? theme.palette.action.hover
    : theme.palette.divider,
}));
const ToggleBtn = styled(Box)(({ theme, screen = "md" }) => ({
  top: 20,
  zIndex: 1,
  padding: 5,
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  position: "absolute",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up(screen)]: {
    display: "none",
  },
}));

const View = ({ trade, onBack }) => {
  // get user id
  const [openLeftDrawer, setOpenLeftDrawer] = useState(false);
  const [openRightDrawer, setOpenRightDrawer] = useState(false);
  const downXl = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [galleryImages, setGalleryImages] = useState([]);

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    sourceIndex: 0,
  });

  function openLightboxOnSource(sourceIndex) {
    let updateState = {
      toggler: !lightboxController.toggler,
      sourceIndex: sourceIndex,
    };
    setLightboxController(updateState);
  }

  const onImageSelect = (image) => {
    const selectedIndex = galleryImages.indexOf(image);
    openLightboxOnSource(selectedIndex);
    //galleryRef.current.open(selectedIndex);
  };

  // const filesContent = (
  //   <Card
  //     sx={{
  //       maxHeight: 700,
  //       height: "100%",
  //       pb: 1,
  //     }}
  //   >
  //     <Stack
  //       alignItems="center"
  //       sx={{
  //         padding: 3,
  //       }}
  //     >
  //       <AppAvatar src="/static/avatar/006-woman-1.svg" />
  //       <H5 mt={1.5} mb={0.5}>
  //         Taylor clark
  //       </H5>
  //       <Tiny fontWeight={500}>Developer</Tiny>
  //     </Stack>

  //     <Divider />

  //     <FlexBox justifyContent="center" gap={2} p={2}>
  //       <Common Icon={FileOutlined} amount={230} title="Files" />
  //       <Common Icon={Link} amount={230} title="Links" />
  //     </FlexBox>

  //     <H5 p={2}>File Type</H5>
  //     <Scrollbar
  //       autoHide={false}
  //       style={{
  //         maxHeight: 435,
  //       }}
  //     >
  //       <Stack spacing={3} px={2}>
  //         <FileType />
  //       </Stack>
  //     </Scrollbar>
  //   </Card>
  // );
  return (
    <Box pt={2} pb={4}>
      <Grid container spacing={3}>
        {/* {downMd ? (
          <Drawer
            anchor="left"
            open={openLeftDrawer}
            onClose={() => setOpenLeftDrawer(false)}
          >
            <Box width={300} padding={1}>
              {trade ? <TradeDetail trade={trade}/> : <h1>Error</h1> }
            </Box>
          </Drawer>
        ) : (
          
        )} */}
        <Grid item xl={6} md={6} xs={12}>
          {trade ? (
            <TradeDetail onBack={onBack} trade={trade} />
          ) : (
            <h1>Error</h1>
          )}
        </Grid>
        <Grid item xl={6} md={6} xs={12}>
          {trade ? (
            <>
              <ImageGalleryContext.Provider
                value={{
                  images: galleryImages,
                  setImages: setGalleryImages,
                  openImage: onImageSelect,
                }}
              >
                <Chat trade={trade} />
              </ImageGalleryContext.Provider>
              <FsligthGallery
                lightboxController={lightboxController}
                images={galleryImages}
              />
            </>
          ) : (
            <h1>Error</h1>
          )}
        </Grid>

        {/* {downXl ? (
          <Drawer
            anchor="right"
            open={openRightDrawer}
            onClose={() => setOpenRightDrawer(false)}
          >
            <Box width={300} padding={1}>
              {filesContent}
            </Box>
          </Drawer>
        ) : (
          <Grid item xl={3}>
            {filesContent}
          </Grid>
        )} */}
      </Grid>
      <FsligthGallery
        lightboxController={lightboxController}
        images={galleryImages}
      />
    </Box>
  );
};

export default View;
