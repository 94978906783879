import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Prepaid2CoinService from "services/Prepaid2CoinService";
import KycBasicInfo from "./KycBasicInfo";
import KycDoc from "./KycDoc";
import { TabContext, TabList } from "@mui/lab";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  styled,
  Tab,
} from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import { H5, Paragraph } from "components/Typography";
import IconWrapper from "components/IconWrapper";
import Add from "icons/Add";
import GroupSenior from "icons/GroupSenior";
import { useTranslation } from "react-i18next"; // styled components
import AddEmployeeModal from "page-sections/data-table/dataTableV2/AddEmployeeModal";
import InviteUserModal from "page-sections/data-table/dataTableV2/InviteUserModal";
import FlexBetween from "components/flexbox/FlexBetween";
import { useNavigate } from "react-router-dom";
import Card from "icons/duotone/Card";
import User from "icons/User";
import Schedule from "./Schedule";

const Wrapper = styled(Box)(() => ({
  // display: "flex",
  // flexWrap: "wrap",
  // alignItems: "center",
  // justifyContent: "space-between",
}));
const TabListWrapper = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(727)]: {
    order: 3,
  },
})); // --------------------------------------------------------------------

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

function ViewP2P() {
  const { id } = useParams();
  const [data, setData] = useState(null);

  const getSingleP2P = async (id) => {
    const resp = await Prepaid2CoinService.getSingleP2P(id);
    if (resp.status) {
      setData(resp.data);
    }
  };

  useEffect(() => {
    getSingleP2P(id);
  }, []);

  console.log("getSingleP2P", data);
  return (
    <div>
      <Wrapper gap={1}>
        <FlexBetween flexWrap="wrap" gap={1} mb={2}>
          <FlexBox alignItems="center">
            <IconWrapper>
              <User
                sx={{
                  color: "primary.main",
                }}
              />
            </IconWrapper>
            {data && data.basic_info && (
              <H5>{`${
                data.basic_info.first_name + " " + data.basic_info.last_name
              } Prepaid 2 Coin Detail`}</H5>
            )}
          </FlexBox>
        </FlexBetween>
      </Wrapper>
      <Box pt={2} pb={4}>
        <Grid container spacing={3}>
          <Grid item xl={6} md={6}>
            {data && data.basic_info && (
              <KycBasicInfo data={data.basic_info} status={data.status} />
            )}
          </Grid>

          <Grid item xl={6} md={6}>
            {data && data.eventDetails && (
              <Box mb={3}>
                <Schedule {...data.eventDetails} />
              </Box>
            )}
            {data && (
              <KycDoc
                data={data?.kyc}
                schedule={data}
                user={
                  data.basic_info.first_name + " " + data.basic_info.last_name
                }
              />
            )}
          </Grid>
          {/* <Grid item xl={6} md={6} xs={12}>
            {data && (
              <KycDoc
                data={data?.kyc}
                schedule={data}
                user={
                  data.basic_info.first_name + " " + data.basic_info.last_name
                }
              />
            )}
          </Grid> */}
        </Grid>
      </Box>{" "}
    </div>
  );
}

export default ViewP2P;
