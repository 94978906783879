import { GET_MASTER_LIST } from "../constants";

export default function reducer(
  state = {
    userMasterList: [],
  },
  action
) {
  switch (action.type) {
    case GET_MASTER_LIST:
      return { ...state, userMasterList: action.payload };
  }
  return state;
}
