import axios from "../utils/axios";

const Database = {};

Database.getCollection = function (collection) {
  return axios({
    url: `/manage/db/sync/${collection}`,
    method: "POST",
  });
};

export default Database;
