import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import ConfigurationService from "services/ConfigurationService";
import DeleteDialog from "components/shared/DeleteDialog";
import { SERVER_BASE_URL } from "config";
import Close from "@mui/icons-material/Close";

function AvatarBox({ onDrop, isUploadEnabled, isVisible, imageUrl, onDelete }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => onDrop(acceptedFiles),
    disabled: !isUploadEnabled,
  });

  if (!isVisible) return null;

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    onDelete(imageUrl);
  };

  return (
    <Box
      {...getRootProps()}
      sx={{
        width: 100,
        height: 100,
        border: "2px dashed gray",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: isUploadEnabled ? "pointer" : "",
        position: "relative",
        overflow: "hidden",
        "&:hover img": {
          opacity: 0.7,
        },
      }}
    >
      <input {...getInputProps()} />
      {imageUrl ? (
        <img
          src={`${SERVER_BASE_URL}/${imageUrl}`}
          alt="Avatar"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            display: "block",
            transition: "opacity 0.3s ease",
          }}
        />
      ) : (
        isUploadEnabled && (
          <IconButton sx={{ position: "absolute" }}>
            <Add />
          </IconButton>
        )
      )}
      {imageUrl && (
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: "0px",
            color: "white",
            "&:hover": {
              color: "#ff2d55",
            },
          }}
          onClick={handleDeleteClick}
        >
          <Close />
        </IconButton>
      )}
    </Box>
  );
}

function Avatars() {
  const [avatars, setAvatars] = useState([]);
  const [avatarToDelete, setAvatarToDelete] = useState(null);

  const [maxAvatars] = useState(6);
  const [open, setOpen] = useState(false);

  const getAvatars = async () => {
    const response = await ConfigurationService.getAvtar();
    if (response.data) {
      setAvatars(response.data);
      console.log("getAvtar", response.data);
    }
  };
  useEffect(() => {
    getAvatars();
  }, []);

  const handleDrop = async (files) => {
    if (avatars.length < maxAvatars) {
      const file = files[0];
      const formData = new FormData();
      formData.append("file", file);

      await ConfigurationService.saveAvtar(formData);
      const newAvatars = [...avatars, URL.createObjectURL(file)];
      setAvatars(newAvatars);
      getAvatars();
    }
  };

  const handleCancelConfirm = () => {
    setOpen(false);
  };

  const handleDeleteAvatar = async () => {
    await ConfigurationService.deleteAvtar({ file_path: avatarToDelete });
    setOpen(false);
    getAvatars();
  };
  const handleDelete = (url) => {
    setOpen(true);
    setAvatarToDelete(url);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <Box p={4}>
            <Typography variant="h5">Avatars</Typography>
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }} p={2}>
              {[...Array(maxAvatars)].map((_, index) => (
                <AvatarBox
                  key={index}
                  onDrop={handleDrop}
                  isUploadEnabled={avatars.length < maxAvatars}
                  isVisible={index <= avatars.length}
                  imageUrl={index < avatars.length ? avatars[index] : null}
                  onDelete={handleDelete}
                />
              ))}
            </Box>
          </Box>
        </CardContent>
      </Card>

      <DeleteDialog
        open={open}
        title={"Confirm Delete"}
        subTitle={"It will delete this Avatar permanently. Are you sure?"}
        handleCancel={handleCancelConfirm}
        handleConfirm={() => handleDeleteAvatar()}
      />
    </div>
  );
}

export default Avatars;
