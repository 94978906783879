import axios from "../utils/axios";

const PaxfulApiLogService = {};

PaxfulApiLogService.getPaxfulApiLog = function (page, limit, type, account) {
  return axios({
    url: `/paxful-api-log?page=${page}&limit=${limit}&type=${type}&account=${account}`,
    method: "GET",
  });
};

PaxfulApiLogService.getUserPaxfulApiLog = function (id, page, limit) {
  return axios({
    url: `/paxful-api-log/${id}?page=${page}&limit=${limit}`,
    method: "GET",
  });
};

export default PaxfulApiLogService;
