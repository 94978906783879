import axios from "../utils/axios";
const FeedbackService = {};

FeedbackService.getBuyerFeedback = function (username, type, platform) {
  return axios({
    url: `/feedback/buyer-feedback/${username}?type=${type}&platform=${platform}`,
    method: "GET",
  });
};
export default FeedbackService;
