// import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// import { Spinner } from "reactstrap";
import { isLoggedIn } from "../helpers/auth";

const RouteGuard = ({ children }) => {
  //   const router = useRouter();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // on initial load - run auth check
    authCheck(location.pathname);

    //works on init load only
    // setTimeout(() => {
    //   document.getElementsByTagName("body")[0].classList.remove("init-loading");
    // }, 600);

    // unsubscribe from events in useEffect return function
  }, []);

  function authCheck(url) {
    const path = url.split("?")[0];

    const is_public = path == "/";
    if (isLoggedIn() && is_public) {
      navigate("/dashboard");
    }

    if (!isLoggedIn() && !is_public) {
      navigate("/");
    }
  }

  return <>{children}</>;
};

export default RouteGuard;
