import React from "react";

function DiscordAvatar({ discordId, className, width }) {
  return (
    <>
      {discordId.avatar ? (
        <img
          src={`https://cdn.discordapp.com/avatars/${discordId.id}/${discordId.avatar}.png`}
          alt="discord"
          width={`${width}px`}
          className={className}
          style={{ borderRadius: 50 }}
        />
      ) : (
        <img
          src="/media/avatars/blank.png"
          alt="discord"
          width={`${width}px`}
          className={className}
          style={{ borderRadius: 50 }}
        />
      )}
    </>
  );
}

export default DiscordAvatar;
