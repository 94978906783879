import { Box, Card, Divider, Typography } from "@mui/material";
import { H4, Small } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { SERVER_BASE_URL } from "config";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import KycDocGallery from "./KycDocGallery";
import { FormattedDateTime } from "components/shared/FormattedDate";

function KycDoc({ data, user, schedule }) {
  console.log(
    "schedule.eventDetails?.eventDetails?.start_time",
    schedule.eventDetails?.eventDetails?.start_time
  );
  console.log(
    "schedule.eventDetails?.eventDetails?.end_time",
    schedule.eventDetails?.eventDetails?.end_time
  );
  return (
    <div>
      <Card
        sx={{
          minHeight: 700,
          height: "100%",
          pb: 1,
        }}
      >
        <Box>
          <FlexBox p={3.4} alignItems="center">
            <H4>{"KYC"}</H4>
          </FlexBox>
        </Box>
        <Divider />

        {data && (
          <Box pt={3} pl={4}>
            <Box>
              <Typography fontSize={14} fontWeight={500}>
                AML Policy
              </Typography>
              <KycDocGallery image={`${SERVER_BASE_URL}/${data.AML_policy}`} />
            </Box>
            <Box>
              <Typography fontSize={14} fontWeight={500}>
                MSB Registration
              </Typography>

              <KycDocGallery
                image={`${SERVER_BASE_URL}/${data.MSB_registration}`}
              />
            </Box>
            <Box>
              <Typography fontSize={14} fontWeight={500}>
                Articles Of Incorporation
              </Typography>

              <KycDocGallery
                image={`${SERVER_BASE_URL}/${data.articles_of_incorporation}`}
              />
            </Box>
            <Box>
              <Typography fontSize={14} fontWeight={500}>
                Business EIN Document
              </Typography>

              <KycDocGallery
                image={`${SERVER_BASE_URL}/${data.business_EIN_document}`}
              />
            </Box>
            <Box>
              <Typography fontSize={14} fontWeight={500}>
                Id
              </Typography>

              <KycDocGallery image={`${SERVER_BASE_URL}/${data.id}`} />
            </Box>
            <Box>
              <Typography fontSize={14} fontWeight={500}>
                Id With Selfie
              </Typography>
              <KycDocGallery
                image={`${SERVER_BASE_URL}/${data.id_with_selfie}`}
              />
            </Box>
            <Box>
              <Typography fontSize={14} fontWeight={500}>
                Personal Bank Satement
              </Typography>
              <KycDocGallery
                image={`${SERVER_BASE_URL}/${data.personal_bank_satement}`}
              />
            </Box>
            <Box>
              <Typography fontSize={14} fontWeight={500}>
                Shareholder Registry
              </Typography>
              <KycDocGallery
                image={`${SERVER_BASE_URL}/${data.shareholder_registry}`}
              />
            </Box>
            <Box>
              <Typography fontSize={14} fontWeight={500}>
                Source Of Fund
              </Typography>
              <Typography
                mt={1}
                color={"text.secondary"}
                fontSize={12}
                fontWeight={500}
              >
                {data.source_of_fund}
              </Typography>
            </Box>
          </Box>
        )}

        {!data && (
          <Box textAlign={"center"} pt={3} pl={4}>
            <Typography color={"text.secondary"} fontSize={14}>
              No KYC Doucment Submitted by {user}
            </Typography>
          </Box>
        )}
      </Card>
    </div>
  );
}

export default KycDoc;
