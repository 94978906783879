import React, { useContext } from "react";
import moment from "moment-timezone";
import ATooltip from "./ATooltip";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/material";
import { Paragraph } from "components/Typography";
import { SettingsContext } from "contexts/settingsContext";
// import ATooltip from "../shared/bootstrap/ATooltip";
// import { Badge } from "reactstrap";

function Timezone({
  timezone,
  showTooltip,
  id,
  color,
  className,
  backgroundColor,
}) {
  const { settings } = useContext(SettingsContext);
  const textColor = settings.theme === "dark" ? "white" : "black";

  let abbr = moment().tz(timezone)?.zoneAbbr();
  const contains_numeric = abbr.match(/\d/) !== null;

  if (abbr.length > 3) {
    let abbr1 = abbr.slice(0, 3);
    let abbr2 = abbr.slice(3);
    abbr = abbr1 + ":" + abbr2;
  }

  const time = timezone
    ? `${
        moment().tz(timezone).format("hh:mm A") +
        ` ${abbr}${contains_numeric ? ` GMT` : ""}`
      }`
    : "";

  return (
    <>
      {showTooltip && (
        <ATooltip content={timezone} id={id}>
          <Chip
            label={
              <Paragraph color={color} fontSize={10}>
                {time}
              </Paragraph>
            }
            size={"xs"}
          />
        </ATooltip>
      )}

      {!showTooltip && (
        <Box className="d-block">
          <Chip
            label={
              <Paragraph color={color} fontSize={10}>
                {time}
              </Paragraph>
            }
            size="small"
            style={{ backgroundColor: backgroundColor }}
          />

          {/* <Chip
            color={"none"}
            // className={`cursor-default${
            //   className ? ` ${className}` : ""
            // } badge-${color}`}
          >
            {time}
          </Chip> */}
        </Box>
      )}
    </>
  );
}

Timezone.defaultProps = {
  showTooltip: true,
  color: "light",
};

export default Timezone;
