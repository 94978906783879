import React from "react";

function PaxfulProfilePic({ account, size, buyer }) {
  const getPaxfulAvatar = (avatar) => {
    return avatar?.substring(avatar.lastIndexOf("/") + 1) == "avatar.png"
      ? "/static/avatar/blank.png"
      : avatar;
  };

  return (
    <>
      {buyer ? (
        <img
          style={{
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: "50%",
          }}
          src={getPaxfulAvatar(account?.avatar_url)}
          alt=""
        />
      ) : (
        <img
          style={{
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: "50%",
          }}
          src={
            account?.paxful_avatar
              ? account?.paxful_avatar
              : "/static/avatar/blank.png"
          }
          alt="dddd"
        />
      )}
    </>
  );
}

PaxfulProfilePic.defaultProps = {
  size: 35,
  buyer: false,
};

export default PaxfulProfilePic;
