import { Small } from "components/Typography";
import React from "react";

function BadgeMui({ color, textColor, children }) {
  return (
    <>
      <Small
        sx={{
          width: 100,
          fontSize: 10,
          color: "grey.500",
          borderRadius: "4px",
          textAlign: "center",
          padding: ".1rem .3rem",
          fontWeight: "bold",
          backgroundColor: color,
          color: textColor && textColor ? textColor : "white",
        }}
      >
        {children}
      </Small>
    </>
  );
}

export default BadgeMui;
