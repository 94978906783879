import { Box } from "@mui/material";
import React from "react";

function Platform({ size, platform }) {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={`/media/logos/${platform}-icon.png`}
        style={{
          height: `${size}px`,
          width: `${size}px`,
        }}
      />
    </Box>
  );
}

Platform.defaultProps = {
  platform: "noones",
};

export default Platform;
