import { Box, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Offers from "./Offers";
import Chat from "./Chat";
import UserRequestApi from "services/userRequestService";
import { UserContextData } from "App";
import { emitToSocket } from "helpers/socketEmmiter";
import { setTimeout } from "timers";

function ViewUserRequest() {
  const { userRequest, setUserRequest, reload, setReload } =
    useContext(UserContextData);
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  // const [reload, setReload] = useState(false);

  const { id } = useParams();

  const getSingleUserRequest = async (_id) => {
    const resp = await UserRequestApi.getSingleUserRequest(id);
    setUserRequest(resp.data);
  };

  useEffect(() => {
    getSingleUserRequest(id);
    return () => {
      setUserRequest(null);
    };
  }, [id]);
  const uploadAttachement = () => {
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    const resp = UserRequestApi.uploadAttachement(userRequest._id, formdata);
    console.log("resp", resp);
    setSelectedFile(null);
  };

  const sendMessage = () => {
    console.log("send message");
    if (selectedFile) {
      // call Api
      uploadAttachement();

      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   emitToSocket("api:send:message", {
      //     message: null,
      //     file: e.target.result,
      //     userRequest: userRequest._id,
      //   });
      //   setMessage("");
      // };
      // reader.readAsDataURL(selectedFile);
    } else {
      emitToSocket("api:send:message", {
        message,
        userRequest: userRequest._id,
        file: null,
      });
    }
    setMessage("");
    //setReload(!reload);
  };

  useEffect(() => {
    console.log("userRequest change", id);
    setTimeout(() => {
      if (userRequest) {
        console.log("userRequest", userRequest);
      }
      emitToSocket("api:read:message", {
        id: id,
      });
      console.log("socket calling");
    }, 1000);
  }, [id, userRequest?.chat]);

  return (
    <Box pt={2} pb={4}>
      <Grid container spacing={3}>
        {!userRequest && (
          <Grid item xl={12} md={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              {<CircularProgress />}
            </Box>
          </Grid>
        )}
        <Grid item xl={6} md={6}>
          {userRequest && <Offers userRequest={userRequest} />}
        </Grid>
        <Grid item xl={6} md={6} xs={12}>
          {userRequest && (
            <Chat
              data={userRequest}
              message={message}
              setMessage={setMessage}
              sendMessage={sendMessage}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ViewUserRequest;
