import { useEffect, useState } from "react";
import { Button, Grid, styled, Select } from "@mui/material";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import Scrollbar from "components/ScrollBar";
import { H2, H6 } from "components/Typography";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axios";
import * as Yup from "yup"; // component props interface
import FormControl from "@mui/material/FormControl";
import countries from "../../../data/country";
import Zones from "../../../data/timezones";
import MenuItem from "@mui/material/MenuItem";
import { openStdin } from "process";
import { saveUsers } from "../../../redux/actions/user";
import { connect } from "react-redux";
import UserService from "services/UserService";
import { LoadingButton } from "@mui/lab";
import Agency from "services/va/Agency";
import { getToken } from "helpers/auth-va";

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 650,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

const InviteVa = ({ open, onClose, edit, data, onSuccess }, props) => {
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
  };

  const validations = {
    email: Yup.string().required("Email is Required!"),
  };

  const fieldValidationSchema = Yup.object().shape(validations);
  const { values, errors, handleChange, handleSubmit, touched, resetForm } =
    useFormik({
      initialValues,
      validationSchema: fieldValidationSchema,
      enableReinitialize: true,
      onSubmit: async (values) => {
        setIsLoading(true);
        console.log(values);
        let resp = await Agency.invite(values);
        if (resp && resp.statusCode == 200) {
          resetForm();
          onClose();
          onSuccess();
        }

        setIsLoading(false);
      },
    });

  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <H2 mb={2}>{"Invite Agency"}</H2>

      <form onSubmit={handleSubmit}>
        <Scrollbar
          style={{
            maxHeight: 400,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <H6 mb={1}>Email</H6>
              <AppTextField
                fullWidth
                size="small"
                name="email"
                placeholder="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                helperText={touched.email && errors.email}
                error={Boolean(errors.email && touched.email)}
              />
            </Grid>
          </Grid>
        </Scrollbar>

        <FlexBox justifyContent="flex-end" gap={2} marginTop={4}>
          <Button fullWidth size="small" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="small"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </FlexBox>
      </form>
    </StyledAppModal>
  );
};

export default InviteVa;
