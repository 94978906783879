import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Stack,
  styled,
  Table,
  TableRow,
  useTheme,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import AppPagination from "components/AppPagination";
import Scrollbar from "components/ScrollBar";
import BlankCheckBoxIcon from "icons/BlankCheckBoxIcon";
import CheckBoxIcon from "icons/CheckBoxIcon";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import columnShape from "page-sections/user-list/columnShape";
import HeadingArea from "page-sections/user-list/heading-area";
import UserService from "../services/UserService";
import { connect } from "react-redux";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { getUsers } from "redux/actions/user";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { arrayIsEmpty } from "utils/utils";
import NoResultFound from "components/shared/NoResultFound";
import Agency from "services/va/Agency";
import InviteVa from "page-sections/data-table/dataTableV2/InviteVa";
import { H5 } from "components/Typography";
import Add from "icons/Add";
import GroupSenior from "icons/GroupSenior";
import IconWrapper from "components/IconWrapper";
import FlexBox from "components/flexbox/FlexBox";
import ColumnShapeAgency from "page-sections/user-list/ColumnShapeAgency";
import fakeData from "page-sections/data-table/dataTableV2/fakeData";
import { getToken } from "helpers/auth-va";
import VaService from "services/VaService";

// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
}));

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 13,
  fontWeight: 500,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const SelectCheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  useEffect(() => {
    if (resolvedRef) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);
  return (
    <Checkbox
      {...rest}
      disableRipple
      ref={resolvedRef}
      icon={<BlankCheckBoxIcon fontSize="small" color="disabled" />}
      checkedIcon={<CheckBoxIcon fontSize="small" color="primary" />}
    />
  );
});

const VirtualAssistance = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState();

  const [agencies, setAgencies] = useState([]);
  const theme = useTheme();
  const columns = useMemo(() => ColumnShapeAgency, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    state,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: agencies,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <SelectCheckBox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => (
            <SelectCheckBox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  ); // handle change pagination

  const handleChange = (_, currentPageNo) => gotoPage(currentPageNo - 1); // handle change for tab list

  const changeTab = (_, newValue) => setValue(newValue); // search value state

  const [searchValue, setSearchValue] = useState(state.globalFilter);
  const handleSearch = useAsyncDebounce(
    (value) => setGlobalFilter(value || undefined),
    200
  );

  const navigate = useNavigate();

  const getAgenctList = async (skip = 0, limit = 100) => {
    setLoading(true);
    const resp = await VaService.getList(skip, limit);
    if (resp.status) {
      setAgencies(resp.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (getToken()) {
    getAgenctList();
    // } else {
    //   navigate(`/dashboard?va-signin=${true}`);
    // }
  }, []);

  const handleOpenPage = (id) => {
    let agency = agencies.filter((item) => {
      return item._id == id;
    });
    navigate(`/dashboard/va-agencies/${id}`, {
      state: agency[0],
    });
  };
  // console.log(agencies);
  return (
    <Box pt={2} pb={4}>
      <Card
        sx={{
          py: 2,
        }}
      >
        <Box px={3}>
          <Wrapper gap={1}>
            <FlexBox alignItems="center">
              <IconWrapper>
                <GroupSenior
                  sx={{
                    color: "primary.main",
                  }}
                />
              </IconWrapper>
              <H5>VA's Agencies</H5>
            </FlexBox>
            <Button
              variant="contained"
              size="small"
              endIcon={<Add />}
              onClick={() => setOpenModal(true)}
            >
              {"Invite User"}
            </Button>
            <InviteVa open={openModal} onClose={() => setOpenModal(false)} />
          </Wrapper>

          {/* <SearchArea
            value={searchValue}
            onChange={handleSearch}
            setValue={setSearchValue}
            selectedItems={selectedFlatRows}
            gridRoute="/dashboards/user-grid"
            listRoute="/dashboards/user-list"
          /> */}
        </Box>

        <Scrollbar autoHide={false}>
          <Table
            {...getTableProps()}
            sx={{
              minWidth: 900,
            }}
          >
            <TableHead
              sx={{
                borderBottom: `2px solid ${theme.palette.divider}`,
              }}
            >
              {headerGroups.map((headerGroup, index) => (
                <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <HeadTableCell
                      key={index}
                      // {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </HeadTableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {loading ? (
                <StyledTableCell colSpan={8} align="center">
                  <CircularProgress />
                </StyledTableCell>
              ) : agencies && arrayIsEmpty(agencies) ? (
                <StyledTableCell colSpan={8} align="center">
                  <NoResultFound />
                </StyledTableCell>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  const { _id } = row.original;
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      key={index}
                      onClick={() => handleOpenPage(_id)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {row.cells.map((cell, index) => (
                        <BodyTableCell key={index} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </BodyTableCell>
                      ))}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </Scrollbar>
        <Stack alignItems="center" marginY={4}>
          <AppPagination
            shape="rounded"
            onChange={handleChange}
            count={pageOptions.length}
          />
        </Stack>
      </Card>
    </Box>
  );
};

export default VirtualAssistance;
