import React from "react";
import PaxfulProfilePic from "./PaxfulProfilePic";
import { formatCurrency } from "utils/currencies";
import ATooltip from "./ATooltip";

function FormattedBalance({
  code,
  amount,
  offer_type,
  fs,
  status,
  isPartner,
  partner,
  user,
}) {
  const getAmountStatus = (offer_type, status) => {
    switch (offer_type) {
      case "buy":
        return status == "new"
          ? `${user.first_name} are paying`
          : `${user.first_name}  paid`;
      case "sell":
        return status == "new"
          ? `${user.first_name} are receiving`
          : `${user.first_name}  received`;
    }
  };

  const getPartnerAmountStatus = (offer_type) => {
    switch (offer_type) {
      case "buy":
        return (
          <>
            <PaxfulProfilePic
              account={partner}
              size={15}
              buyer
              className={"me-1"}
            />
            {partner.username} received
          </>
        );

      case "sell":
        return (
          <>
            <PaxfulProfilePic
              account={partner}
              size={15}
              buyer
              className={"me-1"}
            />
            {partner.username} paid
          </>
        );
    }
  };

  //offer_type == "buy" ? "paying" : "receiving"

  return (
    <>
      <ATooltip
        content={
          <>
            {!isPartner
              ? getAmountStatus(offer_type, status)
              : getPartnerAmountStatus(offer_type)}{" "}
            {formatCurrency(amount, code)} {code}
          </>
        }
      >
        <span className={`fs-${fs}`}>
          {formatCurrency(amount, code)} {code}
        </span>
      </ATooltip>
    </>
  );
}

FormattedBalance.defaultProps = {
  fs: "6",
  isPartner: false,
};

export default FormattedBalance;
