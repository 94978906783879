import axios from "axios";

import { isLoggedIn, getToken, clearToken } from "../helpers/auth";
import notification from "../helpers/notifications";

// import emitter from "helpers/emitter";

const {
  API_BASE_URL,
  TOKEN_PAYLOAD_KEY,
  PUBLIC_REQUEST_KEY,
} = require("../config");

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  withCredentials: true,
});

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    document.getElementsByTagName("body")[0].classList.add("is-loading");
    //emitter.emit('is:loading', true)
    const is_public = config.headers[PUBLIC_REQUEST_KEY];
    if (!is_public && isLoggedIn()) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${getToken()}`;
    }

    if (!isLoggedIn() && !is_public) {
      notification.error(
        "Error!",
        "You must be logged in to access this resource."
      );
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error(
      "Error!",
      "Error while processing your request. Please try again."
    );
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  async (response) => {
    document.getElementsByTagName("body")[0].classList.remove("is-loading");
    //emitter.emit('is:loading', false)
    if (
      response.data &&
      response.data.message &&
      response.data.message !== ""
    ) {
      if (response.data.status) {
        notification.success("Success", response.data.message);
      } else {
        notification.error("Error", response.data.message);
      }
    }

    // await new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve();
    //   }, 6000);
    // });
    return response.data;
  },
  (error) => {
    document.getElementsByTagName("body")[0].classList.remove("is-loading");
    //emitter.emit('is:loading', false)
    let notificationParam = {
      title: "",
      message: "",
    };

    if (error && error.response) {
      if (error.response.status === 401) {
        clearToken();
        window.location.href = "/login";
      }

      if (error.response.status === 0 || error.response.status === 404) {
        notificationParam.title = "Not Found";
        notificationParam.message =
          "Requested resource cannot be found on this server.";
      }

      if (error.response.status === 422 || error.response.status === 400) {
        notificationParam.title = "Invalid Request";
        notificationParam.message =
          "Invalid request data. Please check and try again.";
      }

      if (error.response.status === 403) {
        notificationParam.title = "Authentication Failed";
        notificationParam.message =
          "You are not authorized to access this resource.";
      }

      if (error.response.status === 500) {
        notificationParam.title = "Internal Server Error";
        notificationParam.message =
          "There is some critical error while processing your request.";
      }

      if (error.response.status === 508) {
        notificationParam.title = "Time Out";
        notificationParam.message = "Request Timed out. Please try again.";
      }

      if (notificationParam.title !== "" && notificationParam.message !== "") {
        notification.error(notificationParam.title, notificationParam.message);
      }
    }

    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);
export default service;
