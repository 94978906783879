import React from "react";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Paragraph, Small } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { Box } from "@mui/material";

function FeedbackBadge({ value, type }) {
  return (
    <div>
      {" "}
      <Box
        sx={{
          width: 40,
          fontSize: 10,
          color: "grey.500",
          borderRadius: "4px",
          textAlign: "center",
          //   padding: ".2rem 1rem",
          backgroundColor: type == "positive" ? "success.main" : "error.main",
        }}
      >
        <FlexBox sx={{ justifyContent: "center" }}>
          {type == " positive" ? (
            <ThumbUpOffAltIcon size={"xs"} color={"white"} />
          ) : (
            <ThumbDownOffAltIcon />
          )}
          <Paragraph fontSize={8} color="white">
            {value}
          </Paragraph>
        </FlexBox>
      </Box>
    </div>
  );
}

export default FeedbackBadge;
