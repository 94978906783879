import { SvgIcon } from "@mui/material";

const Reddit = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M22,11.5A2.5,2.5,0,0,0,19.5,9,2.46,2.46,0,0,0,18,9.53,11.4,11.4,0,0,0,12.93,8l.62-3.55a.24.24,0,0,1,.24-.21h.07L17,5.2a2,2,0,1,0,.42-1.44l-3.16-.92a2,2,0,0,0-.49-.07,1.75,1.75,0,0,0-1.72,1.45L11.41,8A11.52,11.52,0,0,0,6,9.53,2.46,2.46,0,0,0,4.5,9,2.49,2.49,0,0,0,3,13.52,3.88,3.88,0,0,0,3,14c0,3.31,4,6,9,6s9-2.69,9-6a3.88,3.88,0,0,0,0-.48A2.5,2.5,0,0,0,22,11.5Zm-15,1A1.5,1.5,0,1,1,8.5,14,1.5,1.5,0,0,1,7,12.5Zm8.21,4.44a.51.51,0,0,1-.25.32,6.29,6.29,0,0,1-5.92,0,.51.51,0,0,1-.25-.32.52.52,0,0,1,.08-.4l.29-.41A.49.49,0,0,1,9.8,16a5.07,5.07,0,0,0,4.4,0,.49.49,0,0,1,.64.16l.29.41A.52.52,0,0,1,15.21,16.94ZM15.5,14A1.5,1.5,0,1,1,17,12.5,1.5,1.5,0,0,1,15.5,14Z" />
    </SvgIcon>
  );
};

export default Reddit;
