import { SvgIcon } from "@mui/material";

const City = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M23,19.5a.5.5,0,0,0-.5-.5H20V8a1,1,0,0,0-1-1H15V4a1,1,0,0,0-1-1H10A1,1,0,0,0,9,4v7H5a1,1,0,0,0-1,1v7H1.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h21a.5.5,0,0,0,.5-.5ZM16.5,9h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,16.5,9Zm0,4h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,16.5,13Zm0,4h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,16.5,17Zm-5-8h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,11.5,9Zm0,4h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,11.5,13ZM13,5.5v1a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,13,5.5ZM7.5,19h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,7.5,19Zm0-4h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,7.5,15ZM11,18.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,11,18.5Z" />
    </SvgIcon>
  );
};

export default City;
