import axios from "../utils/axios";

const UserRequestApi = {};

UserRequestApi.getUserRequest = function (page, limit, type, search) {
  return axios({
    url: `/api-integration?page=${page}&limit=${limit}&type=${type}&search=${search}`,
    method: "GET",
  });
};
UserRequestApi.getSingleUserRequest = function (id) {
  return axios({
    url: `/api-integration/${id}`,
    method: "GET",
  });
};
UserRequestApi.uploadAttachement = function (id, data) {
  return axios({
    url: `/api-integration/attachement/${id}`,
    method: "POST",
    data,
  });
};

UserRequestApi.deleteUserRequest = function (id) {
  return axios({
    url: `/api-integration/${id}`,
    method: "DELETE",
  });
};

export default UserRequestApi;
