import { Box, Button, CircularProgress, Stack, Tab } from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5 } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components

import { io } from "socket.io-client";
import { LoadingButton, TabContext, TabList } from "@mui/lab";
import UserRequestTable from "page-sections/user-request";
import UserRequestApi from "services/userRequestService";
import { UserContextData } from "App";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const TabListWrapper = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(727)]: {
    order: 3,
  },
}));

const UserRequest = (props) => {
  const { apiUserRequests } = useContext(UserContextData);
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItem, setFilteredItem] = useState(INVOICE_LIST);
  const [tabValue, setTabValue] = useState("api");

  // useEffect(() => {
  //   const result = searchByName(INVOICE_LIST, searchValue);
  //   setFilteredItem(result);
  // }, [searchValue]);

  const [data, setData] = useState(null);

  // console.log(data);
  // get User Request
  const getUserRequest = async (
    page = 1,
    limit = 50,
    type = "api",
    search = ""
  ) => {
    setisLoading(true);
    const resp = await UserRequestApi.getUserRequest(page, limit, type, search);
    setPage(page);
    setLimit(limit);
    setData(resp);
    setisLoading(false);
  };

  useEffect(() => {
    getUserRequest(1, limit, tabValue, searchValue);
  }, []);

  useEffect(() => {
    console.log("run use effect by socket");
    console.log("hireRequest", apiUserRequests);
    let _data = { ...data };
    _data.data = apiUserRequests;
    setData(_data);
  }, [apiUserRequests]);

  const reload = () => {
    // setPage(1);
    // setLimit(50);
    setTabValue("api");
    setSearchValue("");
    getUserRequest();
  };

  const handlePageChange = (page) => {
    // setPage(page);
    getUserRequest(page, limit, tabValue, searchValue);
  };

  const handleLimitChange = (event) => {
    // setPage(1);
    // setLimit(event.target.value);
    getUserRequest(1, event.target.value, tabValue, searchValue);
  };

  // Tab selection
  const handleTabChange = (_, value) => {
    setSearchValue("");
    setTabValue(value);
    getUserRequest(1, 50, value, "");
  };

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
    getUserRequest(1, 50, tabValue, searchValue);
  };
  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("API integration")}</H5>
        </Stack>
        {/* tab management */}
        <TabContext value={tabValue}>
          <TabListWrapper variant="scrollable" onChange={handleTabChange}>
            <Tab disableRipple label={t("Api")} value="api" />
            <Tab disableRipple label={t("Deletion")} value="delete_account" />
          </TabListWrapper>
        </TabContext>
        <StyledStack alignItems="center" direction="row" gap={2}>
          <SearchInput
            disable_border="true"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data && (
          <UserRequestTable
            {...data}
            getUserRequest={getUserRequest}
            onPageChange={handlePageChange}
            currentPage={page}
            handleLimitChange={handleLimitChange}
            limit={limit}
            tabValue={tabValue}
          />
        )
      )}
    </Box>
  );
};

export default UserRequest;
