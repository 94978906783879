import { Box, Button, Chip, IconButton, Stack } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { H6, Paragraph, Small, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import styled from "@emotion/styled";
import CustomChip from "components/shared/CustomChip";
import Platform from "components/shared/Platform";
import FlexBox from "components/flexbox/FlexBox";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { copyTextToClipboard } from "utils/utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccountGroup from "components/shared/AccountGroup";
const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;

export const columnShape = (onCellClick) => [
  {
    Header: "DATE",
    accessor: "createdAt",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        <FormattedDateTime date={value} />
      </Paragraph>
    ),
  },

  {
    Header: "TITLE",
    accessor: "aaaaaa",
    Cell: ({ row }) => {
      const { invitation } = row.original;
      return (
        <Paragraph fontSize={13} color="text.secondary">
          {invitation && invitation.title}
        </Paragraph>
      );
    },
  },
  {
    Header: "INVITED VIA",
    accessor: "invitied_via",
    Cell: ({ value }) => {
      return (
        <>
          {
            <Paragraph fontSize={13} color="text.secondary">
              {value}
            </Paragraph>
          }
        </>
      );
    },
  },

  {
    Header: "",
    accessor: "row",
    Cell: ({ row }) => {
      // const { email, mobile_number } = row.original?.user;
      // const { shortlink } = row.original?.invitation;

      const { user, invitation } = row.original;
      let invited_via;
      if (user) {
        invited_via = user.email ? user.email : user.mobile_number;
      }

      return (
        <>
          <FlexBox sx={{ alignItems: "center" }}>
            <Paragraph fontSize={13} color="text.secondary">
              {invited_via}
            </Paragraph>

            {invitation && invitation.shortlink && (
              <Box
                sx={{
                  width: "200px",
                  whiteSpace: "normal", // Controls text wrapping
                  overflow: "hidden",
                  wordWrap: "break-word",
                }}
              >
                <Paragraph fontSize={13} color="text.secondary">
                  {invitation.shortlink}
                </Paragraph>
              </Box>
            )}

            <IconButton
              onClick={() =>
                copyTextToClipboard(
                  invitation?.shortlink ? invitation?.shortlink : invited_via
                )
              }
              // color={isCopied ? "primary" : "default"}
            >
              <ContentCopyIcon
                color="primary"
                sx={{
                  fontSize: "large",
                }}
              />
            </IconButton>
          </FlexBox>
        </>
      );
    },
  },
  {
    Header: "STATUS",
    accessor: "is_accepted",
    Cell: ({ value }) => {
      return (
        <>
          <Small
            sx={{
              borderRadius: "5px",
              backgroundColor: value ? "success.main" : "error.main",
              color: "white",
              fontSize: "8px",
              padding: "5px 8px 5px 8px",
            }}
          >
            {value ? "ACCEPTED" : "PENDING"}
          </Small>
        </>
      );
    },
  },
  {
    Header: "VENDOR",
    accessor: "user",
    Cell: ({ row }) => {
      const { user, invitation } = row.original;
      console.log("user", user);
      return (
        <>
          {user && (
            <Stack direction="row" spacing={2} alignItems={"center"}>
              {user && (
                <UserSymbol
                  user={user}
                  size={25}
                  showOnline={true}
                  isBottomZero={0}
                />
              )}

              <Box
                sx={{
                  marginLeft: "10px !important",
                }}
              >
                {user?.first_name ? (
                  <Link to={`/dashboard/users/${user._id}`}>
                    {user.username}
                  </Link>
                ) : (
                  <Paragraph fontSize={13} color="text.secondary">
                    {user.email}
                  </Paragraph>
                )}
              </Box>
            </Stack>
          )}
          {invitation && invitation.registeredUser && (
            <Stack direction="row" spacing={2} alignItems={"center"}>
              {invitation.registeredUser && (
                <UserSymbol
                  user={invitation.registeredUser}
                  size={25}
                  showOnline={true}
                  isBottomZero={0}
                />
              )}

              <Box
                sx={{
                  marginLeft: "10px !important",
                }}
              >
                {invitation.registeredUser.first_name ? (
                  <Link
                    to={`/dashboard/users/${invitation.registeredUser._id}`}
                  >
                    {invitation.registeredUser.first_name +
                      " " +
                      invitation.registeredUser.last_name}
                  </Link>
                ) : (
                  <Paragraph fontSize={13} color="text.secondary">
                    {invitation.registeredUser.email}
                  </Paragraph>
                )}
              </Box>
            </Stack>
          )}
        </>
      );
    },
  },
  {
    Header: "ACCOUNTS",
    accessor: "account",
    Cell: ({ value }) => {
      return (
        <>
          {value?.length > 0 && (
            <FlexBox>
              <AccountGroup accounts={value} />
            </FlexBox>
          )}
        </>
      );
    },
  },

  {
    Header: "ACTIONS",
    accessor: "actions",
    Cell: ({ row }) => {
      const { user, invitation, invitied_via } = row.original;
      const invite = invitied_via == "link" ? invitation : user;

      return (
        <>
          <StyledButton
            sx={{ alignItems: "center" }}
            onClick={() => onCellClick(invite._id, invitied_via)}
            variant="outlined"
            color="error"
            size="small"
          >
            DELETE
          </StyledButton>
        </>
      );
    },
  },
];
export default columnShape;
