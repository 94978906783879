import axios from "../utils/axios";
const HireVirtualAssistantService = {};

HireVirtualAssistantService.getHireVirtualAssistant = function (
  page,
  limit,
  search
) {
  return axios({
    url: `atlantis-va?page=${page}&limit=${limit}&search=${search}`,
    method: "GET",
  });
};
HireVirtualAssistantService.createHireVirtualAssistant = function (data) {
  return axios({
    url: `atlantis-va`,
    method: "POST",
    data,
  });
};
HireVirtualAssistantService.updateHireVirtualAssistant = function (id, data) {
  return axios({
    url: `atlantis-va/${id}`,
    method: "PUT",
    data,
  });
};
HireVirtualAssistantService.deleteHireVirtualAssistant = function (id) {
  return axios({
    url: `atlantis-va/${id}`,
    method: "DELETE",
  });
};
HireVirtualAssistantService.unAssignedVAs = function () {
  return axios({
    url: `atlantis-va/unassignedVAs`,
    method: "GET",
  });
};

export default HireVirtualAssistantService;
