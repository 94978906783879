import axios from "../utils/axios";
const OfferService = {};

OfferService.getOffers = function (
  user_id,
  account_id,
  type,
  search,
  offerType
) {
  return axios({
    url: `/offers/?user_id=${user_id}&account_id=${account_id}&type=${type}&search=${search}&offerType=${offerType}`,
    method: "GET",
  });
};

OfferService.getOfferToggle = function (id, data) {
  return axios({
    url: `/offers/${id}`,
    method: "PUT",
    data,
  });
};
OfferService.updateOffer = function (id, offer_hash, data) {
  return axios({
    url: `/offers/${id}/${offer_hash}`,
    method: "PUT",
    data,
  });
};

export default OfferService;
