import { Box, Button, Card, Divider, Grid, Stack, styled } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import { H5, H6, Small, Tiny } from "components/Typography";
import AppTextField from "components/input-fields/AppTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthService from "services/AuthService";
import { UserContextData } from "App";
import { useContext } from "react";
import { clearToken } from "helpers/auth";
import { clearVaToken } from "helpers/auth-va";
import { useNavigate } from "react-router-dom";
const Dot = styled(Box)(({ theme }) => ({
  width: 8,
  height: 8,
  flexShrink: 0,
  borderRadius: "50%",
  backgroundColor: theme.palette.text.secondary,
}));

const Password = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    //logout();
    clearToken();
    clearVaToken();
    navigate("/");
  };
  const { userdata } = useContext(UserContextData);

  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };
  const validationSchema = Yup.object({
    current_password: Yup.string()
      .min(3, "Must be greater then 6 characters")
      .required("Current Password is Required!"),
    new_password: Yup.string().min(8).required("New Password is Required!"),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("new_password"), null],
      "Password doesn't matched"
    ),
  });
  const { values, errors, handleSubmit, handleChange, handleBlur, touched } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        console.log("hello");
        console.log("userdata", userdata);
        const resp = await AuthService.ChangePassword(userdata._id, values);
        console.log("helo");
        if (resp.status) {
          setTimeout(() => {
            handleLogout();
          }, 1000);
        }
      },
    });

  return (
    <Card>
      <H5 padding={3}>Password</H5>
      <Divider />

      <Box padding={3}>
        <Grid container spacing={5}>
          <Grid item sm={6} xs={12}>
            <form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <AppTextField
                  fullWidth
                  type="password"
                  variant="outlined"
                  name="current_password"
                  label="Current Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.current_password}
                  helperText={
                    touched.current_password && errors.current_password
                  }
                  error={Boolean(
                    touched.current_password && errors.current_password
                  )}
                />

                <AppTextField
                  fullWidth
                  type="password"
                  name="new_password"
                  variant="outlined"
                  label="New Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.new_password}
                  helperText={touched.new_password && errors.new_password}
                  error={Boolean(touched.new_password && errors.new_password)}
                />
                <AppTextField
                  fullWidth
                  type="password"
                  variant="outlined"
                  name="confirm_password"
                  label="Confirm Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirm_password}
                  helperText={
                    touched.confirm_password && errors.confirm_password
                  }
                  error={Boolean(
                    touched.confirm_password && errors.confirm_password
                  )}
                />
              </Stack>

              <Stack direction="row" spacing={3} mt={4}>
                <Button type="submit" variant="contained">
                  Save Changes
                </Button>
                <Button variant="outlined">Cancel</Button>
              </Stack>
            </form>
          </Grid>

          <Grid item sm={6} xs={12}>
            <H6>Password requirements:</H6>
            <Tiny lineHeight={1.7}>
              Ensure that these requirements are met:
            </Tiny>

            <Stack spacing={1} mt={2}>
              <FlexBox alignItems="center">
                <Dot mr={1} />
                <Small fontSize={12} color="text.secondary">
                  Minimum 8 characters long - the more, the better
                </Small>
              </FlexBox>

              <FlexBox alignItems="center">
                <Dot mr={1} />
                <Small fontSize={12} color="text.secondary">
                  At least one lowercase character
                </Small>
              </FlexBox>

              <FlexBox alignItems="center">
                <Dot mr={1} />
                <Small fontSize={12} color="text.secondary">
                  At least one uppercase character
                </Small>
              </FlexBox>

              <FlexBox alignItems="center">
                <Dot mr={1} />
                <Small fontSize={12} color="text.secondary">
                  At least one number, symbol, or whitespace character
                </Small>
              </FlexBox>
              <FlexBox alignItems="center">
                <Dot mr={1} />
                <Small fontSize={12} color="text.secondary">
                  After change password, user immediately logout. Login With
                  your new password.
                </Small>
              </FlexBox>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default Password;
