import { makeStyles } from "@mui/styles";
import FlexBox from "./flexbox/FlexBox";
import { Paragraph, Small } from "./Typography";

const ATCraftNotice = ({
  icon,
  title,
  message,
  type,
  className,
  bodyPadding,
  border,
  light,
  rounded,
  action,
  titleClass,
  messageClass,
  customIcon,
  content,
  hideButton,
  buttonClass,
  onButtonClick,
  account,
}) => (
  <FlexBox
    px={2}
    py={2}
    sx={{
      background: "aliceblue",
    }}
    className={`notice d-flex p-${bodyPadding} ${className} ${
      border ? `border border-${type}` : ""
    } ${light ? `bg-light-${type}` : `bg-${type}`} ${rounded ? "rounded" : ""}`}
  >
    {icon && (
      <Small
        sx={{
          color: "primary.main",
        }}
        className={`svg-icon svg-icon-2tx svg-icon-${type} me-4`}
      >
        {icon}
      </Small>
    )}
    {customIcon}
    <FlexBox
      sx={{
        display: "flex",
        flexDirection: "column", // for flex-stack
        flexGrow: 1, // for flex-grow-1
      }}
      ml={2}
    >
      <div>
        {title && (
          <h4 className={`fw-bold${titleClass ? ` ${titleClass}` : ""}`}>
            {title}
          </h4>
        )}
        {content ? (
          content
        ) : (
          <Paragraph
            color="black"
            fontSize={13}
            className={`fs-6${messageClass ? ` ${messageClass}` : ""}`}
            dangerouslySetInnerHTML={{ __html: message }}
          ></Paragraph>
        )}
        {action}
      </div>
    </FlexBox>
    {hideButton && (
      <a
        className={`${buttonClass ? buttonClass : " "}`}
        onClick={onButtonClick}
      >
        <i className="fa-solid fa-xmark text-primary"></i>{" "}
      </a>
    )}
  </FlexBox>
);

ATCraftNotice.defaultProps = {
  type: "warning",
  bodyPadding: "6",
  border: false,
  light: true,
  rounded: true,
  action: false,
  customIcon: null,
  content: null,
  hideButton: false,
};
export default ATCraftNotice;
