import { Box } from "@mui/system";
import FlexBox from "components/flexbox/FlexBox";
import { Paragraph, Small } from "components/Typography";
import React from "react";
import { connect } from "react-redux";
import PaxfulProfilePic from "./PaxfulProfilePic";
import Platform from "./Platform";

function PaxfulAccountPreview({ accounts, account_id, model }) {
  // console.log(accounts)

  const account = accounts.find(({ _id }) => _id == account_id);

  return (
    <>
      {account ? (
        <>
          <FlexBox alignItems="center">
            <PaxfulProfilePic account={account} />
            <Box>
              <FlexBox>
                <Paragraph fontSize={12} color="text.secondary" ml={1}>
                  {account.paxful_username}
                </Paragraph>
                <Box ml={1}>
                  <Platform size={20} platform={account.platform} />
                </Box>
              </FlexBox>

              {model && (
                <Paragraph fontSize={12} color="text.secondary" ml={1}>
                  {account._id}
                </Paragraph>
              )}
            </Box>
          </FlexBox>
        </>
      ) : (
        <span style={{ textDecoration: "line-through" }}>{account_id}</span>
      )}
    </>
  );
}

const mapStateToProps = ({ paxfulAccount }) => ({
  accounts: paxfulAccount.paxfulAccounts,
});

export default connect(mapStateToProps, null)(PaxfulAccountPreview);
