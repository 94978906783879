import { Box } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import ATooltip from "components/shared/ATooltip";
import DateTime from "components/shared/DateTime";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import { Tiny } from "components/Typography";
import { SERVER_BASE_URL } from "config";
import { FslightGalleryForSingleImage } from "page-sections/profiles/profile/Trades/FsligthGallery";
import React, { useState } from "react";

const OutgoingMsg = ({ item }) => {
  const [toggler, setToggler] = useState(false);
  return (
    <Box
      maxWidth={{
        md: "60%",
        sm: "70%",
        xs: "80%",
      }}
      alignSelf="end"
    >
      <FlexBox gap={1.5} justifyContent="end">
        <Box
          sx={{
            padding: item?.file?.file_path ? 1 : 1,
            borderRadius: "8px",
            backgroundColor: "primary.main",
          }}
        >
          <Tiny display="block" color="white" fontWeight={500} lineHeight={1.7}>
            {item.message}
          </Tiny>
          {item?.file?.file_path && (
            <>
              <a onClick={() => setToggler(!toggler)}>
                <img
                  src={`${SERVER_BASE_URL}/${item?.file?.file_path}`}
                  width={"40px"}
                  height={"40px"}
                />
              </a>
              <FslightGalleryForSingleImage
                image={`${SERVER_BASE_URL}/${item?.file?.file_path}`}
                toggler={toggler}
              />
            </>
          )}
        </Box>
      </FlexBox>
      <Tiny mt={1} curs fontSize={10} fontWeight={500} textAlign="right">
        <ATooltip
          placement={"top"}
          content={<DateTime date={item.createdAt} />}
        >
          <span style={{ cursor: "pointer" }}>
            <FromNow date={item.createdAt} />
          </span>
          {/* <FormattedDateTime date={} /> */}
        </ATooltip>
      </Tiny>
    </Box>
  );
};

export default OutgoingMsg;
