import { Box, Card, Stack, Typography } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBox from "components/flexbox/FlexBox";
import { FormattedDateTime } from "components/shared/FormattedDate";
import { H5, Tiny } from "components/Typography";
import React from "react";

const BuyerInformation = ({ buyer }) => {
  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <Typography fontSize={15} fontWeight={600}>
        Buyer Details
      </Typography>

      {/* Details */}
      <Box my={2}>
        <Typography
          mb={1}
          fontSize={14}
          color="text.secondary"
          fontWeight={500}
        >
          Details
        </Typography>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Verified
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.is_verified ? "True" : "False"}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Joined{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.joined ? buyer?.profile?.joined : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Vendor{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.is_vendor ? "True" : "False"}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Last seen{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.last_seen
              ? buyer?.profile?.last_seen
              : "No last Seen"}
          </Typography>
        </FlexBox>
        {buyer?.platform === "noones" && (
          <>
            <FlexBox
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={13} color="text.secondary">
                Last seen timestamp
              </Typography>{" "}
              <Typography fontSize={13} color="text.secondary">
                {buyer?.profile?.last_seen_timestamp
                  ? buyer?.profile?.last_seen_timestamp
                  : "No last Seen timestamp"}
              </Typography>
            </FlexBox>
            <FlexBox
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={13} color="text.secondary">
                Online status
              </Typography>{" "}
              <Typography fontSize={13} color="text.secondary">
                {buyer?.profile?.online_status
                  ? buyer?.profile?.online_status
                  : "No online status"}
              </Typography>
            </FlexBox>
            <FlexBox
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={13} color="text.secondary">
                Chat status
              </Typography>{" "}
              <Typography fontSize={13} color="text.secondary">
                {buyer?.profile?.chat_status
                  ? buyer?.profile?.chat_status
                  : "No chat status"}
              </Typography>
            </FlexBox>
            <FlexBox
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={13} color="text.secondary">
                Make as referrer
              </Typography>{" "}
              <Typography fontSize={13} color="text.secondary">
                {buyer?.profile?.make_as_referrer ? "True" : "False"}
              </Typography>
            </FlexBox>
            <FlexBox
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={13} color="text.secondary">
                Can be blocked
              </Typography>{" "}
              <Typography fontSize={13} color="text.secondary">
                {buyer?.profile?.can_be_blocked ? "True" : "False"}
              </Typography>
            </FlexBox>
          </>
        )}

        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Status
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.status ? buyer?.profile?.status : "No status"}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Created At
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            <FormattedDateTime date={buyer?.profile?.created_at} />
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            First trade date
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.first_trade_date}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Blocked by{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.blocked_by ? buyer?.profile?.blocked_by : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Blocked count{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.blocked_count ? buyer?.profile?.blocked_count : 0}
          </Typography>
        </FlexBox>
      </Box>
      {/* verified */}
      <Box my={2}>
        <Typography
          mb={1}
          fontSize={14}
          color="text.secondary"
          fontWeight={500}
        >
          Verified
        </Typography>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Email verified
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.email_verified ? "True" : "False"}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Phone verified
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.phone_verified ? "True" : "False"}
          </Typography>
        </FlexBox>

        {buyer?.platform === "noones" && (
          <>
            <FlexBox
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={13} color="text.secondary">
                Basic verified
              </Typography>{" "}
              <Typography fontSize={13} color="text.secondary">
                {buyer?.profile?.basic_verified ? "True" : "False"}
              </Typography>
            </FlexBox>
            <FlexBox
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={13} color="text.secondary">
                Document verified
              </Typography>{" "}
              <Typography fontSize={13} color="text.secondary">
                {buyer?.profile?.is_document_verified ? "True" : "False"}
              </Typography>
            </FlexBox>
            <FlexBox
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={13} color="text.secondary">
                Address Verified
              </Typography>{" "}
              <Typography fontSize={13} color="text.secondary">
                {buyer?.profile?.is_address_verified ? "True" : "False"}
              </Typography>
            </FlexBox>
            <FlexBox
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={13} color="text.secondary">
                Verified phone country
              </Typography>{" "}
              <Typography fontSize={13} color="text.secondary">
                {buyer?.profile?.verified_phone_country
                  ? buyer?.profile?.verified_phone_country
                  : "none"}
              </Typography>
            </FlexBox>
            <FlexBox
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={13} color="text.secondary">
                Verified id country
              </Typography>{" "}
              <Typography fontSize={13} color="text.secondary">
                {buyer?.profile?.verified_id_country
                  ? buyer?.profile?.verified_id_country
                  : "none"}
              </Typography>
            </FlexBox>
            <FlexBox
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={13} color="text.secondary">
                Last ip country
              </Typography>{" "}
              <Typography fontSize={13} color="text.secondary">
                {buyer?.profile?.last_ip_country}
              </Typography>
            </FlexBox>
          </>
        )}
      </Box>
      {/* monthly trade stats */}
      <Box my={2}>
        <Typography
          mb={1}
          fontSize={14}
          color="text.secondary"
          fontWeight={500}
        >
          Montly trade stats
        </Typography>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Average time to payment
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.monthly_trade_stats?.avg_time_to_payment
              ? buyer?.profile?.monthly_trade_stats?.avg_time_to_payment
              : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Average time to release{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.monthly_trade_stats?.avg_time_to_release
              ? buyer?.profile?.monthly_trade_stats?.avg_time_to_release
              : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Trade count success
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.monthly_trade_stats?.trades_count_success
              ? buyer?.profile?.monthly_trade_stats?.trades_count_success
              : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Trades count total
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.monthly_trade_stats?.trades_count_total
              ? buyer?.profile?.monthly_trade_stats?.trades_count_total
              : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Trade percent success{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.monthly_trade_stats?.trades_percent_success
              ? buyer?.profile?.monthly_trade_stats?.trades_percent_success
              : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Trade volume total{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.monthly_trade_stats?.trades_volume_total
              ? buyer?.profile?.monthly_trade_stats?.trades_volume_total
              : 0}
          </Typography>
        </FlexBox>
      </Box>

      {/* Total crypto traded */}

      <Box my={2}>
        <Typography
          mb={1}
          fontSize={14}
          color="text.secondary"
          fontWeight={500}
        >
          Total crypto trade
        </Typography>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            BTC
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.monthly_trade_stats?.btc
              ? buyer?.profile?.monthly_trade_stats?.btc
              : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            ETH{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.monthly_trade_stats?.eth
              ? buyer?.profile?.monthly_trade_stats?.eth
              : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            USDC
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.monthly_trade_stats?.usdc
              ? buyer?.profile?.monthly_trade_stats?.usdc
              : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            USDT{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.monthly_trade_stats?.usdt
              ? buyer?.profile?.monthly_trade_stats?.usdt
              : 0}
          </Typography>
        </FlexBox>
      </Box>

      {/* Total Things */}
      <Box my={2}>
        <Typography
          mb={1}
          fontSize={14}
          color="text.secondary"
          fontWeight={500}
        >
          Total
        </Typography>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Total ETH
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.total_eth ? buyer?.profile?.total_eth : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Total BTC
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.total_eth ? buyer?.profile?.total_btc : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Total partner{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.total_partners
              ? buyer?.profile?.total_partners
              : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Total trades
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.total_trades ? buyer?.profile?.total_trades : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Total USDC
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.total_usdc ? buyer?.profile?.total_usdc : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Total USDT{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.total_usdt ? buyer?.profile?.total_usdt : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Trade totals{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.trades_total ? buyer?.profile?.trades_total : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Trusted by{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.trusted_by ? buyer?.profile?.trusted_by : 0}
          </Typography>
        </FlexBox>
      </Box>
      {/* offer liquidity*/}
      {buyer?.platform === "noones" && (
        <Box my={2}>
          <Typography
            mb={1}
            fontSize={14}
            color="text.secondary"
            fontWeight={500}
          >
            Offer liquidity
          </Typography>
          <FlexBox
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={13} color="text.secondary">
              Code
            </Typography>{" "}
            <Typography fontSize={13} color="text.secondary">
              {buyer?.profile?.offer_liquidity.code}
            </Typography>
          </FlexBox>
          <FlexBox
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={13} color="text.secondary">
              Value
            </Typography>{" "}
            <Typography fontSize={13} color="text.secondary">
              {buyer?.profile?.offer_liquidity.value}
            </Typography>
          </FlexBox>
        </Box>
      )}

      {/* languages*/}
      {buyer?.platform === "noones" && buyer?.profile?.languages[0] && (
        <Box my={2}>
          <Typography
            mb={1}
            fontSize={14}
            color="text.secondary"
            fontWeight={500}
          >
            Languages
          </Typography>
          <FlexBox
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={13} color="text.secondary">
              Code
            </Typography>{" "}
            <Typography fontSize={13} color="text.secondary">
              {buyer?.profile?.languages[0].code}
            </Typography>
          </FlexBox>
          <FlexBox
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={13} color="text.secondary">
              Value
            </Typography>{" "}
            <Typography fontSize={13} color="text.secondary">
              {buyer?.profile?.languages[0].name}
            </Typography>
          </FlexBox>
          <FlexBox
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={13} color="text.secondary">
              Native
            </Typography>{" "}
            <Typography fontSize={13} color="text.secondary">
              {buyer?.profile?.languages[0].native}
            </Typography>
          </FlexBox>
        </Box>
      )}

      {/* Feedback */}
      <Box my={2}>
        <Typography
          mb={1}
          fontSize={14}
          color="text.secondary"
          fontWeight={500}
        >
          Feedbacks
        </Typography>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Feedback neagtive
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.feedback_negative
              ? buyer?.profile?.feedback_negative
              : 0}
          </Typography>
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={13} color="text.secondary">
            Feedback positive{" "}
          </Typography>{" "}
          <Typography fontSize={13} color="text.secondary">
            {buyer?.profile?.feedback_positive
              ? buyer?.profile?.feedback_positive
              : 0}
          </Typography>
        </FlexBox>
      </Box>
    </Card>
  );
};

export default BuyerInformation;
