import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5, Paragraph } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import InvoiceListTable from "page-sections/invoice/list";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components
import List from "./List";

import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import WalletService from "services/WalletService";
import { LoadingButton } from "@mui/lab";
import PaxfultransactionService from "services/PaxfultransactionService";
import Transaction from "../Transactions/Transactions";
import TransactionList from "./TransactionList";
import FlexBox from "components/flexbox/FlexBox";
import Platform from "components/shared/Platform";
import PaxfulAccountsService from "services/PaxfulAccountsService";
import { AccountSelect } from "../offer/Offers";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const PaxfulTransactions = ({ getUser, openTradeFromTab }) => {
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  const [sortPaxfulTransactions, setSortPaxfulTransactions] = useState([]);

  // ================================
  // wallet Transactions
  // =================================
  const { userdata, paxfulTransactions, setPaxfulTransactions } =
    useContext(UserContext);

  const [account, setAccount] = useState("active");
  const [userAccounts, setUserAccounts] = useState([]);
  const [option, setOption] = useState("all");

  const [isLoading, setisLoading] = useState(false);
  // const [transactions, setTrasnactions] = useState([]);
  // const [num_page, setnum_page] = useState(0);
  // const [limit, setLimit] = React.useState(50);
  const [pages, setPages] = React.useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [limit, setLimit] = useState(50);

  const getPaxfulTransactions = async (
    id,
    page = 1,
    limit = 50,
    account_id = "all",
    type = "active"
  ) => {
    setisLoading(true);
    const transaction = await PaxfultransactionService.getPaxfulTransactions(
      id,
      page,
      limit,
      account_id,
      type
    );
    setPaxfulTransactions(transaction.data);
    setPages(transaction.num_pages);
    setisLoading(false);
  };

  // get User account
  const getUserPaxfulAccounts = async (id) => {
    const accounts = await PaxfulAccountsService.getUserPaxfulAccounts(
      id,
      "",
      ""
      // page,
      // limit
    );
    setUserAccounts(accounts.records);
  };

  // console.log(transactions);

  useEffect(() => {
    getPaxfulTransactions(userdata._id);
    getUserPaxfulAccounts(userdata._id);
  }, []);
  // =========================================

  // function handleChangePage(event, newpage) {
  //   console.log("newpage", newpage);
  //   setpg(newpage);
  //   // getPaxfulTransactions(userdata._id, pg, limit);
  // }

  // function handleChangeRowsPerPage(event) {
  //   setLimit(parseInt(event.target.value, 10));
  //   setpg(0);
  //   // getPaxfulTransactions(userdata._id, pg, event.target.value);
  //   console.log("event.target.value", event.target.value);
  // }

  const reload = () => {
    setAccount("active");
    setOption("all");
    getPaxfulTransactions(userdata._id);
  };

  useEffect(() => {
    let sort =
      paxfulTransactions &&
      paxfulTransactions.sort(function (a, b) {
        return b.createdAt - a.createdAt;
      });
    setSortPaxfulTransactions(sort);
  }, [paxfulTransactions]);

  const selectHandleChange = (event) => {
    setPageNum(1);
    setOption(event.target.value);
    getPaxfulTransactions(userdata._id, 1, limit, event.target.value);
  };

  const handleTabChange = (event) => {
    setPageNum(1);
    console.log("type", event.target.value);
    setAccount(event.target.value);
    console.log("type", event.target.value);

    getPaxfulTransactions(userdata._id, 1, limit, option, event.target.value);
  };

  const handlePageChange = (event, page) => {
    setPageNum(page);
    getPaxfulTransactions(userdata._id, page, limit, option, account);
    window.scrollTo(0, 200);
  };

  const handleLimitChange = (event) => {
    setPageNum(1);
    setLimit(event.target.value);
    getPaxfulTransactions(userdata._id, 1, event.target.value, option, account);
  };

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("P2P Transactions")}</H5>
        </Stack>
        <StyledStack alignItems="center" direction="row" gap={2}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              sx={{
                "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "10px !important", // Remove padding from the Select component
                  },
              }}
              onChange={(e) => handleTabChange(e)}
              value={account}
              disabled={option != "all"}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <Paragraph>All</Paragraph>
              </MenuItem>
              <MenuItem value={"active"}>
                {" "}
                <Paragraph>Active</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"delete"}>
                {" "}
                <Paragraph>Deleted</Paragraph>{" "}
              </MenuItem>
            </Select>
          </FormControl>

          <AccountSelect
            option={option}
            selectHandleChange={selectHandleChange}
            userAccounts={userAccounts}
          />

          <LoadingButton
            onClick={() => reload()}
            variant="contained"
            loading={isLoading}
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>

      {
        // isLoading ? (
        //   <Box
        //     sx={{
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //       height: "20vh",
        //     }}
        //   >
        //     {" "}
        //     <CircularProgress />
        //   </Box>
        // ) : (
        paxfulTransactions && (
          <TransactionList
            isLoading={isLoading}
            transactions={paxfulTransactions}
            pages={pages}
            limit={limit}
            pageNum={pageNum}
            getUser={getUser}
            openTradeFromTab={openTradeFromTab}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
          />
          // )
        )
      }
      {/* {paxfulTransactions && <List paxfulTransactions={paxfulTransactions} />} */}
    </Box>
  );
};

export default PaxfulTransactions;
