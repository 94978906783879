import FsLightbox from "fslightbox-react";
import React, { useState } from "react";

export function getFileExtension(url) {
  return url.split("/").pop().split(".").pop();
}

function KycDocGallery({ image }) {
  const [toggler, setToggler] = useState(false);
  const extenshion = getFileExtension(image);

  return (
    <div>
      {extenshion == "pdf" ? (
        <a href={image} target="blank" download={"dkbfdbf.pdf"}>
          <img src="/pdf.svg" alt="PDF" />
        </a>
      ) : (
        <>
          {" "}
          <img onClick={() => setToggler(!toggler)} src={image} width={60} />
          <FsLightbox toggler={toggler} sources={[image]} />
        </>
      )}
    </div>
  );
}

export default KycDocGallery;
