import { Small } from "components/Typography";
import React, { useState, useEffect } from "react";

const CustomCountdown = ({ targetDate }) => {
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate.getTime() - now;

      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (days) {
          setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        } else setCountdown(` ${hours}h ${minutes}m ${seconds}s`);
      } else {
        setCountdown("Timer Expired");
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [targetDate]);

  return (
    <Small fontWeight={"bold"} fontSize={14} className="fw-bolder fs-5">
      {countdown}
    </Small>
  );
};

export default CustomCountdown;
