import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import { Paragraph, Small, Tiny } from "components/Typography";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import AppModal from "components/AppModal";
import AppTextField from "components/input-fields/AppTextField";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import Scrollbar from "components/ScrollBar";
import { H3, H6, H4 } from "components/Typography";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axios";
import * as Yup from "yup"; // component props interface
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";

import { useTranslation } from "react-i18next";
import JSONPretty from "react-json-pretty";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward } from "@mui/icons-material";
import TradePreview from "components/shared/TradePreview";
import { copyTextToClipboard } from "utils/utils";

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 650,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;

const ViewWebhookModal = ({ open, onClose, data }) => {
  const { t } = useTranslation();
  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <Box pb={4}>
        <H3 mb={3}>{t("Webhook Info")}</H3>

        <Grid container spacing={0} mt={0}>
          <Grid item md={6} xs={6}>
            <H6 color="text.secondary">{t("Account ID")}</H6>
            <PaxfulAccountPreview account_id={data.id} model={true} />
          </Grid>

          <Grid item md={6} xs={6}>
            <H6 color="text.secondary">{t("Delivered At")}</H6>
            <H4 fontWeight={500}>
              {<FormattedDateTime date={data.deliveredAt} />}
            </H4>
          </Grid>
        </Grid>

        <Divider
          sx={{
            my: 3,
          }}
        />

        <Grid container spacing={0} mt={0}>
          <Grid item md={6} xs={6}>
            <H6 color="text.secondary">{t("Type")}</H6>
            <Small
              sx={{
                width: 100,
                fontSize: 10,
                color: "#fff",
                borderRadius: "4px",
                textAlign: "center",
                padding: ".2rem 1rem",
                backgroundColor: "grey.500", //
              }}
            >
              {data.response.type}
            </Small>
          </Grid>

          <Grid item md={6} xs={6}>
            <H6 color="text.secondary">{t("Trade Hash")}</H6>
            <Tiny fontSize={12} fontWeight={500}>
              {data.response && (
                <TradePreview
                  trade_hash={data.response.payload.trade_hash}
                  account_id={data.id}
                />
              )}
            </Tiny>
          </Grid>
        </Grid>

        <Divider
          sx={{
            my: 3,
          }}
        />

        <Grid container spacing={0} mt={0}>
          <Grid item md={6} xs={6}>
            <FlexBox>
              <div>
                <H6 color="text.secondary">{t("Status")}</H6>
                {/* {data.is_delivered == true ? "Success" :  "Fail"} */}
                <Small
                  sx={{
                    width: 100,
                    fontSize: 10,
                    color: "#fff",
                    borderRadius: "4px",
                    textAlign: "center",
                    padding: ".2rem 1rem",
                    backgroundColor:
                      data.is_delivered === true
                        ? "success.main"
                        : "error.main",
                  }}
                >
                  {data.is_delivered == true ? "Success" : "Fail"}
                </Small>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <H6 color="text.secondary">{t("	Delivery Type")}</H6>
                <Small
                  sx={{
                    width: 100,
                    fontSize: 10,
                    color: "#fff",
                    borderRadius: "4px",
                    textAlign: "center",
                    padding: ".2rem 1rem",
                    backgroundColor:
                      data.deliveredAt === true ? "warning.main" : "info.main",
                  }}
                >
                  {data.deliveredAt == true ? "Success" : "Initial"}
                </Small>
              </div>
            </FlexBox>
          </Grid>
          <Grid item md={6} xs={6}>
            <H6 color="text.secondary">{t("Num Retries")}</H6>
            <H4 fontWeight={500}>{data.num_retries}</H4>
          </Grid>
        </Grid>

        <Divider
          sx={{
            my: 3,
          }}
        />

        <div style={{ maxHeight: "200px", overflow: "auto" }}>
          <FlexBetween sx={{ textAlign: " center" }}>
            <H6 color="text.secondary">{t("	Payload")}</H6>
            <ContentCopyIcon
              sx={{
                cursor: "pointer",
              }}
              onClick={() => copyTextToClipboard(JSON.stringify(data.response))}
            />
          </FlexBetween>
          <JSONPretty id="json-pretty" data={data.response}></JSONPretty>
        </div>
      </Box>
    </StyledAppModal>
  );
};

export default ViewWebhookModal;
