import { SvgIcon } from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

const Active = (props) => {
  return (
    <SvgIcon viewBox="0 0 15 15" {...props}>
      <AssignmentTurnedInIcon />
    </SvgIcon>
  );
};

export default Active;
