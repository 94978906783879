import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Stack,
  styled,
  Table,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FlexBetween from "components/flexbox/FlexBetween";
import Scrollbar from "components/ScrollBar";
import NoResultFound from "components/shared/NoResultFound";
import { H5, H6, Paragraph } from "components/Typography";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@mui/material/styles";

import {
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { arrayIsEmpty } from "utils/utils";
import PaginanationAndLimit from "components/shared/PaginanationAndLimit";
// import AddHireVirtualAssistant from "./AddHireVirtualAssistant";
import HireVirtualAssistantService from "services/HireVirtualAssistantService";
import PasswordModal from "page-sections/data-table/PasswordModal";
import Columnshape from "./Columnshape";
import VirtualAccountService from "services/VirtualAccountService";
import DeleteDialog from "components/shared/DeleteDialog";
import AddVirtualAccount from "./AddVirtualAccount";
import ChangePassword from "./ChangePassword";
// import ViewActivityModal from "./view";

const theme = createMuiTheme({
  overrides: {
    MuiPaginationItem: {
      page: {
        "&.Mui-selected": {
          fontWeight: "bold",
        },
      },
    },
  },
});
// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 10px",
  color: theme.palette.text.primary,
}));

const VirtualAccountList = ({
  data,
  num_pages,
  onPageChange,
  currentPage,
  handleLimitChange,
  limit,
  getVirtualAccounts,
}) => {
  const tableData = useMemo(() => data, [data]);
  const [open, setOpen] = useState(false);
  const [hireVA, setHireVA] = useState(false);

  // Save the state for open and close the model
  const [openModal, setOpenModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const edit = (value) => {
    console.log("edit");
    setHireVA(value);
    setOpenModal(true);
  };
  const deleteModalCall = (value) => {
    console.log("delete");
    setHireVA(value);
    setOpen(true);
  };
  const handleDeleteUser = async () => {
    await VirtualAccountService.deleteVirtualAccount(hireVA._id);
    setOpen(false);
    getVirtualAccounts();
  };

  const handleCancelConfirm = () => {
    setHireVA(null);
    setOpen(false);
  };

  const changePassword = (value) => {
    console.log("changePassword");
    setOpenPasswordModal(true);
    setHireVA(value);
  };

  const columns = useMemo(
    () => Columnshape(edit, deleteModalCall, changePassword),
    []
  );

  const [filterInput, setFilterInput] = useState("");

  const defaultColumn = useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    setPageSize,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: tableData, //trade history
      pageCount: num_pages,
      manualPagination: true,
      defaultColumn,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  ); // handle pagination

  // console.log(pageSize);
  // const handleChange = () => gotoPage(invoiceList.length);
  const handleChange = (_, currentPageNo) => onPageChange(currentPageNo);

  const [selectedRecord, setSelectedRecord] = useState(null);

  // const handleViewButton = (trade) => {
  //   setOpenModal(true);
  //   setSelectedRecord(trade);
  // };

  const { globalFilter } = state;

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    setFilterInput(value);
    setGlobalFilter(value);
  };
  // console.log(tableData);
  const navigate = useNavigate();
  return (
    <Box>
      <Scrollbar autoHide={false}>
        <Table
          {...getTableProps()}
          sx={{
            borderSpacing: "0 1rem",
            borderCollapse: "separate",
            minWidth: 900,
          }}
        >
          <TableHead>
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <HeadTableCell
                    key={index}
                    // {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </HeadTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {arrayIsEmpty(tableData) ? (
              <StyledTableCell colSpan={10} align="center">
                <NoResultFound />
              </StyledTableCell>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <StyledTableRow
                    key={index}
                    {...row.getRowProps()}
                    sx={{
                      cursor: "pointer",
                    }}
                    // Click to open a Model
                    // onClick={() => handleViewButton(row.original)}
                  >
                    {row.cells.map((cell, index) => {
                      if (index === 6) {
                        return (
                          <BodyTableCell
                            sx={{
                              minWidth: "130px",
                            }}
                            key={index}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </BodyTableCell>
                        );
                      } else {
                        return (
                          <BodyTableCell key={index} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </BodyTableCell>
                        );
                      }
                    })}
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Scrollbar>
      {/* Model Open And Close */}

      <AddVirtualAccount
        onSuccess={() => getVirtualAccounts()}
        data={hireVA}
        edit={true}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />

      {/* Delete user */}
      <DeleteDialog
        open={open}
        title={"Confirm Delete"}
        subTitle={"It will delete this VA Account Pemanently. Are you sure?"}
        handleCancel={handleCancelConfirm}
        handleConfirm={() => handleDeleteUser()}
      />
      {/* <Dialog
        open={open}
        //  onClose={handleClose}
      >
        <DialogTitle
          style={{
            fontSize: "0.925rem",
          }}
        >
          <H5>Confirm Delete</H5>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              fontSize: "0.925rem",
            }}
          >
            <H6> will delete this Virtual Account Pemanently. Are you sure?</H6>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirm}>Cancel</Button>
          <Button onClick={() => handleDeleteUser()}>Delete</Button>
        </DialogActions>
      </Dialog> */}

      <PaginanationAndLimit
        tableData={tableData}
        handleChange={handleChange}
        count={pageOptions.length}
        currentPage={currentPage}
        handleLimitChange={handleLimitChange}
        limit={limit}
      />

      <ChangePassword
        onSuccess={() => getVirtualAccounts()}
        data={hireVA}
        open={openPasswordModal}
        edit={true}
        onClose={() => setOpenPasswordModal(false)}
      />
    </Box>
  );
};

export default VirtualAccountList;
