import { ArrowRightAlt } from "@mui/icons-material";
import { Box, ButtonBase, Stack, styled, Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AppPagination from "components/AppPagination";
import FlexBetween from "components/flexbox/FlexBetween";
import Scrollbar from "components/ScrollBar";
import NoResultFound from "components/shared/NoResultFound";
import { Paragraph } from "components/Typography";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@mui/material/styles";

import {
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { arrayIsEmpty } from "utils/utils";
import SearchInput from "components/input-fields/SearchInput";
import P2PColumnShape from "./P2PColumnShape";
// import ViewActivityModal from "./view";

const theme = createMuiTheme({
  overrides: {
    MuiPaginationItem: {
      page: {
        "&.Mui-selected": {
          fontWeight: "bold",
        },
      },
    },
  },
});
// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 10px",
  color: theme.palette.text.primary,
}));

const Prepaid2CoinList = ({
  records,
  num_pages,
  limit,
  onPageChange,
  currentPage,
}) => {
  const tableData = useMemo(() => records, [records]);
  const columns = useMemo(() => P2PColumnShape, []);

  const [filterInput, setFilterInput] = useState("");

  const defaultColumn = useMemo(
    () => ({
      Filter: () => null, // Disable individual column filters
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    setPageSize,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: tableData, //trade history
      pageCount: num_pages,
      manualPagination: true,
      defaultColumn,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  ); // handle pagination

  // console.log(pageSize);
  // const handleChange = () => gotoPage(invoiceList.length);
  const handleChange = (_, currentPageNo) => onPageChange(currentPageNo);

  // Save the state for open and close the model
  const [openModal, setOpenModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleViewButton = (trade) => {
    setOpenModal(true);
    setSelectedRecord(trade);
  };

  const { globalFilter } = state;

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    setFilterInput(value);
    setGlobalFilter(value);
  };
  // console.log(tableData);
  const navigate = useNavigate();
  return (
    // <ThemeProvider theme={theme}>
    <Box>
      {/* <SearchInput
        placeholder="Search"
        type="text"
        value={filterInput}
        onChange={handleFilterChange}
      /> */}
      <Scrollbar autoHide={false}>
        <Table
          {...getTableProps()}
          sx={{
            borderSpacing: "0 1rem",
            borderCollapse: "separate",
            minWidth: 900,
          }}
        >
          <TableHead>
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <HeadTableCell
                    key={index}
                    // {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </HeadTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {arrayIsEmpty(tableData) ? (
              <StyledTableCell colSpan={10} align="center">
                <NoResultFound />
              </StyledTableCell>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <StyledTableRow
                    key={index}
                    {...row.getRowProps()}
                    sx={{
                      cursor: "pointer",
                    }}
                    // Click to open a Model
                    // onClick={() => {console.log("Click On Edit")}}
                    onClick={() => handleViewButton(row.original)}
                  >
                    {row.cells.map((cell, index) => (
                      <BodyTableCell key={index} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </BodyTableCell>
                    ))}
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Scrollbar>
      {/* Model Open And Close */}
      {/* {selectedRecord && (
        <ViewActivityModal
          data={selectedRecord}
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )} */}
      {/* 
      <FlexBetween>
        <Paragraph color="text.secondary" fontSize={13}>
          Showing 1-12 of {pageCount * state.pageSize} result
        </Paragraph>

        <ButtonBase
          onClick={handleChange}
          disableRipple
          sx={{
            fontSize: 13,
            fontWeight: 600,
          }}
        >
          View All
          <ArrowRightAlt
            sx={{
              marginLeft: 0.5,
            }}
          />
        </ButtonBase>
      </FlexBetween> */}
      <Stack alignItems="center" marginY={0}>
        {tableData.length > 0 && (
          <AppPagination
            shape="rounded"
            onChange={handleChange}
            count={pageOptions.length}
            page={currentPage} // Control the selected page
          />
        )}
      </Stack>
      {/* <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select> */}
    </Box>
    // </ThemeProvider>
  );
};

export default Prepaid2CoinList;
