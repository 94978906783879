import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5 } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components

import { io } from "socket.io-client";
import { LoadingButton } from "@mui/lab";
import VAService from "services/virtualAssistantService";
import VaTable from "page-sections/virtual-assistant";
import AddVaModal from "page-sections/virtual-assistant/AddVaModal";
import AddvaModal2 from "page-sections/virtual-assistant/AddvaModal2";

import { EventEmitter } from "eventemitter3";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const VirtualAsssistant = (props) => {
  const myEmitter = new EventEmitter();

  // emit an event
  myEmitter.emit("greet", "John");

  // register an event listener
  myEmitter.on("greet", (name) => {
    console.log(name);
  });

  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItem, setFilteredItem] = useState(INVOICE_LIST);

  // useEffect(() => {
  //   const result = searchByName(INVOICE_LIST, searchValue);
  //   setFilteredItem(result);
  // }, [searchValue]);

  const [data, setData] = useState(null);

  // console.log(data);
  // get User Request
  const getVA = async (page, search) => {
    setisLoading(true);
    const resp = await VAService.getVA(page, search);
    setData(resp);
    setisLoading(false);
  };

  useEffect(() => {
    getVA(1, searchValue);
  }, [searchValue]);

  const reload = () => {
    getVA();
  };

  const handlePageChange = (page) => {
    getVA(page);
  };

  console.log("data", data);

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("Virtual Assistants (Fake)")}</H5>
        </Stack>

        <StyledStack alignItems="center" direction="row" gap={2}>
          <SearchInput
            disable_border="true"
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button
            onClick={() => setOpenModal(true)}
            variant="contained"
            startIcon={<Add />}
            sx={{
              flexShrink: 0,
            }}
          >
            Add New
          </Button>

          <AddVaModal
            onSuccess={() => getVA(1, "")}
            open={openModal}
            onClose={() => setOpenModal(false)}
           
          />
        </StyledStack>
      </FlexBetween>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data && (
          <VaTable {...data} onPageChange={handlePageChange} getVA={getVA} />
        )
      )}
    </Box>
  );
};

export default VirtualAsssistant;
