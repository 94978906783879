import React, { useState } from "react";
import AvatarGroup from "@mui/material/AvatarGroup";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserSymbol from "./UserSymbol"; // Replace with your UserSymbol component
import ATooltip from "./ATooltip";
import UserInfo from "./UserInfo";
import FlexBox from "components/flexbox/FlexBox";

const AvatarGroupWithToggle = ({ users, max }) => {
  const [showAll, setShowAll] = useState(false);

  const handleShowAllClick = (event) => {
    console.log("click");
    // Prevent the click event from propagating to the parent elements
    event.stopPropagation();
    setShowAll(true);
  };

  // Slice the users based on whether to show all or not
  const displayedUsers = showAll ? users : users.slice(0, max);

  return (
    <FlexBox max={max}>
      {displayedUsers.map((user, index) => (
        <Tooltip
          key={index}
          title={`User: ${user.user.username}`}
          placement="top"
        >
          <UserSymbol
            user={user}
            size={25}
            showOnline={true}
            isBottomZero={0}
          />
        </Tooltip>
      ))}
      {users.length > max && !showAll && (
        <Tooltip title={`Show all users`} placement="top">
          <IconButton onClick={handleShowAllClick}>
            <ExpandMoreIcon />
          </IconButton>
        </Tooltip>
      )}
    </FlexBox>
  );
};

export default AvatarGroupWithToggle;
