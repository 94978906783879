import React, { useContext, useState } from "react";
// import { Button, Card, CardBody } from "reactstrap";
// import emitter from "../../helpers/emitter";
// import CraftNotice from "../widgets/Notice";
// import Textarea from "./Textarea";
import auto_suggestions from "../../../../data/auto_suggestions";
import Lottie from "lottie-react-web";
import messageAnimationLight from "../../../../assets/animations/Fav Message-02-Light.json";
import messageAnimationDark from "../../../../assets/animations/Fav Message-02-Dark.json";
// import ImageGallery, { ImageGalleryContext } from "../widgets/ImageGallery";
import { useRef } from "react";
// import PaxfulProfilePic from "../widgets/PaxfulProfilePic";
import platforms from "../../../../data/platforms";
// import PlatformLogo from "../widgets/PlatformLogo";
// import { TextareaMessagesSkeleton } from "../skeleton/AutomationsSkeleton";
import Platform from "components/shared/Platform";
import CraftNotice from "components/CraftNotice";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { Box, Button, Card, Grid } from "@mui/material";
import ATCraftNotice from "components/ATCraftNotice";
import FlexBox from "components/flexbox/FlexBox";
import { Paragraph } from "components/Typography";
import { UserContext } from "page-sections/user-list/ViewUser";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import Textarea from "./Textarea";
import Textarea2 from "./Textarea2";
import { SettingsContext } from "contexts/settingsContext";
import PlatformLogo from "components/shared/PlatformLogo";
// import Textarea from "./Textarea";

function Messaging({ messages, onChange, activeAccount }) {
  const { userdata, offers } = useContext(UserContext);
  const { settings, saveSettings } = useContext(SettingsContext);

  let automated_feedback = {
    title: "",
    text: "",
    is_file: false,
  };

  let _message = {
    enabled: true,
    message: [{ ...automated_feedback }],
    offers: [],
  };

  const [message, setMessage] = useState(_message);
  const addItem = () => {
    let _messages = [...messages];
    _messages.push(message);

    onChange(_messages);

    // emitter.emit("clear:select:offers");
    setMessage(_message);
  };

  const removeItem = (index) => {
    let _messages = [...messages].filter((value, _index) => {
      return index != _index;
    });

    onChange(_messages);
  };

  const handleTextareaChange = (index, value) => {
    let _messages = [...messages];
    _messages[index] = { ...value };

    onChange(_messages);
  };

  //already selected offers
  const selectedOffers = messages
    ? messages.flatMap((message) => {
        return message.offers.map((offer) => {
          return offer.value;
        });
      })
    : [];

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const ordered = reorder(
      messages,
      result.source.index,
      result.destination.index
    );

    onChange([...ordered]);
  };

  const hasSpaceOnly = /^\s*$/;
  const noMessage = hasSpaceOnly.test(message.message[0].text);

  return (
    <>
      <Box py={2} px={4}>
        <ATCraftNotice
          icon={
            <svg
              width="38"
              height="38"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                fill="currentColor"
              />
            </svg>
          }
          type={"primary"}
          title={
            <FlexBox color="black" sx={{ alignItem: "center" }}>
              <FlexBox
                sx={{ alignItem: "center" }}
                className="d-flex align-items-baseline"
              >
                {"Set Automatic Messaging for"}
              </FlexBox>
              <FlexBox
                sx={{ alignItem: "center", position: "relative" }}
                className="user-inner-wrap mt-1 mb-1"
              >
                <Box
                  mx={0.5}
                  sx={{ alignItem: "center", position: "relative" }}
                >
                  <PaxfulProfilePic
                    account={activeAccount}
                    size={20}
                    className={"ms-2"}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: -4,
                      left: 10,
                    }}
                  >
                    <Platform
                      platform={activeAccount?.platform}
                      size={15}
                      className={"w-20px me-1"}
                    />
                  </Box>
                </Box>

                <span className="ms-2">{activeAccount?.paxful_username}</span>
              </FlexBox>
            </FlexBox>
          }
          content={
            activeAccount && (
              <Paragraph color="black" fontSize={13}>
                Auto Messaging gives the vendor the ability to set
                offer-specific chat messages, images, and PDF files that will
                preload on the trading tool dashboard, allowing the vendor &
                team members to easily select and send presaved content for{" "}
                <Box
                  mx={0.3}
                  sx={{
                    alignItem: "center",
                    position: "relative",
                    display: "inline",
                  }}
                >
                  <PaxfulProfilePic
                    account={activeAccount}
                    size={20}
                    className={"ms-2"}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: -10,
                      left: 10,
                    }}
                  >
                    <Platform
                      platform={activeAccount?.platform}
                      size={15}
                      className={"w-20px me-1"}
                    />
                  </Box>
                </Box>
                <span className="ms-2">{activeAccount?.paxful_username}</span>{" "}
                {platforms[activeAccount?.platform].title} offers without
                typing. Create your own personalized messages or pick from
                Venva&apos;s suggestions. Drag and drop your messages and images
                to customize the order they appear in on the Venva trading tool.
              </Paragraph>
            )
          }
        />
        <Box
          py={2}
          sx={{ borderBottom: "1px dashed lightgrey" }}
          className="separator separator-dashed my-5 my-xl-10"
        ></Box>
        <Grid container spacing={3} className="mb-1">
          <Grid item lg={2} xl={2} className="d-none d-xl-block left">
            {/* Your Lottie animation code */}
            <Box sx={{ transform: " translate3d(0px, 62px, 0px)" }}>
              {settings && settings.theme == "light" && (
                <Lottie
                  options={{
                    animationData: messageAnimationLight,
                  }}

                  // width="100%"
                />
              )}
              {settings && settings.theme == "dark" && (
                <Lottie
                  options={{
                    animationData: messageAnimationDark,
                  }}

                  // width="100%"
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={5} xl={5} className="mid" mt={3}>
            {/* <Grid item md={9} mt={3}> */}
            <Box
              style={{ pointerEvents: "none" }}
              p={2}
              sx={{
                // border: "1px solid lightgrey",
                boxShadow: "0px 8px 24px 0px rgba(140, 149, 159, 0.2)",
              }}
              borderRadius={"5px"}
              className="card-shadow overflow--hidden"
            >
              <Box>
                <Textarea2
                  selectedOffers={selectedOffers}
                  {...message}
                  onChange={(value) => {
                    setMessage(value);
                  }}
                  uploadImage={true}
                />
                <div className="d-flex justify-content-start mt-3">
                  <Button
                    variant="contained"
                    color="success"
                    type="button"
                    disabled={
                      noMessage ||
                      message.offers.length == 0 ||
                      message.message[0].text == ""
                    }
                    className="cursor-pointer btn btn-success btn-sm fs-6 fw-bolder"
                    onClick={() => addItem("field")}
                  >
                    {" "}
                    Save
                  </Button>
                </div>
              </Box>
            </Box>
            {/* </Grid> */}
          </Grid>
          <Grid item xs={5} xl={5} className="right">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-feedback" direction="vertical">
                {(provided, snapshot) => (
                  <div
                    className="d-flex flex-column messaging-selected-offers"
                    ref={provided.innerRef}
                  >
                    {messages &&
                      messages.map((message, index) => (
                        <Draggable
                          key={index}
                          draggableId={`messages-card-${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              className="mb-5 offers-wrap"
                            >
                              <Box
                                style={{ pointerEvents: "none" }}
                                mt={3}
                                p={2}
                                sx={{
                                  // border: "1px solid lightgrey",
                                  boxShadow:
                                    "0px 8px 24px 0px rgba(140, 149, 159, 0.2)",
                                }}
                                borderRadius={"5px"}
                                className="card-shadow overflow--hidden"
                              >
                                <Box
                                  sx={{
                                    paddingRight: "1.75rem",
                                  }}
                                >
                                  <Textarea2
                                    isEditing={true}
                                    label={"Message"}
                                    onChange={(value) => {
                                      handleTextareaChange(index, value);
                                    }}
                                    {...message}
                                    onRemove={() => removeItem(index)}
                                    uploadImage={true}
                                    id={index}
                                    platform={activeAccount?.platform}
                                  />
                                </Box>
                              </Box>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Messaging;
