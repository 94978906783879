export const IS_PRODUCTION = true;
export const SERVER_BASE_URL = IS_PRODUCTION
  ? `https://api.previewyour.app`
  : `http://localhost:3001`;

export const API_BASE_URL = `${SERVER_BASE_URL}/Tbg6Qull4L`;
export const COMMON_API_BASE_URL = `${SERVER_BASE_URL}`;

// export const VA_SERVER_BASE_URL = IS_PRODUCTION
//   ? "https://api.va.previewyour.app"
//   : `http://localhost:3003`;

export const VA_SERVER_BASE_URL = "https://api.atlantis.crunchit.net";

export const VA_API_BASE_URL = `${VA_SERVER_BASE_URL}/api/v1/admin`;

// export const WEBHOOK_API_URL = IS_PRODUCTION
//   ? `https://webhook.atlantis.previewyour.app`
//   : `http://localhost:3002`;

export const WEBHOOK_API_URL = `https://webhook.previewyour.app`;
// export const WEBHOOK_API_URL = `http://localhost:3002`;

export const AUTH_TOKEN = "admin-jwt";
export const VA_AUTH_TOKEN = "va-admin-jwt";
export const TOKEN_PAYLOAD_KEY = "Authorization";
export const PUBLIC_REQUEST_KEY = "public-request";
export const DATE_FORMAT = "MM-DD-YYYY";
export const DATE_TIME_FORMAT = "MMM DD, YYYY hh:mm A";
export const FEE_PERCENTAGE = 0.0125;
