import { IconButton, Stack } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import { FormattedDateTime } from "components/shared/FormattedDate";
import { formatCurrency } from "utils/currencies";

const columnShape = [
  {
    Header: "DATE",
    accessor: "createdAt",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        <FormattedDateTime date={value} />
      </Paragraph>
    ),
  },
  {
    Header: "NAME",
    accessor: "name",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "HOURLY RATE",
    accessor: "hourlyRate",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}$
      </Paragraph>
    ),
  },
  {
    Header: "ACTIVE",
    accessor: "isActive",
    Cell: ({ value }) => (
      <Small
        sx={{
          width: 100,
          fontSize: 10,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: "0.2rem .5rem",
          backgroundColor: value ? "success.main" : "error.main",
          fontWeight: 500,
        }}
      >
        {value ? "Active" : "Offline"}
      </Small>
    ),
  },
  {
    Header: "COUNTRY",
    accessor: "country",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  // {
  //   Header: "DEVICE ",
  //   accessor: "deviceType",
  //   Cell: ({ value }) => (
  //     <Paragraph fontSize={13} color="text.secondary">
  //       {value}
  //     </Paragraph>
  //   ),
  // },
];
export default columnShape;
