import { Box } from "@mui/material";
import { Span } from "components/Typography";
import React from "react";

function PlatformLogo({ size, platform }) {
  return (
    // <Box
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    // >
    <Span sx={{ position: "relative", top: 5 }}>
      <img
        src={`/media/logos/${platform}-icon.png`}
        style={{
          height: `${size}px`,
          width: `${size}px`,
        }}
      />
    </Span>

    // </Box>
  );
}

PlatformLogo.defaultProps = {
  platform: "noones",
};

export default PlatformLogo;
