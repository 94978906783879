import { useEffect, useState } from "react";
import { Button, Grid, styled, Select, MenuItem } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { SERVER_BASE_URL } from "../../config";
import CancelIcon from "@mui/icons-material/Cancel";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import Scrollbar from "components/ScrollBar";
import { H2, H3, H6, Paragraph, Small } from "components/Typography";
import { ErrorMessage, useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axios";
import * as Yup from "yup"; // component props interface
import FormControl from "@mui/material/FormControl";
import { FileInput, ImageInput } from "formik-file-and-image-input/lib";
import countries from "../../data/country";
import Zones from "../../data/timezones.json";

import { openStdin } from "process";
// import { saveUsers } from "../../../redux/actions/user";
import { connect } from "react-redux";
import UserService from "services/UserService";
import { LoadingButton } from "@mui/lab";
import VAService from "services/virtualAssistantService";
import { Box, fontSize } from "@mui/system";
import { ConstructionOutlined } from "@mui/icons-material";
import HireVirtualAssistantService from "services/HireVirtualAssistantService";

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 650,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

const ImageContainer = styled(Box)`
  position: relative;
`;

// const CustomImageInputWrapper = ({ onClick, fileName, src }) => {
//   return (
//     <div onClick={onClick}>
//       {!src && <button onClick={onClick}>Choose Image</button>}
//       <img src={src} />
//       <p>{fileName}</p>
//     </div>
//   );
// };
const AddHireVirtualAssistant = (
  { open, onClose, onSuccess, edit, data },
  props
) => {
  const [files, setFiles] = useState([]);
  const [deleteProfilePic, setDeleteProfilePic] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      console.log("acceptedFiles", acceptedFiles);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setFieldValue("profile_pic", acceptedFiles[0]);
    },
  });

  const timeZoneOptions = Object.keys(Zones.zones).map((zone) => zone);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    first_name: data && data.first_name ? data.first_name : "",
    last_name: data && data.last_name ? data.last_name : "",
    email: data && data.email ? data.email : "",
    // password: "",
    country: data && data.country ? data.country : "US",
    timezone: data && data.timezone ? data.timezone : "America/New_York",
    experience: data && data.experience ? data.experience : "",
    profile_pic: null,
  };
  const validations = {
    first_name: Yup.string()
      .matches(/^[A-Za-z]+$/, "First name should contain only alphabets")
      .required("First name is Required!"),
    last_name: Yup.string()
      .matches(/^[A-Za-z]+$/, "Last name should contain only alphabets")
      .required("Last name is Required!"),
    email: Yup.string()
      .email("Please eneter valid email")
      .required("Email is required"),

    // password:
    //   !edit &&
    //   Yup.string().min(6, "Too Short").required("Password  is Required!"),
    country: Yup.string().required("Country is Required!"),
    timezone: Yup.string().required("Timezone is Required!"),
    experience: Yup.string().required("Experience is Required!"),

    // profile_pic:
    //   !edit &&
    //   Yup.mixed()
    //     // .required("Image is required")
    //     .test(
    //       "fileSize",
    //       "File is too large",
    //       (value) => !value || value.size <= 2000000
    //     ) // 2 MB

    //     .test(
    //       "fileType",
    //       "Unsupported file format",
    //       (value) => !value || ["image/jpeg", "image/png"].includes(value.type)
    //     ),
  };

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  });
  console.log("file", files);
  const fieldValidationSchema = Yup.object().shape(validations);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    setValues,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: fieldValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log("values", values);
      var formdata = new FormData();
      formdata.append("first_name", values.first_name);
      formdata.append("last_name", values.last_name);
      formdata.append("email", values.email);
      formdata.append("country", values.country);
      formdata.append("timezone", values.timezone);
      formdata.append("experience", values.experience);

      if (values.profile_pic) {
        formdata.append("profile_pic", values.profile_pic);
      }
      if (deleteProfilePic) {
        formdata.append("delete_profile_pic", "true");
      }

      setIsLoading(true);

      let resp;
      if (edit) {
        resp = await HireVirtualAssistantService.updateHireVirtualAssistant(
          data._id,
          formdata
        );
      } else {
        formdata.append("password", values.password);
        resp = await HireVirtualAssistantService.createHireVirtualAssistant(
          formdata
        );
      }

      if (resp && resp.status === true) {
        resetForm();
        onClose();
        onSuccess();
        acceptedFiles.splice(0, acceptedFiles.length);
        setFiles([]);
        setDisplay(false);
        setDeleteProfilePic(false);
      }

      setIsLoading(false);
    },
  });

  const [display, setDisplay] = useState(false);

  //Delete existing profile pic
  const handleDeleteProfile = () => {
    setDisplay(true);
    setDeleteProfilePic(true);
    // acceptedFiles.splice(0, acceptedFiles.length);
    setValues({ ...values, profile_pic: null });
    setFiles([]);
  };

  const onhandleClose = () => {
    resetForm();
    onClose();
    setDisplay(false);
    onClose();
    setFiles([]);
  };

  console.log("touched", touched);
  return (
    <StyledAppModal
      open={open}
      handleClose={onClose}
      className="edit-virtual-assistant-modal"
    >
      {/* <Scrollbar style={{ maxHeight: "400px", maxWidth: "auto" }}> */}
      <H3 mb={2}>
        {edit ? "Edit Virtual Assistant" : "Create Virtual Assistant"}
      </H3>

      <form onSubmit={handleSubmit}>
        {/* <Scrollbar style={{ maxHeight: "200px", maxWidth: "auto" }}> */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <H6 mb={1}>First Name</H6>
            <AppTextField
              fullWidth
              size="small"
              name="first_name"
              placeholder="first name"
              value={values.first_name}
              onChange={handleChange}
              helperText={touched.first_name && errors.first_name}
              error={Boolean(errors.first_name && touched.first_name)}
            />
          </Grid>
          <Grid item xs={6}>
            <H6 mb={1}>Last Name</H6>
            <AppTextField
              fullWidth
              size="small"
              name="last_name"
              placeholder="last name"
              value={values.last_name}
              onChange={handleChange}
              helperText={touched.last_name && errors.last_name}
              error={Boolean(errors.last_name && touched.last_name)}
            />
          </Grid>

          <Grid item xs={6}>
            <H6 mb={1}>Email</H6>
            <AppTextField
              fullWidth
              size="small"
              name="email"
              placeholder="email"
              onChange={handleChange}
              value={values.email}
              helperText={touched.email && errors.email}
              error={Boolean(errors.email && touched.email)}
            />
          </Grid>
          {/* {!edit && (
            <Grid item xs={6}>
              <H6 mb={1}>Password</H6>
              <AppTextField
                fullWidth
                size="small"
                name="password"
                placeholder="password"
                onChange={handleChange}
                value={values.password}
                helperText={touched.password && errors.password}
                error={Boolean(errors.password && touched.password)}
              />
            </Grid>
          )} */}

          <Grid item xs={6}>
            <H6 mb={1}>Country</H6>
            <FormControl sx={{ width: 290, padding: 0 }}>
              <Select
                sx={{ height: 39 }}
                onChange={handleChange}
                name="country"
                value={values.country}
              >
                {countries.map(({ name, code }) => (
                  <MenuItem key={name} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <H6 mb={1}>TimeZone</H6>
            <FormControl sx={{ width: 290, padding: 0 }}>
              <Select
                sx={{ height: 39 }}
                onChange={handleChange}
                value={values.timezone}
                name="timezone"
              >
                {timeZoneOptions.map((zone) => (
                  <MenuItem key={zone} value={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <H6 mb={1}>Experience</H6>
            {/* <FormControl sx={{ width: 290, padding: 0 }}> */}
            <AppTextField
              fullWidth
              size="small"
              name="experience"
              placeholder="Experience"
              onChange={handleChange}
              value={values.experience}
              helperText={touched.experience && errors.experience}
              error={Boolean(errors.experience && touched.experience)}
            />
            {/* </FormControl> */}
          </Grid>
          <Grid item xs={12}>
            <H6 mb={1}>Profile Pic</H6>
            {data && data.profile_pic && (
              <ImageContainer
                style={display ? { display: "none" } : { display: "block" }}
              >
                <img
                  style={{
                    width: `40px`,
                    height: `40px`,
                  }}
                  src={`${SERVER_BASE_URL}/${data?.profile_pic?.file_path.md}`}
                  alt="img"
                />
                <CancelIcon
                  style={{
                    position: "absolute",
                    top: "0%",
                    left: "5%",
                    cursor: "pointer",
                    color: "red",
                  }}
                  onClick={() => handleDeleteProfile()}
                />
              </ImageContainer>
            )}
            {
              <Box
                style={
                  display || !edit || !data?.profile_pic
                    ? { display: "block" }
                    : { display: "none" }
                }
                sx={{
                  border: "2px solid",
                  borderColor: "primary.main",
                  borderRadius: "6px",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                <Box
                  {...getRootProps({ className: "dropzone" })}
                  sx={{
                    padding: "20px 5px",
                    border: "1px dashed ",
                    borderRadius: "5px",

                    borderColor:
                      touched && touched.profile_pic && errors.profile_pic
                        ? "error.main"
                        : "primary.main",
                  }}
                >
                  <input
                    name="profile_pic"
                    type="file"
                    multiple
                    {...getInputProps()}
                  />

                  {files.length === 0 && (
                    <Paragraph fontSize={13}>
                      Drag 'n' drop some files here, or click to select files
                    </Paragraph>
                  )}

                  {values.profile_pic && (
                    <div className="image-preview">
                      <img
                        width={"100px"}
                        height={"100px"}
                        src={URL.createObjectURL(values.profile_pic)}
                        alt="Selected"
                      />
                    </div>
                  )}
                </Box>
              </Box>
            }
            {touched && touched.profile_pic && errors.profile_pic && (
              <Small color="error.main">Please select profile pic</Small>
            )}
            {/* */}
          </Grid>
        </Grid>

        <FlexBox justifyContent="flex-end" gap={2} marginTop={4}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={onhandleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="small"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </FlexBox>
        {/* </Scrollbar> */}
      </form>
      {/* </Scrollbar> */}
    </StyledAppModal>
  );
};

export default AddHireVirtualAssistant;
