import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RouteGuard from "components/RouteGuard";
import RTL from "components/RTL";
import { AppNotification } from "helpers/notifications";
import useSettings from "hooks/useSettings";
import { useRoutes } from "react-router-dom";
import routes from "routes";
import { createCustomTheme } from "theme";
import "./i18n";

import "./styles/custom.scss";
import { createContext, useEffect, useState } from "react";
import ProfileService from "services/ProfileService";
import { Socket } from "socket.io-client";
import socketHelper from "helpers/socketHelper";
import HiringRequestService from "services/HireRequestService";
// import emitter from "helpers/emitter";
import { connect } from "react-redux";
import { getMasterList } from "redux/actions/masterList";
import UserService from "services/UserService";
import emitter, { emitToSocket } from "helpers/socketEmmiter";
import { emit } from "helpers/emitter";
import rateService from "services/rateService";
import { isLoggedIn } from "helpers/auth";
import UserRequestApi from "services/userRequestService";
// import { emitter } from "helpers/socketEmmiter";

export const UserContextData = createContext({
  liveRate: {},
  hireRequestCount: 0,
  userRequestCount: 0,
  userdata: {},
  setUserData: () => {},
  userRequest: null,
  setUserRequest: () => {},
  reload: false,
  setReload: () => {},
  profileReloaded: false,
  setHireRequestCount: () => {},
  hireRequest: null,
  setHireRequest: () => {},
  userMasterList: [],
  setUserMasterList: () => {},
  rate: [],
  setRate: () => {},
  apiUserRequests: [],
  setApiUserRequests: () => {},
  getUserMasterList: () => {},
});

const App = (props) => {
  let socket;
  let listner;

  const [userdata, setUserData] = useState({});
  const [profileReloaded, setProfileReloaded] = useState(false);
  const [userRequest, setUserRequest] = useState(null);
  const [userRequestCount, setUserRequestCount] = useState(0);
  const [apiUserRequests, setApiUserRequests] = useState([]);

  const [reload, setReload] = useState(false);
  const [hireRequestCount, setHireRequestCount] = useState(0);
  const [hireRequest, setHireRequest] = useState(null);
  const [liveRate, setLiveRate] = useState({});
  const [userMasterList, setUserMasterList] = useState([]);
  const [rate, setRate] = useState([]);

  const getUserMasterList = async () => {
    let resp = await UserService.getUserMasterList({ all: 1 });
    if (resp?.status) {
      setUserMasterList(resp.data);
    }
  };

  const getRate = async () => {
    const resp = await rateService.getRate();
    setRate(resp);
  };

  const getUserRequest = async () => {
    let resp = await UserRequestApi.getUserRequest(1, 50, "", "");
    if (resp?.status) {
      setUserRequestCount(resp.pendingUserRequestCount);
      setApiUserRequests(resp.data);
    }
  };
  useEffect(() => {
    if (isLoggedIn()) {
      getUserMasterList();
      getRate();
      getUserRequest();
    }
  }, []);
  console.log("userRequestCount", userRequestCount);
  useEffect(() => {
    socket = socketHelper.connect("atlantis-admin");

    socket.on("connect", () => {
      console.log("socket connected");
      listner = emitter.addListener("socket:emit", function ({ event, data }) {
        console.log("emit: ", socket.id, event);
        socket.emit(event, data);
      });
    });

    socket.on("user:request:updated", (data) => {
      if (userRequest && userRequest._id == data._id) {
        // console.log("userRequest", userRequest);
        setUserRequest(data);
      }
    });

    socket.on("user:request:count", (data) => {
      console.log("user:request:count", data);
      setUserRequestCount(data);
    });

    socket.on("user:api:requests", (data) => {
      console.log("user:api:requests", data);
      setApiUserRequests(data);
    });

    socket.on("user:profile:reloaded", (data) => {
      // console.log("user:profile:reloaded");
      if (data) {
        setProfileReloaded(data);
      }
    });

    socket.on("user:reload:update", (user) => {
      console.log("user:reload:update called", user);
      setUserMasterList((prevUserMasterList) =>
        prevUserMasterList.map((item) => {
          if (item._id === user._id) {
            return {
              ...item,
              last_active: user.last_active,
            };
          }
          return item;
        })
      );
    });

    socket.on("user:unAssigned:request", (data) => {
      // console.log("user:profile:reloaded");
      console.log("user:unAssigned:request", data);

      setHireRequestCount(data);
    });

    socket.on("va:hire_requests:updated", (data) => {
      console.log("va:hire_requests:updated", data);
      setHireRequest(data);
    });

    socket.on("va:single_hire_request:updated", (request) => {
      console.log("va:single_hire_request:updated--", request);
      // emitter("va:single_hire_request:updated", request);
      emit("va:single_hire_request:updated", request);
    });

    socket.on("live:crypto:rates", (rates) => {
      setLiveRate(rates);
    });

    socket.on("disconnect", () => {
      listner?.remove();
    });

    return () => {
      socket?.removeAllListeners();
      listner?.remove();
    };
  }, [userRequest, profileReloaded]);

  const content = useRoutes(routes());

  const { settings } = useSettings();
  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  return (
    <UserContextData.Provider
      value={{
        hireRequestCount,
        userRequestCount,
        userdata,
        setUserData,
        userRequest,
        setUserRequest,
        reload,
        setReload,
        profileReloaded,
        setHireRequestCount,
        liveRate,
        hireRequest,
        setHireRequest,
        userMasterList,
        setUserMasterList,
        rate,
        setRate,
        apiUserRequests,
        setApiUserRequests,
        getUserMasterList,
      }}
    >
      <RouteGuard>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <RTL>
              <CssBaseline />
              {content}
              <AppNotification />
            </RTL>
          </ThemeProvider>
        </StyledEngineProvider>
      </RouteGuard>
    </UserContextData.Provider>
  );
};
//--

// export default App;
const mapStateToProps = ({ masterList }) => {
  return {
    users: masterList.masterList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { getMasterList: (users) => dispatch(getMasterList(users)) };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
