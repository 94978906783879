import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";

import { H5, Paragraph } from "components/Typography";
import Invoice from "icons/sidebar/Invoice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";
import VirtualAccountList from "page-sections/virtual-account/VirtualAccountList";
import VirtualAccountService from "services/VirtualAccountService";
import AddVirtualAccount from "page-sections/virtual-account/AddVirtualAccount";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const VirtualAccount = (props) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);

  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItem, setFilteredItem] = useState();
  const [option, setOption] = React.useState("");
  const [openModal, setOpenModal] = useState(false);

  const [data, setData] = useState(null);

  const getVirtualAccounts = async (page = 1, limit = 50, search = "") => {
    setisLoading(true);
    const resp = await VirtualAccountService.getVirtualAccounts(
      page,
      limit,
      search
    );
    setData(resp);
    setPage(page);
    setisLoading(false);
  };

  const reload = () => {
    // setPage(1);
    setLimit(50);
    setSearchValue("");
    getVirtualAccounts();
  };

  const handlePageChange = (page) => {
    // setPage(page);
    getVirtualAccounts(page, limit, searchValue);
  };

  const handleLimitChange = (event) => {
    // setPage(1);
    setLimit(event.target.value);
    getVirtualAccounts(1, event.target.value, searchValue);
  };

  useEffect(() => {
    getVirtualAccounts(1, limit, searchValue);
  }, []);
  const handleSearch = (searchValue) => {
    setSearchValue(searchValue)
    getVirtualAccounts(1, limit, searchValue);
  }

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("VA Accounts")}</H5>
        </Stack>

        <StyledStack alignItems="center" direction="row" gap={2}>
          <SearchInput
            disable_border="true"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
          <Button
            onClick={() => setOpenModal(true)}
            variant="contained"
            startIcon={<Add />}
            sx={{
              flexShrink: 0,
            }}
          >
            Add New
          </Button>
        </StyledStack>
      </FlexBetween>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data && (
          <VirtualAccountList
            {...data}
            onPageChange={handlePageChange}
            currentPage={page}
            handleLimitChange={handleLimitChange}
            limit={limit}
            getVirtualAccounts={getVirtualAccounts}
          />
        )
      )}

      <AddVirtualAccount
        onSuccess={() => getVirtualAccounts()}
        open={openModal}
        onClose={() => setOpenModal(false)}
        // callBack={() => getVA(1, "")}
      />
    </Box>
  );
};

export default VirtualAccount;
