import React from "react";
import { Small } from "components/Typography";
import { getRoleColor, getRoleName } from "./utils";
import { Box } from "@mui/material";

function Badge({ role }) {
  return (
    <>
      <Box
        sx={{
          minWidth: "100px",
          fontSize: 10,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: ".2rem 1rem",
          backgroundColor: getRoleColor(role),
        }}
      >
        {getRoleName(role)}
      </Box>
    </>
  );
}

export default Badge;
