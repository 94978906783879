import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { H5, Paragraph } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import InvoiceListTable from "page-sections/activity-log/index";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components

import { WEBHOOK_API_URL } from "config";
import WebhookService from "services/WebhookService";
import { io } from "socket.io-client";
import ActivityLogServices from "services/ActivityLogService";
import { LoadingButton } from "@mui/lab";
import HiringRequestListTable from "page-sections/hiring-request/index";
import HiringRequestService from "services/HireRequestService";
import TradeHistoryList from "page-sections/user-list/TradeHistoryList";
import TradeServices from "services/TradeService";
import Prepaid2CoinList from "page-sections/prepaid2Coin/Prepaid2CoinList";
import Prepaid2CoinService from "services/Prepaid2CoinService";

const socket = io(WEBHOOK_API_URL);

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const Prepaid2Coin = (props) => {
  const [page, setPage] = useState(1);

  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItem, setFilteredItem] = useState(INVOICE_LIST);
  const [option, setOption] = React.useState("");

  const [data, setData] = useState(null);

  // get Trade History
  const getAllP2P = async (page = 1, limit = 100) => {
    setisLoading(true);
    const resp = await Prepaid2CoinService.getP2P(page, limit);
    console.log("resp", resp);
    setData(resp);
    setisLoading(false);
  };

  const reload = () => {
    setSearchValue("");
    getAllP2P();
  };

  const handlePageChange = (page, limit) => {
    setPage(page);
    getAllP2P(page, limit, "", "");
  };

  const handleChange = (event) => {
    setOption(event.target.value);
    getAllP2P(1, 100, event.target.value, "");
  };
  useEffect(() => {
    getAllP2P(1, 100, "", searchValue);
  }, [searchValue]);

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("Prepaid 2 Coin")}</H5>
        </Stack>

        <StyledStack alignItems="center" direction="row" gap={2}>
          {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              sx={{
                "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "10px !important", // Remove padding from the Select component
                  },
              }}
              value={option}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <Paragraph>All</Paragraph>
              </MenuItem>
              <MenuItem value={"released"}>
                {" "}
                <Paragraph>Released</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"cancelled"}>
                {" "}
                <Paragraph>Cancelled</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"disputed"}>
                <Paragraph>Disputed</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"paid"}>
                <Paragraph>Paid</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"new"}>
                <Paragraph>New</Paragraph>{" "}
              </MenuItem>
            </Select>
          </FormControl> */}
          <SearchInput
            disable_border="true"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data && (
          <Prepaid2CoinList
            {...data}
            onPageChange={handlePageChange}
            currentPage={page}
          />
        )
      )}
    </Box>
  );
};

export default Prepaid2Coin;
