import axios from "../utils/axios";
import { PUBLIC_REQUEST_KEY } from "../config";

const AuthService = {};

const public_request_headers = {};
public_request_headers[PUBLIC_REQUEST_KEY] = "true";

AuthService.login = function ({ email, password }) {
  return axios({
    url: "/auth",
    method: "POST",
    headers: public_request_headers,
    data: {
      email,
      password,
    },
  });
};

AuthService.ChangePassword = function (id, data) {
  return axios({
    url: `/auth/password/${id}`,
    method: "PUT",
    data,
  });
};

export default AuthService;
