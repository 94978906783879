import { SvgIcon } from "@mui/material";

const Twitch = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M10.59,20.41,12,19h3.17a2,2,0,0,0,1.42-.59l3.82-3.82A2,2,0,0,0,21,13.17V4a1,1,0,0,0-1-1H5.19a1,1,0,0,0-.93.65l-1,2.67A4.23,4.23,0,0,0,3,7.73V18a1,1,0,0,0,1,1H7v1a1,1,0,0,0,1,1H9.17A2,2,0,0,0,10.59,20.41ZM6,16V5H19v8.17L16.17,16h-4l-2,2H9V16Zm4.5-3h1a.5.5,0,0,0,.5-.5v-4a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v4A.5.5,0,0,0,10.5,13Zm5,0h1a.5.5,0,0,0,.5-.5v-4a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v4A.5.5,0,0,0,15.5,13Z" />
    </SvgIcon>
  );
};

export default Twitch;
