// import ApiKeys from "./ApiKeys";
import SyncDatabase from "./SyncDatabase";
import CronJob from "./cronJob/CronJob";
import ResetDatabse from "./ResetDatabase";
import Prepaid2CoinConfig from "./Prepaid2CoinConfig";
import FeeStructure from "./FeeStructure";
import FeeStructureNoones from "./FeeStructureNoones";
import Avtar from "./Avtar";

// import Billing from "./Billing";
// import ConnectedAccounts from "./ConnectedAccounts";
// import DeleteAccount from "./DeleteAccount";
// import Notifications from "./Notifications";
// import Password from "./Password";
// import Preferences from "./Preferences";
// import RecentDevices from "./RecentDevices";
// import Referrals from "./Referrals";
// import SocialAccounts from "./SocialAccounts";
// import Statements from "./Statements";
// import TwoStepVerification from "./TwoStepVerification";
const Tabs = {
  //   ApiKeys,
  SyncDatabase,
  CronJob,
  ResetDatabse,
  Prepaid2CoinConfig,
  FeeStructure,
  FeeStructureNoones,
  Avtar,
  //   Billing,
  //   ConnectedAccounts,
  //   DeleteAccount,
  //   Notifications,
  //   Password,
  //   Preferences,
  //   RecentDevices,
  //   Referrals,
  //   SocialAccounts,
  //   Statements,
  //   TwoStepVerification,
};
export default Tabs;
