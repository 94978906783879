import { Box, Button, IconButton, Stack } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import { formatCurrency } from "utils/currencies";
import CryptoImg from "components/shared/CryptoImg";
import cryptoAssets from "data/supportedAssets";
import CardBrandIcon from "components/shared/CardBrandIcon";
import FlexBox from "components/flexbox/FlexBox";

const BillingColumnShape = () => {
  const getPaymentMethod = (method) => {
    switch (method) {
      case "stripe":
        return "Credit card";
      case "wallet":
        return "Venva Wallet";
      case "lightning":
        return "Lightning Network";
    }
  };
  const getCryptoCode = (asset_id) => {
    return cryptoAssets.find(({ assetID }) => assetID == asset_id)?.code;
  };
  const getPaymentDescription = ({
    payment_method,
    payment_id,
    blockchain_txn,
    stripe_txn,
  }) => {
    let purpose = "new membership";
    if (payment_id.type === "subscription-renew") {
      purpose = "membership renewal";
    }
    if (payment_id.type === "subscription-upgrade") {
      purpose = "membership upgrade";
    }

    return (
      <>
        {blockchain_txn && (
          <>
            <FlexBox
              sx={{
                alignItems: "center",
              }}
            >
              <Box mr={1}>
                <CryptoImg
                  size={"20px"}
                  currency={getCryptoCode(blockchain_txn.assetId)}
                />
              </Box>
              {blockchain_txn.crypto_amount}{" "}
              {getCryptoCode(blockchain_txn.assetId)} paid for {purpose}.
            </FlexBox>
          </>
        )}
        {stripe_txn &&
          stripe_txn.txn_resp &&
          stripe_txn.txn_resp.default_payment_method &&
          stripe_txn.txn_resp.default_payment_method.card && (
            <>
              <FlexBox
                sx={{
                  alignItems: "center",
                }}
              >
                <Box mr={1}>
                  <CardBrandIcon
                    brand={
                      stripe_txn.txn_resp.default_payment_method.card.brand
                    }
                    size={"30px"}
                  />
                </Box>{" "}
                Paid with card ending{" "}
                {stripe_txn.txn_resp.default_payment_method.card.last4} for
                membership renewal.
                {/* <CryptoIcon crypto={getCryptoCode(blockchain_txn.assetId)} />{' '}
                {blockchain_txn.crypto_amount}{' '}
                {getCryptoCode(blockchain_txn.assetId)} paid for membership
                renewal. */}
              </FlexBox>
            </>
          )}
      </>
    );
  };
  return [
    {
      Header: "DATE",
      accessor: "createdAt",
      Cell: ({ value }) => (
        <Paragraph fontSize={13} color="text.secondary">
          <FormattedDateTime date={value} />
        </Paragraph>
      ),
    },
    {
      Header: "PAYMENT METHOD",
      accessor: "payment_method",
      Cell: ({ value }) => {
        return (
          <Paragraph fontSize={13} color="text.secondary">
            {getPaymentMethod(value)}{" "}
          </Paragraph>
        );
      },
    },
    {
      Header: "DESCRIPTION",
      accessor: "bbbb",
      Cell: ({ row }) => {
        return (
          <Paragraph fontSize={13} color="text.secondary">
            {getPaymentDescription(row.original)}
          </Paragraph>
        );
      },
    },
    {
      Header: "PLAN",
      accessor: "payment_id",
      Cell: ({ value }) => {
        return (
          <Paragraph fontSize={13} color="text.secondary">
            {Plan[value.data.plan].title}
          </Paragraph>
        );
      },
    },
    {
      Header: "AMOUNT",
      accessor: "nnnn",
      Cell: ({ row }) => {
        const { payment_id } = row.original;

        return (
          <Paragraph fontSize={13} color="text.secondary">
            {formatCurrency(payment_id.usd_amount)}
          </Paragraph>
        );
      },
    },
    {
      Header: "",
      accessor: "bnnb",
      Cell: ({ row }) => {
        const { payment_method, stripe_txn, ref_id } = row.original;

        return (
          <>
            {payment_method == "stripe" ? (
              <>
                {stripe_txn && (
                  //   <a
                  //     target="_blank"
                  //     rel="noreferrer"
                  //     href={
                  //       stripe_txn.txn_resp?.latest_invoice?.hosted_invoice_url
                  //     }
                  //     className="btn btn-sm btn-atlantis-primary"
                  //   >
                  //     View
                  //   </a>
                  <Button
                    component={Link} // Renders the button as a Link component
                    to={stripe_txn.txn_resp?.latest_invoice?.hosted_invoice_url} // The URL to navigate to
                    target="_blank" // Open the link in a new tab or window
                    rel="noopener noreferrer" // Security attributes
                    variant="outlined"
                    color="primary"
                    size="small"
                  >
                    View
                  </Button>
                )}
              </>
            ) : (
              <>
                <Link href={`/wallet/transactions/${ref_id}`}>
                  <a className="btn btn-sm btn-atlantis-primary">View</a>
                </Link>
              </>
            )}
          </>
        );
      },
    },
  ];
};

/* <StyledButton
              component={Link}
              to={`/dashboard/users/${_id}`}
              variant="outlined"
              color="primary"
              size="small"
            >
              View
            </StyledButton>*/
export default BillingColumnShape;

export const Plan = {
  individual: {
    title: "Individual Plan",
    description: (
      <p>
        Optimal for 10+ team size <br /> and new startups
      </p>
    ),
    price: 29.99,
    features: [
      { label: "1 vendor account", value: true },
      { label: "1 p2p exchange account connection", value: true },
      { label: "access to all features and app", value: true },
      { label: "no team members", value: false },
    ],
  },
  professional: {
    title: "Professional Plan",
    description: (
      <p>
        Optimal for 10+ team size <br /> and new startups
      </p>
    ),
    price: 49.99,
    features: [
      { label: "1 vendor account", value: true },
      { label: "1 p2p exchange account connection", value: true },
      { label: "access to all features and app", value: true },
      { label: "unlimited team members", value: true },
    ],
  },
};
