import React, { useState, useEffect } from 'react'
import { Button, Grid, styled, Select, MenuItem } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { SERVER_BASE_URL } from "../../config";
import CancelIcon from "@mui/icons-material/Cancel";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import Scrollbar from "components/ScrollBar";
import { H2, H6, Paragraph } from "components/Typography";
import { useFormik } from "formik";
import * as Yup from "yup"; // component props interface
import FormControl from "@mui/material/FormControl";
import country from 'data/country';
import Zones from "../../data/timezones.json";
import { LoadingButton } from "@mui/lab";
import RVAService from 'services/RealVAService';
import { Box, fontSize } from "@mui/system";
import { Type } from 'typescript';
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 650,
    minWidth: 200,
    [theme.breakpoints.down(325)]: {
        maxWidth: "100%",
    },
}));

const ImageContainer = styled(Box)`
    position: relative;
  `;

const AddRealVaModal = ({ open, onClose, onSuccess, edit, data }, props) => {
    const [files, setFiles] = useState([]);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": [],
        },
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    const timeZoneOptions = Object.keys(Zones.zones).map((zone) => zone);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const initialValues = {
        first_name: data && data.first_name ? data.first_name : "",
        last_name: data && data.last_name ? data.last_name : "",
        email: data && data.email ? data.email : "",
        profile_pic: data && data.profile_pic ? data.profile_pic : "",
        country: data && data.country ? data.country : "US",
        timezone: data && data.timezone ? data.timezone : "America/New_York",
    };

    const validations = {
        first_name: Yup.string().min(3, "Too Short").required("First_Name is Required!"),
        last_name: Yup.string().min(3, "Too Short").required("Last_Name is Required!"),
        country: Yup.string().required("Country is Required!"),
        timezone: Yup.string().required("Timezone is Required!"),
        email: Yup.string().required("Email is required")
    };
    useEffect(() => {
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    });
    const fieldValidationSchema = Yup.object().shape(validations);
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        touched,
        setValues,
        resetForm,
    } = useFormik({
        initialValues,
        validationSchema: fieldValidationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            var formdata = new FormData();
            formdata.append("first_name", values.first_name);
            formdata.append("last_name", values.last_name);
            formdata.append("email", values.email);
            formdata.append("country", values.country);
            formdata.append("timezone", values.timezone);
            formdata.append("password", values.password);
            formdata.append(
                "profile_pic",
                acceptedFiles[0] ? acceptedFiles[0] : JSON.stringify(values.profile_pic)
            );
            setIsLoading(true);

            let resp;
            if (edit) {
                resp = await RVAService.updateRVA(data._id, formdata);
            } else {
                resp = await RVAService.createRVA(formdata);
            }

            if (resp && resp.status === true) {
                resetForm();
                onClose();
                onSuccess();
                acceptedFiles.splice(0, acceptedFiles.length);
                setError("");
                setFiles([]);
                setDisplay(false);
            }

            setIsLoading(false);
        },
    });
    const [display, setDisplay] = useState(false);
    const handleDeleteProfile = () => {
        setDisplay(true);

        setValues({ ...values, profile_pic: null });
        setFiles([]);
    };
    const onhandleClose = () => {
        resetForm();
        onClose();
        setDisplay(false);
        onClose();
    };

    // edit 

    const [openEditModal, setOpenEditModal] = useState(false);
    const editUser = () => {
        setOpenEditModal(true);

    }
    return (
        <StyledAppModal open={open} handleClose={onClose}>
            <H2 mb={2}>{edit ? "Edit User" : "Add new Real Virtual Assistant"}</H2>
            <form onSubmit={handleSubmit}>
                <Scrollbar
                    style={{
                        maxHeight: 400,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <H6 mb={1}>First_Name</H6>
                            <AppTextField
                                fullWidth
                                size="small"
                                name="first_name"
                                placeholder="first_name"
                                value={values.first_name}
                                onChange={handleChange}
                                helperText={touched.first_name && errors.first_name}
                                error={Boolean(errors.first_name && touched.first_name)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <H6 mb={1}>Last_Name</H6>
                            <AppTextField
                                fullWidth
                                size="small"
                                name="last_name"
                                placeholder="last_name"
                                value={values.last_name}
                                onChange={handleChange}
                                helperText={touched.last_name && errors.last_name}
                                error={Boolean(errors.last_name && touched.last_name)}
                            />
                        </Grid>
                        {!edit && ( // Render email and password fields only if not in edit mode
                            <>
                                 <Grid item xs={6}>
                            <H6 mb={1}>Email</H6>
                            <AppTextField
                                fullWidth
                                size="small"
                                name="email"
                                placeholder="email"
                                value={values.email}
                                onChange={handleChange}
                                helperText={touched.email && errors.email}
                                error={Boolean(errors.email && touched.email)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <H6 mb={1}>Password</H6>
                            <AppTextField
                                fullWidth
                                size="small"
                                name="password"
                                placeholder="password"
                                value={values.password}
                                onChange={handleChange}
                                helperText={touched.password && errors.password}
                                error={Boolean(errors.password && touched.password)}
                            />
                        </Grid>
                            </>
                        )}
                     
                        <Grid item xs={6}>
                            <H6 mb={1}>Country</H6>
                            <FormControl sx={{ width: 290, padding: 0 }}>
                                <Select
                                    sx={{ height: 39 }}
                                    onChange={handleChange}
                                    name="country"
                                    value={values.country}
                                >
                                    {country.map(({ name, code }) => (
                                        <MenuItem key={name} value={code}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <H6 mb={1}>TimeZone</H6>
                            <FormControl sx={{ width: 290, padding: 0 }}>
                                <Select
                                    sx={{ height: 39 }}
                                    onChange={handleChange}
                                    value={values.timezone}
                                    name="timezone"
                                >
                                    {timeZoneOptions.map((zone) => (
                                        <MenuItem key={zone} value={zone}>
                                            {zone}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <H6 mb={1}>Profile Pic</H6>
                            {data && (
                                <ImageContainer
                                    style={display ? { display: "none" } : { display: "block" }}
                                >
                                    <img
                                        style={{
                                            width: `40px`,
                                            height: `40px`,
                                        }}
                                        src={`${SERVER_BASE_URL}/${data.profile_pic?.file_path}`}
                                        alt="img"
                                    />
                                    <CancelIcon
                                        style={{
                                            position: "absolute",
                                            top: "0%",
                                            left: "5%",
                                            cursor: "pointer",
                                            color: "red",
                                        }}
                                        onClick={() => handleDeleteProfile()}
                                    />
                                </ImageContainer>
                            )}
                            {
                                <Box
                                    style={
                                        display || !edit
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }
                                    sx={{
                                        border: "2px solid",
                                        borderColor: "primary.main",
                                        borderRadius: "6px",
                                        padding: "8px",
                                        textAlign: "center",
                                    }}
                                >
                                    <Box
                                        {...getRootProps({ className: "dropzone" })}
                                        sx={{
                                            padding: "20px 5px",
                                            border: "1px dashed ",
                                            borderRadius: "5px",

                                            borderColor: "primary.main",
                                        }}
                                    >
                                        <input
                                            name="profile_pic"
                                            type="file"
                                            multiple
                                            {...getInputProps()}
                                        />

                                        {files.length === 0 && (
                                            <Paragraph fontSize={13}>
                                                Drag 'n' drop some files here, or click to select files
                                            </Paragraph>
                                        )}
                                        <aside className="thumbsContainer">
                                            {files.length > 0 &&
                                                files.map((file) => (
                                                    <div className="thumb" key={file.name}>
                                                        <div className="thumbInner">
                                                            <img
                                                                style={{
                                                                    height: "40px",
                                                                    width: "40px",
                                                                }}
                                                                src={file.preview}
                                                                className="imgDropZone"

                                                            />
                                                        </div>
                                                    </div>
                                                ))}

                                        </aside>
                                    </Box>
                                </Box>
                            }

                        </Grid>
                    </Grid>
                </Scrollbar>
                <FlexBox justifyContent="flex-end" gap={2} marginTop={4}>
                    <Button
                        fullWidth
                        size="small"
                        variant="outlined"
                        onClick={onhandleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        fullWidth
                        size="small"
                        type="submit"
                        variant="contained"
                        loading={isLoading}
                    >
                        Save
                    </LoadingButton>
                </FlexBox>
            </form>
        </StyledAppModal>
    )
}

export default AddRealVaModal