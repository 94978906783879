import axios from "../utils/axios";

const ResetDatabaseService = {};

ResetDatabaseService.Resetdatabase = function () {
  return axios({
    url: `/manage`,
    method: "DELETE",
  });
};

export default ResetDatabaseService;
