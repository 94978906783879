import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5, Paragraph } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import InvoiceListTable from "page-sections/activity-log/index";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components

import { WEBHOOK_API_URL } from "config";
import WebhookService from "services/WebhookService";
import { io } from "socket.io-client";
import ActivityLogServices from "services/ActivityLogService";
import { LoadingButton } from "@mui/lab";
import ActivityLogListTable from "page-sections/activity-log/index";
import VendorAccountList from "page-sections/vendor-account/VendorAccountList";
import PaxfulAccountsService from "services/PaxfulAccountsService";

const socket = io(WEBHOOK_API_URL);

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const VendorAccount = (props) => {
  const [option, setOption] = useState("active");
  const [platformType, setPlatformType] = useState("all");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItem, setFilteredItem] = useState(INVOICE_LIST);

  // useEffect(() => {
  //   const result = searchByName(INVOICE_LIST, searchValue);
  //   setFilteredItem(result);
  // }, [searchValue]);

  const [data, setData] = useState(null);

  const getAccounts = async (
    page = 1,
    limit = 50,
    search = "",
    type = "active",
    platformType = "all"
  ) => {
    setisLoading(true);
    const resp = await PaxfulAccountsService.getAllAccount(
      page,
      limit,
      search,
      type,
      platformType
    );
    setData(resp);
    setPage(page);
    setisLoading(false);
  };

  useEffect(() => {
    getAccounts(page, limit, searchValue, option, platformType);
  }, []);

  const reload = () => {
    setOption("active");
    setSearchValue("");
    setPlatformType("all");
    getAccounts(page, limit, "");
  };

  const handlePageChange = (page) => {
    // setPage(page);
    getAccounts(page, limit, searchValue, option, platformType);
  };

  const handleLimitChange = (event) => {
    // setPage(1);
    setLimit(event.target.value);
    getAccounts(1, event.target.value, searchValue, option, platformType);
  };

  const handleTabChange = (event) => {
    console.log("type", event.target.value);
    // setOption(event.target.value);
    // setPage(1);
    // getActivityLog(1, limit, "", event.target.value);

    // getPaxfulTransactions(userdata._id, pg, limit, option, event.target.value);
  };

  const handleChange = (event) => {
    setOption(event.target.value);
    // setPage(1);
    setLimit(50);
    getAccounts(1, 50, searchValue, event.target.value, platformType);
  };

  const handleChangePlatform = (event) => {
    setPlatformType(event.target.value);
    // setPage(1);
    setLimit(50);
    getAccounts(1, 50, searchValue, option, event.target.value);
  };
  const handleSearch = (searchValue) => {
    setSearchValue(searchValue)
    getAccounts(1,50, searchValue, option, platformType);
  }
  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("P2P Accounts")}</H5>
        </Stack>

        <StyledStack alignItems="center" direction="row" gap={2}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              sx={{
                "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "10px !important", // Remove padding from the Select component
                  },
              }}
              value={platformType}
              onChange={handleChangePlatform}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"all"}>
                {" "}
                <Paragraph>All</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"paxful"}>
                {" "}
                <Paragraph>Paxful</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"noones"}>
                {" "}
                <Paragraph>Noones</Paragraph>{" "}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              sx={{
                "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "10px !important", // Remove padding from the Select component
                  },
              }}
              value={option}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <Paragraph>All</Paragraph>
              </MenuItem>
              <MenuItem value={"active"}>
                {" "}
                <Paragraph>Active</Paragraph>{" "}
              </MenuItem>
              <MenuItem value={"delete"}>
                {" "}
                <Paragraph>Deleted</Paragraph>{" "}
              </MenuItem>
            </Select>
          </FormControl>
          <SearchInput
            disable_border="true"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <LoadingButton
            onClick={() => reload()}
            loading={isLoading}
            variant="contained"
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        data && (
          <VendorAccountList
            {...data}
            onPageChange={handlePageChange}
            currentPage={page}
            handleLimitChange={handleLimitChange}
            limit={limit}
            getAccounts={getAccounts}
          />
        )
      )}
    </Box>
  );
};

export default VendorAccount;
