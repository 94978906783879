import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5 } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import InvoiceListTable from "page-sections/invoice/list";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components
import List from "./List";

import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import WalletService from "services/WalletService";
import { LoadingButton } from "@mui/lab";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const Transaction = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input

  // ================================
  // wallet Transactions
  // =================================
  const { userdata, transactions, setTrasnactions } = useContext(UserContext);
  const [isLoading, setisLoading] = useState(false);
  // const [transactions, setTrasnactions] = useState([]);

  const getWalletTransactions = async (id) => {
    setisLoading(true);
    const transaction = await WalletService.getWalletTransactions(id);
    setTrasnactions(transaction);
    setisLoading(false);
  };

  // console.log(transactions);

  useEffect(() => {
    getWalletTransactions(userdata._id);
  }, []);
  // =========================================

  const reload = () => {
    getWalletTransactions(userdata._id);
  };

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("Venva Wallet Transactions")}</H5>
        </Stack>
        <StyledStack alignItems="center" direction="row" gap={2}>
          <LoadingButton
            onClick={() => reload()}
            variant="contained"
            loading={isLoading}
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>
      ) : (
        transactions && <List transactionsList={transactions} />
      )}
    </Box>
  );
};

export default Transaction;
