import { Box, IconButton, Stack } from "@mui/material";
import { Paragraph, Small, Tiny } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import Platform from "components/shared/Platform";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
 function PaxfulAccountForJSONData({ item, size, hide }) {
  let paxful_avatar;
  // if (item.paxful_avatar) {
  // paxful_avatar = item?.paxful_avatar
  //   ? item?.paxful_avatar
  //   : JSON.parse(item?.paxful_profile)?.avatar_url;
  // } else {
  // paxful_avatar = item.paxful_profile.avatar_url;
  // }
  // console.log("paxful_avatar", paxful_avatar);

  return (
    <>
      <FlexBox>
        <FlexBox
          sx={{
            alignItems: "center",
            minWidth: "10px",
          }}
        >
          <PaxfulProfilePicForJSONData account={item} size={size} />
        </FlexBox>

        <Box
          sx={{
            marginLeft: "10px !important",
            minWidth: "120px",
          }}
        >
          <Tiny fontSize={12} fontWeight={500}>
            <a
              style={{
                marginLeft: "3px",
              }}
              href={`https://${item.platform}.com/user/` + item.paxful_username}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.paxful_username}
            </a>
          </Tiny>

          {!hide && (
            <FlexBox
              sx={{
                alignItems: "center",
              }}
            >
              {item.is_deleted ? (
                <Box>
                  <Small
                    sx={{
                      width: 100,
                      fontSize: 10,
                      color: "#fff",
                      borderRadius: "4px",
                      textAlign: "center",
                      padding: ".2rem 1rem",
                      backgroundColor: "error.main",
                    }}
                  >
                    DELETED
                  </Small>
                </Box>
              ) : (
                <Box>
                  <Small
                    sx={{
                      width: 100,
                      fontSize: 10,
                      color: "#fff",
                      borderRadius: "4px",
                      textAlign: "center",
                      padding: ".2rem 1rem",
                      backgroundColor:
                        item.is_active == true ? "success.main" : "error.main",
                    }}
                  >
                    {item.is_active == true ? "active" : " not active"}
                  </Small>
                </Box>
              )}
              {item?.is_delegate && (
                <CheckCircleIcon
                  sx={{
                    fontSize: "14px",
                    color: "success.main",
                    marginLeft: "5px",
                  }}
                />
              )}
            </FlexBox>
          )}
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          // ml={1}
        >
          <Platform size={20} platform={item.platform} />
        </Box>
      </FlexBox>
    </>
  );
}

function PaxfulProfilePicForJSONData({ account, size, buyer }) {
  console.log("avatarUrl", JSON.parse(account?.paxful_profile));
  const getPaxfulAvatar = (avatar) => {
    return avatar?.substring(avatar.lastIndexOf("/") + 1) == "avatar.png"
      ? "/static/avatar/blank.png"
      : avatar;
  };

  return (
    <>
      {buyer ? (
        <img
          style={{
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: "50%",
          }}
          src={getPaxfulAvatar(JSON.parse(account?.paxful_profile).avatar_url)}
          alt=""
        />
      ) : (
        <img
          style={{
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: "50%",
          }}
          src={
            JSON.parse(account?.paxful_profile).avatar_url
              ? JSON.parse(account?.paxful_profile).avatar_url
              : "/static/avatar/blank.png"
          }
          alt=""
        />
      )}
    </>
  );
}
export default PaxfulAccountForJSONData;