import { ArrowOutward, Drafts, Edit } from "@mui/icons-material";
import { IconButton, styled, Button, Box } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBox from "components/flexbox/FlexBox";
import { replaceNotificationTextTags } from "components/shared/AppNotification";
import AvatarGroupWithToggle from "components/shared/AvatarGroupWithToggle";
import CryptoImg from "components/shared/CryptoImg";
import CryptoSymbol from "components/shared/CryptoSymbol";
import { FormattedDateTime, FromNow } from "components/shared/FormattedDate";
import Platform from "components/shared/Platform";
import UserGroup from "components/shared/UserGroup";
import UserSymbol from "components/shared/UserSymbol";
import { H6, Paragraph, Small, Span, Tiny } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import Badge from "utils/Badge";
import EmailIcon from "@mui/icons-material/Email";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

// const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
//   maxWidth: 16,
//   fill: theme.palette.error.main,
// }));

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));

const columnShape = [
  {
    Header: " DATE",
    accessor: "createdAt",
    Cell: ({ value }) => {
      return (
        <>
          <Paragraph fontSize={13} color="text.secondary">
            {<FormattedDateTime date={value} />}
          </Paragraph>
          <Small
            sx={{
              width: 100,
              fontSize: 10,
              color: "#fff",
              borderRadius: "4px",
              textAlign: "center",
              padding: ".2rem 1rem",
              backgroundColor: "lightgrey",
              color: "black",
            }}
          >
            <FromNow date={value} />
          </Small>
        </>
      );
    },
  },
  {
    Header: "MESSAGE",
    accessor: "message",
    Cell: ({ row }) => {
      const { trade, message, buyer, subtext, transaction } = row.original;
      // console.log("__Rows__", row);
      return (
        <>
          <FlexBox>
            {row.original && row.original.transaction && (
              <Span mr={1}>
                {/* <CryptoImg
                  currency={row.original.transaction.crypto_symbol}
                  size={20}
                /> */}
                <CryptoSymbol id={row.original.transaction.assetId} />
              </Span>
            )}
            <Span sx={{ display: "flex", alignItems: "center" }}>
              <Small fontSize={15} color="text.secondary">
                {replaceNotificationTextTags(
                  message,
                  trade,
                  buyer,
                  transaction
                )}
              </Small>
              {buyer?.platform && (
                <div style={{ marginLeft: 8 }}>
                  <Platform platform={buyer?.platform} size={18} />
                </div>
              )}
            </Span>
          </FlexBox>

          <Box>
            {subtext &&
              replaceNotificationTextTags(subtext, trade, buyer, transaction)}
          </Box>
        </>
      );
    },
  },
  // {
  //   Header: "SUBTEXT",
  //   accessor: "row",
  //   Cell: ({ row }) => {
  //     const { trade, subtext, buyer } = row.original;

  //     return (
  //       <Paragraph>
  //         {replaceNotificationTextTags(subtext, trade, buyer)}
  //       </Paragraph>
  //     );
  //   },
  // },
  {
    Header: "",
    accessor: "is_read",
    Cell: ({ value }) => {
      let color = !value ? "primary.main" : "text.secondary";
      return (
        <>
          {!value ? (
            <EmailIcon
              sx={{
                color,
              }}
            />
          ) : (
            <Drafts
              sx={{
                color,
              }}
            />
          )}
        </>
        // <FlexBox
        //   sx={{
        //     justifyContent: "flex-start",
        //   }}
        // >
        //   {value && value.length > 0 ? <UserGroup users={value} /> : null}
        //   {/* {value && value.length > 0 ? (
        //     <AvatarGroupWithToggle users={value} max={5} />
        //   ) : null} */}
        // </FlexBox>
      );
    },
  },
  // {
  //   Header: "Account",
  //   accessor: "account",
  //   Cell: ({ value }) => {
  //     console.log("value", value);
  //     const image =
  //       value != undefined
  //         ? JSON.parse(value?.paxful_profile)?.avatar_url
  //         : null;
  //     console.log("value", image);
  //     return (
  //       <FlexBox justifyContent="start" alignItems="center">
  //         {image && (
  //           <img
  //             style={{
  //               width: `30px`,
  //               height: `30px`,
  //               borderRadius: "50%",
  //             }}
  //             src={image}
  //             alt=""
  //           />
  //         )}
  //         <Paragraph ml={1} mr={1} fontSize={13} color="text.secondary">
  //           {value?.paxful_username}
  //         </Paragraph>
  //         {value?.account_platform && (
  //           <Platform size={20} platform={value?.account_platform} />
  //         )}
  //       </FlexBox>
  //     );
  //   },
  // },
  // {
  //   Header: "User",
  //   accessor: "user",
  //   Cell: ({ value }) => {
  //     return (
  //       <FlexBox>
  //         <UserSymbol
  //           role={value.role}
  //           name={value.first_name ? value.first_name : value.email}
  //         />

  //         <Box
  //           sx={{
  //             marginLeft: "10px !important",
  //           }}
  //         >
  //           <H6>{value.name}</H6>
  //           <Tiny fontSize={12} fontWeight={500}>
  //             {value.first_name + " " + value.last_name}
  //           </Tiny>

  //           <Box direction="row" spacing={2} display={"flex"}>
  //             {/* <Box ml={2}>
  //               {value.is_deleted ? (
  //                 <Small
  //                   sx={{
  //                     width: 100,
  //                     fontSize: 10,
  //                     color: "#fff",
  //                     borderRadius: "4px",
  //                     textAlign: "center",
  //                     padding: ".2rem 1rem",
  //                     backgroundColor: "error.main",
  //                   }}
  //                 >
  //                   DELETED
  //                 </Small>
  //               ) : (
  //                 <LastActive date={value.last_active} />
  //               )}
  //             </Box> */}
  //           </Box>
  //         </Box>
  //       </FlexBox>
  //     );
  //   },
  // },
  // {
  //   Header: "Title",
  //   accessor: "title",
  //   Cell: ({ value }) => {
  //     return (
  //       <Paragraph ml={1} mr={1} fontSize={13} color="text.secondary">
  //         {value}
  //       </Paragraph>
  //     );
  //   },
  // },
  // {
  //   Header: "Message",
  //   accessor: "message",
  //   Cell: ({ value }) => {
  //     return (
  //       <Paragraph ml={1} mr={1} fontSize={13} color="text.secondary">
  //         {value}
  //       </Paragraph>
  //     );
  //   },
  // },
  // {
  //   Header: "Subtext",
  //   accessor: "subtext",
  //   Cell: ({ value }) => {
  //     return (
  //       <Paragraph ml={1} mr={1} fontSize={13} color="text.secondary">
  //         {value}
  //       </Paragraph>
  //     );
  //   },
  // },
];
export default columnShape;
