import { Box } from "@mui/material";
import { Small } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import PaxfulProfilePic from "./PaxfulProfilePic";
import Platform from "./Platform";

export function ProfileImageWithAccount({
  account,
  buyer,
  platform,
  platformSize,
  pfSize,
}) {
  return (
    <>
      <Box
        sx={{
          position: "relative",
        }}
        className="me-3"
      >
        {account && (
          <PaxfulProfilePic account={account} buyer={buyer} size={pfSize} />
        )}
        <Box
          sx={{
            position: "absolute",
            top: -5,
            left: 20,
          }}
        >
          {platform && <Platform size={platformSize} platform={platform} />}
        </Box>
      </Box>
    </>
  );
}
export function BuyerSymbol({ username, color, size }) {
  return (
    <FlexBox
      color="black"
      sx={{
        height: size,
        width: size,
        borderRadius: "50%",
        backgroundColor: color,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Small fontSize={12}>{username.slice(0, 1).toUpperCase()}</Small>
    </FlexBox>
  );
}
BuyerSymbol.defaultProps = {
  color: "lightgray",
  size: "30px",
};
