
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5 } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import { useNavigate } from "react-router-dom";
import RealVaList from 'page-sections/real-va/RealVaList';
import { EventEmitter } from 'eventemitter3';
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react'
import RVAService from "services/RealVAService";
import AddRealVaModal from "page-sections/real-va/AddRealVaModal";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const RealVA = () => {

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState(null);

  const getRVA = async ( page = 1,
    limit = 50,
    type = "active",
    search = ""
    ) => {
    setisLoading(true);
    const resp = await RVAService.getRVA(page, search, limit, type);
    setData(resp);
    setisLoading(false);

  }

  useEffect(() => {
    getRVA(1, 50, searchValue);
  }, [searchValue]);

  const handlePageChange =  (page) => {
    setPage(page);
    getRVA(page, limit, searchValue);
  }

  const handleLimitChange = (event) => {
    setPage(1);
    setLimit(event.target.value);
    getRVA(page, event.target.value, searchValue);
  }

  return (
    <Box>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("Real Virtual Assistants")}</H5>
        </Stack>
        <StyledStack alignItems="center" direction="row" gap={2}>
          <SearchInput
            disable_border="true"
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button
          onClick={() => setOpenModal(true)}
            variant="contained"
            startIcon={<Add />}
            sx={{
              flexShrink: 0,
            }}
          >
            Add New
          </Button>
         <AddRealVaModal
         onSuccess={() => getRVA(1, "")}
            open={openModal}
            onClose={() => setOpenModal(false)}
         />
        </StyledStack>
      </FlexBetween>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {" "}
          <CircularProgress />
        </Box>

      ) : (
        data && (
          <RealVaList {...data} 
          onPageChange={handlePageChange}
          currentPage={page}
          handleLimitChange={handleLimitChange}
          limit={limit} getRVA={getRVA} />
        )
      )}
    </Box>
  )
}

export default RealVA