import React from "react";
import { getOfferColor } from "utils/utils";
import { Small } from "components/Typography";
import { getOfferTag } from "utils/utils";
import { Box } from "@mui/material";

function OfferType({ value }) {
  return (
    <Box>
      <Small
        sx={{
          width: 100,
          fontSize: 10,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: ".2rem 1rem",
          backgroundColor: getOfferColor(value),
        }}
      >
        {getOfferTag(value)}
      </Small>
    </Box>
  );
}

export default OfferType;
