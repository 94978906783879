import * as React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import ATooltip from "./ATooltip";
import PaxfulAccount from "./PaxfulAccount";
import FlexBox from "components/flexbox/FlexBox";
import PaxfulProfilePic from "./PaxfulProfilePic";
import Platform from "./Platform";
import { Box } from "@mui/material";

function AccountGroup({ accounts }) {
  console.log("ACCOUNTS", accounts);
  return (
    <>
      <FlexBox max={5}>
        {accounts &&
          accounts.map((account) => {
            return (
              <>
                <ATooltip content={<PaxfulAccount item={account} size={30} />}>
                  {/* <PaxfulProfilePic account={account} size={25} /> */}
                  {/* <Avatar
                    sx={{ width: 25, height: 25 }}
                    alt={username}
                    src={`${avatar_url}`}
                  /> */}

                  <Box
                    sx={{
                      display: "block",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        display: "inline",
                        marginRight: "4px",
                      }}
                    >
                      <PaxfulProfilePic
                        size={22}
                        account={account.paxful_profile}
                        buyer={true}
                        className="bg-light"
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: -14,
                          bottom: 0,
                          left: 18,
                          right: 0,
                        }}
                      >
                        <Platform platform={account.platform} size={15} />
                      </Box>
                    </Box>
                  </Box>
                </ATooltip>
              </>
            );
          })}
      </FlexBox>
    </>
  );
}

export default AccountGroup;
