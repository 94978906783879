import { ContentCopy, Edit } from "@mui/icons-material";
import { IconButton, styled, Button, Box, Tooltip } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Span } from "components/Typography";
import CheckmarkCircle from "icons/CheckmarkCircle";
import FlexBox from "components/flexbox/FlexBox";
import { Link } from "react-router-dom";
import { Tiny } from "components/Typography";
import { ArrowOutward, ArrowRight } from "@mui/icons-material";

import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag from "components/shared/CountryFlag";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import TradeStatus from "components/shared/TradeStatus";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import Platform from "components/shared/Platform";
import BuyerProfilePic from "components/shared/BuyerProfilePic";
import PaxfulAccount from "components/shared/PaxfulAccount";
import { FlagAndPlatform } from "page-sections/profiles/profile/Trades/PartnerDetail";
import UserSymbol from "components/shared/UserSymbol";
import OfferType from "components/shared/OfferType";
import { formatCurrency } from "utils/currencies";
import CryptoBalance from "components/shared/CryptoBalance";
import FormattedBalance from "components/shared/FormattedBalance";
import CustomPopover from "components/CustomPopover";
import { VATooltip } from "page-sections/profiles/profile/Trades/Chat";
import ATooltip from "components/shared/ATooltip";
import UserInfo from "components/shared/UserInfo";
import platforms from "data/platforms";
import { isEmptyArray } from "formik";
import TradeStatIcons from "components/shared/TradeStatIcons";
import notification from "helpers/notifications";
import { displayPartsToString } from "typescript";
import CreatedBy from "components/shared/CreatedBy";
import TimeElapsedTimer from "components/TimeElapsedTimer";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)(() => ({
  padding: 4,
  minWidth: 60,
  marginRight: 3,
}));
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};
const TradeHistoryColumnShape = [
  {
    Header: "ID",
    accessor: "_id",
    Cell: ({ value }) => (
      <>
        <Box
          sx={{
            maxWidth: "40px",
            marginRight: "10px",
          }}
        >
          <ATooltip content={value}>
            <Span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyTextToClipboard(value);
              }}
            >
              {value.slice(0, 2)}...
              {value.slice(value.length - 3, value.length)}
            </Span>
          </ATooltip>
        </Box>
      </>
    ),
  },
  {
    Header: () => <Box sx={{ marginLeft: "15px" }}>DATE</Box>,
    accessor: "completedAt",
    Cell: ({ row }) => {
      const { completedAt, startedAt, status, createdAt } = row.original;
      let date = completedAt ? completedAt : startedAt;
      return (
        <Box
          sx={{
            minWidth: "148px",
          }}
        >
          <Paragraph fontSize={13} color="text.secondary">
            <FormattedDateTime date={date} />
          </Paragraph>
          <Small
            sx={{
              width: 100,
              fontSize: 10,
              color: "#fff",
              borderRadius: "4px",
              textAlign: "center",
              padding: ".2rem 1rem",
              backgroundColor: "lightgrey",
              color: "black",
            }}
          >
            <FromNow date={date} />
          </Small>
        </Box>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "15px" }}> VENDOR</Box>,
    accessor: "user",
    Cell: ({ value }) => {
      const user = value[0];
      return (
        <>
          <FlexBox sx={{ alignItems: "center" }}>
            <span>
              <UserSymbol
                user={user}
                size={25}
                showOnline={true}
                isBottomZero={0}
              />
            </span>
            <Box
              sx={{
                marginLeft: "10px !important",
              }}
            >
              <Link to={`/dashboard/users/${user._id}`}>{user.username}</Link>
            </Box>
          </FlexBox>
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "15px" }}> ACCOUNT</Box>,
    accessor: "account.paxful_username",
    Cell: ({ row }) => {
      const { paxful_username, platform } = row.original.account;
      return <>{<PaxfulAccount item={row.original.account} size={25} />}</>;
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "14px" }}> TRADE HASH</Box>,
    accessor: "trade.trade_hash",
    Cell: ({ row, onClick }) => {
      const {
        user,
        account,
        trade,
        status,
        trade_chat,
        unread_messages,
        unread_files,
        otp_verified,
        trade_security,
        trade_attachments,
      } = row.original;
      const { _id } = user[0];

      return (
        <>
          {/* <Paragraph
            sx={{
              cursor: "pointer",
            }}
            color="primary.main"
            onClick={() => onClick(trade.trade_hash)}
          >
            {trade.trade_hash}
          </Paragraph>{" "} */}

          {/* {status == "paid" ? (
            <Paragraph fontSize={12} fontWeight={"bold"}>
              {trade.trade_hash}
            </Paragraph>
          ) : ( */}
          <Link to={`/dashboard/users/${_id}?trade=${trade.trade_hash}`}>
            {trade.trade_hash}
          </Link>
          {/* )} */}
          <FlexBox mt={1} minWidth={110}>
            <TradeStatIcons
              chatCount={
                trade_chat[0] && trade_chat[0]?.messages.length > 0
                  ? trade_chat[0]?.messages.length
                  : 0
              }
              chatDisabled={Number(unread_messages) < 3}
              chatBlink={Number(unread_messages) > 3}
              attachmentCount={
                trade_attachments[0] &&
                trade_attachments[0]?.messages.length > 0
                  ? trade_attachments[0]?.messages.length
                  : 0
              }
              attachmentDisabled={Number(unread_files) < 3}
              attachmentBlink={Number(unread_files) > 3}
              otp_verified={otp_verified}
              trade_security={trade_security}
            />
          </FlexBox>
        </>
      );
    },
  },

  {
    Header: () => <Box sx={{ marginLeft: "12px" }}> PAYMENT METHOD</Box>,
    accessor: "trades",
    Cell: ({ row }) => {
      const { trade } = row.original;
      return (
        <FlexBox>
          <Paragraph mr={2} fontSize={12}>
            {" "}
            {trade.payment_method_name}
          </Paragraph>
          {/* <OfferType value={trade.offer_type} /> */}
        </FlexBox>
      );
    },
  },
  {
    Header: "",
    accessor: "fgf",
    Cell: ({ row }) => {
      const { trade } = row.original;
      return (
        <FlexBox>
          <OfferType value={trade.offer_type} />
        </FlexBox>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "14px" }}>PARTNERS </Box>,
    accessor: "buyer",
    Cell: ({ row }) => {
      const { buyer, partner_location, account } = row.original;

      return (
        <FlexBox justifyContent="start" alignItems="center">
          <PaxfulProfilePic size={25} account={buyer[0].profile} buyer />
          {/* {buyer[0] && <BuyerProfilePic size={30} buyer={buyer[0]} />} */}

          {buyer[0].username && (
            <>
              <FlexBox fontSize={12} fontWeight={500}>
                <a
                  style={{
                    marginLeft: "3px",
                  }}
                  href={
                    `https://${account.platform}.com/user/` + buyer[0].username
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {buyer[0].username}
                </a>
                <a>
                  {/* <ArrowOutward
                    sx={{
                      fontSize: 14,
                      marginLeft: "1px",
                    }}
                  /> */}
                </a>
              </FlexBox>
              {partner_location && (
                <FlagAndPlatform
                  CountryName={
                    partner_location.detected_location.localized_name
                  }
                  country={partner_location.detected_location.iso}
                  platform={account.platform}
                />
              )}
            </>
          )}
        </FlexBox>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "10px" }}>FEE </Box>,
    accessor: "fbhdbf",
    Cell: ({ row }) => {
      let item = row.original;
      return (
        <>
          {item?.atlantis_fee_details?.atlantis_fee ? (
            <Paragraph color="text.secondary" fontSize={15} fontWeight="600">
              {formatCurrency(item.atlantis_fee_details.atlantis_fee)}{" "}
            </Paragraph>
          ) : (
            <Paragraph color="text.secondary" fontWeight="600" fontSize={15}>
              $0
            </Paragraph>
          )}
        </>
      );
    },
  },
  {
    Header: () => <Box sx={{ marginLeft: "14px" }}> AMOUNT </Box>,
    accessor: "amount",
    Cell: ({ row }) => {
      let item = row.original;
      let user = row.original.user[0];
      return (
        <>
          <FlexBox
            sx={{
              minHeight: "70px",
              minWidth: "150px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Small fontWeight={"bold"}>
              <FormattedBalance
                offer_type={item.trade.offer_type}
                code={item.trade.fiat_currency_code}
                amount={item.trade.fiat_amount_requested}
                status={item.status}
                user={user}
              />
            </Small>
            <span className="cursor-default text-gray-700">
              <CryptoBalance
                offer_type={item.trade.offer_type}
                code={item.trade.crypto_currency_code}
                amount={item.trade.crypto_amount_total}
                status={item.status}
                user={user}
              />
            </span>
            {item.status !== "cancelled" && (
              <div className="fs-7 cursor-default">
                Profit:{" "}
                <Small color="success.main">
                  {formatCurrency(
                    item.estimated_profit,
                    item.trade.fiat_currency_code
                  )}
                </Small>
              </div>
            )}
          </FlexBox>
        </>
      );
    },
  },
  {
    Header: "STATUS",
    accessor: "status",
    Cell: ({ row }) => {
      const {
        releasedBy,
        status,
        vaActivity,
        hireRequestId,
        is_synced,
        account,
        trade,
        buyer,
        user,
      } = row.original;
      // console.log("RoWW", row);
      return (
        <Box
          sx={{
            minWidth: "110px",
          }}
        >
          {<TradeStatus _trade={row.original} />}
          {row.original.status === "paid" && (
            <>
              <ATooltip content="Time Elapsed">
                <Paragraph color="primary.main" fontSize={12} fontWeight={600}>
                  <TimeElapsedTimer
                    start={row.original.startedAt}
                    id={row.original._id}
                  />
                </Paragraph>
              </ATooltip>
            </>
          )}

          {releasedBy?.length > 0 && status == "released" && (
            <>
              <Box>
                <Small
                  sx={{
                    display: "block",
                  }}
                  className="text-muted"
                >
                  Released by:
                </Small>
                <CreatedBy user={user[0]} otherUser />
                {/* <ATooltip content={<UserInfo user={user[0]} is_live={false} />}>
                  <Paragraph
                    fontWeight="bold"
                    fontSize="8"
                    color="primary.main"
                    className="fs-7 fw-bolder text-primary cursor-pointer"
                  >
                    {" "}
                    {user[0].first_name}
                  </Paragraph>
                </ATooltip> */}
                {/* {releasedBy[0].role === "va" ? (
                  <ATooltip
                    content={
                      <UserInfo
                        user={releasedBy[0]}
                        hireRequestId={hireRequestId}
                      />
                    }
                  >
                    <Paragraph
                      color={"primary.main"}
                      fontSize={11}
                      className="fs-7 fw-bolder text-primary cursor-pointer"
                    >
                      {" "}
                      {releasedBy[0].first_name}
                    </Paragraph>
                  </ATooltip>
                ) : (
                  <ATooltip content={<UserInfo user={releasedBy[0]} />}>
                    <Paragraph
                      color={"primary.main"}
                      fontSize={11}
                      className="fs-7 fw-bolder text-primary cursor-pointer"
                    >
                      {" "}
                      {releasedBy[0].first_name}
                    </Paragraph>
                  </ATooltip>
                )} */}
              </Box>
            </>
          )}
          {!releasedBy?.length > 0 && status == "released" && is_synced && (
            <Box>
              {" "}
              <Paragraph color={"text.secondary"} fontSize={11} mt={1}>
                Synced from {platforms[account.platform].title}
              </Paragraph>{" "}
            </Box>
          )}

          {!releasedBy?.length > 0 &&
            status == "released" &&
            !is_synced &&
            trade.offer_type == "sell" && (
              <Box className="fs-8 release d-block">
                <Small color="text.secondary" fontSize={11}>
                  Released on {platforms[account.platform].title}
                </Small>
              </Box>
            )}
          {!releasedBy?.length > 0 &&
            status == "released" &&
            !is_synced &&
            trade.offer_type == "buy" && (
              <FlexBox mt={1}>
                Released by{" "}
                <ATooltip
                  content={
                    <FlexBox sx={{ alignItems: "center" }}>
                      <Paragraph color={"text.secondary"} fontSize={11}>
                        {buyer[0].username}
                      </Paragraph>
                      <Small ml={1}>
                        <Platform platform={account.platform} size={15} />
                      </Small>
                    </FlexBox>
                  }
                >
                  <Box ml={1} style={{ position: "relative" }}>
                    <PaxfulProfilePic
                      account={buyer[0].profile}
                      size={15}
                      buyer
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-5px",
                        right: "-10px",
                      }}
                    >
                      <Platform
                        size={15}
                        platform={account.platform}
                        className="h-15px platform-icon"
                      />
                    </Box>
                  </Box>
                </ATooltip>
              </FlexBox>
            )}
        </Box>
      );
    },
  },
];
export default TradeHistoryColumnShape;
