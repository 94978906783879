import { AUTH_TOKEN } from '../config';

export function isLoggedIn() {
  const token = localStorage.getItem(AUTH_TOKEN);
  return token !== null;
}

export function setToken(token) {
  localStorage.setItem(AUTH_TOKEN, token);
}

export function getToken() {
  return localStorage.getItem(AUTH_TOKEN);
}

export function clearToken() {
  localStorage.removeItem(AUTH_TOKEN);
}

//-
