import { useEffect, useState } from "react";
import { Button, Grid, MenuItem, Select, styled } from "@mui/material";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import Scrollbar from "components/ScrollBar";
import { H2, H6 } from "components/Typography";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosInstance from "utils/axios";
import * as Yup from "yup"; // component props interface
import UserService from "services/UserService";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 450,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

const PlanModal = ({ open, onClose, edit, data, Password, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  //   const [value, setValue] = useState(dayjs("2022-04-17"));

  const initialValues = {
    plan: data && data.plan ? data.plan : "",
    subscriptionStartedAt:
      data && data.subscriptionStartedAt ? data.subscriptionStartedAt : "",
    subscriptionEndsAt:
      data && data.subscriptionEndsAt ? data.subscriptionEndsAt : "",
    autoRenew:
      data && data.autoRenew ? "1" : data.autoRenew == false ? "0" : "",
  };
  const fieldValidationSchema = Yup.object().shape({
    plan: Yup.string().required("plan is Required!"),
    // subscriptionStartedAt: Yup.date().required(
    //   "subscriptionStartedAt is Required!"
    // ),
    subscriptionEndsAt: Yup.date().required("subscriptionEndsAt is Required!"),
    // autoRenew: Yup.string().required("autoRenew is Required!"),
  });

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    setValues,
  } = useFormik({
    initialValues,

    validationSchema: fieldValidationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      setIsLoading(true);
      const resp = await UserService.updateUserPlan(data._id, values);
      if (resp && resp.status === true) {
        resetForm();
        onClose();
        onSuccess();
      }
      setIsLoading(false);
    },
  });

  const onCloseForm = () => {
    resetForm();
    onClose();
  };
  return (
    <StyledAppModal
      sx={{
        minWidth: 650,
        minHeight: 400,
      }}
      open={open}
      handleClose={onClose}
    >
      <H2 mb={2}>Plan Change</H2>

      <form onSubmit={handleSubmit}>
        <Scrollbar
          style={{
            maxHeight: 400,
          }}
        >
          <FlexBox justifyContent="flex-end" gap={2} marginTop={1}>
            <Grid item xs={6}>
              <H6 mb={1}>Plan</H6>

              <FormControl sx={{ width: 290, padding: 0 }}>
                <Select
                  sx={{ height: 39 }}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setValues((prevValues) => ({
                      ...prevValues,
                      plan: newValue,
                    }));
                  }}
                  name="plan"
                  value={values.plan}
                  displayEmpty
                  renderValue={(selected) => {
                    console.log("selected", selected);

                    if (!selected) {
                      return <>Choose a plan</>;
                    }

                    return selected;
                  }}
                  error={Boolean(touched.plan) && Boolean(errors.plan)}
                  helperText={touched.plan && errors.plan}
                >
                  <MenuItem value="" disabled>
                    Select a Plan For Vendor
                  </MenuItem>
                  <MenuItem value={"professional"}>Professional</MenuItem>
                  <MenuItem value={"individual"}>Individual</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <H6 mb={1}>Auto Renew</H6>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={values.autoRenew}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setValues((prevValues) => ({
                      ...prevValues,
                      autoRenew: newValue,
                    }));
                  }}
                  error={
                    Boolean(touched.autoRenew) && Boolean(errors.autoRenew)
                  }
                  helperText={touched.autoRenew && errors.autoRenew}
                >
                  <FlexBox>
                    <FormControlLabel
                      disabled
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </FlexBox>
                </RadioGroup>
              </FormControl>
            </Grid>
          </FlexBox>
          <FlexBox justifyContent="flex-end" gap={2} marginTop={1}>
            <Grid item xs={6}>
              <H6 mb={1}>Subscription Ends At</H6>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <DemoContainer components={["DatePicker"]}> */}
                <DatePicker
                  name="subscriptionEndsAt"
                  value={dayjs(values.subscriptionEndsAt)}
                  onChange={(newValue) =>
                    setValues((values) => ({
                      ...values,
                      subscriptionEndsAt: newValue.toDate(),
                    }))
                  }
                  error={
                    Boolean(touched.subscriptionEndsAt) &&
                    Boolean(errors.subscriptionEndsAt)
                  }
                  helperText={
                    touched.subscriptionEndsAt && errors.subscriptionEndsAt
                  }
                />
                {/* </DemoContainer> */}
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              {/* <H6 mb={1}>Subscription Started At</H6>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    name="subscriptionStartedAt"
                    value={dayjs(values.subscriptionStartedAt)}
                    onChange={(newValue) =>
                      setValues((values) => ({
                        ...values,
                        subscriptionStartedAt: newValue.toDate(),
                      }))
                    }
                    error={
                      Boolean(touched.subscriptionStartedAt) &&
                      Boolean(errors.subscriptionStartedAt)
                    }
                    helperText={
                      touched.subscriptionStartedAt &&
                      errors.subscriptionStartedAt
                    }
                  />
                </DemoContainer>
              </LocalizationProvider> */}
            </Grid>
          </FlexBox>
        </Scrollbar>

        <FlexBox justifyContent="flex-end" gap={2} marginTop={4}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={onCloseForm}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="small"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </FlexBox>
      </form>
    </StyledAppModal>
  );
};

export default PlanModal;
