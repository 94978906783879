import SvgIcon from "@mui/material/SvgIcon";

function Convert(props) {
  return (
    <SvgIcon {...props} style={{ color: "skyblue" }}>
      {/* <span className="svg-icon svg-icon-primary svg-icon-2"> */}
      <svg
        width="20"
        height="20"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 13V13.5C21 16 19 18 16.5 18H5.6V16H16.5C17.9 16 19 14.9 19 13.5V13C19 12.4 19.4 12 20 12C20.6 12 21 12.4 21 13ZM18.4 6H7.5C5 6 3 8 3 10.5V11C3 11.6 3.4 12 4 12C4.6 12 5 11.6 5 11V10.5C5 9.1 6.1 8 7.5 8H18.4V6Z"
          fill="currentColor"
        />
        <path
          opacity="0.3"
          d="M21.7 6.29999C22.1 6.69999 22.1 7.30001 21.7 7.70001L18.4 11V3L21.7 6.29999ZM2.3 16.3C1.9 16.7 1.9 17.3 2.3 17.7L5.6 21V13L2.3 16.3Z"
          fill="currentColor"
        />
      </svg>
      {/* </span> */}
    </SvgIcon>
  );
}

export default Convert;
