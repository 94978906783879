import axios from "../utils/axios";

const AtlantisRevenueService = {};

AtlantisRevenueService.getATRevenue = function (page, limit, search) {
  return axios({
    url: `/atlantis-revenue?page=${page}&limit=${limit}&search=${search}`,
    method: "GET",
  });
};

export default AtlantisRevenueService;
