import { Button, IconButton, Stack } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import styled from "@emotion/styled";

const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;

export const InvoiceColumnShape = (onCellClick, handleViewButton) => [
  {
    Header: "ACCOUNT ID",
    accessor: "id",
    Cell: ({ value }) => <PaxfulAccountPreview account_id={value} />,
  },
  {
    Header: "DATE",
    accessor: "createdAt",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        <FormattedDateTime date={value} />
      </Paragraph>
    ),
  },
  {
    Header: "TYPE",
    accessor: "response.type",
    Cell: ({ value }) => (
      <Small
        sx={{
          width: 100,
          fontSize: 10,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: ".2rem 1rem",
          backgroundColor: "grey.500", //
        }}
      >
        {value ? value : "No type"}
      </Small>
    ),
  },
  {
    Header: "TRADE HASH",
    accessor: "response.payload.trade_hash",
    Cell: ({ row }) => {
      const { id, response } = row.original;
      return (
        <Tiny fontSize={12} fontWeight={500}>
          {/* <a
            style={{
              marginLeft: "3px",
            }}
            // href={"https://paxful.com/trade/" + value}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
            {value ? (
              <ArrowOutward
                sx={{
                  fontSize: 14,
                  marginLeft: "1px",
                }}
              />
            ) : null}
          </a> */}
          {response && (
            <TradePreview
              trade_hash={response?.payload?.trade_hash}
              account_id={id}
            />
          )}
        </Tiny>
      );
    },
  },
  {
    Header: "STATUS",
    accessor: "is_delivered",
    Cell: ({ value }) => (
      <Small
        sx={{
          width: 100,
          fontSize: 10,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: ".2rem 1rem",
          backgroundColor: value === true ? "success.main" : "error.main",
        }}
      >
        {value == true ? "success" : "Fail"}
      </Small>
    ),
  },
  {
    Header: "DELIVERY TYPE",
    accessor: "is_resent",
    Cell: ({ value }) => (
      <Small
        sx={{
          width: 100,
          fontSize: 10,
          color: "#fff",
          borderRadius: "4px",
          textAlign: "center",
          padding: ".2rem 1rem",
          backgroundColor: value === true ? "warning.main" : "info.main",
        }}
      >
        {value == true ? "success" : "Intial"}
      </Small>
    ),
  },
  {
    Header: "NUM RETRIES",
    accessor: "num_retries",
    Cell: ({ value }) => {
      return (
        <Paragraph fontSize={13} color="text.secondary">
          {value}
        </Paragraph>
      );
    },
  },
  {
    Header: "ACTIONS",
    accessor: "saidbsakjb",
    Cell: ({ row }) => {
      return (
        <>
          <StyledButton
            sx={{
              ml: 2,
            }}
            onClick={() => handleViewButton(row.original)}
            variant="outlined"
            color="primary"
            size="small"
          >
            View
          </StyledButton>
          <StyledButton
            sx={{
              ml: 2,
            }}
            onClick={() => onCellClick(row.original._id)}
            variant="outlined"
            color="primary"
            size="small"
          >
            Resend
          </StyledButton>
        </>
      );
    },
  },

  // {
  //   Header: "Edit",
  //   // accessor: "",
  //   Cell: ({ value }) => {
  //     return (
  //       <Paragraph fontSize={13} color="text.secondary">
  //         ...
  //       </Paragraph>
  //     );
  //   },
  // },

  // {
  //     /**
  //  * // {
  // //   Header: "Name",
  // //   accessor: "name",
  // //   Cell: ({ row }) => {
  // //     const { avatar, name } = row.original;
  // //     return (
  // //       <Stack direction="row" spacing={1} alignItems="center">
  // //         <AppAvatar src={avatar} alt={name} />
  // //         <Paragraph fontSize={13} color="text.secondary">
  // //           {name}
  // //         </Paragraph>
  // //       </Stack>
  // //     );
  // //   },
  // // },
  // // {
  // //   Header: "Email",As I’ve said above, we are going to stop and start the React logo spinning. This means we are going to need two actions as follows:
  // //   accessor: "email",
  // //   Cell: ({ value }) => <Small color="text.secondary">{value}</Small>,
  // // },
  // // {
  // //   Header: "Date",
  // //   accessor: "date",
  // //   Cell: ({ value }) => (
  // //     <Small color="text.secondary">{format(value, "MMM dd, yyyy")}</Small>
  // //   ),
  // // },
  // // {
  // //   Header: "Status",
  // //   accessor: "status",
  // //   Cell: ({ value }) => (
  // //     <Small
  // //       sx={{
  // //         width: 100,
  // //         fontSize: 10,
  // //         color: "#fff",
  // //         borderRadius: "4px",
  // //         textAlign: "center",
  // //         padding: ".2rem 1rem",
  // //         backgroundColor: value === "Complete" ? "success.main" : "error.main",
  // //       }}
  // //     >
  // //       {value}
  // //     </Small>
  // //   ),
  // // },
  // // {
  // //   Header: "Edit",
  // //   accessor: "edit",
  // //   Cell: ({ value }) => (
  // //     <IconButton
  // //       sx={{
  // //         padding: 0,
  // //       }}
  // //     >
  // //       <MoreHorizontal
  // //         sx={{
  // //           color: "text.disabled",
  // //         }}
  // //       />
  // //     </IconButton>
  // //   ),
  // // },
  //  */
  // }
];
export default InvoiceColumnShape;
