import axios from "../utils/axios";

const crptoRateService = {};

crptoRateService.getCryptoRate = function () {
  return axios({
    url: `/crypto-rate`,
    method: "GET",
  });
};

export default crptoRateService;
