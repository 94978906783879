import axios from "../utils/axios";
const PaymentFeeStructure = {};

PaymentFeeStructure.SaveFeeStructure = function (data, platform) {
  return axios({
    url: `/fee-structure?platform=${platform}`,
    method: "POST",
    data,
  });
};
PaymentFeeStructure.getFeeStructure = function (platform) {
  return axios({
    url: `/fee-structure/${platform}`,
    method: "GET",
  });
};
export default PaymentFeeStructure;
