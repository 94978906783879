import axios from "../utils/axios";

const Webhooklog = {};

Webhooklog.getWebhooklog = function ({ page, limit, tabValue }) {
  return axios({
    url: `/webhook-log?page=${page}&limit=${limit}&type=${tabValue}`,
    method: "GET",
  });
};

export default Webhooklog;
