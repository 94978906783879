import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  styled,
  Table,
  TableRow,
  useTheme,
} from "@mui/material";
import { Button } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import FlexBetween from "components/flexbox/FlexBetween";
import { H2, H5, Paragraph, Small } from "components/Typography";
import IconWrapper from "components/IconWrapper";
import GroupSenior from "icons/GroupSenior";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import AppPagination from "components/AppPagination";
import Scrollbar from "components/ScrollBar";
import BlankCheckBoxIcon from "icons/BlankCheckBoxIcon";
import CheckBoxIcon from "icons/CheckBoxIcon";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import ColumnShape from "./ColumnShape";
import HeadingArea from "page-sections/user-list/heading-area";
import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import { connect } from "react-redux";
import TradeServices from "services/TradeService";
import { LoadingButton } from "@mui/lab";
import OfferService from "services/OffersService";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { getUsers } from "redux/actions/user";
import NoResultFound from "components/shared/NoResultFound";
import ViewOfferModal from "./ViewOfferModal";
import { arrayIsEmpty } from "utils/utils";
import Platform from "components/shared/Platform";
import PaxfulAccountsService from "services/PaxfulAccountsService";
import SearchInput from "components/input-fields/SearchInput";
import Invoice from "icons/duotone/Invoice";
import EditOffer from "./EditOffer";
// import { error } from "console";
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 13,
  fontWeight: 500,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const SelectCheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  useEffect(() => {
    if (resolvedRef) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);
  return (
    <Checkbox
      {...rest}
      disableRipple
      ref={resolvedRef}
      icon={<BlankCheckBoxIcon fontSize="small" color="disabled" />}
      checkedIcon={<CheckBoxIcon fontSize="small" color="primary" />}
    />
  );
});
const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

// styled components
const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const Offers = ({ getOffer }) => {
  const theme = useTheme();

  const { userdata, allOffers, setoffers, OfferCount } =
    useContext(UserContext);
  const [account, setAccount] = useState("active");
  const [offerType, setOfferType] = useState("active");

  const [userAccounts, setUserAccounts] = useState([]);
  const [option, setOption] = useState("all");

  const [isLoading, setisLoading] = useState(false);

  const handleSwitchChange = async (id, isActive) => {
    const response = await OfferService.getOfferToggle(id, {
      isActive: isActive,
    });
    if (response.status) {
      setoffers((prevOffers) =>
        prevOffers.map((offer) =>
          offer._id === id ? { ...offer, is_active: isActive } : offer
        )
      );
    }
  };
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleEditModal = (offer) => {
    setOpenEditModal(true);
    setViewOffer(offer);
  };

  // handleSwitchChange;
  const columns = useMemo(() => ColumnShape(handleEditModal), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: allOffers,
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  const [searchValue, setSearchValue] = useState("");

  const getUserPaxfulAccounts = async (id) => {
    setisLoading(true);
    const accounts = await PaxfulAccountsService.getUserPaxfulAccounts(
      id,
      "",
      ""
    );
    setUserAccounts(accounts.records);
    setisLoading(false);
  };

  useEffect(() => {
    getOffer(userdata._id, "all", "active", searchValue, offerType);
    getUserPaxfulAccounts(userdata._id);
  }, []);

  const reload = () => {
    setAccount("active");
    setOption("all");
    setSearchValue("");
    //  getOffer(userdata._id);
    getUserPaxfulAccounts(userdata._id);
  };

  // Save the state for open and close the model
  const [openModal, setOpenModal] = useState(false);
  const [viewOffer, setViewOffer] = useState(null);
  const navigate = useNavigate();
  const handleViewModal = (offer) => {
    setOpenModal(true);
    setViewOffer(offer);
  };

  const [pageNum, setPage] = useState(1);

  const selectHandleChange = (event) => {
    setOption(event.target.value);
    setPage(1);
    getOffer(userdata._id, event.target.value, "", searchValue, offerType);
    // gotoPage(1);
  };

  const handleTabChange = (event) => {
    setAccount(event.target.value);
    // gotoPage(1);
    setPage(1);

    getOffer(userdata._id, option, event.target.value, searchValue, offerType);
  };
  const handleTabChange3 = (event) => {
    setOfferType(event.target.value);
    // gotoPage(1);
    setPage(1);

    getOffer(userdata._id, option, account, searchValue, event.target.value);
  };

  const handleChange2 = (event, value) => {
    setPage(value);
    // gotoPage(value - 1);
  };
  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
    getOffer(userdata._id, option, account, searchValue, offerType);
    getUserPaxfulAccounts(userdata._id);
  };

  // Function to focus on pagination component

  return (
    <Box pt={2} pb={4}>
      <Card
        sx={{
          py: 2,
        }}
      >
        <FlexBetween flexWrap="wrap" gap={1} mb={2}>
          <Stack direction="row" alignItems="center">
            <IconWrapper ml={2}>
              <Invoice
                sx={{
                  color: "primary.main",
                }}
              />
            </IconWrapper>
            <H5>{`Offers (${OfferCount})`}</H5>
          </Stack>

          <StyledStack alignItems="center" direction="row" gap={2} px={2}>
            <SearchInput
              placeholder="Search"
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <FormControl sx={{ m: 1, minWidth: 180 }}>
              <Select
                sx={{
                  "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "10px !important", // Remove padding from the Select component
                    },
                }}
                value={offerType}
                // disabled={option != "all"}
                onChange={(e) => handleTabChange3(e)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <Paragraph>All Offers</Paragraph>
                </MenuItem>
                <MenuItem value={"active"}>
                  {" "}
                  <Paragraph>Active Offers</Paragraph>{" "}
                </MenuItem>
                <MenuItem value={"deleted"}>
                  {" "}
                  <Paragraph>Inactive Offers</Paragraph>{" "}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                sx={{
                  "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "10px !important", // Remove padding from the Select component
                    },
                }}
                value={account}
                disabled={option != "all"}
                onChange={(e) => handleTabChange(e)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <Paragraph>All</Paragraph>
                </MenuItem>
                <MenuItem value={"active"}>
                  {" "}
                  <Paragraph>Active</Paragraph>{" "}
                </MenuItem>
                <MenuItem value={"delete"}>
                  {" "}
                  <Paragraph>Deleted</Paragraph>{" "}
                </MenuItem>
              </Select>
            </FormControl>

            <AccountSelect
              option={option}
              selectHandleChange={selectHandleChange}
              userAccounts={userAccounts}
            />

            <LoadingButton
              onClick={() => reload()}
              variant="contained"
              loading={isLoading}
              sx={{
                flexShrink: 0,
              }}
            >
              Reload
            </LoadingButton>
          </StyledStack>
        </FlexBetween>

        <Scrollbar autoHide={false}>
          <Table
            {...getTableProps()}
            sx={{
              minWidth: 900,
            }}
          >
            <TableHead
              sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              {headerGroups.map((headerGroup, index) => (
                <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <HeadTableCell
                      key={index}
                      // {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </HeadTableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {isLoading ? (
                <StyledTableCell colSpan={8} align="center">
                  <CircularProgress />
                </StyledTableCell>
              ) : arrayIsEmpty(allOffers) ? (
                <StyledTableCell colSpan={8} align="center">
                  <NoResultFound />
                </StyledTableCell>
              ) : (
                rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      key={index}
                      // onClick={() => handleViewModal(row.original)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {allOffers.length === 0 ? (
                        <BodyTableCell
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <NoResultFound />
                        </BodyTableCell>
                      ) : (
                        row.cells.map((cell, index) => {
                          return (
                            <BodyTableCell key={index} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </BodyTableCell>
                          );
                          // if (index == 5) {
                          //   return (
                          //     <BodyTableCell
                          //       key={index}
                          //       {...cell.getCellProps()}
                          //     >
                          //       {cell.render("Cell")}
                          //     </BodyTableCell>
                          //   );
                          // } else {
                          //   return (
                          //     <BodyTableCell
                          //       key={index}
                          //       {...cell.getCellProps()}
                          //       onClick={() => handleViewModal(row.original)}
                          //     >
                          //       {cell.render("Cell")}
                          //     </BodyTableCell>
                          //   );
                          // }
                        })
                      )}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </Scrollbar>
        {/* Model Open And Close */}
        {viewOffer && (
          <ViewOfferModal
            data={viewOffer}
            open={openModal}
            onClose={() => setOpenModal(false)}
          />
        )}

        {openEditModal && viewOffer && (
          <EditOffer
            userdata={userdata}
            offer={viewOffer}
            open={openEditModal}
            onClose={() => setOpenEditModal(false)}
          />
        )}

        {/* <Stack alignItems="center" marginY={4}>
          {allOffers.length > 0 && (
            // <AppPagination
            //   // defaultPage={currentPage}
            //   page={pageNum}
            //   shape="rounded"
            //   onChange={handleChange2}
            //   // count={pageOptions.length}
            // />
          )} */}
        {/* </Stack> */}
      </Card>
    </Box>
  );
};
export default Offers;

export const AccountSelect = ({ option, selectHandleChange, userAccounts }) => {
  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 125 }}>
        <Select
          sx={{
            "& .css-vj6nng-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
              {
                padding: "10px !important", // Remove padding from the Select component
              },
          }}
          value={option}
          onChange={selectHandleChange}
          // inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={"all"}>
            <Paragraph>All</Paragraph>
          </MenuItem>
          {userAccounts &&
            userAccounts.map((account, key) => {
              return (
                <MenuItem value={account._id}>
                  {" "}
                  <FlexBox
                    sx={{
                      opacity: account.is_deleted ? "0.5" : "",
                    }}
                    key={key}
                  >
                    <Paragraph
                      sx={
                        {
                          // color: account.is_deleted ? "#ED2E6F" : "",
                          // opacity: account.is_deleted ? "0.5" : "",
                        }
                      }
                    >
                      {account.paxful_username}
                    </Paragraph>{" "}
                    <Box ml={0.5}>
                      <Platform size={20} platform={account.platform} />
                    </Box>
                  </FlexBox>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  );
};
