export const getBlockchainTxnUrl = (txn_id, blockchain) => {
  switch (blockchain) {
    case "bitcoin":
      return `https://www.blockchain.com/explorer/transactions/btc/${txn_id}`;
    case "ethereum":
      return `https://etherscan.io/tx/${txn_id}`;
    case "tron":
      return `https://tronscan.org/#/transaction/${txn_id}`;
  }
};
