import { Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import AdditionalDetails from "./AdditionalDetails";
import Hobbies from "./Hobbies";
import MyConnections from "./MyConnections";
import PaxfullAccount from "./PaxfullAccount";
import Portfolio from "./Portfolio";
import Post from "./Post";
import Skills from "./Skills";
import Summery from "./Summery";
import Teams from "./Teams";
import Trades from "./Trades";
import Wallet from "./Wallet";
import Wallet2 from "./Wallet2";
import { useContext } from "react";
import { UserContext } from "page-sections/user-list/ViewUser";
import OutsideClickHandler from "react-outside-click-handler";

const Overview = ({ tabchange, _handleChange, userdata, anotherMember }) => {
  // Get data from user context api
  // const { userdata, getUser } = useContext(UserContext);
  console.log("anotherMember", anotherMember);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [active, setActive] = useState(anotherMember);

  const [showUser, setShowUser] = useState(userdata);

  // let [showInfo1, setShowInfo1] = useState(false);

  const handleSelectedClick = (item, id) => {
    console.log("runnning", item?._id, id);
    setActive(id);
    setShowUser(item);
  };

  return (
    <Box mt={3}>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (!openModal && !openEditModal) {
            console.log("helooooooooooo");

            setActive(null);
            setShowUser(userdata);
          }
        }}
      >
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} xs={12}>
            <Stack spacing={3}>
              <PaxfullAccount />

              {userdata && (
                <Teams
                  userdata={userdata}
                  _handleChange={_handleChange}
                  handleSelectedClick={handleSelectedClick}
                  active={active}
                  anotherMember={anotherMember}
                />
              )}
              {/* 
            {userdata && (
              <Teams
                userdata={userdata}
                _handleChange={_handleChange}
                handleSelectedClick={handleSelectedClick}
              />
            )} */}
              {/* <Trades /> */}
              {/* <Summery /> */}
              {/* <Skills /> */}
              {/* <Hobbies /> */}
              {/* <Post /> */}
              {/* <Portfolio /> */}
            </Stack>
          </Grid>

          <Grid item lg={4} md={4} xs={12}>
            <Stack spacing={3}>
              {/* {<Wallet />} */}
              {userdata && userdata.wallet && <Wallet2 tabchange={tabchange} />}
              {showUser && (
                <AdditionalDetails
                  showUser={showUser}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  openEditModal={openEditModal}
                  setOpenEditModal={setOpenEditModal}
                />
              )}
              {/* <MyConnections /> */}
            </Stack>
          </Grid>
        </Grid>
      </OutsideClickHandler>
    </Box>
  );
};

export default Overview;
