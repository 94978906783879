import { Box, Button, IconButton, Stack, styled } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { H6, Paragraph, Small, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import { SERVER_BASE_URL } from "config";

const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;

export const VaColumnShape = (onCellClick) => [
  {
    Header: "DATE",
    accessor: "createdAt",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        <FormattedDateTime date={value} />
      </Paragraph>
    ),
  },
  {
    Header: "NAME",
    accessor: "name",
    Cell: ({ row }) => {
      const { name, profile_pic } = row.original;
      return (
        <>
          <FlexRowAlign justifyContent="start">
            <img
              style={{
                width: `30px`,
                height: `30px`,
                borderRadius: "50%",
              }}
              src={`${SERVER_BASE_URL}/${profile_pic?.file_path}`}
              alt="img"
            />

            <Paragraph fontSize={13} ml={2} color="text.secondary">
              {name}
            </Paragraph>
          </FlexRowAlign>
        </>
      );
    },
  },
  {
    Header: "TIMEZONE",
    accessor: "timezone",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "EXPERIENCE",
    accessor: "experience",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "COUNTRY",
    accessor: "country",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "DISCORD",
    accessor: "discord",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "ACTIONS",
    accessor: "actions",
    Cell: ({ row }) => {
      return (
        <>
          <StyledButton
            sx={{
              ml: 2,
            }}
            onClick={() => onCellClick(row.original)}
            variant="outlined"
            size="small"
          >
            Edit
          </StyledButton>
        </>
      );
    },
  },
];

export default VaColumnShape;
