import React from "react";
import moment from "moment";

import { API_BASE_URL } from "../../config";

function UserSymbolAT({
  user,
  size,
  circle,
  fs,
  fw,
  className,
  showOnline,
  noWrapper,
  team,
  profile,
  // isProfile,
}) {
  let color;
  let status;
  switch (user?.role) {
    case "admin":
      color = "info";
      break;
    case "employee":
      color = "primary";
      break;
    case "manager":
      color = "warning";
      break;
    case "trader":
      color = "success";
      break;
  }

  const _user = team ? team.find((member) => member._id == user._id) : null;

  if (_user && _user.last_active) {
    const fifteen_min_ago = moment().subtract(15, "minutes");
    const away = moment().subtract(2, "days");

    const is_online = moment(_user.last_active).isAfter(fifteen_min_ago);
    const is_away = moment(_user.last_active).isAfter(away);

    if (is_online) {
      status = "success";
    } else if (is_away) {
      status = "warning";
    } else {
      status = "secondary";
    }
  } else {
    status = "secondary";
  }

  //force va to show ionline always
  if (user?.role === "trader") {
    status = "success";
  }

  let Symbol = (
    <>
      {profile && profile.profile_pic ? (
        <img
          className={`symbol-label bg-${color} text-inverse-${color} fw-${fw} fs-${fs}`}
          src={`${API_BASE_URL}/${profile.profile_pic.file_path.md}`}
        />
      ) : (
        <>
          <span
            className={`symbol-label bg-${color} text-inverse-${color} fw-${fw} fs-${fs}`}
          >
            {user && user.first_name.slice(0, 1).toUpperCase()}
          </span>

          {showOnline && (
            <span
              className={`online-status position-absolute bg-${status} rounded-circle border border-1 h-8px w-8px `}
            ></span>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      {noWrapper ? (
        Symbol
      ) : (
        <div
          className={`symbol symbol-${size}px overflow--hidden${
            circle ? " symbol-circle" : ""
          } ${className ? className : ""} position-relative`}
        >
          {Symbol}
        </div>
      )}
    </>
  );
}

UserSymbolAT.defaultProps = {
  size: 50,
  circle: true,
  fs: "",
  fw: "bold",
  showOnline: false,
  noWrapper: false,
};

export default UserSymbolAT;
