import React, { useState } from "react";

import { handleAttachmentRead } from "./Chat";
import { getToken } from "helpers/auth";
import { API_BASE_URL } from "config";
import { FslightGallery } from "./FsligthGallery";
import { Image } from "./Image";
import { SERVER_BASE_URL } from "config";

function Attachments({ trade, onImageSelect }) {
  // const [lightboxController, setLightboxController] = useState({
  //   toggler: false,
  //   sourceIndex: 0,
  // });

  // function openLightboxOnSource(sourceIndex) {
  //   setLightboxController({
  //     toggler: !lightboxController.toggler,
  //     sourceIndex: sourceIndex,
  //   });
  // }

  // const images = [];

  return (
    <>
      {trade && trade.attachments && (
        <ul
          style={{
            display: "flex",
            listStyle: "none",
            alignItems: "center",
          }}
        >
          {trade.attachments.map(({ message, is_webhook_msg }, idx) => {
            console.log("idx", idx);
            return message.text.files.map(
              ({ file_id, full_url, type }, index) => {
                let file_path = file_id
                  ? `${API_BASE_URL}/files/s3/${file_id}`
                  : `${SERVER_BASE_URL}/${full_url}`;
                if (is_webhook_msg) {
                  //-
                }

                if (type == 1) {
                  // // Image type
                  // let image = `${file_path}.png?tempToken=${getToken()}`;
                  // if (!is_webhook_msg) {
                  //   image = `${SERVER_BASE_URL}/${full_url}?tempToken=${getToken()}`;
                  // }
                  // images.push(image);

                  return (
                    <li key={idx} style={{ padding: "5px" }}>
                      <a
                        role={"button"}
                        onClick={(e) => {
                          e.preventDefault();
                          //openLightBox(imgIndex);
                          //handleAttachmentRead(trade._id);

                          onImageSelect(file_path);
                        }}
                        //onClick={() => openLightboxOnSource(idx)}
                        // onClick={() => setToggler(!toggler)}
                        key={idx}
                        className={idx}
                      >
                        <span className="symbol symbol-35px cursor-pointer">
                          <Image
                            height={35}
                            width={35}
                            image={file_path}
                            className={""}
                            alt={"image"}
                          />
                        </span>
                      </a>
                    </li>
                  );
                }

                if (type == 3) {
                  // PDF type
                  return (
                    <li key={`${idx}-${index}`}>
                      <a
                        className="symbol symbol-35px"
                        href={`${file_path}.pdf`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="icon"
                          src="/media/svg/files/pdf.svg"
                          alt="PDF icon"
                          width={"40px"}
                        />
                      </a>
                    </li>
                  );
                }

                return null;
              }
            );
          })}
        </ul>
      )}

      {/* <FslightGallery lightboxController={lightboxController} images={images} /> */}
    </>
  );
}

export default Attachments;
