import axios from "../utils/axios";

const VAService = {};

VAService.createVa = function (data) {
  return axios({
    url: `/virtual-assistant`,
    method: "POST",
    data,
  });
};

VAService.getVA = function (page, search) {
  return axios({
    url: `/virtual-assistant?page=${page}&search=${search}`,
    method: "GET",
  });
};
VAService.updateVa = function (id, data) {
  return axios({
    url: `/virtual-assistant/${id}`,
    method: "PUT",
    data,
  });
};

VAService.getDiscordUser = function () {
  return axios({
    url: `/discord`,
    method: "GET",
  });
};

export default VAService;
