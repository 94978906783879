import { IconButton, Stack } from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { Paragraph, Small } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import { FormattedDateTime } from "components/shared/FormattedDate";
import { formatCurrency } from "utils/currencies";

const columnShape = [
  {
    Header: "NAME",
    accessor: "name",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  {
    Header: "LAST RAN",
    accessor: "last_ran",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        <FormattedDateTime date={value} />
      </Paragraph>
    ),
  },

  //   {
  //     Header: "DESCRIPTION",
  //     accessor: "description",
  //     Cell: ({ value }) => (
  //       <Paragraph fontSize={13} color="text.secondary">
  //         {value}
  //       </Paragraph>
  //     ),
  //   },
  //   {
  //     Header: "ACTIVE",
  //     accessor: "is_active",
  //     Cell: ({ value }) => (
  //       <Small
  //         sx={{
  //           width: 100,
  //           fontSize: 12,
  //           color: "#fff",
  //           borderRadius: "4px",
  //           textAlign: "center",
  //           padding: "0.1rem .7rem",
  //           backgroundColor: value == "true" ? "success.main" : "error.main",
  //           fontWeight: 500,
  //         }}
  //       >
  //         {value == "true" ? "true" : "false"}
  //       </Small>
  //     ),
  //   },
  {
    Header: "FREQUENCY",
    accessor: "frequency",
    Cell: ({ value }) => (
      <Paragraph fontSize={13} color="text.secondary">
        {value}
      </Paragraph>
    ),
  },
  //   {
  //     Header: "BALANCE",
  //     accessor: "fiat_balance",
  //     Cell: ({ value }) => (
  //       <Paragraph fontSize={13} color="text.secondary">
  //         {formatCurrency(value)}
  //       </Paragraph>
  //     ),
  //   },
];
export default columnShape;
