import moment from "moment";
import React, { useState, useEffect } from "react";

function TimeElapsedTimer({ start, id }) {
  const [timeElapsed, setTimeElapsed] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const elapsedTime = (new Date() - new Date(start)) / 1000;
      const duration = moment.duration(elapsedTime, "seconds");

      if (duration > 0) {
        const days = Math.floor(duration.asDays());

        const hours = Math.floor(
          (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

        const seconds = Math.floor((duration % (1000 * 60)) / 1000);

        if (days > 0) {
          setTimeElapsed(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        } else if (hours > 0) {
          setTimeElapsed(`${hours}h ${minutes}m ${seconds}s`);
        } else setTimeElapsed(`${minutes}m ${seconds}s`);
      }
    });
    return () => clearInterval(interval);
  }, [id, start]);

  return timeElapsed;
}

export default TimeElapsedTimer;
