import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { IconButton } from "@mui/material";

const ProfilePicButton = (props) => {
  return (
    <IconButton {...props}>
      <AccountCircleIcon
        sx={{
          fontSize: 17,
        }}
      />
    </IconButton>
  );
};

export default ProfilePicButton;
