var { EventEmitter } = require("fbemitter");
const emitter = new EventEmitter();

export const emitToSocket = (event, data = null) => {
  emitter.emit("socket:emit", {
    event,
    data,
  });
};

export default emitter;
