import { GET_PAXFUL_ACCOUNTS } from "redux/constants";

export default function reducer(
  state = {
    paxfulAccounts: [],
  },
  action
) {
  switch (action.type) {
    case GET_PAXFUL_ACCOUNTS:
      return { ...state, paxfulAccounts: action.payload };
  }

  return state;
}
