import { SvgIcon } from "@mui/material";

const ShoppingBagIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 15 21" {...props}>
      <path
        d="M7.5 0.630859C6.40441 0.630859 5.35369 1.05229 4.57899 1.80243C3.80429 2.55258 3.36907 3.56999 3.36907 4.63086V5.29753H2.79763C2.218 5.29694 1.66047 5.51284 1.2411 5.90028C0.82174 6.28773 0.572857 6.81686 0.546269 7.37753L0.00236299 18.3442C-0.0115799 18.6391 0.0362889 18.9337 0.143084 19.2102C0.249879 19.4867 0.413388 19.7394 0.623757 19.9531C0.834125 20.1667 1.087 20.3369 1.36713 20.4534C1.64726 20.5699 1.94885 20.6303 2.25372 20.6309H12.7463C13.0512 20.6303 13.3527 20.5699 13.6329 20.4534C13.913 20.3369 14.1659 20.1667 14.3762 19.9531C14.5866 19.7394 14.7501 19.4867 14.8569 19.2102C14.9637 18.9337 15.0116 18.6391 14.9976 18.3442L14.4537 7.37753C14.4271 6.81686 14.1783 6.28773 13.7589 5.90028C13.3395 5.51284 12.782 5.29694 12.2024 5.29753H11.6309V4.63086C11.6309 3.56999 11.1957 2.55258 10.421 1.80243C9.64631 1.05229 8.59559 0.630859 7.5 0.630859ZM4.74605 4.63086C4.74605 3.92362 5.03619 3.24534 5.55266 2.74524C6.06913 2.24514 6.76961 1.96419 7.5 1.96419C8.23039 1.96419 8.93087 2.24514 9.44734 2.74524C9.96381 3.24534 10.254 3.92362 10.254 4.63086V5.29753H4.74605V4.63086ZM13.0768 7.43753L13.6207 18.4109C13.625 18.5255 13.6059 18.6399 13.5646 18.7474C13.5232 18.8549 13.4604 18.9535 13.3797 19.0375C13.2973 19.1195 13.1988 19.1847 13.09 19.2293C12.9813 19.274 12.8644 19.2972 12.7463 19.2975H2.25372C2.13559 19.2972 2.01872 19.274 1.90996 19.2293C1.8012 19.1847 1.70273 19.1195 1.62031 19.0375C1.5396 18.9535 1.47678 18.8549 1.43543 18.7474C1.39408 18.6399 1.37502 18.5255 1.37934 18.4109L1.92325 7.43753C1.93387 7.21998 2.03066 7.01477 2.1935 6.86454C2.35634 6.71431 2.57271 6.63062 2.79763 6.63086H12.2024C12.4273 6.63062 12.6437 6.71431 12.8065 6.86454C12.9693 7.01477 13.0661 7.21998 13.0768 7.43753Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ShoppingBagIcon;
