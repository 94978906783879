import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  styled,
  Select,
  MenuItem,
  Box,
  InputAdornment,
} from "@mui/material";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import Scrollbar from "components/ScrollBar";
import { H2, H6, Paragraph, Small } from "components/Typography";
import { useFormik } from "formik";
import * as Yup from "yup"; // component props interface
// import PhoneInput from "react-phone-number-input";

import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import CustomPhoneNumber from "./PhoneNumber";

// import { saveUsers } from "../../../redux/actions/user";
import { connect } from "react-redux";
import UserService from "services/UserService";
import { LoadingButton } from "@mui/lab";
import { Tabs, Tab } from "@mui/material";

import { forwardRef } from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import VendorInviteService from "services/VendorInviteService";
import { CopyAll } from "@mui/icons-material";
import { copyTextToClipboard, validateEmail } from "utils/utils";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)`
  padding: 4,
  minWidth: 60,
  marginRight: 3,
`;

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 650,
  minWidth: 200,
  [theme.breakpoints.down(325)]: {
    maxWidth: "100%",
  },
}));

const CustomTab = styled(Tab)`
  padding: 0px;
`;

const InviteUserModal = ({ open, onClose, onSuccess }, props) => {
  const navigate = useNavigate();
  const [inviteVendorLink, setInviteVendorLink] = useState({
    link: null,
    title: "",
  });
  const generateVendorLink = async (title) => {
    if (title && title != "") {
      let data = await VendorInviteService.InviteVendor(title);
      setInviteVendorLink((pv) => ({ ...pv, link: data.data.shortlink }));
    }
  };

  const generateVendorInviteLink = async () => {
    await generateVendorLink(inviteVendorLink.title);
    // if (resp && resp.status) {
    //   navigate("/dashboard/pending-invitations", {
    //     state: dataToSend,
    //   });
    // }
  };

  const [isLoading, setIsLoading] = useState(false);
  // const [Role, setRole] = useState(null);
  useEffect(() => {
    resetForm();
    setInviteVendorLink({ link: null, title: "" });
  }, [open]);

  const initialValues = {
    email: "",
  };

  const validations = {
    email: Yup.string().required("Please enter a valid Email or Phone number"),
  };
  const dataToSend = {
    call_again: true,
  };

  const fieldValidationSchema = Yup.object().shape(validations);
  const [error, setError] = useState(null);
  const [_value, _setValue] = useState(null);
  const [value, setValue] = useState(0);

  const {
    setValues,
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues,
    // validationSchema: fieldValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      // console.log("values", values);
      setIsLoading(true);
      let resp;
      if (_value && _value !== "") {
        // console.log("mobile_number");
        values.email = _value;
        if (_value.length >= 10) {
          console.log("_value", _value);

          resp = await UserService.createUser(values);
        } else {
          console.log("_value", _value);
          setError((pv) => ({ ...pv, phone: true }));
          console.log("error");
        }
      } else if (values?.email !== "") {
        // console.log("email");
        if (validateEmail(values.email)) {
          resp = await UserService.createUser(values);
        } else {
          setError((pv) => ({ ...pv, email: true }));
          console.log("error");
          console.log("values", values);
        }
      }
      // console.log("out");

      if (resp && resp.status === true) {
        resetForm();
        onSuccess();
        closeform();
        _setValue(null);
        navigate(`/dashboard/pending-invitations`, {
          state: dataToSend,
        });
        setError(null);
      }

      setIsLoading(false);
    },
  });

  const _handleChange = (event, newValue) => {
    setValue(newValue);
    _setValue(null);
  };

  const closeform = () => {
    setInviteVendorLink({ link: null, title: "" });
    setValue(0);
    onClose();
    setError(null);
  };

  // const [phoneNumber, setPhoneNumber] = React.useState("");

  // const handlePhoneChange = (value) => {
  //   setPhoneNumber(value);
  // };

  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <H2>Invite User</H2>

      <form onSubmit={handleSubmit}>
        <Scrollbar
          style={{
            maxHeight: 400,
          }}
        >
          <Grid item xs={10}>
            {/* <H6 mb={1}>Email or Phone Number</H6> */}
            <div>
              <Tabs
                value={value}
                onChange={_handleChange}
                indicatorColor="primary"
                textColor="primary"
                sx={{
                  padding: "10px 0px 20px 0",
                }}
              >
                <CustomTab label="Email" />
                <CustomTab label="Phone" />
                <CustomTab label="Link" />
              </Tabs>
              {/* Additional content for each tab */}

              {value === 0 && (
                <Box>
                  {" "}
                  <AppTextField
                    size="small"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(errors.email && touched.email)}
                  />
                  {error && error.email && (
                    <Paragraph
                      mt={1}
                      sx={{
                        color: "red",
                        fontSize: "10px",
                      }}
                    >
                      Enter a valid Email{" "}
                    </Paragraph>
                  )}
                </Box>
              )}
              {value === 1 && (
                <div>
                  {" "}
                  {/* <AppTextField
                      fullWidth
                      size="small"
                      name="email"
                      placeholder="Phone Number"
                      onChange={handleChange}
                      value={values.email}
                      // helperText={touched.email && errors.email}
                      // error={Boolean(errors.email && touched.email)}
                    /> */}
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="US"
                    labels={"us"}
                    onChange={(_value) => _setValue(_value)}
                    // onChange={setValues}
                    value={_value}
                    inputComponent={CustomPhoneNumber}
                  />
                  {error && error.phone && (
                    <Paragraph
                      mt={1}
                      sx={{
                        color: "red",
                        fontSize: "10px",
                      }}
                    >
                      Enter a valid Phone Number{" "}
                    </Paragraph>
                  )}
                  {/* <PhoneInput
                      international
                      // defaultCountry="US"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      inputComponent={(props) => (
                        <TextField
                          // {...props}
                          fullWidth
                          variant="outlined"
                          label="Phone Number"
                        />
                      )}
                    /> */}
                </div>
              )}
              {value === 2 && (
                <Box>
                  {/* <Paragraph>{inviteVendorLink}</Paragraph> */}
                  <Box pt={2}>
                    <AppTextField
                      name="title"
                      placeholder="title"
                      fullWidth
                      size="small"
                      onChange={(e) =>
                        setInviteVendorLink({ title: e.target.value })
                      }
                      value={inviteVendorLink.title || ""}
                    />
                  </Box>
                  {inviteVendorLink?.link && (
                    <Box pt={2}>
                      {/* <TextField
                        variant="standard"
                        fullWidth
                        size="small"
                        onChange={handleChange}
                        value={inviteVendorLink.link}
                        inputProps={{ style: { border: "none" } }} // Inline style to remove border
                      /> */}

                      <TextField
                        fullWidth
                        size="small"
                        value={inviteVendorLink.link}
                        variant="outlined"
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  copyTextToClipboard(inviteVendorLink.link)
                                }
                                // color={isCopied ? "primary" : "default"}
                              >
                                <FileCopyIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}

                  {/* {inviteVendorLink && (
                    <Paragraph pt={1} fontSize={10} color={"error.main"}>
                      This link is valid for 30 minutes only
                    </Paragraph>
                  )} */}

                  {/* {inviteVendorLink?.link && (
                    <Box mt={1}>
                      <StyledButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() =>
                          copyTextToClipboard(inviteVendorLink.link)
                        }
                      >
                        Copy
                      </StyledButton>
                    </Box>
                  )} */}
                  <Box mt={2}>
                    <StyledButton
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => generateVendorInviteLink()}
                    >
                      Generate Link
                    </StyledButton>
                  </Box>
                </Box>
              )}
            </div>
          </Grid>
        </Scrollbar>

        <FlexBox justifyContent="flex-end" gap={2} marginTop={4}>
          <Button
            sx={{
              width: "50%",
            }}
            size="small"
            variant="outlined"
            onClick={closeform}
          >
            Cancel
          </Button>
          {value !== 2 && (
            <LoadingButton
              fullWidth
              size="small"
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          )}
        </FlexBox>
      </form>
    </StyledAppModal>
  );
};

export default InviteUserModal;
