import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useEffect } from "react";
import emitter, { emit } from "./emitter";
import React from "react";

const openNotification = (message, type) => {
  emit("notification", {
    open: true,
    message,
    type,
  });
};

const notification = {
  success: (title, message) => {
    openNotification(message, "success");
  },
  error: (title, message) => {
    openNotification(message, "error");
  },
};

export default notification;

export const AppNotification = () => {
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: null,
    type: "success",
  });
  const { vertical, horizontal, open, message, type } = state;

  useEffect(() => {
    emitter.addListener("notification", ({ open, message, type }) => {
      setState({
        ...state,
        open,
        message,
        type,
      });
    });
  }, []);

  const handleClose = () => {
    setState({
      ...state,
      open: false,
      message: null,
    });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      key={vertical + horizontal}
      autoHideDuration={5000}
    >
      <MuiAlert severity={type}>{message}</MuiAlert>
    </Snackbar>
  );
};
