import moment from "moment";
import React from "react";
import Countdown from "react-countdown";
import { connect } from "react-redux";
// import { Badge } from "reactstrap";
import CustomCountdown from "./CustomCountdown";
import { Chip } from "@mui/material";

function VASchedule({ hireRequests, requestId }) {
  const schedule = hireRequests.find(
    ({ request_id }) => request_id == requestId
  );

  return (
    <>
      {schedule?.is_active && (
        <span>
          <small className="d-block text-gray-800 fs-8 fw-light">
            Shift ends in
          </small>
          <div className="va-schedule-timer text-gray-800">
            <CustomCountdown
              targetDate={moment()
                .add(schedule.shift_ends_in / 1000, "seconds")
                .toDate()}
            />
          </div>
        </span>
      )}

      {!schedule?.is_active && schedule?.shiftStarts && (
        <span>
          <small className="d-block text-gray-800 fs-8 fw-light">
            Scheduled to work in
          </small>
          <div className="va-schedule-timer">
            <CustomCountdown
              targetDate={moment()
                .add(schedule.shiftStarts / 1000, "seconds")
                .toDate()}
            />
          </div>
        </span>
      )}
      {schedule == undefined && (
        <Chip label="Completed" color="success" variant="outlined" />
      )}
    </>
  );
}

const mapStateToProps = ({ hire }) => ({
  hireRequests: hire.activeVAs,
});

export default connect(mapStateToProps, null)(VASchedule);
