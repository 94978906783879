import { VA_AUTH_TOKEN } from "../config";

export function isLoggedIn() {
  const token = localStorage.getItem(VA_AUTH_TOKEN);
  return token !== null;
}

export function setToken(token) {
  localStorage.setItem(VA_AUTH_TOKEN, token);
}

export function getToken() {
  return localStorage.getItem(VA_AUTH_TOKEN);
}

export function clearVaToken() {
  localStorage.removeItem(VA_AUTH_TOKEN);
}
