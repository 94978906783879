import axios from "../utils/axios";

let FilesServices = {};

FilesServices.uploadFile = (data, id) => {
  return axios({
    url: `/files/${id}`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
    },
    data,
  });
};

FilesServices.getFile = (id, user_id) => {
  return axios({
    url: `/files/s3/${id}/${user_id}`,
    method: "GET",
  });
};

export default FilesServices;
