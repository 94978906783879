import { UserContext } from "page-sections/user-list/ViewVa";
import Agency from "services/va/Agency";
import { Box, Button, Stack } from "@mui/material";
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5 } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import InvoiceListTable from "page-sections/invoice/list";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchByName } from "utils/utils";
import INVOICE_LIST from "__fakeData__/invoice_data"; // styled components

import { useContext } from "react";
import WalletService from "services/WalletService";
import { LoadingButton } from "@mui/lab";
import VaList from "./VaList";
import VaService from "services/VaService";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const OverViewVa = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate(); // search input
  const [isLoading, setisLoading] = useState(false);

  // ============
  //  Get VA List
  // ============
  const user = useContext(UserContext);

  const [virtualAssistances, setVirtualAssistances] = useState([]);
  const getAgencyVaList = async (id) => {
    const response = await VaService.getSingleVa(id);
    console.log("response", response);
    if (response) {
      setVirtualAssistances(response);
    }
  };
  useEffect(() => {
    getAgencyVaList(user._id);
  }, []);
  // console.log(virtualAssistances.data);
  // =========================================

  const reload = () => {
    getAgencyVaList(user._id);
  };

  return (
    <Box pt={2} pb={4}>
      <FlexBetween flexWrap="wrap" gap={1} mb={2}>
        <Stack direction="row" alignItems="center">
          <IconWrapper>
            <Invoice
              sx={{
                color: "primary.main",
              }}
            />
          </IconWrapper>
          <H5>{t("Virtual Assistants")}</H5>
        </Stack>
        <StyledStack alignItems="center" direction="row" gap={2}>
          <LoadingButton
            onClick={() => reload()}
            variant="contained"
            loading={isLoading}
            sx={{
              flexShrink: 0,
            }}
          >
            Reload
          </LoadingButton>
        </StyledStack>
      </FlexBetween>

      {virtualAssistances.data && (
        <VaList virtualAssistancesList={virtualAssistances.data} />
      )}
    </Box>
  );
};

export default OverViewVa;
