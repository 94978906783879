import axios from "../utils/axios";

const VendorInviteService = {};

VendorInviteService.InviteVendor = function (title) {
  return axios({
    url: `/invitation/vendor?title=${title}`,
    method: "POST",
  });
};
VendorInviteService.getPendingInvite = function (page, search) {
  return axios({
    url: `/invitation/pending-invitation?page=${page}&search=${search}`,
    method: "GET",
  });
};

VendorInviteService.delPendingInvitation = function (id) {
  return axios({
    url: `/invitation/pending-invitation/${id}`,
    method: "GET",
  });
};

VendorInviteService.getInvitationLog = function (page) {
  return axios({
    url: `/invitation-log/?page=${page}`,
    method: "GET",
  });
};

VendorInviteService.getInvitationHistory = function (id) {
  return axios({
    url: `/invitation-log/history/${id}`,
    method: "GET",
  });
};

VendorInviteService.deleteInvitaitonHistory = function (id) {
  return axios({
    url: `/invitation-log/revoke/${id}`,
    method: "POST",
  });
};

export default VendorInviteService;
