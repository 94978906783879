import React from "react";
// import { getCountryNameByCode } from "../../helpers/utils";

// import UserSymbol from "./UserSymbol";
import CountryFlag from "./CountryFlag";
import OnlineStatus from "./OnlineStatus";
import UserSymbol from "./UserSymbol";
import FlexBox from "components/flexbox/FlexBox";
import RoleBadge from "utils/RoleBadge";
import Timezone from "./Timezone";
import { Box } from "@mui/material";
import { Paragraph, Small } from "components/Typography";
import ComputerIcon from "@mui/icons-material/Computer";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LanguageIcon from "@mui/icons-material/Language";
import AppAvatar from "components/avatars/AppAvatar";
import { COMMON_API_BASE_URL } from "config";
import DiscordAvatar from "components/avatars/DiscordAvatar";

function SignInMethod({ user, pfp, email, discordId }) {
  return (
    <>
      <FlexBox
        sx={{
          padding: "",
          alignItems: "center",
        }}
      >
        {/* <UserSymbol user={user} size={30} fw={"semibold"} showOnline={true} /> */}
        {discordId && <DiscordAvatar discordId={discordId} width={30} />}
        {pfp && <SignInMethodPFP pfp={pfp} size={30} />}

        <Box ml={2}>
          <Paragraph color={""}>{email}</Paragraph>
          {/* {user.timezone && (
            <FlexBox className="d-flex mt-1 mb-1">
              <Box ml={1}>
                <Timezone
                  className={"timezone-tooltip-badge"}
                  timezone={user.timezone}
                  showTooltip={false}
                  id={`member-${user._id}`}
                />
              </Box>
            </FlexBox>
          )} */}
        </Box>
      </FlexBox>
    </>
  );
}

export default SignInMethod;

export function SignInMethodPFP({ pfp, size }) {
  console.log("pfp", pfp);
  return (
    <>
      <AppAvatar
        alt="Profile pic"
        src={`${pfp ? `${pfp}` : "/static/avatar/001-man.svg"}`}
        sx={{
          width: size ? size : 35,
          height: size ? size : 35,
        }}
      />
    </>
  );
}
