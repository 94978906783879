import { SvgIcon } from "@mui/material";

const Card = (props) => {
  return (
    <SvgIcon viewBox="0 0 14 12" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 40 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M22 7H2V11H22V7Z" fill="currentColor" />
        <path
          opacity="0.3"
          d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default Card;
