import axios from "../../utils/axios-va";
import { PUBLIC_REQUEST_KEY, VA_SERVER_BASE_URL } from "../../config";
let token = "";

const Agency = {};

Agency.invite = function ({ email }) {
  return axios({
    url: `${VA_SERVER_BASE_URL}/api/v1/admin/agency/invite`,
    method: "POST",
    data: {
      email,
    },
  });
};
Agency.getList = function () {
  return axios({
    url: `${VA_SERVER_BASE_URL}/api/v1/admin/agency`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "API-KEY":
        "722ed475c48e17c5eba792edd762561e003e6e736fa9342b61d9a8b4ac57de66",
    },
  });
};

Agency.getVaList = function (id) {
  return axios({
    url: `${VA_SERVER_BASE_URL}/api/v1/admin/agency/virtual-assistants/${id}`,
    method: "GET",
  });
};

export default Agency;
